import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';

import { reducer as dataReducer } from '../Data/index';

const appReducer = combineReducers({
    data: dataReducer,
});

const enhancer = compose(
/*
    applyMiddleware(thunk, logger),
*/
    applyMiddleware(thunk),

);

let store;

store = createStore(
    appReducer,
    enhancer,
);
export default store;

export const newStore = createStore(
    appReducer,
    enhancer,
);

