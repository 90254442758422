import * as ActionTypes from '../actionTypes';

export const initialState = {
    log_subscription_payment: {},
    log_subscription_payment_list: []
}
/*
*/
export const log_subscription_payment_reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.LOG_SUBSCRIPTION_PAYMENT:
            return Object.assign({}, state, {
                log_subscription_payment: action.log_subscription_payment
            });
        case ActionTypes.LOG_SUBSCRIPTION_PAYMENT_LIST:
            return Object.assign({}, state, {
                log_subscription_payment_list: action.log_subscription_payment_LIST
            });
        default:
            return state;
        }
}
