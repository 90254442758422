module.exports = {
    "translation": {
        // 공용
        "login": "Login",
        "id": "ID",
        "password": "Password",
        "franchise_mode": "Franchise Mode",
        "alarm_mode": "Alarm Mode",
        "customer_mode": "Customer Mode",

        // 로그인화면
        "password_wrong_noti": "You entered wrong password",
        "id_wrong_noti": "You entered wrong id",

        // 회원가입화면

        // 예약 공통
        "customer_phone_number" : "Phone Number",
        "customer_name" : "Name",
        "team_unit" : "teams",
        "time_unit" : "mins",
        "prefer_place" : "Preferred stores",
        "menu" : "Menu",
        "number_of_people" : "Number Of People",
        "baby_seat_amount" : "Baby seats",
        "number_of_people_unit" : "",
        "baby_seat_amount_unit" : "",
        "direct_input" : "Enter Directly",
        "confirm" : "Confirm",
        "all" : "Agree",
        "agree" : "All",

        // 가맹점모드 - 예약현황






        // 고객모드
        "instruction1" : "Please enter your phone number.",
        "instruction2" : "Notification will be sent!",
        "next" : "Next",
        "customer" : "Please enter your reservation.",
        "enter_reservation_info" : " ",
        "complete_reservation" : "Reservation complete",
        "cancel" : "Cancel",
        "reservation_completed" : "Your reservation complete.",
        "waiting_receipt" : "Waiting confirmation",
        "optional" : "Optional",
        "print_waiting_number" : "Print out",

        // 모바일웹
        "show_map" : "Show Map",
        "everyday" : "Everyday",
        "waiting" : "waiting",
        "make_reservation" : "Make a reservation",
        "enter_reservation_info_mobile" : "Please enter your reservation information",
        "no_results" : "No results found",
        "search" : "Search",
        "my_waiting_number" : "My waiting number",
        "cancel_reservation" : "Cancel reservation",
    }
}
