import React, {Component, useState} from 'react';
import { browserHistory } from 'react-router';
import {withRouter, Route, Link} from 'react-router-dom';
import {connect} from 'react-redux';
import Modal from '../../../Common/Modal';
import { MoonLoader } from 'react-spinners';
import { withTranslation } from 'react-i18next';

// Components

// Actions
import { action } from "../../../../Data";

// styles
import styleBoard from '../../../Styles/Components/Board.css'
import Swiper from 'react-id-swiper';

// Utils
import { parseUrlParameter, parsePathname, converter, dateUtil, string } from '../../../../Utils';
import {Pagination} from "swiper/dist/js/swiper.esm";


class SendCoupon extends Component{
    constructor(){
        super();

        this.state = {
            notificationList: [],
            currentNotification: {},
            type: 99,
            credit: 0,
            originalMessage: '',

            isLoading: true,
        }

        this.getCouponList = this.getCouponList.bind(this);

        this.handleModal = this.handleModal.bind(this);
        this.handleToggle = this.handleToggle.bind(this);
        this.handleSelectCoupon = this.handleSelectCoupon.bind(this);
        this.handleNotification = this.handleNotification.bind(this);
        this.handleSendMessage = this.handleSendMessage.bind(this);

        this.setEventType = this.setEventType.bind(this);
    }

    componentWillMount() {
        this.setState({
            customerParams: {
                fk_storeId: this.props.store.store ? this.props.store.store.storeId : null,
            },
            credit: this.props.store && this.props.store.store ? this.props.store.store.credit : 0,
        })

    }

    componentDidMount() {
        this.props.onRef(this);
        this.setEventType();
    }


    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.search !== this.props.location.search) {
            this.setEventType();
        }
        if (this.props.isOpenCouponModal) {
            this.handleModal();
            this.props.dispatch(action.board.setOpenCouponModal(false))
        }
    }

    getCouponList() {
        return new Promise((resolve, reject) => {
            this.setState({
                isLoading: true,
            }, () => {
                let params = {
                    type: this.state.type,
                    fk_storeId: this.props.store.store.storeId,
                }
                this.props.dispatch(action.store_notification.getList(params))
                    .then((res) => {
                        this.setState({
                            notificationList: res.length > 0 ? res.map((value) => {
                                value.store_notification.isChecked = false;
                                return value.store_notification
                            }) : [],
                            isLoading: false,
                        }, () => {
                            resolve(true);
                        });
                    }).catch((err) => {
                    reject(err);
                });
            })
        })
    }

    handleToggle(index) {
        let notificationList = this.state.notificationList.map((item) => {
            item.isChecked = false;
            return item;
        });

        notificationList[index].isChecked = !notificationList[index].isChecked;

        this.setState({
            notificationList: notificationList,
        })
    }

    handleSelectCoupon() {
        const selected = this.state.notificationList.length > 0 ? this.state.notificationList.filter((item) => {
            return item.isChecked;
        }) : [];
        if (selected.length === 0) {
            this.props.handleChangingStatus('쿠폰을 선택해주세요.', false);
            return;
        }
        this.setState({
            currentNotification: selected[0],
            originalMessage: (selected[0] || {}).message,
        }, () => {
            this.handleModal();
        });
    }

    handleModal() {
        this.messageModal.setState({
            isOpened: !this.messageModal.state.isOpened
        })
    }
    /*
    * value: 변경된 값
    * field: 변경된 값의 이름
    * state: 변경시킬 state
    */
    handleNotification(value, field, state) {
        let newState = {};
        newState[state] = this.state[state];

        newState[state][field] = value;

        this.setState(newState)
    }

    handleSendMessage() {
        if (this.props.selectedList.length === 0) {
            this.props.handleChangingStatus('문자를 보낼 고객을 선택해주세요.', false);
            return;
        }

        if (this.state.credit - this.props.selectedList.length < -1000) {
            this.props.handleChangingStatus('구슬이 부족합니다. 구슬을 충전해주세요.', false);
            return;
        }

        if (!this.state.currentNotification.message) {
            if (this.props.disableModal) {
                this.props.handleChangingStatus('문자 내용을 입력해주세요.', false);
            } else {
                this.handleModal();
            }
            return;
        }

        if (confirm('선택한 고객에게 문자를 전송하시겠습니까?')) {
            let credit = this.state.credit;
            const sendMessage = this.props.selectedList ? this.props.selectedList.map((value) => {
                return new Promise((resolve, reject) => {
                    let params = {
                        fk_storeId: this.props.store.store.storeId,
                        fk_storeCustomerId: value.store_customer.storeCustomerId,
                        fk_storeNotificationId: this.state.currentNotification.storeNotificationId,
                        type: this.state.type || 99,
                        message: this.state.originalMessage === this.state.currentNotification.message
                        ? null : this.state.currentNotification.message
                    }

                    this.props.dispatch(action.notification.create(params)).then((res) => {
                        //console.log(res,'!!!!!!!!!!!!')]
                        if (res.credit) {
                            credit = res.credit;
                        }
                        resolve(res);
                    })
                })
            }) : [];

            Promise.all(sendMessage).then((res) => {
                this.setState({
                    credit: credit,
                }, () => {
                    this.props.store.store.credit = this.state.credit;
                    this.props.dispatch(action.store.setStore(this.props.store));
                    //alert('문자를 전송했습니다.');
                    this.props.handleChangingStatus('문자를 전송했습니다.', true);
                });
            })
        }
    }

    setEventType() {
        const query = parseUrlParameter.parse(this.props.location.search);

        const path = parsePathname.getPathIndex(this.props.location.pathname, 3, 1);

        let type = null;

        if ((path === '/coupon' && !(query.type >= 20 && query.type < 30))
        || (path === '/notice' && !(query.type >= 30 && query.type < 40))) {
            type = this.props.defaultMessage;
        } else {
            type = Number(query.type);
        }

        this.setState({
            type: type,
        }, () => {
            if (this.props.store.store) {
                this.getCouponList();
            }
        })
    }

    render(){
        const { t, i18n } = this.props;

        // 쿠폰 선택 창 팝업
        let renderPopUpBody = () => {
            const params = {
                modules: [Pagination],
                pagination: {
                    el: '.' + styleBoard.pagination,
                    type: 'bullets',
                    clickable: true,
                    bulletClass: styleBoard.bullet,
                    bulletActiveClass: styleBoard.active,
                },
                slidesPerView: 'auto',
                slidesPerGroup: 2,
                containerClass: styleBoard.messageContainer + ' swiper-container',
            }

            let storeNotificationList = () => {
                return this.state.notificationList.map((item, index) => {
                    return (
                        <div key={item.storeNotificationId} className={styleBoard.messageSlide + (item.isChecked ? ' ' + styleBoard.active : '')} onClick={() => this.handleToggle(index)}>
                            {item.message}
                            <div className={styleBoard.byteBox}>
                                {string.getByteLength(item.message) || 0} byte
                            </div>
                        </div>
                    );
                });
            }

            return (
                <div className={styleBoard.popupComponent}>
                    <div className={styleBoard.title}>{this.props.messageTitle}</div>
                    {
                        this.state.notificationList.length > 0 ?
                            <Swiper {...params}>
                                {storeNotificationList()}
                            </Swiper> :
                            <div style={{width: '100%', height: '20px', margin: '182px 0', textAlign: 'center'}}>양식이 없습니다.</div>
                    }
                    <div className={styleBoard.selectBtn} onClick={this.handleSelectCoupon}>쿠폰선택</div>
                </div>
            )
        }

        return(
            <div className={styleBoard.right}>
                <div className={styleBoard.pointArea}>
                    { this.props.noCredit ? null :
                        <div className={styleBoard.pointBox}>
                            <img src="/Assets/img/ic-point.png"/>
                            {t('left_points')} <input type="text" value={this.state.credit} readOnly={true}/> 개
                        </div>
                    }
                </div>
                <div className={styleBoard.textArea}>
                    <div className={styleBoard.title}>
                        { this.props.titleText || t('coupon_content') }
                    </div>
                    <div className={styleBoard.others}>
                        {this.props.disableModal ? null : <div onClick={this.handleModal}>{t('select_other')}</div>}
                    </div>
                    <textarea className={styleBoard.textBox} value={this.state.currentNotification.message} readOnly={this.props.readOnly}
                              onChange={(e) => this.handleNotification(e.target.value, 'message', 'currentNotification')} />
                    <div onClick={this.props.customEventHandler || this.handleSendMessage} className={styleBoard.sendText}>
                        { this.props.btnText || t('send_text') }
                    </div>
                </div>
                    <Modal
                        onRef={(ref) => {this.messageModal = ref;}}
                        modalBody={this.state.isLoading ? null : renderPopUpBody()}
                        customBody={styleBoard.messageModal}
                        isCouponModal={this.props.isCouponModal}
                        isNoticeModal={this.props.isNoticeModal}
                    />
            </div>
        )
    }
}

export default connect((state) => {
    return {
        author: state.data.account.author,
        store: state.data.store.store,
        isOpenCouponModal: state.data.board.isOpenCouponModal
    }
})(withRouter(withTranslation()(SendCoupon)))