/**
 * @providesModule DateUtilService
 */

import moment from 'moment';
import 'moment/locale/ko';

/*
* @params  {string} fmt
* @params  {timestamp} date
* @returns {string} date
*/
export const format = ( fmt, date ) => {
    moment.locale( 'ko' );

    const fmts = {
        'llll': 'llll',
        'll': 'll',
        'LT': 'LT',
        'point': 'YYYY.MM.DD',
        'number': 'YYMMDD',
        'dash': 'YYYY-MM-DD',
        'slash': 'YYYY/MM/DD',
        'year': 'YYYY',
        'day': 'dddd',
        'month': 'MM',
        'date': 'DD',
        'time': 'HH:mm',
    }

    if (!date || !moment( date ).isValid()) {
        return '-'
    }

    if (Object.keys(fmts).indexOf(fmt) > -1) {
        return moment( date ).format(fmts[fmt]);
    } else {
        return moment( date ).format(fmt);
    }
}

export const getCurrentTimeStamp = ( date ) => {
    let tzoffset = (new Date()).getTimezoneOffset() * 60000;
    let photoDate = new Date(date);
    return new Date(photoDate.getTime() - tzoffset).toISOString();
}

// 현재 시간으로부터 몇분 지났는지
export const getTimeLeft = ( date, baseTime ) => {
    let d = baseTime ? moment(baseTime) : moment();
    let minutes = d.diff(date, 'minutes')
    if (minutes >= 60) {
        return parseInt(minutes / 60) + '시간';
    } else {
        return minutes + '분';
    }
}

export const getDiffDays = ( date ) => {
    let endAt = moment(moment(date).format('YYYY/MM/DD'), 'YYYY/MM/DD');
    let today = moment(moment().format('YYYY/MM/DD'), 'YYYY/MM/DD');

    let leftDays = 0;

    leftDays = endAt.diff(today, 'days');

    return leftDays;
}

export const getDiffSeconds = ( date ) => {
    let endAt = moment(date);
    let today = moment();

    let leftSeconds = 0;

    leftSeconds = today.diff(endAt, 'seconds');

    return leftSeconds;
}

export const getLeftDaysAhead = ( date ) => {
    let endAt = moment(date);
    let today = moment().format('YYYY-MM-DD');
    let leftDays = today.diff(endAt, 'days');

    return leftDays > 0 ? leftDays : 0;
}
export const countDays = ( date ) => {
    let createdAt = moment(date);

    return moment().diff(createdAt, 'day');
}
export const isDate = ( date ) => {
    return typeof new Date(date) === 'object' && new Date(date) instanceof Date
}
export const getMomentDate = (date) => {
    return date ? moment(date) : moment();
}

export const getSubtractedDay = (num) => {
    return moment().subtract(num, 'days')
}

/*
* str => HH:mm:ss 형태의 스트링
* */
export const getTimeStringToDate = (str) => {
    try {
        let d = new Date();
        let [hours, minutes, seconds] = str.split(':');

        d.setHours(hours); // set the hours, using implicit type coercion
        d.setMinutes(minutes); // you can pass Number or String, it doesn't really matter
        if (seconds) d.setSeconds(seconds);

        return d;
    } catch (e) {
        console.log(e);
        return e;
    }
}

export const getFromNow = (date) => {
    if (!date || !moment( date ).isValid()) {
        return '-'
    }
    return moment(date).fromNow();
}

export const toUtc = (date, fmt) => {
    if (!date || !fmt) return;

    if (fmt === 'dayOfWeek') {
        const d = new Date(date);
        console.log(moment.utc(d).weekday());
        return moment.utc(d).weekday();
    } else if (fmt === 'hour') {
        const d = new Date(moment().format('YYYY-MM-DD') + " " + date);

        return moment.utc(d).format('HH:mm');
    }

    return moment.utc(date).format(fmt);
}

// time => 시분초
// 새벽시간인가요? 새벽 시간이면 24:00:00 으로 돌려줌
export const isDayBreak = (time) => {
    const t = new Date(moment(moment().format('YYYY-MM-DD') + ' ' + time));
    console.log(t);
    console.log(moment(moment().format('YYYY-MM-DD') + ' 00:00:00'));
    console.log(t);
    return t >= new Date(moment(moment().format('YYYY-MM-DD') + ' 00:00:00')) && t <= moment(moment().format('YYYY-MM-DD') + ' 06:00:00') ? '24:00:00' : time;
}

export const parseDayBreakToMidnight = (date) => {
    const hms = moment(date).format('HH:mm:ss');
    return hms >= '00:00:00' && hms <= '06:00:00' ?
        new Date(moment(date).add(1, 'day').format('YYYY-MM-DD 00:00:00')).toISOString() : date;
}

export const toISOString = (date) => {
    return moment(date).add(-9, 'hour').format('YYYY-MM-DDTHH:mm:ss[Z]')  
}
