import * as ActionTypes from '../actionTypes';

export const initialState = {
    store_menu: {},
    store_menu_list: []
}
/*
*/
export const store_menu_reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.STORE_MENU:
            return Object.assign({}, state, {
                store_menu: action.store_menu
            });
        case ActionTypes.STORE_MENU_LIST:
            return Object.assign({}, state, {
                store_menu_list: action.store_menu_LIST
            });
        default:
            return state;
        }
}
