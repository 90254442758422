import React, {Component} from 'react';
import {withRouter, Route, Link} from 'react-router-dom';
import {connect} from 'react-redux';
import { withTranslation } from 'react-i18next';

// Components
import ReceiverList from '../../ReceiverList';
import MessageBox from '../../MessageBox';

// styles
import styleStore from '../../../../../Styles/Components/Store.css'
import styleBoard from '../../../../../Styles/Components/Board.css'
import styleTable from '../../../../../Styles/Common/Table.css'
import Swiper from 'react-id-swiper';
import { Pagination, Navigation } from 'swiper/dist/js/swiper.esm'

import { parseUrlParameter, parsePathname } from '../../../../../../Utils';
import styleReservationModal from '@/Service/Styles/Components/ReservationModal.css'


class SendCoupon extends Component{
    constructor(){
        super();

        this.state = {
            type: null,

            currentCoupon: {}, // 현재 쿠폰
            selectedCoupon: {}, // 팝업에서 선택된 쿠폰

            messageTitle: {
                20: '명함 이벤트 쿠폰',
                21: '단골고객',
            },

            selectedList: [],
        }

        this.handleAddToSelectedList = this.handleAddToSelectedList.bind(this);
        this.handleChangingStatus = this.handleChangingStatus.bind(this);
    }

    componentWillMount() {
        this.setState({
            customerParams: {
                fk_storeId: this.props.author && this.props.author.store ? this.props.author.store.storeId : null,
            },
        })
    }

    handleAddToSelectedList() {
        this.setState({
            selectedList: this.receiverList.state.selectedList
        })
    }

    handleChangingStatus(text, bool) {
        this.setState({
            isStatusChanging: true,
            alertText: text,
            isPositive: bool
        }, () => {
            setTimeout(() => {
                this.setState({ isStatusChanging: false });
            }, bool ? 1000 : 3000);
        })
    }

    render(){
        const { t, i18n } = this.props;

        return(
            <div className={styleStore.homeContainer + ' ' + styleBoard.boardContainer}>
                <div className={styleStore.homeContent}>
                    {this.state.isStatusChanging && !this.state.isPositive ? <div className={styleReservationModal.completedAlert} style={{top: '40%', backgroundColor: '#F08080'}}>{this.state.alertText}</div> : null}
                    {this.state.isStatusChanging && this.state.isPositive ? <div className={styleReservationModal.completedAlert} style={{top: '40%'}}>{this.state.alertText}</div> : null}
                    <ReceiverList
                        pageTitle={t('send_coupon')}
                        onRef={(ref) => {this.receiverList = ref;}}
                        handleAddToSelectedList={this.handleAddToSelectedList}
                        handleChangingStatus={this.handleChangingStatus}
                    />
                    <MessageBox onRef={(ref) => {this.messageBox = ref;}} defaultMessage={20}
                                disableModal={parseUrlParameter.parse(this.props.location.search).type ? false : true}
                                messageTitle={this.state.messageTitle[parseUrlParameter.parse(this.props.location.search).type]}
                                selectedList={this.state.selectedList}
                                isCouponModal={true}
                                handleChangingStatus={this.handleChangingStatus}
                    />
                </div>
            </div>
        )
    }
}

export default connect((state) => {
    return {
        author: state.data.account.author,
    }
})(withRouter(withTranslation()(SendCoupon)))