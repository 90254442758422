export default {
  url: '',
  //// ACCOUNT SESSION
  GET_AUTH_SESSION: '/account/auth/session',
  GET_AUTH_ADMIN_SESSION: '/account/auth/session/admin',
  POST_AUTH_LOGIN: '/account/auth/login',
  POST_AUTH_ADMIN_LOGIN: '/account/auth/login/admin',
  POST_AUTH_LOGOUT: '/account/auth/session/logout',

  //// ACCOUNT API || undefined
  GET_ACCOUNT_LIST: '/account/list',
  GET_ACCOUNT_COUNT: '/account/count',
  GET_ACCOUNT_DETAIL: '/account/detail/:accountId',
  POST_ACCOUNT_CREATE: '/account/create',
  POST_ACCOUNT_UPDATE_ADMIN: '/account/auth/setting/update/admin',
  DELETE_ACCOUNT_DELETE: '/account/delete',

  //// ACCOUNT_DELETE API || undefined
  GET_ACCOUNT_DELETE_LIST: '/account-delete/list',
  GET_ACCOUNT_DELETE_COUNT: '/account-delete/count',
  GET_ACCOUNT_DELETE_DETAIL: '/account-delete/detail/:accountDeleteId',
  POST_ACCOUNT_DELETE_CREATE: '/account-delete/create',
  PUT_ACCOUNT_DELETE_UPDATE: '/account-delete/update',
  DELETE_ACCOUNT_DELETE_DELETE: '/account-delete/delete',

  //// ACCOUNT_EMAIL_VERIFICATION API || undefined
  GET_ACCOUNT_EMAIL_VERIFICATION_LIST: '/account-email-verification/list',
  GET_ACCOUNT_EMAIL_VERIFICATION_COUNT: '/account-email-verification/count',
  GET_ACCOUNT_EMAIL_VERIFICATION_DETAIL: '/account-email-verification/detail/:key',
  POST_ACCOUNT_EMAIL_VERIFICATION_CREATE: '/account-email-verification/create',
  PUT_ACCOUNT_EMAIL_VERIFICATION_UPDATE: '/account-email-verification/update',
  DELETE_ACCOUNT_EMAIL_VERIFICATION_DELETE: '/account-email-verification/delete',

  //// ACCOUNT_PHONE_VERIFICATION API || undefined
  GET_ACCOUNT_PHONE_VERIFICATION_LIST: '/account-phone-verification/list',
  GET_ACCOUNT_PHONE_VERIFICATION_COUNT: '/account-phone-verification/count',
  GET_ACCOUNT_PHONE_VERIFICATION_DETAIL: '/account-phone-verification/detail/:verificationCode',
  POST_ACCOUNT_PHONE_VERIFICATION_CREATE: '/account-phone-verification/create',
  PUT_ACCOUNT_PHONE_VERIFICATION_UPDATE: '/account-phone-verification/update',
  DELETE_ACCOUNT_PHONE_VERIFICATION_DELETE: '/account-phone-verification/delete',

  //// ACCOUNT_SOCIAL API || undefined
  GET_ACCOUNT_SOCIAL_LIST: '/account-social/list',
  GET_ACCOUNT_SOCIAL_COUNT: '/account-social/count',
  GET_ACCOUNT_SOCIAL_DETAIL: '/account-social/detail/:socialId',
  POST_ACCOUNT_SOCIAL_CREATE: '/account-social/create',
  PUT_ACCOUNT_SOCIAL_UPDATE: '/account-social/update',
  DELETE_ACCOUNT_SOCIAL_DELETE: '/account-social/delete',

  //// BOARD API || undefined
  GET_BOARD_LIST: '/board/list',
  GET_BOARD_COUNT: '/board/count',
  GET_BOARD_DETAIL: '/board/detail/:boardId',
  POST_BOARD_CREATE: '/board/create',
  PUT_BOARD_UPDATE: '/board/update',
  DELETE_BOARD_DELETE: '/board/delete',

  //// COMMENT API || undefined
  GET_COMMENT_LIST: '/comment/list',
  GET_COMMENT_COUNT: '/comment/count',
  GET_COMMENT_DETAIL: '/comment/detail/:commentId',
  POST_COMMENT_CREATE: '/comment/create',
  PUT_COMMENT_UPDATE: '/comment/update',
  DELETE_COMMENT_DELETE: '/comment/delete',

  //// COMMENT_LIKE API || undefined
  GET_COMMENT_LIKE_LIST: '/comment-like/list',
  GET_COMMENT_LIKE_COUNT: '/comment-like/count',
  GET_COMMENT_LIKE_DETAIL: '/comment-like/detail/:fk_accountId',
  POST_COMMENT_LIKE_CREATE: '/comment-like/create',
  PUT_COMMENT_LIKE_UPDATE: '/comment-like/update',
  DELETE_COMMENT_LIKE_DELETE: '/comment-like/delete',

  //// LOG_PAYMENT API || 예약금이 결제된 기록이다.
  GET_LOG_PAYMENT_LIST: '/log-payment/list',
  GET_LOG_PAYMENT_EXCEL: '/log-payment/excel',
  GET_LOG_PAYMENT_COUNT: '/log-payment/count',
  POST_BILL_CANCEL: '/payment/danalPay/card/billCancel',

  //// BOARD_LIKE API || undefined
  GET_BOARD_LIKE_LIST: '/board-like/list',
  GET_BOARD_LIKE_COUNT: '/board-like/count',
  GET_BOARD_LIKE_DETAIL: '/board-like/detail/:fk_accountId',
  POST_BOARD_LIKE_CREATE: '/board-like/create',
  PUT_BOARD_LIKE_UPDATE: '/board-like/update',
  DELETE_BOARD_LIKE_DELETE: '/board-like/delete',

  //// INVOICE API || undefined
  GET_INVOICE_LIST: '/invoice/list',
  GET_INVOICE_COUNT: '/invoice/count',
  GET_INVOICE_DETAIL: '/invoice/detail/:invoiceId',
  POST_INVOICE_CREATE: '/invoice/create',
  PUT_INVOICE_UPDATE: '/invoice/update',
  DELETE_INVOICE_DELETE: '/invoice/delete',

  //// NOTIFICATION API || undefined
  GET_NOTIFICATION_LIST: '/notification/list',
  GET_NOTIFICATION_COUNT: '/notification/count',
  GET_NOTIFICATION_DETAIL: '/notification/detail/:notificationId',
  GET_NOTIFICATION_EXCEL: '/notification/excel',
  POST_NOTIFICATION_CREATE: '/notification/create',
  PUT_NOTIFICATION_UPDATE: '/notification/update',
  DELETE_NOTIFICATION_DELETE: '/notification/delete',

  //// LOG_ACCOUNT_LOGINHISTORY API || undefined
  GET_LOG_ACCOUNT_LOGINHISTORY_LIST: '/log-account-loginHistory/list',
  GET_LOG_ACCOUNT_LOGINHISTORY_COUNT: '/log-account-loginHistory/count',
  GET_LOG_ACCOUNT_LOGINHISTORY_DETAIL: '/log-account-loginHistory/detail/:notificationId',
  POST_LOG_ACCOUNT_LOGINHISTORY_CREATE: '/log-account-loginHistory/create',
  PUT_LOG_ACCOUNT_LOGINHISTORY_UPDATE: '/log-account-loginHistory/update',
  DELETE_LOG_ACCOUNT_LOGINHISTORY_DELETE: '/log-account-loginHistory/delete',

  //// ORDER_PRODUCT API || undefined
  GET_ORDER_PRODUCT_LIST: '/order-product/list',
  GET_ORDER_PRODUCT_COUNT: '/order-product/count',
  GET_ORDER_PRODUCT_DETAIL: '/order-product/detail/:fk_productId',
  POST_ORDER_PRODUCT_CREATE: '/order-product/create',
  PUT_ORDER_PRODUCT_UPDATE: '/order-product/update',
  DELETE_ORDER_PRODUCT_DELETE: '/order-product/delete',

  //// PRODUCT API || undefined
  GET_PRODUCT_LIST: '/product/list',
  GET_PRODUCT_COUNT: '/product/count',
  GET_PRODUCT_DETAIL: '/product/detail/:productId',
  POST_PRODUCT_CREATE: '/product/create',
  PUT_PRODUCT_UPDATE: '/product/update',
  DELETE_PRODUCT_DELETE: '/product/delete',

  //// ACCOUNT_ADMIN_SYSTEM API || undefined
  GET_ACCOUNT_ADMIN_SYSTEM_LIST: '/account-admin-system/list',
  GET_ACCOUNT_ADMIN_SYSTEM_COUNT: '/account-admin-system/count',
  GET_ACCOUNT_ADMIN_SYSTEM_DETAIL: '/account-admin-system/detail/:productId',
  POST_ACCOUNT_ADMIN_SYSTEM_CREATE: '/account-admin-system/create',
  PUT_ACCOUNT_ADMIN_SYSTEM_UPDATE: '/account-admin-system/update',
  DELETE_ACCOUNT_ADMIN_SYSTEM_DELETE: '/account-admin-system/delete',

  //// BRAND API || 브랜드
  GET_BRAND_LIST: '/brand/list',
  GET_BRAND_COUNT: '/brand/count',
  GET_BRAND_DETAIL: '/brand/detail/:brandId',
  POST_BRAND_CREATE: '/brand/create',
  PUT_BRAND_UPDATE: '/brand/update',
  DELETE_BRAND_DELETE: '/brand/delete',

  //// CUSTOMER_PREFER_PLACE API || 고객의 가맹점별 선호 매장을 저장한다
  GET_CUSTOMER_PREFER_PLACE_LIST: '/customer-prefer-place/list',
  GET_CUSTOMER_PREFER_PLACE_COUNT: '/customer-prefer-place/count',
  GET_CUSTOMER_PREFER_PLACE_DETAIL: '/customer-prefer-place/detail/:customerPreferPlaceId',
  POST_CUSTOMER_PREFER_PLACE_CREATE: '/customer-prefer-place/create',
  PUT_CUSTOMER_PREFER_PLACE_UPDATE: '/customer-prefer-place/update',
  DELETE_CUSTOMER_PREFER_PLACE_DELETE: '/customer-prefer-place/delete',

  //// LOG_STORE_ALARM_HISTORY API || 가맹점의 고객 호출 기록
  GET_LOG_STORE_ALARM_HISTORY_LIST: '/log-store-alarm-history/list',
  GET_LOG_STORE_ALARM_HISTORY_COUNT: '/log-store-alarm-history/count',
  GET_LOG_STORE_ALARM_HISTORY_DETAIL: '/log-store-alarm-history/detail/:storeAlarmHistoryId',
  POST_LOG_STORE_ALARM_HISTORY_CREATE: '/log-store-alarm-history/create',
  PUT_LOG_STORE_ALARM_HISTORY_UPDATE: '/log-store-alarm-history/update',
  DELETE_LOG_STORE_ALARM_HISTORY_DELETE: '/log-store-alarm-history/delete',
  GET_LOG_STORE_ALARM_HISTORY_NOW: '/log-store-alarm-history/now',

  //// LOG_STORE_CALL_HISTORY API || 가맹점 지점별 통화 내역
  GET_LOG_STORE_CALL_HISTORY_LIST: '/log-store-call-history/list',
  GET_LOG_STORE_CALL_HISTORY_COUNT: '/log-store-call-history/count',
  GET_LOG_STORE_CALL_HISTORY_DETAIL: '/log-store-call-history/detail/:phoneHistoryId',
  POST_LOG_STORE_CALL_HISTORY_CREATE: '/log-store-call-history/create',
  PUT_LOG_STORE_CALL_HISTORY_UPDATE: '/log-store-call-history/update',
  DELETE_LOG_STORE_CALL_HISTORY_DELETE: '/log-store-call-history/delete',

  //// LOG_STORE_RESERVATION_HISTORY API || 가맹점 지점별 예약접수 내역
  GET_LOG_STORE_RESERVATION_HISTORY_LIST: '/log-store-reservation-history/list',
  GET_LOG_STORE_RESERVATION_HISTORY_COUNT: '/log-store-reservation-history/count',
  GET_LOG_STORE_RESERVATION_HISTORY_DETAIL: '/log-store-reservation-history/detail/:phoneHistoryId',
  POST_LOG_STORE_RESERVATION_HISTORY_CREATE: '/log-store-reservation-history/create',
  PUT_LOG_STORE_RESERVATION_HISTORY_UPDATE: '/log-store-reservation-history/update',
  DELETE_LOG_STORE_RESERVATION_HISTORY_DELETE: '/log-store-reservation-history/delete',

  //// LOG_STORE_COUPON_HISTORY API || 가맹점 지점별 쿠폰
  GET_LOG_STORE_COUPON_HISTORY_LIST: '/log-store-coupon-history/list',
  GET_LOG_STORE_COUPON_HISTORY_COUNT: '/log-store-coupon-history/count',
  GET_LOG_STORE_COUPON_HISTORY_DETAIL: '/log-store-coupon-history/detail/:storeCouponHistoryId',
  POST_LOG_STORE_COUPON_HISTORY_CREATE: '/log-store-coupon-history/create',
  PUT_LOG_STORE_COUPON_HISTORY_UPDATE: '/log-store-coupon-history/update',
  DELETE_LOG_STORE_COUPON_HISTORY_DELETE: '/log-store-coupon-history/delete',

  //// SERVICE_DOCS API || 가맹점 정보
  GET_SERVICE_DOCS: '/service-docs/list',
  GET_SERVICE_DOCS_COUNT: '/service-docs/count',
  GET_SERVICE_DOCS_DETAIL: '/service-docs/detail/:serviceDocsId',
  POST_SERVICE_DOCS_CREATE: '/service-docs/create',
  PUT_SERVICE_DOCS_UPDATE: '/service-docs/update',
  DELETE_SERVICE_DOCS_DELETE: '/service-docs/delete',

  //// STORE API || 가맹점 정보
  GET_STORE_LIST: '/store/list',
  GET_STORE_COUNT: '/store/count',
  GET_STORE_DETAIL: '/store/detail/:storeId',
  GET_STORE_SALES_BY_DATE: '/store/sales/byDate',
  GET_STORE_RESERVATION_BY_DATE: '/store/reservation/byDate',
  GET_STORE_CALL_NOW: '/store/call/now',
  POST_STORE_CREATE: '/store/create',
  POST_TEST_CALL: '/store/call/trigger',
  PUT_STORE_UPDATE: '/store/update',
  DELETE_STORE_DELETE: '/store/delete',

  //// STORE_CUSTOMER API || 가맹점별 고객의 정보
  GET_STORE_CUSTOMER_LIST: '/store-customer/list',
  GET_STORE_CUSTOMER_COUNT: '/store-customer/count',
  GET_STORE_CUSTOMER_DETAIL: '/store-customer/detail/:storeCustomerId',
  GET_STORE_CUSTOMER_EXCEL: '/store-customer/excel',
  POST_STORE_CUSTOMER_CREATE: '/store-customer/create',
  POST_STORE_CUSTOMER_EXCEL_UPLOAD: '/store-customer/excel/upload',
  PUT_STORE_CUSTOMER_UPDATE: '/store-customer/update',
  DELETE_STORE_CUSTOMER_DELETE: '/store-customer/delete',

  //// LOG_CREDIT API || 구슬이 충전/차감/사용된 기록이다.
  GET_LOG_CREDIT_LIST: '/log-credit/list',
  GET_LOG_CREDIT_COUNT: '/log-credit/count',
  POST_LOG_CREDIT_CREATE: '/log-credit/create',

  //// STORE_CUSTOMER_INFO API || 가맹점 고객의 고객 정보 (예약현황_예약추가 화면에서 고객 정보라 써있는 부분, 제플린 v2_0_store_예약현황_예약추가 참조)
  GET_STORE_CUSTOMER_INFO_LIST: '/store-customer-info/list',
  GET_STORE_CUSTOMER_INFO_COUNT: '/store-customer-info/count',
  GET_STORE_CUSTOMER_INFO_DETAIL: '/store-customer-info/detail/:storeCustomerInfoId',
  POST_STORE_CUSTOMER_INFO_CREATE: '/store-customer-info/create',
  PUT_STORE_CUSTOMER_INFO_UPDATE: '/store-customer-info/update',
  DELETE_STORE_CUSTOMER_INFO_DELETE: '/store-customer-info/delete',

  //// STORE_HISTORY API || 가맹점 이력
  GET_STORE_HISTORY_LIST: '/store-history/list',
  GET_STORE_HISTORY_COUNT: '/store-history/count',
  GET_STORE_HISTORY_DETAIL: '/store-history/detail/:storeHistoryId',
  POST_STORE_HISTORY_CREATE: '/store-history/create',
  PUT_STORE_HISTORY_UPDATE: '/store-history/update',
  DELETE_STORE_HISTORY_DELETE: '/store-history/delete',

  //// STORE_MEMO API || 가맹점 지점별 예약메모
  GET_STORE_MEMO_LIST: '/store-memo/list',
  GET_STORE_MEMO_COUNT: '/store-memo/count',
  GET_STORE_MEMO_DETAIL: '/store-memo/detail/:storeMemoId',
  POST_STORE_MEMO_CREATE: '/store-memo/create',
  PUT_STORE_MEMO_UPDATE: '/store-memo/update',
  DELETE_STORE_MEMO_DELETE: '/store-memo/delete',

  //// STORE_PHONE_NUMBER API || 가맹점의 전화번호
  GET_STORE_PHONE_NUMBER_LIST: '/store-phone-number/list',
  GET_STORE_PHONE_NUMBER_COUNT: '/store-phone-number/count',
  GET_STORE_PHONE_NUMBER_DETAIL: '/store-phone-number/detail/:fk_storeId',
  POST_STORE_PHONE_NUMBER_CREATE: '/store-phone-number/create',
  PUT_STORE_PHONE_NUMBER_UPDATE: '/store-phone-number/update',
  DELETE_STORE_PHONE_NUMBER_DELETE: '/store-phone-number/delete',

  //// STORE_PLACE API || 가맹점 지점별 매장 (ex. 본관, 신관 등)
  GET_STORE_PLACE_LIST: '/store-place/list',
  GET_STORE_PLACE_COUNT: '/store-place/count',
  GET_STORE_PLACE_DETAIL: '/store-place/detail/:storePlaceId',
  POST_STORE_PLACE_CREATE: '/store-place/create',
  PUT_STORE_PLACE_UPDATE: '/store-place/update',
  DELETE_STORE_PLACE_DELETE: '/store-place/delete',

  //// STORE_MENU API || 가맹점 지점별 메뉴
  GET_STORE_MENU_LIST: '/store-menu/list',
  GET_STORE_MENU_COUNT: '/store-menu/count',
  GET_STORE_MENU_DETAIL: '/store-menu/detail/:storeMenuId',
  POST_STORE_MENU_CREATE: '/store-menu/create',
  PUT_STORE_MENU_UPDATE: '/store-menu/update',
  DELETE_STORE_MENU_DELETE: '/store-menu/delete',

  //// STORE_PHONE_NUMBER API || 가맹점의 전화번호
  GET_STORE_PHONE_NUMBER_LIST: '/store-phone-number/list',
  GET_STORE_PHONE_NUMBER_COUNT: '/store-phone-number/count',
  GET_STORE_PHONE_NUMBER_DETAIL: '/store-phone-number/detail/:fk_storeId',
  POST_STORE_PHONE_NUMBER_CREATE: '/store-phone-number/create',
  PUT_STORE_PHONE_NUMBER_UPDATE: '/store-phone-number/update',
  DELETE_STORE_PHONE_NUMBER_DELETE: '/store-phone-number/delete',

  //// STORE_NOTIFICATION API || 가맹점 지점별 문자 내용
  GET_STORE_NOTIFICATION_LIST: '/store-notification/list',
  GET_STORE_NOTIFICATION_COUNT: '/store-notification/count',
  GET_STORE_NOTIFICATION_DETAIL: '/store-notification/detail/:storeNotificationId',
  POST_STORE_NOTIFICATION_CREATE: '/store-notification/create',
  PUT_STORE_NOTIFICATION_UPDATE: '/store-notification/update',
  DELETE_STORE_NOTIFICATION_DELETE: '/store-notification/delete',

  //// STORE_RESERVATION API || 예약 정보
  GET_STORE_RESERVATION_LIST: '/store-reservation/list',
  GET_STORE_RESERVATION_COUNT: '/store-reservation/count',
  GET_STORE_RESERVATION_DETAIL: '/store-reservation/detail/:storeReservationId',
  GET_STORE_RESERVATION_TOTAL_BY_DATE: '/store-reservation/total/byDate',
  GET_STORE_RESERVATION_EXCEL: '/store-reservation/excel',
  POST_STORE_RESERVATION_CREATE: '/store-reservation/create',
  PUT_STORE_RESERVATION_UPDATE: '/store-reservation/update',
  PUT_STORE_RESERVATION_UPDATE_GROUPNUM: '/store-reservation/update/groupNum',
  DELETE_STORE_RESERVATION_DELETE: '/store-reservation/delete',

  //// STORE_RESERVATION_MEMO API || 예약에서 예약 메모 정보
  GET_STORE_RESERVATION_MEMO_LIST: '/store-reservation-memo/list',
  GET_STORE_RESERVATION_MEMO_COUNT: '/store-reservation-memo/count',
  GET_STORE_RESERVATION_MEMO_DETAIL: '/store-reservation-memo/detail/:storeReservationMemoId',
  POST_STORE_RESERVATION_MEMO_CREATE: '/store-reservation-memo/create',
  PUT_STORE_RESERVATION_MEMO_UPDATE: '/store-reservation-memo/update',
  DELETE_STORE_RESERVATION_MEMO_DELETE: '/store-reservation-memo/delete',

  //// STORE_PLACE_FLOOR API || 가맹점 지점별 층 (본관 1층, 신관 2층 등)
  GET_STORE_PLACE_FLOOR_LIST: '/store-place-floor/list',
  GET_STORE_PLACE_FLOOR_COUNT: '/store-place-floor/count',
  GET_STORE_PLACE_FLOOR_DETAIL: '/store-place-floor/detail/:storePlaceFloorId',
  POST_STORE_PLACE_FLOOR_CREATE: '/store-place-floor/create',
  PUT_STORE_PLACE_FLOOR_UPDATE: '/store-place-floor/update',
  DELETE_STORE_PLACE_FLOOR_DELETE: '/store-place-floor/delete',

  //// STORE_RESERVATION_MENU API || 예약한 메뉴 정보
  GET_STORE_RESERVATION_MENU_LIST: '/store-reservation-menu/list',
  GET_STORE_RESERVATION_MENU_COUNT: '/store-reservation-menu/count',
  GET_STORE_RESERVATION_MENU_DETAIL: '/store-reservation-menu/detail/:storeReservationMenuId',
  POST_STORE_RESERVATION_MENU_CREATE: '/store-reservation-menu/create',
  PUT_STORE_RESERVATION_MENU_UPDATE: '/store-reservation-menu/update',
  DELETE_STORE_RESERVATION_MENU_DELETE: '/store-reservation-menu/delete',

  //// STORE_RESERVATION_TABLE API || 예약한 테이블 정보
  GET_STORE_RESERVATION_TABLE_LIST: '/store-reservation-table/list',
  GET_STORE_RESERVATION_TABLE_COUNT: '/store-reservation-table/count',
  GET_STORE_RESERVATION_TABLE_DETAIL: '/store-reservation-table/detail/:storeReservationTableId',
  POST_STORE_RESERVATION_TABLE_CREATE: '/store-reservation-table/create',
  PUT_STORE_RESERVATION_TABLE_UPDATE: '/store-reservation-table/update',
  DELETE_STORE_RESERVATION_TABLE_DELETE: '/store-reservation-table/delete',

  //// STORE_STAFF API || 가맹점 지점 직원 리스트
  GET_STORE_STAFF_LIST: '/store-staff/list',
  GET_STORE_STAFF_COUNT: '/store-staff/count',
  GET_STORE_STAFF_DETAIL: '/store-staff/detail/:storeStaffId',
  POST_STORE_STAFF_CREATE: '/store-staff/create',
  PUT_STORE_STAFF_UPDATE: '/store-staff/update',
  DELETE_STORE_STAFF_DELETE: '/store-staff/delete',

  //// STORE_TABLE API || 가맹점 층별 테이블
  GET_STORE_TABLE_LIST: '/store-table/list',
  GET_STORE_TABLE_COUNT: '/store-table/count',
  GET_STORE_TABLE_DETAIL: '/store-table/detail/:storeTableId',
  POST_STORE_TABLE_CREATE: '/store-table/create',
  PUT_STORE_TABLE_UPDATE: '/store-table/update',
  DELETE_STORE_TABLE_DELETE: '/store-table/delete',

  //// STORE_RESERVATION_PLACE API || 예약 정보 중 장소와 관련된 값들 & 입장 상태 || const/data/data.reservation.js 의 RESERVATION_WAITING_STATUS 참조
  GET_STORE_RESERVATION_PLACE_LIST: '/store-reservation-place/list',
  GET_STORE_RESERVATION_PLACE_COUNT: '/store-reservation-place/count',
  GET_STORE_RESERVATION_PLACE_DETAIL: '/store-reservation-place/detail/:storeReservationPlaceId',
  POST_STORE_RESERVATION_PLACE_CREATE: '/store-reservation-place/create',
  PUT_STORE_RESERVATION_PLACE_UPDATE: '/store-reservation-place/update',
  DELETE_STORE_RESERVATION_PLACE_DELETE: '/store-reservation-place/delete',

  //// MEDIA
  UPLOAD_IMAGE: '/media/image',
  UPLOAD_REMOTE_IMAGE: '/media/image',
  UPLOAD_REMOTE_FILE: '/media/files',
  UPLOAD_AUDIO: '/media/audio',

  //// CONST
  GET_CONST_RESERVATION: '/const/reservation',
  GET_CONST_NOTIFICATION: '/const/data/notification',
  GET_CONST_ADMIN_RESERVATION: '/const/data/reservation',
  GET_CONST_ADMIN_CUSTOMER: '/const/data/customer',

  //// Admin
  POST_ACCOUNT_AUTH_LOGIN_ADMIN: '/account/auth/login/admin',

  //// Admin list
  GET_ACCOUNT_USER_LIST_ADMIN: '/account/user/list/admin',

  //// service system
  GET_SERVICE_SYSTEM: '/service-system',
  PUT_SERVICE_SYSTEM: '/service-system/update',

  //// service docs
  GET_SERVICE_DOCS_LIST: '/service-docs/list',
  POST_SERVICE_DOCS: '/service-docs/create',
  UPDATE_SERVICE_DOCS: '/service-docs/update',
  DELETE_SERVICE_DOCS: '/service-docs/delete',

  //// store_authority
  UPDATE_STORE_AUTHORITY_UPDATE: '/store-authority/update',

  //// store_device
  GET_STORE_DEVICE_LIST: '/store-device/list',
  GET_STORE_DEVICE_COUNT: '/store-device/count',
  GET_STORE_DEVICE_DETAIL: '/store-device/detail/:storeDeviceId',
  POST_STORE_DEVICE_CREATE: '/store-device/create',
  UPDATE_STORE_DEVICE_UPDATE: '/store-device/update',
  DELETE_STORE_DEVICE_DELETE: '/store-device/delete',

  //// service management
  GET_SYSTEM_PAYMENT: '/system-payment/list',
  UPDATE_SYSTEM_PAYMENT: '/system-payment/update',

  //// store system
  UPDATE_STORE_SYSTEM: '/store-system/update',
  GET_STORE_SYSTEM_DETAIL: '/store-system/detail/:fk_storeId',

  //// store-file
  GET_STORE_FILE_LIST: '/store-file/list',
  GET_STORE_FILE_COUNT: '/store-file/count',
  GET_STORE_FILE_DETAIL: '/store-file/:storeFileId',
  POST_STORE_FILE_CREATE: '/store-file/create',
  UPDATE_STORE_FILE_UPDATE: '/store-file/update',
  DELETE_STORE_FILE_DELETE: '/store-file/delete',


	//// LOG_SUBSCRIPTION_PAYMENT API || 비즈 및 이용서비스의 월 결제이력입니다.
	GET_LOG_SUBSCRIPTION_PAYMENT_LIST: '/log-subscription-payment/list',
	GET_LOG_SUBSCRIPTION_PAYMENT_COUNT: '/log-subscription-payment/count',
	POST_LOG_SUBSCRIPTION_PAYMENT_CREATE: '/log-subscription-payment/create',

  /////////////////////////////////////////////////////////////////////////
  ////// basic setting ////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////

    BASIC_ADMIN_LIST: {
      TITLE: '관리자 계정정보',
      COLUMN_NAME: ['ID', 'PW'],
      COLUMN_FIELD: ['id', 'password'],
      COLUMN_SIZE: ['10%', '10%'],
      COLUMN_SIZE2: ['100px', '100px'],
    },

    BASIC_IMAGE_SETTING: {
      TITLE: '클라이언트 매니저 상호명 이미지 설정',
      COLUMN_NAME: ['LOGO', '유형', '이미지 파일', '미리보기'],
      COLUMN_FIELD1: ['Main Logo', '로그인 상단/ 모바일 검색배경', '', ''],
      COLUMN_FIELD2: ['Logo(흰배경)', '모바일/태블릿 방문대기 화면', '', ''],
      COLUMN_FIELD3: ['Logo(검은배경)', '모바일 메뉴/고객호출', '', ''],
      COLUMN_SIZE: ['20%', '25%', '35%', '20%'],
    },

    /////////////////////////////////////////////////////////////////////////
    ////// policy ////// ////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////

    KLIENT_MANAGER_POLICY_ADD_ROW: {
      TITLE: '클라이언트 매니저 정책 ADD ROW',
      COLUMN_NAME: ['문서명', '저장취소버튼', '이미지 파일', '미리보기'],
      COLUMN_FIELD: ['', '', '', ''],
      COLUMN_SIZE: ['30%', '30%', '30%', '10%'],
    },

    MANAGER_POLICY: {
      TITLE: '클라이언트 매니저 정책',
      COLUMN_NAME: ['', '문서ID', '문서명', '코드', '저장취소버튼', '수정버튼', '업로드', '미리보기'],
      COLUMN_FIELD: ['', 'serviceDocId', 'title', 'docCode', '', '', 'docUrl', ''],
      COLUMN_SIZE: ['5%', '5%', '20%', '10%', '15%', '9%', '26%', '10%'],
    },

    /////////////////////////////////////////////////////////////////////////
    ////// franchiseebasicinfo ////// ///////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////

    BRAND_LIST: {
      TITLE: '브랜드 리스트',
      COLUMN_NAME: ['', '브랜드 No.', '브랜드명', '주소', 'ID', '담당자', '담당자연락처', '파일관리','가맹점 추가', '수정'],
      COLUMN_NAME1: ['', '브랜드 No.', '브랜드명', '주소', 'ID', '담당자', '담당자연락처', '파일관리','가맹점 추가', '수정'],
      COLUMN_NAME2: ['', '', '', '', 'PW', '', '', '', ''],
      COLUMN_FIELD1: ['', 'brandNum', 'name', 'headAddress', 'ID', 'nameInCharge',  'phoneNumberInCharge', '', '', ''],
      COLUMN_FIELD2: ['', '', 'nameEn', '', 'PW', '', '', '', ''],
      COLUMN_SIZE: ['5%', '8%', '10%', '20%', '8%', '9%', '10%', '12%', '10%', '8%'],
    },
    FILE_MANAGEMENT_ADD_ROW: {
      TITLE: '파일관리 ADD ROW',
      COLUMN_NAME: ['문서명', '유형', '저장취소버튼', '이미지 파일', '미리보기'],
      COLUMN_FIELD: ['', 'type', '', '', ''],
      COLUMN_SIZE: ['30%', '10%', '25%', '23%','12%'],
    },
    STORE_FILE_MANAGEMENT: {
      TITLE: '가맹점 정적 파일관리',
      COLUMN_NAME: ['선택', '문서명', '저장취소', '업로드', '미리보기', '수정'],
      COLUMN_FIELD: ['', '', '', '', ''],
      COLUMN_FIELD1: ['', '브랜드 로고', '', '', '', ''],
      COLUMN_FIELD2: ['', '메뉴 사진', '', '', '', ''],
      COLUMN_FIELD3: ['', '가맹점 사진', '', '', '', ''],
      COLUMN_FIELD4: ['', '서비스 이용계약서', '', '', '', ''],
      COLUMN_SIZE: ['5%', '25%', '10%', '33%', '15%', '12%'],
    },

    FILE_MANAGEMENT: {
      TITLE: '파일 관리 공통',
      COLUMN_NAME: ['선택', '문서명', '유형', '저장취소', '업로드', '미리보기', '수정'],
      COLUMN_FIELD: ['', 'title', 'type', '', 'fileUrl', '', ''],
      COLUMN_SIZE: ['5%', '18%', '10%', '20%', '24%', '13%', '10%'],
    },

    STORE_LIST: {
      TITLE: '가맹점 리스트',
      COLUMN_NAME: ['', '가맹점No.', '가맹점명', '사업자번호', 'ID', '은행', '파일관리', '관리페이지', '수정'],
      COLUMN_NAME1: ['', '가맹점No.', '가맹점명', '사업자번호', 'ID', '은행', '파일관리', '관리페이지', '수정'],
      COLUMN_NAME2: ['', '', '', '', 'PW', '계좌번호', '', '', ''],
      COLUMN_FIELD1: ['', 'storeNum', 'name', 'registrationNumber', 'ID', 'bankName', '', '', ''],
      COLUMN_FIELD2: ['', '', 'nameEn', '', 'PW', 'bankAccount', '', '', ''],
      COLUMN_SIZE: ['5%', '10%', '15%', '15%', '10%', '15%', '12%', '10%', '8%'],
    },

    STORE_HISTORY: {
      TITLE: '가맹점 이력',
      COLUMN_NAME: ['선택', '가맹점명', '날짜', '내용', '수정'],
      COLUMN_FIELD1: ['', 'name', 'createdAt', 'content', ''],
      COLUMN_FIELD2: ['', 'nameEn', '', '', ''],
      COLUMN_SIZE: ['5%', '17%', '13%', '55%', '10%'],
    },

    /////////////////////////////////////////////////////////////////////////
    ////// contractmanagement ////// ////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////

    CONTRACT_MANAGEMENT: {
      TITLE: '계약관리',
      COLUMN_NAME: ['가맹점No.', '가맹점명', '약정기간', '월정액', '건당추가금액', '비즈서비스', '이용서비스', '수정'],
      COLUMN_FIELD1: ['storeNum', 'name', 'contractStartTime', 'monthFee', 'costPerReservation', 'isBizService', 'serviceType', ''],
      COLUMN_FIELD2: ['', 'nameEn', 'contractEndTime', '', '', '', '', ''],
      COLUMN_SIZE: ['10%', '10%', '24%', '11%', '10%', '10%', '10%', '14%'],
    },
    BILLING_HISTORY: {
      TITLE: '과금내역',
      COLUMN_NAME: ['가맹점No.','가맹점명', '청구연월', '상품명', '월정액', '약정건수', '추가요금(추가건)', '총 이용료'],
      COLUMN_FIELD1: ['storeNum','name', 'contractMonth', 'title', 'monthFee', 'reservationAmount', 'costPerReservation', 'totalPrice'],
      COLUMN_FIELD2: ['', 'nameEn', '', '', '', '', '', ''],
      COLUMN_SIZE: ['10%', '15%', '15%', '15%', '10%', '10%', '10%', '15%'],
    },
    TELEPHONE_RESERVATION: {
      TITLE: '전화예약',
      COLUMN_NAME: ['가맹점No', '가맹점명', '예약금', '가맹점모드', '수정'],
      COLUMN_FIELD1: ['storeNum','name', 'isReservationFee', '', ''],
      COLUMN_FIELD2: ['','nameEn', '', '', ''],
      COLUMN_SIZE: ['15%', '15%', '25%', '25%', '20%'],
    },
    VISIT_RESERVATION: {
      TITLE: '방문예약',
      COLUMN_NAME: ['가맹점No.','가맹점명', '메뉴', '고객명', '선호매장', '대기시간', '호출모니터',  '모니터메뉴', '번호표출력', '모바일대기','수정'],
      COLUMN_FIELD1: ['storeNum','name', 'isMenu', 'isName', 'isPreferPlace', 'isWaitingTime', 'isScreen', 'isScreenMenu', 'isTicket', 'isMobileReservation', ''],
      COLUMN_FIELD2: ['','nameEn', '', '', '', '', '', '', '', '', ''],
      COLUMN_SIZE: ['8%','18%', '6%', '6%', '7%', '7%', '8%', '8%', '8%', '8%', '15%'],
    },
    /////////////////////////////////////////////////////////////////////////
    ////// SMS management ///////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////
    SMS_BIZMESSAGE: {
      TITLE: '비즈메세지',
      TITLE: '문자관리_비즈메세지',
      COLUMN_NAME: ['', '가맹점No.', '가맹점', '언어',  '내용', '수정'],
      COLUMN_FIELD1: ['', 'storeNum', 'name', 'langType', 'message', ''],
      COLUMN_FIELD2: ['', '', 'nameEn', '', '', ''],
      COLUMN_SIZE: ['5%', '10%', '15%', '10%', '50%', '10%'],
    },

    SMS_TELEPHONE_RESERVATION: {
      TITLE: '문자관리_전화예약',
      COLUMN_NAME: ['', '가맹점No.', '가맹점', '언어', '발송시간', '내용', '수정'],
      COLUMN_FIELD1: ['', 'storeNum', 'name', 'langType', 'type', 'message', ''],
      COLUMN_FIELD2: ['', '', 'nameEn', '', '', '', ''],
      COLUMN_SIZE: ['5%', '10%', '15%', '5%', '18%', '37%', '10%'],
    },

    SMS_DEPOSIT_FEE_GUIDE: {
      TITLE: '문자관리_예약금안내',
      COLUMN_NAME: ['', '가맹점No.', '가맹점', '언어', '내용', '수정'],
      COLUMN_FIELD1: ['', 'storeNum', 'name', 'langType', 'message', ''],
      COLUMN_FIELD2: ['', '', 'nameEn', '', '', ''],
      COLUMN_SIZE: ['5%', '10%', '15%', '10%', '50%', '10%'],
    },

    SMS_VISIT_STANDBY: {
      TITLE: '문자관리_방문대기',
      COLUMN_NAME: ['', '가맹점No.', '가맹점', '언어', '발송시간', '내용', '수정'],
      COLUMN_FIELD1: ['', 'storeNum', 'name', 'langType', 'type', 'message', ''],
      COLUMN_FIELD2: ['', '', 'nameEn', '', '', '', ''],
      COLUMN_SIZE: ['5%', '10%', '15%', '5%', '18%', '37%', '10%'],
    },

    SMS_COUPON_NAMECARD_EVENT: {
      TITLE: '문자관리_쿠폰_명함이벤트',
      COLUMN_NAME: ['', '가맹점No.', '가맹점', '언어', '내용', '수정'],
      COLUMN_FIELD1: ['', 'storeNum', 'name', 'langType','message', ''],
      COLUMN_FIELD2: ['', '', 'nameEn', '', '', ''],
      COLUMN_SIZE: ['5%', '10%', '15%', '10%', '50%', '10%'],
    },

    SMS_COUPON_REGULAR_CUSTOMER: {
      TITLE: '문자관리_쿠폰_단골고객',
      COLUMN_NAME: ['', '가맹점No.', '가맹점', '언어', '내용', '수정'],
      COLUMN_FIELD1: ['', 'storeNum', 'name', 'langType', 'message', ''],
      COLUMN_FIELD2: ['', '', 'nameEn', '', '', ''],
      COLUMN_SIZE: ['5%', '10%', '15%', '10%', '50%', '10%'],
    },
    SMS_NOTICE_EVENT_NOTICE: {
      TITLE: '문자관리_공지_이벤트공지',
      COLUMN_NAME: ['', '가맹점No.', '가맹점', '언어', '내용', '수정'],
      COLUMN_FIELD1: ['', 'storeNum', 'name', 'langType', 'message', ''],
      COLUMN_FIELD2: ['', '', 'nameEn', '', '', ''],
      COLUMN_SIZE: ['5%', '10%', '15%', '10%', '50%', '10%'],
    },
    SMS_NOTICE_CLOSED_NOTICE: {
      TITLE: '문자관리_공지_휴무공지',
      COLUMN_NAME: ['', '가맹점No.', '가맹점', '언어', '내용', '수정'],
      COLUMN_FIELD1: ['', 'storeNum', 'name', 'langType', 'message', ''],
      COLUMN_FIELD2: ['', '', 'nameEn', '', '', ''],
      COLUMN_SIZE: ['5%', '10%', '15%', '10%', '50%', '10%'],
    },
    SMS_MOBILE: {
      TITLE: '문자관리_모바일',
      COLUMN_NAME: ['', '가맹점No.', '가맹점', '언어', '내용', ''],
      COLUMN_FIELD1: ['', 'storeNum', 'name', 'langType', 'message', ''],
      COLUMN_FIELD2: ['', '', 'nameEn', '', '', '', ''],
      COLUMN_SIZE: ['5%', '10%', '15%', '10%', '50%', '10%'],
    },

    /////////////////////////////////////////////////////////////////////////
    ////// Marble management ////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////
    MARBLE_MANAGEMENT: {
      TITLE: '구슬관리',
      COLUMN_NAME: ['', '가맹점NO.', '가맹점명', '날짜', '구슬적립', '구슬사용', '잔여구슬'],
      COLUMN_FIELD1: ['', 'storeNum', 'name', 'createdAt', 'additionAmount', 'deductionAmount', 'remainAmount'],
      COLUMN_FIELD2: ['', '', 'nameEn', '', '', '', ''],
      COLUMN_SIZE: ['5%', '15%', '15%', '15%', '15%', '20%', '15%'],
    },
    /////////////////////////////////////////////////////////////////////////
    ////// Device management ////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////
    DEVICE_MANAGEMENT: {
      TITLE: '기기관리',
      COLUMN_NAME: ['', '기기 S/N', '가맹점NO.', '가맹점명', '유형', '날짜', '수정'],
      COLUMN_NAME1: ['', '기기 S/N', '가맹점NO.', '가맹점명', '유형', '날짜', '수정'],
      COLUMN_NAME2: ['', '', '', '', '', '', ''],
      COLUMN_FIELD1: ['', 'serialNo', 'storeNum', 'name', 'type', 'sellTime', ''],
      COLUMN_FIELD2: ['', '', '', 'nameEn', '', 'rentStartTime', ''],
      COLUMN_FIELD3: ['', '', '', '', '', 'rentEndTime', ''],
      COLUMN_SIZE: ['5%', '15%', '15%', '15%', '15%', '25%', '10%'],
    },
    /////////////////////////////////////////////////////////////////////////
    ////// Query management ////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////
    QUERY_MANAGEMENT_CUSTOMER: {
      TITLE: '조회관리_고객',
      COLUMN_NAME: ['가맹점NO.', '가맹점명', '고객명', '연락처', '기타정보', '고객성향', '전화예약 횟수', '방문예약 횟수'],
      COLUMN_FIELD1: ['storeNum', 'storeName', 'name', 'phoneNumber', 'customerInfo', 'isVip', 'phoneReservationAmount', 'waitingReservationAmount'],
      COLUMN_FIELD2: ['', 'nameEn', '', '', '', '', '', ''],
      COLUMN_SIZE: ['10%', '15%', '10%', '10%', '15%', '10%', '15%', '15%'],
    },
    QUERY_MANAGEMENT_SMS: {
      TITLE: '조회관리_문자',
      COLUMN_NAME: ['가맹점NO.', '가맹점명',  '고객명', '연락처', '문자발송일시', '문자분류', '보기'],
      COLUMN_FIELD1: ['storeNum', 'storeName', 'name', 'phoneNumber', 'sendAt', 'type', 'message'],
      COLUMN_FIELD2: ['', 'nameEn', '', '', 'smsType', '', ''],
      COLUMN_SIZE: ['10%', '15%', '15%', '15%', '15%', '15%', '15%'],
    },
    QUERY_MANAGEMENT_RESERVATION: {
      TITLE: '조회관리_예약',
      COLUMN_NAME: ['가맹점NO.', '가맹점명', '고객명', '연락처', '예약방법', '예약일자', '방문결과', '내용보기'],
      COLUMN_FIELD1: ['storeNum', 'storeName', 'name', 'phoneNumber', 'reservationStatus', 'reservedAt', 'visitStatus', ''],
      COLUMN_FIELD2: ['', 'nameEn', '', '', '', '', '', ''],
      COLUMN_SIZE: ['10%', '15%', '10%', '10%', '10%', '15%', '15%', '15%'],
    },
    QUERY_MANAGEMENT_VISIT_STANDBY: {
      TITLE: '조회관리_방문대기',
      COLUMN_NAME: ['가맹점NO.', '가맹점명', '고객명', '연락처', '대기일자', '대기처리', '대기처리시간', '대기인원', '입장매장', '메뉴'],
      COLUMN_FIELD1: ['storeNum', 'storeName', 'name', 'phoneNumber', 'createdAt', 'waitingStatus', 'admitAt', 'numberOfPeople', 'admitPlaceName', 'menu'],
      COLUMN_FIELD2: ['', 'nameEn', '', '', '', '', '', '', '', ''],
      COLUMN_SIZE: ['10%', '10%', '10%', '10%', '10%', '10%', '10%', '10%', '10%', '10%'],
    },
    QUERY_MANAGEMENT_RESERVATION_FEE: {
      TITLE: '조회관리_예약금',
      COLUMN_NAME: ['가맹점NO.', '가맹점명', '예약정보', '예약금', '결제일', '가맹점지급액', '승인여부'],
      COLUMN_FIELD1: ['storeNum', 'storeName', '', 'sales', 'createdAt', 'profit', 'cancelledAt'],
      COLUMN_FIELD2: ['', 'nameEn', '', '', '', '', ''],
      COLUMN_SIZE: ['10%', '15%', '15%', '15%', '15%', '15%', '15%'],
    },
};
