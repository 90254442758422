import React, {Component} from 'react';
import {withRouter, Route} from 'react-router-dom';
import {connect} from 'react-redux';
import {Helmet} from "react-helmet";
import moment from 'moment';

// styles
import styleStore from '../../../../Styles/Components/Store.css'
import styleBoard from '../../../../Styles/Components/Board.css'
import SearchBar from '@/Service/Components/Store/Statistics/PeriodSearchBar'
import styleTable from '@/Service/Styles/Common/Table.css'
import InfiniteList from '../../../../Common/InfiniteList/index';
import { converter, dateUtil } from '@/Utils'

// Actions
import * as ActionStore from '../../../../../Data/Action/store';

import {
  PieChart, Pie, Sector, Cell, Tooltip
} from 'recharts';
import styleReservationModal from '@/Service/Styles/Components/ReservationModal.css'

const COLORS = ['#0088FE', '#00C49F'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x  = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy  + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} 	dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

//대기고객 분석
class WaitingAnalysis extends Component{
  constructor(){
    super();

    this.state = {
      waitingAnalysisConfig: {
        COLUMN_NAME: ['매출일자', '총 대기 건수', '입장', '없음' ],
        COLUMN_FIELD: ['date', 'waitingTotal', 'enterTotal', 'notComeTotal'],
        COLUMN_SIZE: ['25%', '25%', '25%', '25%'],
      },

      chartData: [],
      totalWaitingNumber: 0,
      totalEntranceMember: 0,
      totalNoShowMember: 0,
    }

    this.getStoreReservationListData = this.getStoreReservationListData.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChangingStatus = this.handleChangingStatus.bind(this);
  }

  componentWillMount() {
    this.setState({
      waitingAnalysisParams: {
        fk_storeId: this.props.store && this.props.store.store ? this.props.store.store.storeId : null,
        order: 'reservedAt',
        orderMethod: 'DESC',
        reservationStatus: [1,2],
        startDate: dateUtil.format('YYYY-MM-DD', new Date(moment().subtract(7, 'days'))),
        endDate: dateUtil.format('YYYY-MM-DD', new Date()),
      }
    })
  }

  getStoreReservationListData(list) {
    if (!list || list.length === 0) {
      return;
    }
    let checked = [];
    list.forEach(() => {
      checked.push(false);
    })
    this.setState({
      storeReservationList: list,
      checked: checked,
    }, () => {
      let entered = 0;
      let notCome = 0;
      let waitingTotal = 0;

      this.state.storeReservationList ? this.state.storeReservationList.forEach((value) => {
        entered += value.enterTotal
        notCome += value.notComeTotal
        waitingTotal += value.waitingTotal;
      }) : null;

      let chartData = [{
        name: '입장',
        value: entered
      }, {
        name: '없음',
        value: notCome
      }];

      this.setState({
        chartData: chartData,
        totalWaitingNumber: waitingTotal
      })
    })
  }

  handleSearch(params) {
    let waitingAnalysisParams =  Object.assign(this.state.waitingAnalysisParams, params)
    this.setState({
      waitingAnalysisParams: waitingAnalysisParams
    }, () => {
      this.waitingAnalysisList.reset();
    })
  }

  handleChangingStatus(text, bool) {
    this.setState({
      isStatusChanging: true,
      alertText: text,
      isPositive: bool
    }, () => {
      setTimeout(() => {
        this.setState({ isStatusChanging: false });
      }, bool ? 1000 : 3000);
    })
  }

  render(){
    const renderWaitingAnalysisListHeader = () => {
      return (
        <div className={styleTable.tableContentHeader}>
          { this.state.waitingAnalysisConfig.COLUMN_FIELD.map((field, index) => {
            let waitingAnalysisConfig = this.state.waitingAnalysisConfig;
            return (
              <div
                className={styleTable.column + ' ' + (field ? styleTable.style2 : styleTable.style3)}
                style={{width: waitingAnalysisConfig.COLUMN_SIZE[index], height: '52px', lineHeight: index == 2 || index == 3 ? '30px' : '52px'}}
                key={index}
              >
                { waitingAnalysisConfig.COLUMN_NAME[index] }
                {index == 2 || index == 3 ? <div style={{color: '#98a0ab', fontSize: '12px', lineHeight: '12px'}}>비중</div> : null}
              </div>
            )
          }) }
        </div>
      );
    }

    const renderWaitingAnalysisList = (item, index) => {
      const config = this.state.waitingAnalysisConfig;

      return (
        <div className={styleTable.tableContentItem + (item.isChecked ? ' ' + styleTable.active : '')}
             key={index}  style={{height: '52px', lineHeight: '52px'}}
        >
          <div
            className={styleTable.column}
            style={{width: config.COLUMN_SIZE[0], lineHeight: '52px'}}
          >
            {dateUtil.format('slash', item[config.COLUMN_FIELD[0]])}
          </div>
          <div
            className={styleTable.column}
            style={{width: config.COLUMN_SIZE[1], lineHeight: '52px'}}
          >
            {converter.parseListByType(item[config.COLUMN_FIELD[1]])}
          </div>
          <div
            className={styleTable.column}
            style={{width: config.COLUMN_SIZE[2], lineHeight: '30px'}}
          >
            {converter.parseListByType(item[config.COLUMN_FIELD[2]])}
            <div style={{color: '#98a0ab', fontSize: '12px', lineHeight: '12px'}}>
              {(converter.parseListByType(item[config.COLUMN_FIELD[2]])/(converter.parseListByType(item[config.COLUMN_FIELD[1]]))*100).toFixed(2)} %
            </div>
          </div>
          <div
            className={styleTable.column}
            style={{width: config.COLUMN_SIZE[3], lineHeight: '30px'}}
          >
            {converter.parseListByType(item[config.COLUMN_FIELD[3]])}
            <div style={{color: '#98a0ab', fontSize: '12px', lineHeight: '12px'}}>
              {(converter.parseListByType(item[config.COLUMN_FIELD[3]])/(converter.parseListByType(item[config.COLUMN_FIELD[1]]))*100).toFixed(2)} %
            </div>
          </div>
        </div>
      )
    }

    return(
      <div className={styleStore.homeContainer}>
        <Helmet>
          <title>대기고객 분석</title>
        </Helmet>
        <div className={styleStore.homeContent}>
          {this.state.isStatusChanging && !this.state.isPositive ? <div className={styleReservationModal.completedAlert} style={{top: '40%', backgroundColor: '#F08080'}}>{this.state.alertText}</div> : null}
          {this.state.isStatusChanging && this.state.isPositive ? <div className={styleReservationModal.completedAlert} style={{top: '40%'}}>{this.state.alertText}</div> : null}
          <div className={styleBoard.left}>
            <div className={styleBoard.top}>
              <p className={styleBoard.title}>대기 접수 현황</p>
              <SearchBar onRef={(ref) => {this.waitingSearchBar = ref;}} handleSearch={(params) => this.handleSearch(params)} handleChangingStatus={this.handleChangingStatus}/>
            </div>
            <div className={styleBoard.bottom}>
              <div className={styleTable.tableContentBox + ' ' + styleTable.style1}>
                {renderWaitingAnalysisListHeader()}
                <InfiniteList
                  onRef={(ref) => this.waitingAnalysisList = ref}
                  ListItem={renderWaitingAnalysisList}
                  Get={ActionStore.getReservationByDate}
                  GetParams={this.state.waitingAnalysisParams}
                  DataTitle={['store_reservation', 'store']}
                  GetListData={(list) => this.getStoreReservationListData(list)}
                  customTableStyle={styleTable.tableContentWrapper}
                />
              </div>
            </div>
          </div>
          <div className={styleBoard.right}>
            <div className={styleBoard.graphTitle}>대기 접수 그래프</div>
            {/*날짜 수정해주세요*/}
            <div className={styleBoard.graphDate}>{this.state.waitingAnalysisParams.startDate} ~ {this.state.waitingAnalysisParams.endDate}</div>
            <div className={styleBoard.graphBox}>
              <PieChart width={800} height={300} onMouseEnter={this.onPieEnter}>
                <Pie
                  data={this.state.chartData}
                  cx={'20%'}
                  labelLine={false}
                  label={renderCustomizedLabel}
                  outerRadius={130}
                  fill="#8884d8"
                >
                  {
                    this.state.chartData.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]}/>)
                  }
                </Pie>
                <Tooltip/>
              </PieChart>
              <div className={styleBoard.graphBottomBox}>
                <div className={styleBoard.graphBottomContent1}>총 대기 건수</div>
                <div className={styleBoard.graphBottomContent2}>{this.state.totalWaitingNumber} 건</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect((state) => {
  return {
    author: state.data.account.author,
    store: state.data.store.store,
  }
})(withRouter(WaitingAnalysis))