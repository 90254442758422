import * as ActionTypes from '../actionTypes';

export const initialState = {
    store_reservation_memo: {},
    store_reservation_memo_list: []
}
/*
*/
export const store_reservation_memo_reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.STORE_RESERVATION_MEMO:
            return Object.assign({}, state, {
                store_reservation_memo: action.store_reservation_memo
            });
        case ActionTypes.STORE_RESERVATION_MEMO_LIST:
            return Object.assign({}, state, {
                store_reservation_memo_list: action.store_reservation_memo_LIST
            });
        default:
            return state;
        }
}
