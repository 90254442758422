import React, {Component} from 'react';
import {withRouter, Route} from 'react-router-dom';
import {connect} from 'react-redux';

// Components]
import LoginComponent from '../../Components/Auth/Login'

// Styles
import style from '../../Styles/style.css';

class AuthView extends Component {
  constructor(){
    super();
  }

render() {
  return(
    <div className={style.loginContainer}>
      <Route path={"/auth/login"} exact={false} strict={false} component={LoginComponent} />
    </div>
  )
}
}

export default connect((state) => {
  return { author: state.data.account.author};
})(withRouter(AuthView))