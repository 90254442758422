import React, {Component} from 'react';
import {withRouter, Route, Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {Helmet} from "react-helmet";
import Modal from '../../../../../Common/Modal';
import Swiper from 'react-id-swiper';
import onClickOutside from "react-onclickoutside";
import moment from 'moment';

// Components
import InfiniteList from '../../../../../Common/InfiniteList';
import MessageBox from '../../MessageBox';
import SearchBar from '../../SearchBar';

// styles
import styleStore from '../../../../../Styles/Components/Store.css'
import styleBoard from '../../../../../Styles/Components/Board.css'
import styleTable from '../../../../../Styles/Common/Table.css'
import { action } from "../../../../../../Data";
import { converter, dateUtil } from "../../../../../../Utils";
import styleReservationModal from '@/Service/Styles/Components/ReservationModal.css'

class InquiryCoupon extends Component{
  constructor(){
    super();

    this.state = {
        searchQuery: '',

        notificationConfig: {
            COLUMN_NAME: ['상태', '전화번호', '이름', '발송일자', '쿠폰 내용'],
            COLUMN_FIELD: ['isUsed', 'phoneNumber', 'name', 'sendAt', 'message'],
            COLUMN_SIZE: ['15%', '15%', '15%', '15%', '40%'],
        },

        selectedNotification: {},
        btnText: '쿠폰 사용'
    }

    this.getNotificationData = this.getNotificationData.bind(this);
    this.handleSelectNotification = this.handleSelectNotification.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleUseCoupon = this.handleUseCoupon.bind(this);
    this.handleChangingStatus = this.handleChangingStatus.bind(this);
  }

  componentWillMount() {
        this.setState({
            notificationParams: {
                fk_storeId: this.props.store && this.props.store.store ? this.props.store.store.storeId : null,
                order: 'sendAt',
                orderMethod: 'DESC',
                msgResult: 1,
                dateFrom: dateUtil.format('YYYY-MM-DD', new Date(moment().subtract(7, 'days'))),
                dateTo: dateUtil.format('YYYY-MM-DD', new Date()),
                searchOption: 'customerInfo',
                type: [20,21],
            },
        });
  }

    componentDidUpdate(prevProps) {
        if (prevProps.author !== this.props.author) {
            this.setState({
                notificationParams: {
                    fk_storeId: this.props.store.store ? this.props.store.store.storeId : null,
                }
            }, () => {
                this.notificationList.reset();
            });
        }
    }

    getNotificationData(list) {
        if (!list || list.length === 0) {
            return;
        }

        this.setState({
            notificationList: list,
        })
    }

    handleSelectNotification(item, index) {
      let notiList = this.state.notificationList.map((item) => {
          item.isChecked = false;
          return item;
      });
      notiList[index].isChecked = !notiList[index].isChecked;

      let btnText = '쿠폰 사용';
      if (notiList[index].notification.isUsed) {
        btnText = '사용 취소'
      }

      this.setState({
          notificationList: notiList,
          btnText: btnText
      }, () => {
          this.messageBox.setState({
              currentNotification: item['notification'],
          });
      });
    }

    handleSearch(params) {
      let notificationParams =  Object.assign(this.state.notificationParams, params)
        console.log(notificationParams)
      this.setState({
        notificationParams: notificationParams
      }, () => {
        this.notificationList.reset();
      })
    }

    handleUseCoupon() {
        let notification = this.messageBox.state.currentNotification
        if (!notification.notificationId) {
            return this.handleChangingStatus('쿠폰을 선택해주세요.', false);
        }
        if (notification.isUsed) {
          if (confirm('사용한 쿠폰을 취소하시겠습니까?')) {
            this.props.dispatch(action.notification.update({
              notificationId: notification.notificationId,
              isUsed: 0
            })).then((res) => {
              this.messageBox.setState({
                currentNotification: res,
              }, () => {
                this.notificationList.reset();
                this.handleChangingStatus('쿠폰사용을 취소하였습니다.', true);
              });
            })
          }
          return;
        }
        if (confirm('선택한 쿠폰을 사용하시겠습니까?')) {
          this.props.dispatch(action.notification.update({
              notificationId: notification.notificationId,
              isUsed: 1
          })).then((res) => {
              this.messageBox.setState({
                  currentNotification: res,
              }, () => {
                  this.notificationList.reset();
                  this.handleChangingStatus('쿠폰을 사용했습니다.', true);
              });
          })
      }
    }

  handleChangingStatus(text, bool) {
    this.setState({
      isStatusChanging: true,
      alertText: text,
      isPositive: bool
    }, () => {
      setTimeout(() => {
        this.setState({ isStatusChanging: false });
      }, bool ? 1000 : 3000);
    })
  }

  render(){
      let renderCustomerListHeader = () => {
          return (
              <div className={styleTable.tableContentHeader}>
                  { this.state.notificationConfig.COLUMN_FIELD.map((field, index) => {
                      let notificationConfig = this.state.notificationConfig;
                      return (
                          <div
                              className={styleTable.column + ' ' + (field ? styleTable.style2 : styleTable.style3)}
                              style={{width: notificationConfig.COLUMN_SIZE[index]}}
                              key={index}
                          >
                              { notificationConfig.COLUMN_NAME[index] }
                          </div>
                      )
                  }) }
              </div>
          );
      }

      let renderCustomerList = (item, index) => {
          const config = this.state.notificationConfig;
          return (
              <div className={styleTable.tableContentItem + (item.isChecked ? ' ' + styleTable.active : '')}
                   key={index}
                   onClick={() => this.handleSelectNotification(item, index)}>
                  <div
                      className={styleTable.column}
                      style={{width: config.COLUMN_SIZE[0]}}
                  >
                      {item['notification'][config.COLUMN_FIELD[0]] ? '사용완료' : null}
                  </div>
                  <div
                      className={styleTable.column}
                      style={{width: config.COLUMN_SIZE[1]}}
                  >
                      {converter.parseListByType(item['store_customer'][config.COLUMN_FIELD[1]])}
                  </div>
                  <div
                      className={styleTable.column}
                      style={{width: config.COLUMN_SIZE[2]}}
                  >
                      {converter.parseListByType(item['store_customer'][config.COLUMN_FIELD[2]])}
                  </div>
                  <div
                      className={styleTable.column}
                      style={{width: config.COLUMN_SIZE[3]}}
                  >
                      {dateUtil.format('slash', item['notification'][config.COLUMN_FIELD[3]])}
                  </div>
                  <div
                      className={styleTable.column}
                      style={{width: config.COLUMN_SIZE[4]}}
                  >
                      {converter.parseListByType(item['notification'][config.COLUMN_FIELD[4]])}
                  </div>
              </div>
          )
      }

    return(
      <div className={styleStore.homeContainer + ' ' + styleStore.boardContainer}>
        <div className={styleStore.homeContent}>
          <Helmet>
            <title>공지 및 이벤트</title>
          </Helmet>
          {this.state.isStatusChanging && !this.state.isPositive ? <div className={styleReservationModal.completedAlert} style={{top: '40%', backgroundColor: '#F08080'}}>{this.state.alertText}</div> : null}
          {this.state.isStatusChanging && this.state.isPositive ? <div className={styleReservationModal.completedAlert} style={{top: '40%'}}>{this.state.alertText}</div> : null}
          <div className={styleBoard.left}>
              <div className={styleBoard.top}>
                  <p className={styleBoard.title}>{this.props.pageTitle || '쿠폰 사용/조회'}</p>
                  <SearchBar onRef={(ref) => {this.searchBar = ref;}} customParams={this.state.notificationParams} handleSearch={(params) => this.handleSearch(params)} handleChangingStatus={this.handleChangingStatus}/>
              </div>
              <div className={styleBoard.bottom}>
                  <div className={styleTable.tableContentBox + ' ' + styleTable.style1}>
                      {renderCustomerListHeader()}
                      <InfiniteList
                          onRef={(ref) => this.notificationList = ref}
                          ListItem={renderCustomerList}
                          Get={action.notification.getList}
                          GetParams={this.state.notificationParams}
                          GetListData={(list) => this.getNotificationData(list)}
                          customTableStyle={styleTable.tableContentWrapper}
                      />
                  </div>
              </div>
          </div>
          <MessageBox onRef={(ref) => {this.messageBox = ref;}}
                      disableModal={true}
                      titleText={'쿠폰 내용'}
                      btnText={this.state.btnText}
                      noCredit={true}
                      readOnly={true}
                      customEventHandler={this.handleUseCoupon}
          />
        </div>
      </div>
    )
  }
}

export default connect((state) => {
    return {
        author: state.data.account.author,
        store: state.data.store.store,
    }
})(withRouter(InquiryCoupon))