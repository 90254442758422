import React, {Component} from 'react';
import {withRouter, Route} from 'react-router-dom';
import {connect} from 'react-redux';
import {Helmet} from "react-helmet";

// styles
import styleStore from '../../../Styles/Components/Store.css'

// components
import CustomerInquiryComponent from './CustomerInquiry'
import ExtraRecordsComponent from './ExtraRecords'
import CallRecordsComponent from './ExtraRecords/CallRecords'
import ReservationRecordsComponent from './ExtraRecords/ReservationRecords'

import SalesAnalysisComponent from './SalesAnalysis'
import ReservationSalesComponent from './SalesAnalysis/ReservationSales'
import CustomerSalesComponent from './SalesAnalysis/CustomerSales'

import WaitingAnalysisComponent from './WaitingAnalysis'

class StatisticsView extends Component{
  constructor(){
    super();
  }

  render(){
    return(
      <div className={styleStore.homeContainer}>
        <Helmet>
          <title>통계 현황</title>
        </Helmet>
        <Route path={'/store/statistics/customerInquiry'} exact={false} strict={false} component={CustomerInquiryComponent}/>
        <Route path={'/store/statistics/extraRecords'} exact={false} strict={false} component={ExtraRecordsComponent}/>
        <Route path={'/store/statistics/callRecords'} exact={false} strict={false} component={CallRecordsComponent}/>
        <Route path={'/store/statistics/reservationRecords'} exact={false} strict={false} component={ReservationRecordsComponent}/>
        <Route path={'/store/statistics/salesAnalysis'} exact={false} strict={false} component={SalesAnalysisComponent}/>
        <Route path={'/store/statistics/reservationSales'} exact={false} strict={false} component={ReservationSalesComponent}/>
        <Route path={'/store/statistics/customerSales'} exact={false} strict={false} component={CustomerSalesComponent}/>
        <Route path={'/store/statistics/waitingAnalysis'} exact={false} strict={false} component={WaitingAnalysisComponent}/>
      </div>
    )
  }
}
export default connect()(withRouter(StatisticsView))