import React, {Component} from 'react';
import {withRouter, Route, Link} from 'react-router-dom';
import {connect} from 'react-redux';

// Components
import CouponInquiryComponent from './Coupon/Inquiry'
import CouponSendComponent from './Coupon/Send'
import EtcComponent from './Etc'
import NoticeComponent from './Notice'

// Actions
import { action } from "../../../../Data";

// styles
import styleStore from '../../../Styles/Components/Store.css'

class BoardComponent extends Component{
  constructor(){
    super();

    this.state = {
        isLoading: true,
    }
  }

  componentWillMount() {
      console.log(this.props.store);
      this.props.dispatch(action.board.setOpenCouponModal(false))

      if (!this.props.store.store) return;

      // 크레딧 정보 업데이트를 위해 한번 더 받아온다.
      this.props.dispatch(action.store.getById({ storeId: this.props.store.store.storeId }))
          .then((res) => {
              if (res.store) {
                  this.props.dispatch(action.store.setStore(res));
              }

              this.setState({ isLoading: false });
          })
          .catch((err) => {
              console.log('매장 정보 에러', err);
              this.setState({ isLoading: false });
          })
  }

  render(){
    return(
      <div className={styleStore.homeContainer}>
          { this.state.isLoading ? null : <Route path={'/store/board/coupon/inquiry'} exact={false} strict={false} component={CouponInquiryComponent}/> }
          { this.state.isLoading ? null : <Route path={'/store/board/coupon/send'} exact={false} strict={false} component={CouponSendComponent}/> }
          { this.state.isLoading ? null : <Route path={'/store/board/notice'} exact={false} strict={false} component={NoticeComponent}/> }
          { this.state.isLoading ? null : <Route path={'/store/board/etc'} exact={false} strict={false} component={EtcComponent}/> }
      </div>
    )
  }
}

export default connect((state) => {
    return {
        store: state.data.store.store,
    }
})(withRouter(BoardComponent))