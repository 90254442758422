import axios from 'axios';
import apiConfig from './config';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const config = {
    headers: {
        Authorization: "Bearer " + cookies.get('klient-manager-auto-login')
    }
}

const pathParamParsing = (path, parameters) => {
    let result = '';
    let pathItems = path.toString().split('/');

    pathItems.forEach((item, index) => {
        if (index !== 0) result += '/';
        if (item.substring(0, 1) === ':' && parameters[item.substring(1, item.length)]) {
            console.log(parameters[item.substring(1, item.length)]);

            result += parameters[item.substring(1, item.length)];
        } else {
            result += item;
        }
    })
    return result;
}

export const get = (path, parameters = {}) => {
    console.log('/Lib/Api/index.js :: GET :: ' + apiConfig[path]);
    let realPath;
    if (typeof parameters === 'object') {
        parameters.updateCallback = new Date();
    }
    realPath = pathParamParsing(apiConfig[path], parameters)

    return axios.get(apiConfig.url + realPath, Object.assign({ params: parameters }, config))
      .then((response) => {
          console.log('/Lib/Api/index.js :: GET :: ' + path + ' :: Success!');
          return Promise.resolve(response);
      })
      .catch((err) => {
          console.log('/Lib/Api/index.js :: GET :: ' + path + ' :: Failed!');
          //console.log(err);
          return Promise.reject(err);
      });
};
export const post = (path, parameters = {}) => {
    console.log('/Lib/Api/index.js :: POST :: ' + apiConfig[path]);
    let realPath;
    if (typeof parameters === 'object') {
        parameters.updateCallback = new Date();
    }
    console.log(apiConfig[path])
    if (parameters.param) {
        realPath = apiConfig[path].replace(/:boardId|:optionId|:productId|:hashtagId|:userId|:commentId/g, parameters.param);
    } else {
        realPath = apiConfig[path].replace(/:boardId|:optionId|:productId|:hashtagId|:userId|:commentId/g, parameters);
    }
    console.log(parameters);

    return axios.post(apiConfig.url + realPath, parameters)
        .then((response) => {
            console.log('/Lib/Api/index.js :: POST :: ' + path + ' :: Success!');
            return Promise.resolve(response);
        })
        .catch((err) => {
            console.log('/Lib/Api/index.js :: POST :: ' + path + ' :: Failed!');
            console.log(err);
            return Promise.reject(err);
        });
};
export const put = (path, parameters = {}) => {
    console.log('/Lib/Api/index.js :: PUT :: ' + apiConfig[path]);
    let realPath;
    if (typeof parameters === 'object') {
        parameters.updateCallback = new Date();
    }
    console.log(apiConfig[path])
    if (parameters.param) {
        realPath = apiConfig[path].replace(/:boardId|:optionId|:productId|:hashtagId|:userId|:commentId/g, parameters.param);
    } else {
        realPath = apiConfig[path].replace(/:boardId|:optionId|:productId|:hashtagId|:userId|:commentId/g, parameters);
    }
    return axios.put(apiConfig.url + realPath, parameters)
        .then((response) => {
            console.log('/Lib/Api/index.js :: PUT :: ' + path + ' :: Success!');
            return Promise.resolve(response);
        })
        .catch((err) => {
            console.log('/Lib/Api/index.js :: PUT :: ' + path + ' :: Failed!');
            console.log(err);
            return Promise.reject(err);
        });
};
export const del = (path, parameters = {}) => {
    return axios.delete(apiConfig.url + apiConfig[path], { data: parameters })
        .then((response) => {
            console.log('/Lib/Api/index.js :: DELETE :: ' + path + ' :: Success!');
            return Promise.resolve(response);
        })
        .catch((err) => {
            console.log('/Lib/Api/index.js :: DELETE :: ' + path + ' :: Failed!');
            console.log(err);
            return Promise.reject(err);
        });
};

export const upload = (path, parameters = {}) => {
    console.log('/Lib/Api/index.js :: UPLOAD :: ' + apiConfig[path]);
    parameters.updateCallback = new Date();
    const body = new FormData();
    body.append('file', parameters.file);
    return axios.post(apiConfig.url + apiConfig[path], body, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then((response) => {
            console.log('/Lib/Api/index.js :: UPLOAD :: ' + path + ' :: Success!');
            return Promise.resolve(response);
        })
        .catch((err) => {
            console.log('/Lib/Api/index.js :: UPLOAD :: ' + path + ' :: Failed!');
            console.log(err);
            return Promise.reject(err);
        });
}

// 참조문서1: axios => https://github.com/mzabriskie/axios
