import React from 'react';
import {
    Link,
    withRouter,
} from 'react-router-dom';
import { connect } from 'react-redux';

import DatePicker from 'react-datepicker';
import moment from 'moment';


// CSS
import styles from "react-datepicker/dist/react-datepicker.css";
import styleBoard from '../../Styles/Components/Board.css'

/*
* this.props.fieldName
* this.props.onUpdate
* */

class TimeRangePicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            today: new Date(),
            startDate: this.props.startDate || new Date(),
            endDate: this.props.endDate || new Date(),
        }

        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleDateChangeRaw = this.handleDateChangeRaw.bind(this);
        this.handleStartDate = this.handleStartDate.bind(this);
        this.handleEndDate = this.handleEndDate.bind(this);
        this.handleSetDate = this.handleSetDate.bind(this);
    }
    componentDidMount() {
        this.props.onRef(this);
    }

    handleStartDate(date) {
        if (date > this.state.endDate) {
            return this.props.handleChangingStatus(this.props.alertStartDate || '날짜 범위가 올바르지 않습니다.', false);
        }
        this.handleDateChange(date, 'startDate');
    }

    handleEndDate(date) {
        if (this.state.startDate > date) {
            return this.props.handleChangingStatus(this.props.alertEndDate || '날짜 범위가 올바르지 않습니다.', false);
        }
        this.handleDateChange(date, 'endDate');
    }

    handleDateChange(date, field) {
        if (this.props.dateKind) {
            if (field === 'startDate') date.set({hour: 0, minute: 0, second: 0});
            if (field === 'endDate') date.set({hour: 23, minute: 59, second: 59});
        }
        const newState = {};
        newState[field] = date;
        this.setState(newState, () => {
            if (this.props.onUpdate) {
                console.log(this.state);
                this.props.onUpdate(this.state.startDate, this.state.endDate);
            }
        })
    }

    handleDateChangeRaw(e, timeFormat) {
        const date = moment(e.target.value, timeFormat);
        if (date.isValid()) {
            this.handleDateChange(date);
        }
    }

    handleSetDate(date) {
        let setDate = moment(date).utcOffset(0);
        setDate.set({hour:0,minute:0,second:0,millisecond:0})
        setDate.toISOString();
        return setDate.format();
    }

    render() {
        return (
            <div className={this.props.wrapperStyle || ''}>
                <div className={styleBoard.datePickerBox}>
                    <DatePicker
                      selected={this.props.startDate ? this.props.startDate : null}
                      selectsStart
                      startDate={this.props.startDate}
                      endDate={this.props.endDate}
                      onChange={this.handleStartDate}
                      onChangeRaw={(e) => this.handleDateChangeRaw(e, this.props.dateFormat)}
                      className={styles.dateInput + (this.props.style ? ' ' + this.props.style : '')}
                      showTimeSelect={this.props.showTimeSelect}
                      showTimeSelectOnly={this.props.showTimeSelectOnly}
                      timeFormat={this.props.timeFormat}
                      timeCaption={this.props.timeCaption}
                      dateFormat={this.props.dateFormat}
                      placeholderText={this.props.placeholderText}
                      disabled={this.props.disabled ? true : false}
                      readOnly={this.props.readOnly}
                      timeIntervals={this.props.timeIntervals ? this.props.timeIntervals: 30}
                    />
                </div>
                <div className={styleBoard.datePickerRange}>~</div>
                <div className={styleBoard.datePickerBox}>
                    <DatePicker
                      selected={this.props.endDate ? this.props.endDate : null}
                      selectsEnd
                      startDate={this.props.startDate}
                      endDate={this.props.endDate}
                      onChange={this.handleEndDate}
                      onChangeRaw={(e) => this.handleDateChangeRaw(e, this.props.dateFormat)}
                      className={styles.dateInput + (this.props.style ? ' ' + this.props.style : '')}
                      showTimeSelect={this.props.showTimeSelect}
                      showTimeSelectOnly={this.props.showTimeSelectOnly}
                      timeFormat={this.props.timeFormat}
                      timeCaption={this.props.timeCaption}
                      dateFormat={this.props.dateFormat}
                      placeholderText={this.props.placeholderText}
                      disabled={this.props.disabled ? true : false}
                      readOnly={this.props.readOnly}
                      timeIntervals={this.props.timeIntervals ? this.props.timeIntervals: 30}
                    />
                </div>
            </div>
        );
    }
}
export default connect()(withRouter(TimeRangePicker));
