import React, {Component} from 'react';
import {withRouter, Route} from 'react-router-dom';
import {connect} from 'react-redux';
import { Helmet } from 'react-helmet'

// styles
import styleAdministrator from '../../../../Styles/Components/Administrator.css'
import styleReservationManagement from '../../../../Styles/Components/ReservationManagement.css'

// action
import { action } from '../../../../../Data/index';
import style from '@/Service/Styles/style.css'
import { dateUtil, listUtil } from '@/Utils'
import styleReservationModal from '@/Service/Styles/Components/ReservationModal.css'


class ReservationManagement extends Component{
  constructor(props){
    super(props);

    this.state = {
      store: {},
      storeMenus: [],
      originalStoreMenus: [],
      originalStoreMemos: [],
      originalStoreStaffs: [],
      storeMemos: [],
      storeStaffs: [],
      isMenuDirectInput: false,
      isMemoDirectInput: false,
      menuDirectInput: '',
      memoDirectInput: '',
      staffDirectInput: '',
    }

    // this.onUnload = this.onUnload.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getStoreValues = this.getStoreValues.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleStateValue = this.handleStateValue.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
    this.directInputType = this.directInputType.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
    this.handleSubmitStoreMenus = this.handleSubmitStoreMenus.bind(this);
    this.handleSubmitStoreMemos = this.handleSubmitStoreMemos.bind(this);
    this.handleAddStaff = this.handleAddStaff.bind(this);
    this.handleSubmitStoreStaffs = this.handleSubmitStoreStaffs.bind(this);
    this.handleChangingStatus = this.handleChangingStatus.bind(this);
  }

  componentDidMount() {
    this.getStoreValues();

    //window.addEventListener("beforeunload", this.onUnload)
  }

  componentWillUnmount() {
    //window.removeEventListener("beforeunload", this.onUnload)
  }

  // onUnload(e) {
  //   console.log('페이지 새로고침');
  //   console.log(e);
  //   e.returnValue = "변경되었습니다";
  // }

  getStoreValues() {
    if (!this.props.store) return;
    this.props.dispatch(action.store.getById({
      storeId: this.props.store.store.storeId,
    })).then((res) => {
      if (res.store) {
        this.setState({
          store: res.store
        });
      }
    }).catch((err) => {
      this.setState({
        isLoading: false,
      })
    });

    const storeMenus = this.props.dispatch(action.store_menu.getList({ fk_storeId: this.props.store.store.storeId, order: 'storeMenuId', orderMethod: 'asc' })).then((res) => {
      return res.map((item, index) => {
        return Object.assign({storeMenuId: item.store_menu.storeMenuId}, item.store_menu)
      })
    })
    const storeMemos = this.props.dispatch(action.store_memo.getList({ fk_storeId: this.props.store.store.storeId, order: 'storeMemoId', orderMethod: 'asc' })).then((res) => {
      return res.map((item, index) => {
        return Object.assign({storeMemoId: item.store_memo.storeMemoId}, item.store_memo)
      })
    })
    const storeStaffs = this.props.dispatch(action.store_staff.getList({ fk_storeId: this.props.store.store.storeId, order: 'storeStaffId', orderMethod: 'asc' })).then((res) => {
      return res.map((item, index) => {
        return Object.assign({storeStaffId: item.store_staff.storeStaffId}, item.store_staff)
      })
    })
    Promise.all([
      storeMenus,
      storeMemos,
      storeStaffs
    ]).then((res) => {
      this.setState({
        storeMenus: res[0],
        originalStoreMenus: res[0],
        storeMemos: res[1],
        originalStoreMemos: res[1],
        storeStaffs: res[2],
        originalStoreStaffs: res[2],
      })
    }).catch((err) => {
      console.log('예약 등록 에러 :: ', err);
    })
  }

  handleTimeChange(e) {
    this.setState({
      selectedTime: e.target.value
    })
  }

  onKeyPress(e) {
    if (e.key === 'Enter') {
      this.directInputType();
      this.setState({
        menuDirectInput: '',
        memoDirectInput: ''
      })
    }
  }

  directInputType() {
    if (this.state.menuDirectInput) {
      let flag = false;
      this.state.storeMenus.forEach((menu) => {
        if (this.state.menuDirectInput === menu.menu) {
          flag = true
        }
      });
      if (this.state.storeMenus.length + 1 > 16) {
        this.handleChangingStatus('16개 이하로 입력해주세요.', false);
        flag = true
      }
      if (!flag) {
        this.setState({
          storeMenus: [...this.state.storeMenus, {
            menu: this.state.menuDirectInput,
          }]
        })
      }
    } else if (this.state.memoDirectInput) {
      let flag = false;
      this.state.storeMemos.forEach((memo) => {
        if (this.state.memoDirectInput === memo.memo) {
          flag = true
        }
      });
      if (this.state.storeMemos.length + 1 > 10) {
        this.handleChangingStatus('10개 이하로 입력해주세요.', false);
        flag = true
      }
      if (!flag) {
        this.setState({
          storeMemos: [...this.state.storeMemos, {
            memo: this.state.memoDirectInput,
          }]
        })
      }
    }
  }

  deleteItem(type, index) {
    if (type===1) {
      let storeMenus = this.state.storeMenus.filter((item, idx) => {
        return idx !== index
      });

      this.setState({
        storeMenus: storeMenus
      });
    } else if (type===2) {
      let storeMemos = this.state.storeMemos.filter((item, idx) => {
        return idx !== index
      });

      this.setState({
        storeMemos: storeMemos
      });
    } else if (type===3) {
      let storeStaffs = this.state.storeStaffs.filter((item, idx) => {
        return idx !== index
      });

      this.setState({
        storeStaffs: storeStaffs
      });
    }
  }

  handleAddStaff(staff) {
    this.setState({
      staffDirectInput: '',
      storeStaffs: [...this.state.storeStaffs, {
        name: staff
      }]
    })
  }

  handleStateValue(value, field, state) {
    let newState = {};
    newState[state] = this.state[state];

    newState[state][field] = value;

    this.setState(newState);
  }

  handleFileChange(e, field, state){
    let file = e.target.files[0];

    let reader = new FileReader();

    let newState = {};

    newState[state] = this.state[state];

    newState[state][field] = file;
    newState[state][field + 'Name'] = file.name;

    reader.onloadend = () => {
      newState[state][field + 'Preview'] = reader.result;
      this.setState(newState, () => {
      });
    };
    reader.readAsDataURL(file);
  }

  handleSubmitStoreMenus(params) {
    let menus = this.state.storeMenus;
    let originalMenus = this.state.originalStoreMenus;

    let changed = listUtil.getJSONArrayChangedValues(originalMenus, menus, 'storeMenuId');
    const changeFunc = [];
    if (changed) {
      changeFunc.push(menus.reduce((prevProm, item) => {
        return prevProm.then(() => !item.storeMenuId ? this.props.dispatch(action.store_menu.create({
          fk_storeId: params.storeId,
          menu: item.menu
        })) : null)
      }, Promise.resolve()))

      changeFunc.push(changed.deleted.reduce((prevProm, item) => {
        return prevProm.then(() => this.props.dispatch(action.store_menu.remove({ storeMenuId: item.storeMenuId })))
      }, Promise.resolve()))
    }

    return changeFunc;

    // 지우지 마세욥
    // const createFunc = menus.length > 0 ? menus.map((item) => {
    //   return !item.storeMenuId ? new Promise((resolve, reject) => {
    //     this.props.dispatch(action.store_menu.create({
    //       fk_storeId: params.storeId,
    //       menu: item.menu,
    //     })).then((res) => {
    //       if (res.storeMenuId) {
    //         resolve(res);
    //       } else {
    //         console.log('STORE MENU ERR::');
    //         reject(res);
    //       }
    //     })
    //   }) : null;
    // }) : [];
    //
    // if (changed) {
    //   const deleteFunc = changed.deleted.length > 0 ? changed.deleted.map((item) => {
    //     return new Promise((resolve, reject) => {
    //       this.props.dispatch(action.store_menu.remove({
    //         storeMenuId: item.storeMenuId
    //       })).then((res) => {
    //         if (res) {
    //           resolve(res);
    //         } else {
    //           console.log('STORE MENU ERR::');
    //           reject(res);
    //         }
    //       })
    //     });
    //   }) : [];
    //
    //   return Promise.all(createFunc.concat(deleteFunc));
    // } else {
    //   return [];
    // }
  }

  handleSubmitStoreMemos(params) {
    let memos = this.state.storeMemos;
    let originalMemos = this.state.originalStoreMemos;

    let changed = listUtil.getJSONArrayChangedValues(originalMemos, memos, 'storeMemoId');
    const changeFunc = [];
    if (changed) {
      changeFunc.push(memos.reduce((prevProm, item) => {
        return prevProm.then(() => !item.storeMemoId ? this.props.dispatch(action.store_memo.create({
          fk_storeId: params.storeId,
          memo: item.memo
        })) : null)
      }, Promise.resolve()))

        changeFunc.push(changed.deleted.reduce((prevProm, item) => {
          return prevProm.then(() => this.props.dispatch(action.store_memo.remove({ storeMemoId: item.storeMemoId })))
        }, Promise.resolve()))
    }

    return changeFunc;

    // 지우지 마세욥
    // const createFunc = memos.length > 0 ? memos.map((item) => {
    //   return !item.storeMemoId ? new Promise((resolve, reject) => {
    //     this.props.dispatch(action.store_memo.create({
    //       fk_storeId: params.storeId,
    //       memo: item.memo,
    //     })).then((res) => {
    //       if (res.storeMemoId) {
    //         resolve(res);
    //       } else {
    //         console.log('STORE MEMO ERR::');
    //         reject(res);
    //       }
    //     })
    //   }) : null;
    // }) : [];
    // if (changed) {
    //   const deleteFunc = changed.deleted.length > 0 ? changed.deleted.map((item) => {
    //     return new Promise((resolve, reject) => {
    //       this.props.dispatch(action.store_memo.remove({
    //         storeMemoId: item.storeMemoId
    //       })).then((res) => {
    //         if (res) {
    //           resolve(res);
    //         } else {
    //           console.log('STORE MEMO ERR::');
    //           reject(res);
    //         }
    //       })
    //     });
    //   }) : [];
    //   return Promise.all(createFunc.concat(deleteFunc));
    // } else {
    //   return [];
    // }
  }

  handleSubmitStoreStaffs(params) {
    let staffs = this.state.storeStaffs;
    let originalStaffs = this.state.originalStoreStaffs;

    let changed = listUtil.getJSONArrayChangedValues(originalStaffs, staffs, 'storeStaffId');
    const changeFunc = [];
    if (changed) {
      changeFunc.push(staffs.reduce((prevProm, item) => {
        return prevProm.then(() => !item.storeStaffId ? this.props.dispatch(action.store_staff.create({
          fk_storeId: params.storeId,
          name: item.name,
        })) : null)
      }, Promise.resolve()))

      changeFunc.push(changed.deleted.reduce((prevProm, item) => {
        return prevProm.then(() => this.props.dispatch(action.store_staff.remove({storeStaffId: item.storeStaffId})))
      }, Promise.resolve()))
    }

    return changeFunc;

    // const createFunc = staffs.length > 0 ? staffs.map((item) => {
    //   return !item.storeStaffId ? new Promise((resolve, reject) => {
    //     this.props.dispatch(action.store_staff.create({
    //       fk_storeId: params.storeId,
    //       name: item.name,
    //     })).then((res) => {
    //       if (res.storeStaffId) {
    //         resolve(res);
    //       } else {
    //         console.log('STORE STAFF ERR::');
    //         reject(res);
    //       }
    //     })
    //   }) : null;
    // }) : [];
    // if (changed) {
    //   const deleteFunc = changed.deleted.length > 0 ? changed.deleted.map((item) => {
    //     return new Promise((resolve, reject) => {
    //       this.props.dispatch(action.store_staff.remove({
    //         storeStaffId: item.storeStaffId
    //       })).then((res) => {
    //         if (res) {
    //           resolve(res);
    //         } else {
    //           console.log('STORE STAFF ERR::');
    //           reject(res);
    //         }
    //       })
    //     });
    //   }) : [];
    //   return Promise.all(createFunc.concat(deleteFunc));
    // } else {
    //   return [];
    // }
  }

  handleSubmit() {
    let params = {
      storeId: this.state.store.storeId,
      screenChangeTime: this.state.store.screenChangeTime,
      screenFontSize: this.state.store.screenFontSize,
      guideText: this.state.store.guideText,
      maximumReservation: this.state.store.maximumReservation,
      babySeatAmount: this.state.store.babySeatAmount,
      menuUrl: this.state.store.menuUrlName || this.state.store.menuUrl
    }

    const uploadFileList = [
      'menuUrl',
    ]

    const uploadFiles = Object.keys(this.state.store).filter((key) => {
      return uploadFileList.indexOf(key) > -1 && this.state.store[key + 'Preview'];
    }).map((key) => {
      return new Promise((resolve, reject) => {
        action.media.uploadImage(this.state.store[key]).then((res) => {
          console.log(res);
          if (res.code === 200 && res.data) {
            params[key] = res.data.original
            resolve(null);
          } else {
            reject('이미지 업로드 실패');
          }
        }).catch((err) => {
          reject(err);
        });
      })
    });

    Promise.all(uploadFiles).then(() => {
      this.props.dispatch(action.store.update(params)).then((res) => {
        // this.handleSubmitStoreMemos(params);
        // this.handleSubmitStoreStaffs(params);
        Promise.all(this.handleSubmitStoreMenus(params).concat(this.handleSubmitStoreMemos(params), this.handleSubmitStoreStaffs(params))).then((res) => {
          this.handleChangingStatus('저장되었습니다.', true);
        })
      })
    })
  }

  handleChangingStatus(text, bool) {
    this.setState({
      isStatusChanging: true,
      alertText: text,
      isPositive: bool
    }, () => {
      setTimeout(() => {
        this.setState({ isStatusChanging: false });
        location.reload();
      }, bool ? 1000 : 3000);
    })
  }

  render(){
    let renderStoreStaffs = this.state.storeStaffs ? this.state.storeStaffs.map((staff, index) => {
      return (
        <li key={index}>
          {staff.name}
          <img className={styleReservationManagement.deleteIcon} src="/Assets/img/ic-delete-item.png" onClick={() => this.deleteItem(3, index)}/>
        </li>
      )
    }): null;

    let renderStoreMenus = this.state.storeMenus ? this.state.storeMenus.map((menu, index) => {
      return (
        <li key={index}>
          {menu.menu}
          <img className={styleReservationModal.deleteIcon} src="/Assets/img/ic-delete-item.png" onClick={() => this.deleteItem(1, index)}/>
        </li>
      )
    }): null;

    let renderStoreMemos = this.state.storeMemos ? this.state.storeMemos.map((memo, index) => {
      return (
        <li key={index}>
          {memo.memo}
          <img className={styleReservationModal.deleteIcon} src="/Assets/img/ic-delete-item.png" onClick={() => this.deleteItem(2, index)}/>
        </li>
      )
    }) : null;

    return(
      <div>
        <Helmet>
          <title>예약등록 관리</title>
        </Helmet>
        <div className={styleAdministrator.administratorContainer}>
          {this.state.isStatusChanging && !this.state.isPositive ? <div className={styleReservationModal.completedAlert} style={{top: '40%', backgroundColor: '#F08080'}}>{this.state.alertText}</div> : null}
          {this.state.isStatusChanging && this.state.isPositive ? <div className={styleReservationModal.completedAlert} style={{top: '40%'}}>{this.state.alertText}</div> : null}
          <div className={styleAdministrator.leftContent}>
            <div className={styleReservationManagement.leftContentInner}>
              <div className={styleReservationManagement.leftContentTitle}>예약등록 관리</div>
              <div className={styleReservationManagement.section}>
                <div className={styleReservationManagement.sectionDesc}>
                  <div className={styleReservationManagement.sectionTitle}>메뉴 관리</div>
                  <div className={styleReservationManagement.maxAmount}>최대 16개</div>
                </div>
                <div className={styleReservationManagement.sectionBox}>
                  <ul>
                    {renderStoreMenus}
                    {this.state.isMenuDirectInput ?
                      <li className={styleReservationModal.directInput}>
                        <input type="text" onKeyPress={this.onKeyPress} value={this.state.menuDirectInput} onChange={(e) => this.setState({ menuDirectInput: e.target.value })}/>
                      </li> : null}
                    <li className={styleReservationModal.addIconBox} onClick={() => this.setState({isMenuDirectInput: !this.state.isMenuDirectInput})}><img className={styleReservationModal.addIcon} src="/Assets/img/btn-add.png"/></li>
                  </ul>
                </div>
                <div className={styleReservationManagement.pressEnter}>메뉴 입력 후 엔터를 누르면 추가 됩니다.</div>
              </div>
              <div className={styleReservationManagement.section}>
                <div className={styleReservationManagement.sectionDesc}>
                  <div className={styleReservationManagement.sectionTitle}>예약메모 관리</div>
                  <div className={styleReservationManagement.maxAmount}>최대 10개</div>
                </div>
                <div className={styleReservationManagement.sectionBox}>
                  <ul>
                    {renderStoreMemos}
                    {this.state.isMemoDirectInput ?
                      <li className={styleReservationModal.directInput}>
                        <input type="text" onKeyPress={this.onKeyPress} value={this.state.memoDirectInput} onChange={(e) => this.setState({ memoDirectInput: e.target.value })}/>
                      </li> : null}
                    <li className={styleReservationModal.addIconBox} onClick={() => this.setState({isMemoDirectInput: !this.state.isMemoDirectInput})}><img className={styleReservationModal.addIcon} src="/Assets/img/btn-add.png"/></li>
                  </ul>
                </div>
                <div className={styleReservationManagement.pressEnter}>메모 입력 후 엔터를 누르면 추가 됩니다.</div>
              </div>
              <div className={styleReservationManagement.section}>
                <div className={styleReservationManagement.sectionTitle + ' ' + styleReservationManagement.style1}>입장인원 관리</div>
                <div className={styleReservationManagement.subSection}>
                  <div className={styleReservationManagement.maxAmount}>최대 예약가능 인원</div>
                  <div className={styleReservationManagement + ' ' + styleReservationManagement.style2}>
                    <input type="text" value={this.state.store.maximumReservation} onChange={(e) => this.handleStateValue(e.target.value, 'maximumReservation', 'store')}/>명
                  </div>
                </div>
                <div className={styleReservationManagement.subSection}>
                  <div className={styleReservationManagement.maxAmount}>아기의자 보유 수</div>
                  <div className={styleReservationManagement.sectionBox + ' ' + styleReservationManagement.style2}>
                    <input type="text" value={this.state.store.babySeatAmount} onChange={(e) => this.handleStateValue(e.target.value, 'babySeatAmount', 'store')}/>개
                  </div>
                  <p className={styleReservationManagement.extraInfo}>
                    아기의자 수를 0으로 입력하면,
                    아기의자 선택옵션은 비활성화됩니다.
                  </p>
                </div>
              </div>
              <div className={styleReservationManagement.section}>
                <div className={styleReservationManagement.sectionTitle + ' ' + styleReservationManagement.style1}>호출화면 관리</div>
                <div className={styleReservationManagement.subSection + ' ' + styleReservationManagement.subSectionStyle1}>
                  <div className={styleReservationManagement.maxAmount + ' ' + styleReservationManagement.style3}>메뉴 이미지</div>
                  <div className={styleReservationManagement.inputBox}>
                    <input type="text" placeholder="이미지 첨부" value={this.state.store.menuUrlName || this.state.store.menuUrl || ''} onChange={(e) => this.handleStateValue(e.target.value, 'menuUrl', 'store')}/>
                    <label className={style.uploadLabel + ' ' + styleAdministrator.plusIcon} style={{ backgroundImage: "url(/Assets/img/ic-plus.png)"}} htmlFor={'menuUrl'}>
                      <div className={style.uploadIcon}/>
                      <input className={style.uploadInput} id={'menuUrl'} type={'file'} onChange={(e) => this.handleFileChange(e, 'menuUrl', 'store')}  accept={'image/*'}/>
                    </label>
                  </div>
                  <div className={styleReservationManagement.extraInfo}>이미지가 없으면 대기현황만 보여집니다.</div>
                </div>
                <div className={styleReservationManagement.subSection + ' ' + styleReservationManagement.subSectionStyle1}>
                  <div className={styleReservationManagement.maxAmount + ' ' + styleReservationManagement.style3}>화면 변경 시간 설정 (대기현황-매장사진)</div>
                  <div className={styleReservationManagement.selectBox}>
                    <select onChange={(e) => this.handleStateValue(e.target.value, 'screenChangeTime', 'store')}>
                      <option value={10000} selected={this.props.store.store.screenChangeTime == 10000 ? true : false}>10초</option>
                      <option value={20000} selected={this.props.store.store.screenChangeTime == 20000 ? true : false}>20초</option>
                      <option value={30000} selected={this.props.store.store.screenChangeTime == 30000 ? true : false}>30초</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className={styleReservationManagement.section}>
                <div className={styleReservationManagement.sectionTitle + ' ' + styleReservationManagement.style1}>안내 문구</div>
                <div className={styleReservationManagement.inputBox + ' ' + styleReservationManagement.inputBoxStyle1}>
                  <input type="text" placeholder="테이블 상황과 인원에 따라 다르게 안내될 수 있습니다."
                         value={this.state.store.guideText} onChange={(e) => this.handleStateValue(e.target.value, 'guideText', 'store')}/>
                </div>
                <div className={styleReservationManagement.extraInfo}>ex. 테이블 상황과 인원에 따라 다르게 안내될 수 있습니다.</div>
              </div>
              <div className={styleReservationManagement.infoSaveBtn} onClick={this.handleSubmit}>정보저장</div>
            </div>
          </div>
          <div className={styleAdministrator.rightContent}>
            <div className={styleReservationManagement.sectionStyle1}>
              <div className={styleReservationManagement.sectionTitle + ' ' + styleReservationManagement.style1}>화면 글자 크기 설정</div>
              <ul className={styleReservationManagement.fontSizeList}>
                <li onClick={() => this.handleStateValue('12', 'screenFontSize', 'store')} style={{color: this.state.store.screenFontSize ==12 ? '#5b9fdc' : null}}>12px</li>
                <li onClick={() => this.handleStateValue('14', 'screenFontSize', 'store')} style={{color: this.state.store.screenFontSize ==14 ? '#5b9fdc' : null}}>14px</li>
                <li onClick={() => this.handleStateValue('16', 'screenFontSize', 'store')} style={{color: this.state.store.screenFontSize==16 ? '#5b9fdc' : null}}>16px</li>
                <li onClick={() => this.handleStateValue('18', 'screenFontSize', 'store')} style={{color: this.state.store.screenFontSize==18 ? '#5b9fdc' : null}}>18px</li>
              </ul>
            </div>
            <div className={styleReservationManagement.sectionStyle1}>
              <div className={styleReservationManagement.sectionTitle + ' ' + styleReservationManagement.style1}>직원 관리</div>
              <div className={styleReservationManagement.addStaffBox}>
                <input type="text" placeholder="직원 추가" value={this.state.staffDirectInput} onChange={(e) => this.setState({ staffDirectInput: e.target.value })} onKeyPress={(e) => e.key === 'Enter' ? this.handleAddStaff(this.state.staffDirectInput) : null}/>
                <img className={styleReservationManagement.plusIcon} src="/Assets/img/ic-plus.png" onClick={() => this.handleAddStaff(this.state.staffDirectInput)}/>
              </div>
              <ul className={styleReservationManagement.staffList}>
                {renderStoreStaffs}
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect((state) => {
  return {
    author: state.data.account.author,
    store: state.data.store.store,
  };
})(withRouter(ReservationManagement));
