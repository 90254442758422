import * as ActionTypes from '../actionTypes';

// API
import * as HttpApi from '../Api/index';

export const actionTypes = ActionTypes;

//////////////////
///// GET ////////
//////////////////
export const getList = (params) => {
	return (dispatch) => {
		return HttpApi.get('GET_STORE_CUSTOMER_LIST', params)
			.then((response) => {
				return Promise.resolve(response.data);
			})
			.catch((err) => {
				return Promise.reject(err);
			});
	};
};

export const getCount = (params) => {
	return (dispatch) => {
		return HttpApi.get('GET_STORE_CUSTOMER_COUNT', params)
			.then((response) => {
				return Promise.resolve(response.data);
			})
			.catch((err) => {
				return Promise.reject(err);
			});
	};
};

export const getById = (params) => {
	return (dispatch) => {
		return HttpApi.get('GET_STORE_CUSTOMER_DETAIL', params)
			.then((response) => {
				return Promise.resolve(response.data);
			})
			.catch((err) => {
				return Promise.reject(err);
			});
	};
};

export const getExcel = (params) => {
    return (dispatch) => {
        return HttpApi.get('GET_STORE_CUSTOMER_EXCEL', params)
            .then((response) => {
                return Promise.resolve(response.data);
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };
};


//////////////////
///// POST ///////
//////////////////
export const create = (params) => {
	return (dispatch) => {
		return HttpApi.post('POST_STORE_CUSTOMER_CREATE', params)
			.then((response) => {
				return Promise.resolve(response.data);
			})
			.catch((err) => {
				return Promise.reject(err);
			});
	};
};

export const importExcel = (params) => {
    return (dispatch) => {
        return HttpApi.upload('POST_STORE_CUSTOMER_EXCEL_UPLOAD', params)
            .then((response) => {
                return Promise.resolve(response.data);
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };
};


//////////////////
///// PUT  ///////
//////////////////
export const update = (params) => {
	return (dispatch) => {
		return HttpApi.put('PUT_STORE_CUSTOMER_UPDATE', params)
			.then((response) => {
				return Promise.resolve(response.data);
			})
			.catch((err) => {
				return Promise.reject(err);
			});
	};
};

//////////////////
///// DELETE /////
//////////////////
export const remove = (params) => {
    return (dispatch) => {
        return HttpApi.del('DELETE_STORE_CUSTOMER_DELETE', params)
            .then((response) => {
                return Promise.resolve(response.data);
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };
};

//////////////////
/// FUNCTIONS ////
//////////////////
export const setSelectedCustomerId = (params) => {
	return (dispatch) => {
		dispatch({ type: ActionTypes.SELECTED_CUSTOMER_ID, selectedCustomerId: params });
		return true;
	}
}