import * as ActionTypes from '../actionTypes';

// API
import * as HttpApi from '../Api/index';

export const actionTypes = ActionTypes;

//////////////////
///// GET ////////
//////////////////
export const getList = (params) => {
	return (dispatch) => {
		return HttpApi.get('GET_STORE_RESERVATION_LIST', params)
			.then((response) => {
				return Promise.resolve(response.data);
			})
			.catch((err) => {
				return Promise.reject(err);
			});
	};
};

export const getCount = (params) => {
	return (dispatch) => {
		return HttpApi.get('GET_STORE_RESERVATION_COUNT', params)
			.then((response) => {
				return Promise.resolve(response.data);
			})
			.catch((err) => {
				return Promise.reject(err);
			});
	};
};

export const getById = (params) => {
	return (dispatch) => {
		return HttpApi.get('GET_STORE_RESERVATION_DETAIL', params)
			.then((response) => {
				return Promise.resolve(response.data);
			})
			.catch((err) => {
				return Promise.reject(err);
			});
	};
};

export const getTotalByDate = (params) => {
    return (dispatch) => {
        return HttpApi.get('GET_STORE_RESERVATION_TOTAL_BY_DATE', params)
            .then((response) => {
                return Promise.resolve(response.data);
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };
};

export const getExcel = (params) => {
    return (dispatch) => {
        return HttpApi.get('GET_STORE_RESERVATION_EXCEL', params)
            .then((response) => {
                return Promise.resolve(response.data);
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };
};


//////////////////
///// POST ///////
//////////////////
export const create = (params) => {
	return (dispatch) => {
		return HttpApi.post('POST_STORE_RESERVATION_CREATE', params)
			.then((response) => {
				return Promise.resolve(response.data);
			})
			.catch((err) => {
				return Promise.reject(err);
			});
	};
};

//////////////////
///// PUT  ///////
//////////////////
export const update = (params) => {
	return (dispatch) => {
		return HttpApi.put('PUT_STORE_RESERVATION_UPDATE', params)
			.then((response) => {
				return Promise.resolve(response.data);
			})
			.catch((err) => {
				return Promise.reject(err);
			});
	};
};

export const updateGroupNum = (params) => {
    return (dispatch) => {
        return HttpApi.put('PUT_STORE_RESERVATION_UPDATE_GROUPNUM', params)
            .then((response) => {
                return Promise.resolve(response.data);
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };
};

//////////////////
///// DELETE /////
//////////////////
export const remove = (params) => {
    return (dispatch) => {
        return HttpApi.del('DELETE_STORE_RESERVATION_DELETE', params)
            .then((response) => {
                return Promise.resolve(response.data);
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };
};

//////////////////
/// FUNCTIONS ////
//////////////////
export const setSelectedReservationId = (params) => {
    return (dispatch) => {
        dispatch({ type: ActionTypes.SELECTED_RESERVATION_ID, selectedReservationId: params });
        return true;
    }
}
export const setSelectedDate = (params) => {
	return (dispatch) => {
        dispatch({ type: ActionTypes.RESERVATION_DATE, selectedDate: params });
        return true;
	}
}
// 1: 전체 2: 점심 3: 저녁
export const setSelectedTime = (params) => {
    return (dispatch) => {
        dispatch({ type: ActionTypes.RESERVATION_TIME, selectedTime: params });
        return true;
    }
}

export const reservationUpdated = (params) => {
    return (dispatch) => {
        dispatch({ type: ActionTypes.RESERVATION_UPDATED, reservationUpdated: params });
        return true;
    }
}