import * as ActionTypes from '../actionTypes';

export const initialState = {
    store_reservation: {},
    store_reservation_list: [],
    selectedReservationId: null,
    selectedDate: new Date(),
    selectedTime: 0,
    reservationUpdated: new Date(),
}
/*
*/
export const store_reservation_reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.STORE_RESERVATION:
            return Object.assign({}, state, {
                store_reservation: action.store_reservation
            });
        case ActionTypes.STORE_RESERVATION_LIST:
            return Object.assign({}, state, {
                store_reservation_list: action.store_reservation_list
            });
        case ActionTypes.SELECTED_RESERVATION_ID:
            return Object.assign({}, state, {
                selectedReservationId: action.selectedReservationId
            });
        case ActionTypes.RESERVATION_DATE:
            return Object.assign({}, state, {
                selectedDate: action.selectedDate
            });
        case ActionTypes.RESERVATION_TIME:
            return Object.assign({}, state, {
                selectedTime: action.selectedTime
            });
        case ActionTypes.RESERVATION_UPDATED:
            return Object.assign({}, state, {
                reservationUpdated: action.reservationUpdated
            })
        default:
            return state;
        }
}
