import React, {Component} from 'react';
import { withRouter, Route, Link } from 'react-router-dom'
import {connect} from 'react-redux';
import Modal from '../../Common/Modal';
import { withTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

// actions
import { action } from "../../../Data/index";

// CSS
import styleLogin from '../../Styles/Components/Login.css';
import { parseUrlParameter } from '../../../Utils'

const customModalStyle = {
    content : {
        width: '720px',
        height: '400px',
        marginTop: '150px',
    }
}

class LoginView extends Component {
    constructor(props) {
        super(props);

        /*const [cookies, setCookie] = useCookies(['km-login']);
        this.cookies = cookies;
        this.setCookie = setCookie;
        this.removeCookie = removeCookie;*/

        this.state = {
            id: cookies.get('klient-manager-save-id') || '',
            password: '',
            saveCookie: false,
            warnIdNotValid: false,
            warnPasswordNotValid: false,

            isAuto: false,
            isSaveId: false,
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
        this.login = this.login.bind(this);
        this.saveIdInLocal = this.saveIdInLocal.bind(this);
        this.handleLanguage = this.handleLanguage.bind(this);
    }

    componentDidMount() {
        let params = parseUrlParameter.parse(this.props.location.search);

        if (params.isOpened || Object.keys(this.props.author).indexOf('account') > -1) {
            this.loginModal.setState({
                isOpened: true
            })
        }
        this.props.dispatch(action.service_system.getServiceSystem()).then((res) => {
          this.setState({
            serviceSystem: res[0]
          })
        })
    }


    handleSubmit() {
        this.setState({
            warnIdNotValid: false,
            warnPasswordNotValid: false,
        }, () => {
            if (this.state.warnIdNotValid || this.state.warnPasswordNotValid) {
                return;
            }

            let params = {
                id: this.state.id,
                password: this.state.password,
                isAuto: this.state.isAuto
            };

            // 로그인 시작
            this.login(params);
        });
    }

    login(params) {
        this.props.dispatch(action.account.login(params))
          .then((response) => {
              console.log('로그인 성공', response);
              console.log(cookies.get('klient-manager-auto-login'));

              if (this.state.isSaveId) {
                  cookies.set('klient-manager-save-id', this.state.id, {
                      path: "/",
                      expires: new Date(Date.now()+604800)
                  });
              } else if (cookies.get('klient-manager-save-id')) {
                  cookies.remove('klient-manager-save-id', { path: '/' });
              }


              if (response.code) {
                  if (response.code === 411) {
                      this.setState({
                          warnIdNotValid: true
                      })
                  } else if(response.code === 428) {
                      this.setState({
                          warnPasswordNotValid: true
                      })
                  } else {
                      alert('로그인이 실패하였습니다. 계정을 확인하여주세요.');
                  }

              } else {
                  if (response.token) {
                      this.saveIdInLocal(response.token);
                  }
                  this.loginModal.setState({
                      isOpened: true
                  })
              }
          })
          .catch((err) => {
              console.log(err);
              alert('로그인이 실패하였습니다. 계정을 확인하여주세요.');
          });
    }

    saveIdInLocal(accountId) {
      /*if (this.state.saveCookie) {
        this.setCookie('id', this.state.id, {
          path: '/'
        })
      }*/
        // 기존 로그인 정보 삭제
        if (cookies.get('klient-manager-auto-login')) {
            cookies.remove('klient-manager-auto-login', { path: '/' });
        }
        // accountId 저장

        cookies.set('klient-manager-auto-login', accountId, {
            path: "/",
            expires: new Date(Date.now()+2592000)
        });

        console.log('로컬 저장됨')
        console.log(cookies.get('klient-manager-auto-login'));
    }

    onKeyPress(e) {
        if (e.key === 'Enter') {
            this.handleSubmit();
        }
    };

  handleLanguage() {
    if (this.props.i18n.language === 'ko') {
      this.props.i18n.changeLanguage('en');
    } else {
      this.props.i18n.changeLanguage('ko');
    }
  }

    render() {
        const { t, i18n } = this.props;

        let renderPopUpBody = () => {
            return (
              <div className={styleLogin.modalBody}>
                  <Link className={styleLogin.mode} to={'/store/reservation'} target="_blank">
                      <div className={styleLogin.modeIcon1}><img src="/Assets/img/img-shop.png"/></div>
                      <div className={styleLogin.modeName}>{t('franchise_mode')}</div>
                  </Link>
                  <Link className={styleLogin.mode} to={'/waiting/call'} target="_blank">
                      <div className={styleLogin.modeIcon1}><img src="/Assets/img/img-bell.png"/></div>
                      <div className={styleLogin.modeName}>{t('alarm_mode')}</div>
                  </Link>
                  <Link className={styleLogin.mode} to={'/waiting/visit'} target="_blank">
                      <div className={styleLogin.modeIcon2}><img src="/Assets/img/img-user.png"/></div>
                      <div className={styleLogin.modeName}>{t('customer_mode')}</div>
                  </Link>
              </div>
            );
        }

        return (
          <div className={styleLogin.loginContainerBox}>
            <div className={styleLogin.lang} onClick={this.handleLanguage}>
              {i18n.language === 'en' ? <img src="/Assets/img/btn-english.png"/> : <img src="/Assets/img/btn-korea.png"/>}
            </div>
            <div className={styleLogin.logo} style={{ backgroundImage: "url(" + (this.state.serviceSystem ? this.state.serviceSystem.service_system.mainLogoUrl : "/Assets/img/km-logo-2-56-px.png") + ")"}}/>
            <div className={styleLogin.loginContainer}>
              <div className={styleLogin.formContainer}>
                <div className={styleLogin.userId}>
                  <label>{t('id')}</label>
                  <div>
                    <input type="text" placeholder={t('id')} value={this.state.id} onChange={(e) => this.setState({ id: e.target.value })} onKeyPress={this.onKeyPress}
                           style={this.state.warnIdNotValid ? {borderBottomColor:'#ea3e0e'} : {borderBottomColor:'#d8d8d8'}}/>
                    {this.state.id != '' ? <p className={styleLogin.clearBtn}><img onClick={() => this.setState({ id: '' })} src="/Assets/img/ic_clear.png"/></p> : null}
                  </div>
                  {this.state.warnIdNotValid ? <span className={styleLogin.incorrect}>{t('id_wrong_noti')}</span> : <span></span>}
                </div>
                <div className={styleLogin.userPwd}>
                  <label>{t('password')}</label>
                  <div>
                    <input type="password" placeholder={t('password')} value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} onKeyPress={this.onKeyPress}
                           style={this.state.warnPasswordNotValid ? {borderBottomColor:'#ea3e0e'} : {borderBottomColor:'#d8d8d8'}}/>
                    {this.state.password != '' ? <p className={styleLogin.clearBtn}><img onClick={() => this.setState({ password: '' })} src="/Assets/img/ic_clear.png"/></p> : null}
                  </div>
                  {this.state.warnPasswordNotValid ? <span className={styleLogin.incorrect}>{t('password_wrong_noti')}</span> : <span></span>}
                </div>
                <div className={styleLogin.inputCheckbox}>
                  <input type="checkbox" value={this.state.isAuto} onChange={(e) => this.setState({ isAuto: e.target.checked })}/><label>{t('auto_login')}</label>
                </div>
                <div className={styleLogin.inputCheckbox}>
                  <input type="checkbox" value={this.state.isSaveId} onChange={(e) => this.setState({ isSaveId: e.target.checked })}/><label>{t('save_id')}</label>
                </div>
              </div>
              <div className={styleLogin.loginBtn} onClick={this.handleSubmit}>
                {t('login')}
              </div>
              {/*<div onClick={() => { i18n.changeLanguage('en'); }}>*/}
              {/*다국어 변경 테스트 버튼*/}
              {/*</div>*/}
              <Modal onRef={(ref) => {this.loginModal = ref;}} modalBody={renderPopUpBody()} customStyles={customModalStyle}/>
            </div>
            <div className={styleLogin.companyInfo}>
              <ul>
                <li>회사명 : 클라이언트매니저</li>
                <li>주소 : 경기도 성남시 분당구 대왕판교로 645번길 12, 9층</li>
                <li>대표자 : 김지은</li>
                <li>사업자 등록번호 : 238-71-00148</li>
                <li>고객센터번호 : 031-706-0611</li>
                <li>Fax : 070-4108-1188</li>
                <li>E-mail : klientmanager@naver.com</li>
                <li>개인정보 보호 책임자 : 김지은</li>
              </ul>
              <div className={styleLogin.copyright}>Copyright © 2019 KlientManager - All rights reserved.</div>
            </div>
          </div>
        )
    }
}

export default connect((state) => {
  return {author: state.data.account.author,}
})(withRouter(withTranslation()(LoginView)))
