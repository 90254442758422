import React, {Component} from 'react';
import {Route, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Modal from '../../../Common/Modal';
import {Helmet} from "react-helmet";
import io from 'socket.io-client';
import * as Const from '../../../../Data/const';

// Actions
import {action} from "../../../../Data/index";

// styles
import styleStore from '../../../Styles/Components/Store.css'
import styleReservation from '../../../Styles/Components/Reservation.css'

// component
import CustomerPopup from "./CustomerModal";
import ReservationPopup from "./ReservationModal";
import ReservationComponent from './Reservation';
import SeatList from './SeatList';
import Calendar from '../Calendar';
import SubHeader from '../../Header';

// Utils
import { converter, parseUrlParameter, string } from '../../../../Utils'
import _ from 'underscore'
import empty from 'just-is-empty'
import styleTableSelectModal from '@/Service/Styles/Components/TableSelectModal.css'
import TableSelectPopup from '@/Service/Components/Store/Reservation/TableSelectModal'
import {dateUtil} from "@/Utils";

class ReservationView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            store: {},
            brand: {},

            timeSection: [],

            selectedPlace: {},
            selectedFloor: {},
            selectedDate: new Date(),
            selectedTime: 0,

            storeTableIds: [],

            tableDataValue: {
                tableDataList: [],
                floorTableData: []
            },

            reservationPopupOpened: false,

            isSubmitTable: false,

            socket: io('/', {
                reconnection: true,
                reconnectionDelay: 1000,
                reconnectionDelayMax: 10000,
                reconnectionAttempts: 1000,
            }),
        }

      this.updateStoreTableValues = this.updateStoreTableValues.bind(this);
	    this.getStoreValues = this.getStoreValues.bind(this);
	    this.getStoreTableValues = this.getStoreTableValues.bind(this);
	    this.onTableSelect = this.onTableSelect.bind(this);

      this.openCalendarModal = this.openCalendarModal.bind(this);
      this.closeCalendarModal = this.closeCalendarModal.bind(this);
	    this.openCustomerModal = this.openCustomerModal.bind(this);
	    this.closeCustomerModal = this.closeCustomerModal.bind(this);
	    this.openReservationModal = this.openReservationModal.bind(this);
	    this.closeReservationModal = this.closeReservationModal.bind(this);
	    this.openTableSelectModal = this.openTableSelectModal.bind(this);
	    this.closeTableSelectModal = this.closeTableSelectModal.bind(this);
	    this.handleSubmit = this.handleSubmit.bind(this);
	    this.handleCancel = this.handleCancel.bind(this);

	    this.setReservationModalItems = this.setReservationModalItems.bind(this);
	    this.handleChangingStatus = this.handleChangingStatus.bind(this);
    }

    componentWillMount() {
        console.log("Reservation >> index :: componentWillMount");
        const params = parseUrlParameter.parse(this.props.location.search);
        if (params.date) {
            this.props.dispatch(action.store_reservation.setSelectedDate(new Date(params.date)));
        }
        this.getStoreValues();
       setInterval(this.getStoreTableValues, Const.intervalTime);
    }

    componentDidMount() {
        this.setState({
            isLoading: false,
        }, () => {
            let params = parseUrlParameter.parse(this.props.location.search);

            if (params.isOpened === 'true') {
                this.reservationModal.setState({
                    isOpened: true,
                })
            }
        });

        // SOCKET 설정
        console.log("TEST >> ", this.props.store);
        this.state.socket.emit('leave:store', { storeId: this.props.store.store.storeId });
        this.state.socket.emit('open:store', { storeId: this.props.store.store.storeId });
        this.state.socket.on('openPopup', (data) => {
            console.log('------openPopup');
            this.setState({
                reservationPopupOpened: !this.state.reservationPopupOpened
                || (this.props.recentCallInfo && data.log_store_call_history.phoneNumber !== this.props.recentCallInfo.phoneNumber) ? new Date() : this.state.reservationPopupOpened,
            }, () => {
                let customer = {
                    storeCustomerId: null,
                    name: null,
                    phoneNumber: data.log_store_call_history.phoneNumber,
                }
                if (data.store_customer) {
                    customer.storeCustomerId = data.store_customer.storeCustomerId;
                    customer.name = data.store_customer.name;
                    customer.calledAt = data.store_customer.calledAt;
                }

                if ((this.props.recentCallInfo && this.props.recentCallInfo.phoneNumber == customer.phoneNumber)
                //|| this.customerModal.state.isOpened
                // 전화예약 테스트 중 계속 return 되어서 주석 달아놈 (이여름)
                ) {
                    // 이 부분때문에, 나중에 다시 전화걸었는데 팝업안뜨는 문제가 있을 수도 있다. 그때가서 다시 개선한다.

                    // 같은 번호로 전화왔을때 10초 이상이 지났으면 모달을 다시 띄워준다.
                    if (dateUtil.getDiffSeconds(this.state.reservationPopupOpened) >= 10) {
                        this.setState({
                            reservationPopupOpened: new Date(),
                        }, () => {
                            this.openCustomerModal();
                        })
                    }
                    return;
                }

                this.props.dispatch(action.store.setRecentCallInfo(customer));
            });
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.author && this.props.author.accountId !== prevProps.author.accountId) {
            this.getStoreValues();
        }

        if (this.props.reservationUpdated !== prevProps.reservationUpdated
        || this.props.store_place !== prevProps.store_place
        || this.props.store_place_floor !== prevProps.store_place_floor
        || this.props.location.search !== prevProps.location.search) {
            this.getStoreValues();
        }

        if (prevState.tableDataValue
            && prevState.tableDataValue.floorTableData
            && prevState.tableDataValue.floorTableData.length > 0
            && JSON.stringify(this.state.tableDataValue.floorTableData) !== JSON.stringify(prevState.tableDataValue.floorTableData)) {
            this.getStoreValues();
        }

        //전화예약 체크
        if (this.props.recentCallInfo && !this.props.openModal && (JSON.stringify(prevProps.recentCallInfo) !== JSON.stringify(this.props.recentCallInfo))) {
            this.openCustomerModal();
        }

        // 모달 열기
        if (this.props.openModal && (this.props.openModal !== prevProps.openModal)) {
            if (this.props.openModal === 'customerModal') {
                this.openCustomerModal();
            }
        }
    }

    // 테이블 정보 리셋
    getStoreTableValues() {
        if (!this.state.selectedPlace.storePlaceId || !this.state.selectedFloor.storePlaceFloorId) {
            return;
        }

        const params = parseUrlParameter.parse(this.props.location.search);

        this.props.dispatch(action.store_table.getList({
            count: 100,
            fk_storeId: this.state.store.storeId,
            fk_storePlaceId: this.state.selectedPlace.storePlaceId,
            fk_storePlaceFloorId: this.state.selectedFloor.storePlaceFloorId,
            reservationStartTime: dateUtil.toISOString(dateUtil.format('dash', this.state.selectedDate)
                + ' ' + (this.props.selectedTime === 0 ? '00:00:00' : this.state.timeSection[this.props.selectedTime].startTime)),
            reservationEndTime: dateUtil.toISOString(dateUtil.format('dash', this.state.selectedDate)
                + ' ' + (this.props.selectedTime === 0 ? '24:00:00'
                    : dateUtil.isDayBreak(this.state.timeSection[this.props.selectedTime].endTime))), // 전체일때는 00:00:00 ~ 24:00:00
        })).then((result) => {
            const storeTableList = result.map((item) => {
                let storeTable = item.store_table;

                if (storeTable) {
                    storeTable.storeId = storeTable.fk_storeId;
                    storeTable.storePlaceFloorId = storeTable.fk_storePlaceFloorId;
                    storeTable.storePlaceId = storeTable.fk_storePlaceId;
                    storeTable.tableInfo = JSON.parse(storeTable.tableInfo);
                    storeTable.store_reservation = item.store_reservation;
                    ////// 새로 추가 (김진혁) - 이름별, 메뉴별 토글할 때 필요한 값 ////////
                    /////////////// mode 값 정의 //////////////////////////////////////////
                    //// 없거나 customer => 이름 띄우기 ///////////////////////////////////
                    //// menu => 메뉴 띄우기 //////////////////////////////////////////////
                    if (params.mode === 'menu') {
                        storeTable.mode = 'menu';
                    } else if (params.mode === 'customer') {
                        storeTable.mode = 'customer';
                    } else {
                        storeTable.mode = 'customer';
                    }
                }

                return storeTable || {};
            });

            let updateSelectFloorData = [];
            let findFloorData = _.filter(storeTableList, (item) => {
                return item.storePlaceId === this.state.selectedPlace.storePlaceId && item.storePlaceFloorId === this.state.selectedFloor.storePlaceFloorId;
            });
            if(!empty(findFloorData)){
                updateSelectFloorData = [].concat(findFloorData);
            }

            if(JSON.stringify(this.state.tableDataValue.floorTableData) !== JSON.stringify(updateSelectFloorData)) {
                this.setState({
                    tableDataValue: {
                        tableDataList:storeTableList,
                        floorTableData: updateSelectFloorData
                    }
                });
            }
        });
    }

    getStoreValues() {
        // GET 파라미터 파싱 (?xxx=xxx)
        let params = parseUrlParameter.parse(this.props.location.search);
        this.setState({
            account: this.props.author.account,
            store: this.props.store.store || {},
            brand: this.props.store.brand || {},
            selectedPlace: this.props.store_place || {},
            selectedFloor: this.props.store_place_floor || {},
            selectedDate: this.props.selectedDate || new Date(),
            selectedTime: this.props.selectedTime || 0,
            tableDataValue: {
                tableDataList:[],
                floorTableData: []
            }
        }, () => {
            this.setState({
                timeSection: [
                    {
                        index: 0,
                        name: '전체',
                        startTime: this.state.store.lunchStartTime ? this.state.store.lunchStartTime.substr(0,5) : '-',
                        endTime: this.state.store.dinnerEndTime ? this.state.store.dinnerEndTime.substr(0,5) : '-',
                        src: '/Assets/img/ic-all.png',
                        isChecked: true
                    }, {
                        index: 1,
                        name: '점심',
                        startTime: this.state.store.lunchStartTime ? this.state.store.lunchStartTime.substr(0,5) : '-',
                        endTime: this.state.store.lunchEndTime ? this.state.store.lunchEndTime.substr(0,5) : '-',
                        src: '/Assets/img/sun.png',
                        isChecked: false
                    }, {
                        index: 2,
                        name: '저녁',
                        startTime: this.state.store.dinnerStartTime ? this.state.store.dinnerStartTime.substr(0,5) : '-',
                        endTime: this.state.store.dinnerEndTime ? this.state.store.dinnerEndTime.substr(0,5) : '-',
                        src: '/Assets/img/sun.png',
                        isChecked: false
                    }
                ],
            }, () => {
                this.getStoreTableValues();
            });
        });
    }

    updateStoreTableValues(storeReservation) {
        let floorTableData = this.state.tableDataValue.floorTableData;
        for (let i = 0; i < storeReservation.length; i++) {
            if (storeReservation[i].store_reservation_table) {
                for (let a = 0; a < floorTableData.length; a++) {
                    if (storeReservation[i].store_reservation_table[0].fk_storeTableId == floorTableData[a].storeTableId) {
                        floorTableData[a].store_customer = storeReservation[i].store_customer;
                        floorTableData[a].store_reservation = storeReservation[i].store_reservation;
                    }
                }
            }
        }
        this.setState({
            tableDataValue: {
                tableDataList: this.state.tableDataValue.tableDataList,
                floorTableData: floorTableData
            }
        })
    }

	onTableSelect(storeTable) {
        console.log('테이블 선택');
        console.log(storeTable);
        if (this.state.isSubmitTable) return;
        this.setState({
            isSubmitTable: true,
        }, () => {
            let storeTableIds = this.tableSelectModalBody.state.storeTableIds || [];

            if (storeTableIds.indexOf(storeTable.storeTableId) > -1) {
                storeTableIds.splice(storeTableIds.indexOf(storeTable.storeTableId), 1);
            } else {
                storeTableIds.push(storeTable.storeTableId);
            }

            this.tableSelectModalBody.setState({
                storeTableIds: storeTableIds,
            });
            this.reservationModalBody.setStoreTableIds(storeTableIds);
            this.setState({
                isSubmitTable: false,
            });
            //this.closeTableSelectModal();
        });
	}

	openReservationModal(storeReservationId) {
        console.log('추가됩니다', storeReservationId)
        this.props.dispatch(action.store.setOpenModal('reservationModal'))

        this.props.dispatch(action.store_reservation.setSelectedReservationId(storeReservationId));
        this.reservationModal.setState({
          isOpened: !this.reservationModal.state.isOpened,
        }, () => {
          this.props.dispatch(action.store_reservation.getById({storeReservationId: this.props.selectedReservationId})).then((res) => {
              this.setState({
              selectedReservationStatus: res.store_reservation.reservationStatus
            })
      }).catch(() => {
        this.setState({
          selectedReservationStatus: 1
        })
      })
    });
	}

	openCustomerModal(params) {
		this.customerModal.setState({
			isOpened: true
		})
	}
    closeCustomerModal() {
        this.customerModal.close();
    }

    openCalendarModal() {
        this.calendarModal.setState({
          isOpened: !this.calendarModal.state.isOpened,
        })
  }

	closeReservationModal() {
		this.reservationModal.close();
		//this.getStoreValues(); 주석
	}

	openTableSelectModal() {
        const storeReservation = this.reservationModalBody && this.reservationModalBody.state ? this.reservationModalBody.state.storeReservation : null;

        if (!(storeReservation && storeReservation.storeReservationId)) {
            alert('예약을 먼저 완료해주세요.');
            return;
        }
		this.tableSelectModal.setState({
			isOpened: !this.tableSelectModal.state.isOpened
		});
	}

	closeCalendarModal() {
        this.calendarModal.close();
    }

	closeTableSelectModal() {
		this.tableSelectModal.close();
		this.reservationModalBody.handleSelectTable();
	}

	handleSubmit() {
		this.reservationModalBody.handleSubmit();
	}

	handleCancel() {
		this.reservationModalBody.handleCancel();
	}
    // 테이블 id
    setReservationModalItems(items) {
        this.setState(items);
    }

    handleChangingStatus(text, bool) {
        this.setState({
            isStatusChanging: true,
            alertText: text,
            isPositive: bool
        }, () => {
            setTimeout(() => {
                this.setState({ isStatusChanging: false });
            }, bool ? 1000 : 3000);
        })
    }

    render() {
	    let ReservationModalBtn = () => {
		    return (
			    <div className={styleReservation.reservationModalBtn}>
				    <div className={styleReservation.cancelReservation} onClick={this.handleCancel}>예약취소</div>
				    <div className={styleReservation.saveReservation} onClick={this.handleSubmit}>
              {this.state.selectedReservationStatus === 0 ? '예약수정' : '예약저장'}
            </div>
				    <div className={styleReservation.assignSeat} onClick={this.openTableSelectModal}>+ 좌석배정</div>
			    </div>
		    )
	    }

      let CustomerModalBtn = () => {
        return (
          this.props.store.system_payment && this.props.store.system_payment.isPhoneReservation ?
          <div className={styleReservation.reservationModalBtn} onClick={this.openCalendarModal}>
            <div className={styleReservation.assignSeat} style={{position:'absolute', left: '520px'}}>+ 예약추가</div>
          </div> : null
        )
      }

      let reservationModalStyle = { overlay: { zIndex: 99999 } }
      let calendarModalStyle = { overlay: { zIndex: 999999 } }
      let tableModalStyle = { overlay: { zIndex: 999999 } }

        return (
            <div className={styleStore.homeContainer} id="fullScreen">
                {
                    this.state.isLoading ? null :
                        <div className={styleStore.homeContent}>
                            <Helmet>
                                <title>예약현황</title>
                            </Helmet>
	                          <Route path={'/store/reservation'} exact={true} strict={false}
	                               component={() =>
		                               <ReservationComponent
			                               openReservationModal={(storeReservationId) => this.openReservationModal(storeReservationId)}
			                               parentState={this.state}
                                     updateTableInfo={() => this.getStoreTableValues()}
		                               />}
	                          />
                            <Route path={'/store/reservation/seatList'} exact={true} strict={false}
                                   component={SeatList}
                            />

	                        <Modal
                            customStyles={reservationModalStyle}
		                        closeParams={['storeReservationId', 'isOpened']}
		                        onRef={(ref) => {
			                        this.reservationModal = ref;
		                        }}
                                onClose={() => {
                                    this.props.dispatch(action.store.setOpenModal(null))
                                }}
		                        modalBody={
			                        <ReservationPopup
				                        onRef={(ref) => {
					                        this.reservationModalBody = ref;
				                        }}
				                        closeModal={() => this.closeReservationModal()}
                                        updateTableInfo={() => this.getStoreTableValues()}
                                        setReservationModalItems={(items) => this.setReservationModalItems(items)}
			                        />
		                        }
		                        customBtn={ReservationModalBtn()}
	                        />

                            <Modal
                                onRef={(ref) => {
                                    this.customerModal = ref;
                                }}
                                modalBody={
                                    <CustomerPopup
                                        onRef={(ref) => {
                                            this.customerModalBody = ref;
                                        }}
                                        openCalendarModal={this.openCalendarModal}
                                        closeCustomerModal={() => this.closeCustomerModal()}
                                        openReservationModal={this.openReservationModal}
                                    />}
                                openCustomerModal={this.openCustomerModal}
                                customBtn={CustomerModalBtn()}
                            />
                          <Modal
                            customStyles={calendarModalStyle}
                            onRef={(ref) => {
                              this.calendarModal = ref;
                            }}
                            noCloseBtn={true}
                            isCallProcessing={this.state.isCallProcessing}
                            shouldCloseOnOverlayClick={true}
                            customBody={styleTableSelectModal.modalContainer + ' ' + styleTableSelectModal.calendarModalContainer}
                            modalBody={
                              <div>
                                <SubHeader isCallReservation={true} />
                                <Calendar isCallReservation={true} customerModalBody={this.customerModalBody}
                                          closeCalendarModal={this.closeCalendarModal} closeCustomerModal={this.closeCustomerModal} closeReservationModal={this.closeReservationModal}/>
                              </div>
                            }
                          />
	                        <Modal
                            customStyles={tableModalStyle}
		                        closeParams={['tableSelect', 'isOpened']}
		                        onRef={(ref) => {
			                        this.tableSelectModal = ref;
		                        }}
                                customBody={styleTableSelectModal.modalContainer}
		                        modalBody={
			                        <TableSelectPopup
				                        onRef={(ref) => { this.tableSelectModalBody = ref; }}
				                        closeModal={this.closeTableSelectModal}
				                        onClick={this.onTableSelect}
				                        parentState={this.state}
			                        />
		                        }
	                        />
                        </div>
                }
            </div>
        )
    }
}

export default connect((state) => {
    return {
        author: state.data.account.author,
        store: state.data.store.store,
        recentCallInfo: state.data.store.recentCallInfo,
        store_place: state.data.store_place.store_place,
        store_place_floor: state.data.store_place_floor.store_place_floor,
        selectedReservationId: state.data.store_reservation.selectedReservationId,
        selectedDate: state.data.store_reservation.selectedDate,
        selectedTime: state.data.store_reservation.selectedTime,
        reservationUpdated: state.data.store_reservation.reservationUpdated,
        openModal: state.data.store.openModal,
    }
})(withRouter(ReservationView))
