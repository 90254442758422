import * as ActionTypes from '../actionTypes';

export const initialState = {
    store_staff: {},
    store_staff_list: []
}
/*
*/
export const store_staff_reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.STORE_STAFF:
            return Object.assign({}, state, {
                store_staff: action.store_staff
            });
        case ActionTypes.STORE_STAFF_LIST:
            return Object.assign({}, state, {
                store_staff_list: action.store_staff_LIST
            });
        default:
            return state;
        }
}
