import * as ActionTypes from '../actionTypes';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
// API
import * as HttpApi from '../Api/index';

export const actionTypes = ActionTypes;

//////////////////
///// GET ////////
//////////////////
export const getList = (params) => {
	return (dispatch) => {
		return HttpApi.get('GET_ACCOUNT_LIST', params)
			.then((response) => {
				return Promise.resolve(response.data);
			})
			.catch((err) => {
				return Promise.reject(err);
			});
	};
};

export const getCount = (params) => {
	return (dispatch) => {
		return HttpApi.get('GET_ACCOUNT_COUNT', params)
			.then((response) => {
				return Promise.resolve(response.data);
			})
			.catch((err) => {
				return Promise.reject(err);
			});
	};
};

export const getById = (params) => {
	return (dispatch) => {
		return HttpApi.get('GET_ACCOUNT_accountId', params)
			.then((response) => {
				return Promise.resolve(response.data);
			})
			.catch((err) => {
				return Promise.reject(err);
			});
	};
};

//////////////////
///// POST ///////
//////////////////
export const create = (params) => {
	return (dispatch) => {
		return HttpApi.post('POST_ACCOUNT_CREATE', params)
			.then((response) => {
				return Promise.resolve(response.data);
			})
			.catch((err) => {
				return Promise.reject(err);
			});
	};
};

//////////////////
///// PUT  ///////
//////////////////
export const updateAdmin = (params) => {
	return (dispatch) => {
		return HttpApi.post('POST_ACCOUNT_UPDATE_ADMIN', params)
			.then((response) => {
				console.log('POSTDFSFSDF', response);
				return Promise.resolve(response.data);
			})
			.catch((err) => {
				return Promise.reject(err);
			});
	};
};

//////////////////
///// DELETE /////
//////////////////
export const remove = () => {
    return (dispatch) => {
        return HttpApi.del('DELETE_ACCOUNT_DELETE')
            .then((response) => {
                return Promise.resolve(response.data);
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };
};

//////////////////
/// FUNCTIONS ////
//////////////////
export const session = () => {
	return (dispatch) => {
		return HttpApi.get('GET_AUTH_SESSION')
			.then((response) => {
				console.log('get-auth-session', response);
				dispatch({ type: ActionTypes.LOGIN, author: response.data });
				return Promise.resolve(response.data);
			})
			.catch((err) => {
				return Promise.reject(err);
			});
	};
};

export const sessionAdmin = () => {
    return (dispatch) => {
        return HttpApi.get('GET_AUTH_ADMIN_SESSION')
            .then((response) => {
                dispatch({ type: ActionTypes.LOGIN, author: response.data });
                return Promise.resolve(response.data);
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };
};

export const login = (params) => {
	return (dispatch) => {
		return HttpApi.post('POST_AUTH_LOGIN', params)
			.then((response) => {
				dispatch({ type: ActionTypes.LOGIN, author: response.data });
				return Promise.resolve(response.data);
			})
			.catch((err) => {
				return Promise.reject(err);
			});
	};
};

export const logout = () => {
	return (dispatch) => {
		return HttpApi.post('POST_AUTH_LOGOUT')
			.then((response) => {
				cookies.remove('klient-manager-auto-login', { path: '/' });
				dispatch({ type: ActionTypes.LOGOUT });
				return (Promise.resolve(response.data));
			})
			.catch((err) => {
				dispatch({ type: ActionTypes.LOGOUT });
				return Promise.resolve(err);
			});
	};
};

export const loginAdmin = (params) => {
  return (dispatch) => {
    return HttpApi.post('POST_ACCOUNT_AUTH_LOGIN_ADMIN', params)
      .then((response) => {
		if(response) {
			dispatch({ type: ActionTypes.LOGIN, author: response.data });
		}
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };
};

export const getAdminList = (params) => {
  return (dispatch) => {
    return HttpApi.get('GET_ACCOUNT_USER_LIST_ADMIN', params)
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };
};