import React, {Fragment, useEffect, useState} from "react";
import styleTableManagement from "@/Service/Styles/Components/TableManagement.css";
import _ from "underscore";
import empty from 'just-is-empty';
import {withRouter} from 'react-router-dom';
import {connect} from "react-redux";
import {Rnd} from "react-rnd";
import { action } from '@/Data'

// Utils
import * as dateUtil from '@/Utils/date'

const Tables = ({floorTableData, setTablePosition, selectTableShape, editValue, saveData, onClick, selectedTableIds = [], tableStyle}) => {
    const [deltaPosition, setDeltaPosition] = useState([]);
    const [floorTableData_, setFloorTableData_] = useState(floorTableData);
    const [moveFlagList, setMoveFlagList] = useState([]);
    const [lastClickedTable, setLastClickedTable] = useState({});

    useEffect(() => {
        let moveFlagList_ = [];
        _.each(floorTableData, (item) => {
            let itemKey = `${item.storePlaceId}-${item.storePlaceFloorId}-${item.storeTableId}`;
            moveFlagList_.push({
                key: itemKey,
                isLoad: false
            });
        });
        setMoveFlagList(moveFlagList_);
        setFloorTableData_(floorTableData);
    }, [floorTableData]);

    const setInitDeltaPosition = () => {
        setDeltaPosition([]);
    }

    let handleDragStart = (e, data, tableInfo) => {
        if (editValue.index == -1) return; // 수정 불가능한 모드임
        let itemKey = `${tableInfo.storePlaceId}-${tableInfo.storePlaceFloorId}-${tableInfo.storeTableId}`;
        let findItem = _.find(moveFlagList, (item) => {
            return item.key === itemKey;
        });
        if (!empty(findItem)) {
            findItem.isLoad = true;
        }
    };

    // 테이블 드래그할때
    let handleDrag = (e, data, tableItem) => {
        if (editValue.index == -1) return; // 수정 불가능한 모드임
        let itemKey = `${tableItem.storePlaceId}-${tableItem.storePlaceFloorId}-${tableItem.storeTableId}`;
        let deltaPosition_ = JSON.parse(JSON.stringify(deltaPosition));
        let findItem = _.find(deltaPosition_, (item) => {
            return `${item.storePlaceId}-${item.storePlaceFloorId}-${item.storeTableId}` === itemKey;
        });
        if (!empty(findItem)) {
            findItem.tableInfo.x = data.x;
            findItem.tableInfo.y = data.y;
        } else {
            tableItem.tableInfo.x = data.x;
            tableItem.tableInfo.y = data.y;
            deltaPosition_.push(tableItem);
        }
        setDeltaPosition(deltaPosition_);
    };

    let handleDragStop = (e, data, tableItem) => {
        if (editValue.index == -1) return; // 수정 불가능한 모드임
        setTimeout(function () {
            console.log('드래그 끝', deltaPosition, tableItem);
            const deltaPosition_ = JSON.parse(JSON.stringify(deltaPosition));
            setTablePosition(deltaPosition_, tableItem);
            const findPositionIndex = _.findIndex(deltaPosition_, (item) => {
                return tableItem.storePlaceId === item.storePlaceId && tableItem.storePlaceFloorId === item.storePlaceFloorId
                  && tableItem.storeTableId === item.storeTableId;
            });
            saveData(deltaPosition_[findPositionIndex]);
        }, 100)
    };

    let handleResize = (e, direction, ref, delta, position, tableItem) => {
        if (editValue.index == -1) return; // 수정 불가능한 모드임
        console.log('--handleResize')
        let itemKey = `${tableItem.storePlaceId}-${tableItem.storePlaceFloorId}-${tableItem.storeTableId}`;
        let deltaPosition_ = JSON.parse(JSON.stringify(deltaPosition));
        console.info("deltaPosition_ >>> handleResize  ", deltaPosition_)

        let findItem = _.find(deltaPosition_, (item) => {
            return `${item.storePlaceId}-${item.storePlaceFloorId}-${item.storeTableId}` === itemKey;
        });
        if (!empty(findItem)) {
            findItem.tableInfo.width = ref.style.width.replace('px', '');
            findItem.tableInfo.height = ref.style.height.replace('px', '');
        } else {
            tableItem.tableInfo.width = ref.style.width.replace('px', '');
            tableItem.tableInfo.height = ref.style.height.replace('px', '');
            deltaPosition_.push(tableItem)
        }
        console.log(deltaPosition_)
        setDeltaPosition(deltaPosition_);
    }

    let handleResizeStart = (e, direction, ref, tableInfo) => {
        if (editValue.index == -1) return; // 수정 불가능한 모드임
        let itemKey = `${tableInfo.storePlaceId}-${tableInfo.storePlaceFloorId}-${tableInfo.storeTableId}`;
        let findItem = _.find(moveFlagList, (item) => {
            return item.key === itemKey;
        });
        if (!empty(findItem)) {
            findItem.isLoad = true;
        }
    };

    let handleResizeStop = (e, direction, ref, delta, position, tableItem) => {
        if (editValue.index == -1) return; // 수정 불가능한 모드임
        console.log('--handleResizeStop')
        let itemKey = `${tableItem.storePlaceId}-${tableItem.storePlaceFloorId}-${tableItem.storeTableId}`;
        let deltaPosition_ = JSON.parse(JSON.stringify(deltaPosition));
        for (let i = 0; i < deltaPosition_.length; i++) {
            if (typeof deltaPosition_[i].tableInfo === 'string') {
                deltaPosition_[i].tableInfo = JSON.parse(deltaPosition_[i].tableInfo);
            }
        }
        console.info("deltaPosition_ >>> handleResizeStop  ", deltaPosition_)
        let findItem = _.find(deltaPosition_, (item) => {
            return `${item.storePlaceId}-${item.storePlaceFloorId}-${item.storeTableId}` === itemKey;
        });
        if (!empty(findItem)) {
            findItem.tableInfo.width = ref.style.width.replace('px', '');
            findItem.tableInfo.height = ref.style.height.replace('px', '');
        } else {
            tableItem.tableInfo.width = ref.style.width.replace('px', '');
            tableItem.tableInfo.height = ref.style.height.replace('px', '');
            deltaPosition_.push(tableInfo)
        }
        setDeltaPosition(deltaPosition_);
        setTimeout(function () {
            setTablePosition(deltaPosition_, tableItem);
            saveData(findItem);
        }, 100)
    };

    let onDragStop_ = _.debounce(handleDragStop, 0);
    let onResizeStop_ = _.debounce(handleResizeStop, 0);

    let selectShape = (e, index, storeTable) => {
        if (typeof onClick === 'function')
            onClick(storeTable);
        if (editValue.index == -1) return; // 수정 불가능한 모드임
        let floorTableData__ = _.clone(floorTableData_);
        _.each(floorTableData__, (item, i) => {
            item.selected = i === index;
        });
        setFloorTableData_(floorTableData__);

        selectTableShape(storeTable.storeTableId, floorTableData__);
    };

    let dragOver = (e) => {
        // console.warn("dragOver >>>> ", e)
    };


    let renderShape = (tableInfo, item, i) => {
        let top_ = tableInfo.type === 'diamond' ? 0 : -10;
        let left_ = tableInfo.type === 'diamond' ? -4 : -10;
        let right_ = tableInfo.type === 'diamond' ? 684 : 695;
        let bottom_ = tableInfo.type === 'diamond' ? 463 : 470;
        let itemKey = `${item.storePlaceId}-${item.storePlaceFloorId}-${item.storeTableId}`;
        let position_ = {
            x: tableInfo.x,
            y: tableInfo.y,
        };
        let size_ = {
            width: tableInfo.width,
            height: tableInfo.height,
        };
        let findItem = _.find(moveFlagList, (item) => {
            return item.key === itemKey;
        });
        if (!empty(findItem) && findItem.isLoad) {
            position_ = null;
            size_ = null;
        }

        let tableTypeClass, noTitleClass;
        if (tableInfo.type === 'rect') {
            tableTypeClass = styleTableManagement.tableRect;
            noTitleClass = styleTableManagement.tableItemRect;
        } else if (tableInfo.type === 'circle') {
            tableTypeClass = styleTableManagement.tableCircle;
            noTitleClass = styleTableManagement.tableItemCircle;
        } else if (tableInfo.type === 'diamond') {
            tableTypeClass = styleTableManagement.tableDiamond;
            noTitleClass = styleTableManagement.tableItemDiamond;
        }
        let lineStyle, lineWidth;
        if (tableInfo.lineType === 1) {
            lineStyle = 'solid';
            lineWidth = 1;

        } else if (tableInfo.lineType === 2) {
            lineStyle = 'dotted';
            lineWidth = 2;

        } else if (tableInfo.lineType === 3) {
            lineStyle = 'dashed';
            lineWidth = 2;

        }


        let itemStyle = {
            border: `${lineWidth}px ${lineStyle} ${tableInfo.lineColor}`,
            background: tableInfo.backColor,
        };
        let isEditAble = false;
        if(!empty(findItem) && editValue.index === item.storeTableId){
            isEditAble = true;
        }
        let itemResizing = {
            top: false, right: false, bottom: false, left: false,
            topRight: isEditAble, bottomRight: isEditAble,
            bottomLeft: isEditAble, topLeft: isEditAble
        };

        return (
          <Fragment>
              <div>
                  <Rnd
                    className={`${styleTableManagement.tableItemWrapper} ${!isEditAble ? styleTableManagement.tableItemWrapperEdit : ""}`
                    + ' ' + (typeof onClick === 'function' ? styleTableManagement.tableItemBoxSelect
                    + (selectedTableIds.indexOf(item.storeTableId) > -1 ? ' ' + styleTableManagement.active : '') : '')}
                    // bounds={`${styleTableManagement.tablesWrapper}`}
                    size={size_ || null}
                    enableResizing={itemResizing}
                    disableDragging={editValue.index == -1 ? true : false}
                    minWidth={100}
                    minHeight={100}
                    dragGrid={[10,10]}
                    resizeGrid={[10,10]}
                    resizeHandleClasses={{
                        // bottomLeft: string,
                        bottomRight: `${styleTableManagement.resizeBR} ${isEditAble ? styleTableManagement.resizeBREdit : ""}`,
                        // topLeft: string;
                        // topRight: string;
                    }}
                    lockAspectRatio={tableInfo.lockAspectRatio || false}
                    position={position_ || null}
                    onDragStart={(e, data) => handleDragStart(e, data, item)}
                    onDragStop={(e, data) => onDragStop_(e, data, item)}
                    onDrag={(e, data) => handleDrag(e, data, item)}
                    onClick={(e) => {setLastClickedTable(item)}}
                    onResizeStart={(e, direction, ref) => handleResizeStart(e, direction, ref, item)}
                    onResizeStop={(e, direction, ref, delta, position) => onResizeStop_(e, direction, ref, delta, position, item)}
                    onResize={(e, direction, ref, delta, position) => handleResize(e, direction, ref, delta, position, item)}
                  >
                      <div
                        style={itemStyle}
                        onClick={(e) => selectShape(e, i, item)}
                        className={`${styleTableManagement.tableItemBox} ${tableTypeClass}`}>
                          <div
                            onDragOver={(e) => dragOver(e)}
                            className={`${styleTableManagement.tableItem} ${styleTableManagement.reactDraggableCursor} ${noTitleClass}`}>
                              <div className={styleTableManagement.tableItemTileNo} style={{fontSize: tableStyle + 'px'}}>
                                  {tableInfo.tableTitleNo}
                              </div>
                              { (item.store_reservation || []).map((reservation, index) => {
                                  return (
                                    <div key={index} className={styleTableManagement.tableItemReservationList}>
                                        <div className={styleTableManagement.tableItemReservationItem} style={
                                            reservation.visitStatus === 0 ? {backgroundColor: '#fff'} :
                                              reservation.visitStatus === 1 ? {backgroundColor: '#cdecff'} :
                                                reservation.visitStatus === 2 ? {backgroundColor: 'rgba(201, 161, 52, 0.4)'} :
                                                  reservation.visitStatus === 10 ? {backgroundColor: '#d8d8d8'} :
                                                    reservation.visitStatus === 20 ? {backgroundColor: 'rgba(234, 62, 14, 0.3)'} : null
                                        }>
                                            <div className={styleTableManagement.tableItemReservationItemTime} style={{fontSize: tableStyle + 'px'}}>
                                                {dateUtil.format('time', reservation.reservedAt)}
                                            </div>
                                            <div className={styleTableManagement.tableItemReservationItemName} style={
                                                {left: tableStyle == 18 || tableStyle == 16 ? '50px' : null, fontSize: tableStyle + 'px'
                                                  , color: reservation.store_customer.isVip ? '#7434cc' : null, right: reservation.groupNum ? '56px' : '30px'}
                                            }>
                                                {item.mode === 'customer' ? reservation.store_customer.name : null}
                                                {item.mode === 'menu' ? (reservation.store_menu || []).map((menu) => {
                                                    return menu.menu;
                                                }).join(',') : null}
                                            </div>
                                            <div className={styleTableManagement.tableItemReservationNumberOfPeople} style={
                                              {fontSize: tableStyle + 'px', right: reservation.groupNum ? '20px' : '5px' }}>
                                                {reservation.numberOfPeople + ' 명'}
                                            </div>
                                            {
                                                reservation.groupNum ?
                                                    <div className={styleTableManagement.tableItemReservationItemCount}>
                                                        {reservation.groupNum}
                                                    </div> : null
                                            }
                                        </div>
                                    </div>
                                  )
                              })}
                          </div>
                      </div>
{/*                      {selectedTableIds.indexOf(item.storeTableId) > -1 && lastClickedTable.storeTableId === item.storeTableId ?
                          <div style={{position:'absolute', left: '-70px', bottom: '-70px'}}>선택완료</div> : null
                      }*/}
                  </Rnd>
              </div>


          </Fragment>
        )
    };
    return (
      <Fragment>
          <div className={styleTableManagement.tablesWrapper}>
              <div className={"tablesWrapper"} id="tables">
                  {
                      (floorTableData_ || []).map((item, index) => {
                          return (
                            <Fragment key={index} style={{position: 'relative'}}>
                                {
                                    renderShape(item.tableInfo, item, index)
                                }
                            </Fragment>
                          )
                      })
                  }
              </div>
          </div>
      </Fragment>
    );
};

const style = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "solid 1px #ddd",
    background: "#ffffff00",
};
export default connect((state) => {
    return {
        author: state.data.account.author,
        store: state.data.store.store,
    };
})(withRouter(Tables));
