import * as ActionTypes from '../actionTypes';

export const initialState = {
    customer: {},
    customer_list: []
}
/*
*/
export const customer_reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.CUSTOMER:
            return Object.assign({}, state, {
                customer: action.customer
            });
        case ActionTypes.CUSTOMER_LIST:
            return Object.assign({}, state, {
                customer_list: action.customer_list
            });
        default:
            return state;
        }
}
