import * as ActionTypes from '../actionTypes';

export const initialState = {
    store_reservation_table: {},
    store_reservation_table_list: []
}
/*
*/
export const store_reservation_table_reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.STORE_RESERVATION_TABLE:
            return Object.assign({}, state, {
                store_reservation_table: action.store_reservation_table
            });
        case ActionTypes.STORE_RESERVATION_TABLE_LIST:
            return Object.assign({}, state, {
                store_reservation_table_list: action.store_reservation_table_LIST
            });
        default:
            return state;
        }
}
