import React, {Component} from 'react';
import {withRouter, Route} from 'react-router-dom';
import {connect} from 'react-redux';
import {Helmet} from "react-helmet";

// styles
import styleStore from '../../Styles/Components/Store.css'
import styleCall from '../../Styles/Components/Call.css'
import { action } from '../../../Data'
import styleVisit from '../../Styles/Components/Visit.css'

class CallView extends Component{
  constructor(){
    super();

    this.state = {
      store: {},
      brand: {},
      systemPaymentDefault: {},
      storePlace: [],
      callNow: {},
      images: [],
      imageSeq: 0,
      step: 0,
      screenChangeTime: 10000,

      isFullScreen: false
    }

    this.getStoreRelatedValues = this.getStoreRelatedValues.bind(this);
    this.pageTransition = this.pageTransition.bind(this);
    this.checkAlarm = this.checkAlarm.bind(this);
    this.handleFullScreen = this.handleFullScreen.bind(this);
    this.hanldeStep = this.handleStep.bind(this);
    this.pageTransitionInterval = null;
    this.prevCallNow = {};
  }

  componentWillMount() {
      if (this.props.author.store) {
          this.props.dispatch(action.store.getById({storeId: this.props.author.store.storeId})).then((res) => {
              this.pageTransitionInterval = setInterval(this.pageTransition, res.store.screenChangeTime);
              this.setState({
                  store: res.store,
                  brand: res.brand,
                  screenChangeTime: res.store.screenChangeTime,
                  systemPaymentDefault: res.system_payment_default,
                  images: [
                    res.store.menuUrl,
                    ...res.store_file.filter(file => file.store_file.type != '0').map(file => file.store_file.fileUrl)
                    ],
              });
          });

          if (!this.props.store.store_system.isScreen) {
            this.setState({
              step: 1
            })
          }

          setInterval(this.checkAlarm, 3000);
          this.getStoreRelatedValues();
      }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.author !== prevProps.author) {
      this.setState({
        store: this.props.author.store,
        brand: this.props.author.brand
      })
    }
  }

  checkAlarm() {
      const checkStoreChanged = () => {
          const promise = new Promise((resolve) => {
              this.props.dispatch(action.store.getById({storeId: this.props.author.store.storeId})).then((res) => {
                  resolve(res.store.screenChangeTime != this.state.screenChangeTime ? res.store.screenChangeTime : null)
              });
          });
          return promise;
      }

      checkStoreChanged().then((result) => {
          this.props.dispatch(action.log_store_alarm_history.now({
              fk_storeId: this.state.store.storeId
          })).then((res) => {
              if (!res.code && res.length > 0) {
                  clearInterval(this.pageTransitionInterval);
                  this.pageTransitionInterval = setInterval(this.pageTransition, result || this.state.screenChangeTime);

                  const audio = new Audio(this.state.systemPaymentDefault.notificationUrl);
                  if(this.prevCallNow.createdAt != res[0].createdAt) {
                    audio.play();
                    this.prevCallNow = res[0];
                  }

                  this.setState({
                      callNow: res[0],
                      step: 2
                  })
              } else if (result) {
                  clearInterval(this.pageTransitionInterval);
                  this.pageTransitionInterval = setInterval(this.pageTransition, result);
              }
              this.setState({
                  screenChangeTime: result || this.state.screenChangeTime,
              })
          })
      });
  }

  pageTransition() {
    const imageSeq = this.state.step == 1
      ? (this.state.imageSeq + 1) % this.state.images.length : this.state.imageSeq;

    if (!this.props.store.store_system.isScreen) {
      this.setState({
        step: 1,
        imageSeq: imageSeq,
      })
    } else if (!this.props.store.store_system.isScreenMenu) {
      this.setState({
        step: 0
      }, () => {
        this.getStoreRelatedValues();
      });
    } else {
      this.setState({
        step: this.state.step == 0 && this.state.store.menuUrl ? 1 : 0,
        imageSeq: imageSeq,
      }, () => {
        if (this.state.step === 0) {
          this.getStoreRelatedValues();
        }
      });
    }
  }

  getStoreRelatedValues() {
    if (this.props.author.store) {
        this.props.dispatch(action.store_place.getList({
            fk_storeId: this.props.author.store.storeId,
            order: 'createdAt',
            orderMethod: 'ASC'
        })).then((res) => {
            this.setState({
                storePlace: res
            })
        })
        this.props.dispatch(action.service_system.getServiceSystem()).then((res) => {
            this.setState({
                serviceSystem: res[0]
            })
        })
    }
  }

  handleFullScreen(element) {
    let requestMethod = element.requestFullScreen || element.webkitRequestFullScreen || element.mozRequestFullScreen || element.msRequestFullScreen;
    if (requestMethod) {
      requestMethod.call(element);
    } else if (typeof window.ActiveXObject !== "undefined") { // Older IE.
      let wscript = new ActiveXObject("WScript.Shell");

      if (wscript !== null) {
        wscript.SendKeys("{F11}");
      }
    }

    // this.setState({
    //   isFullScreen: !this.state.isFullScreen
    // })
    // if (!this.state.isFullScreen) {
    // } else {
    //   // document.exitFullscreen();
    // }
  }

  handleStep() {
    if (!this.props.store.store_system.isScreen || !this.props.store.store_system.isScreenMenu) return;

    this.setState({
        step: this.state.step == 0 ? 1 : 0,
    })
  }

  render(){
    const storePlaceLen = this.state.storePlace.length;
    let renderWaitingStatus = this.state.storePlace ? this.state.storePlace.map((place, index) => {
      if (place.store_place.storePlaceId !== 4) {
        return (
          <div key={index} className={storePlaceLen === 1 ? styleCall.callOutputDiv : storePlaceLen === 2 ? styleCall.callOutputDiv2 : styleCall.callOutputDiv3 }>
            <div className={styleCall.building} style={this.props.store.store_system.isWaitingTime ? null : {marginTop: '70px'}}>{place.store_place.name}</div>
            <div className={styleCall.teams}>{place.store_place.waitingTeams} 팀</div>
            { this.props.store.store_system.isWaitingTime ?
              <div>
                <img className={styleCall.timeIcon} src="/Assets/img/ic-time.png"/>
                <span className={styleCall.time}>{place.store_place.waitingTime} 분</span>
              </div>
            : null }
          </div>
        )
      }
    }) : null

    return (
      <div className={styleCall.callContainer}>
        <Helmet>
          <title>호출모드</title>
        </Helmet>
        { this.state.step === 0 ?
        <div className={styleCall.callContent} onClick={() => this.handleStep(this.state.step)}>
          <div className={styleCall.expandIcon} onClick={() => this.handleFullScreen(document.body)}><img src="/Assets/img/ic-expand.png"/></div>
          <div className={styleCall.brandInfo}>
            <div className={styleCall.branchIcon}><img className={styleCall.brandIcon} src={this.state.store.profileUrl}/></div>
            <div className={styleCall.branchName}>{this.state.store ? this.state.store.name : ''}</div>
          </div>
          <div className={styleCall.callOutput}>
            {renderWaitingStatus}
          </div>
        </div> : null }
        { this.state.step === 1 ?
        <div className={styleCall.menuContent} onClick={() => this.handleStep(this.state.step)}>
          <div className={styleCall.expandIcon} onClick={() => this.handleFullScreen(document.body)}><img src="/Assets/img/ic-expand.png" /></div>
          <img className={styleCall.menuContentImgBlur} style={{ backgroundImage: "url(" + (this.state.images ? this.state.images[this.state.imageSeq] : '') + ")" }}/>
          <div className={styleCall.menuContentImg} style={{ backgroundImage: "url(" + (this.state.images ? this.state.images[this.state.imageSeq] : '') + ")" }}/>
        </div> : null }
        { this.state.step === 2 ?
        <div className={styleCall.callContent}>
          <div className={styleCall.expandIcon} onClick={() => this.handleFullScreen(document.body)}><img src="/Assets/img/ic-expand.png" /></div>
          <div className={styleCall.brandInfoCall}>
            <div className={styleCall.branchIcon}><img className={styleCall.brandIcon} src={this.state.store.profileUrl}/></div>
            <div className={styleCall.branchName}>{this.state.store ? this.state.store.name : ''}</div>
          </div>
          <div className={styleCall.callOutputCall}>
            <div className={styleCall.building} style={{ marginTop: '80px'}}>{this.state.callNow.name}</div>
            <div className={styleCall.enterNumber}>{this.state.callNow.waitingNumber} 번</div>
            <div className={styleCall.enterMent}>{this.state.store.guideText}</div>
          </div>
        </div> : null }
        <div className={styleVisit.kmLogo}>
          <img src={this.state.serviceSystem ? this.state.serviceSystem.service_system.blackBgLogoUrl : "/Assets/img/km-logo-2-24-px-white.png"} />
        </div>
      </div>
    )
  }
}

export default connect((state) => {
  return {
    author: state.data.account.author,
    store: state.data.store.store,
  };
})(withRouter(CallView));