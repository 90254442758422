import * as ActionTypes from '../actionTypes';

export const initialState = {
    customer_prefer_place: {},
    customer_prefer_place_list: []
}
/*
*/
export const customer_prefer_place_reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.CUSTOMER_PREFER_PLACE:
            return Object.assign({}, state, {
                customer_prefer_place: action.customer_prefer_place
            });
        case ActionTypes.CUSTOMER_PREFER_PLACE_LIST:
            return Object.assign({}, state, {
                customer_prefer_place_list: action.customer_prefer_place_LIST
            });
        default:
            return state;
        }
}
