import * as ActionTypes from '../actionTypes';

export const initialState = {
    store_phone_number: {},
    store_phone_number_list: []
}
/*
*/
export const store_phone_number_reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.STORE_PHONE_NUMBER:
            return Object.assign({}, state, {
                store_phone_number: action.store_phone_number
            });
        case ActionTypes.STORE_PHONE_NUMBER_LIST:
            return Object.assign({}, state, {
                store_phone_number_list: action.store_phone_number_LIST
            });
        default:
            return state;
        }
}
