import React from 'react';
// import {
//     Link,
// } from 'react-router-dom';
import ReactModal from 'react-modal';
import styleModal from './Modal.css';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {parseUrlParameter} from "../../../Utils";
import onClickOutside from "react-onclickoutside";
import { MoonLoader } from 'react-spinners';
import { action } from '@/Data'


class Modal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpened: false,
        };

        this.close = this.close.bind(this)
    }
    componentWillMount() {
        ReactModal.setAppElement('body');
    }
    componentDidMount() {
        this.props.onRef(this);
    }
    open() {
        this.setState({ isOpened: true });
    }
    close() {
        this.setState({ isOpened: false }, () => {
            let pathname = this.props.location.pathname;
            let query = parseUrlParameter.parse(this.props.location.search);

            //query = query.replace('isOpened=true', 'isOpened=false');
            const queryArr = [];
            let closeParams = this.props.closeParams || [];
            for (let i=0; i<Object.keys(query).length; i++) {
                let key = Object.keys(query)[i];
                if (key && closeParams.indexOf(key) === -1) {
                    queryArr.push(key + '=' + query[key]);
                }
            }
            if (this.props.onClose) {
                this.props.onClose();
            }
        });


        // 같은 쿠폰눌렀을때 모달오픈되도록
/*        if(this.props.isCouponModal) {
            this.props.history.push('/store/board/coupon/send');
        } else if (this.props.isNoticeModal) {
            this.props.history.push('/store/board/notice');
        }*/
    }

    render() {
        let isLoading = (
            <div style={{width: '91px', height: '91px', top: 0, bottom: 0, left: 0, right: 0, margin: 'auto', position: 'absolute'}}>
                <MoonLoader />
            </div>
        );

        return (
            <ReactModal
                isOpen={this.state.isOpened}
                onRequestClose={this.close.bind(this)}
                contentLabel="Modal"
                shouldCloseOnEsc={false}
                shouldCloseOnOverlayClick={this.props.shouldCloseOnOverlayClick || false}
                overlayClassName={styleModal.overlay}
                className={this.props.customBody || styleModal.modalContainer}
                style={this.props.customStyles}
            >
                <div className={styleModal.modalBody + (this.props.customBody ? ' ' + this.props.customBody : '')}>
                    {this.props.modalBody || isLoading}
                </div>
                <div className={styleModal.closeBtn} onClick={this.close} style={this.props.noCloseBtn ? {display: 'none'} : null}><img src="/Assets/img/btn-close.png"/></div>
                { this.props.customBtn || null }
            </ReactModal>
        );
    }
}

export default connect((state) => {
    return {
        author: state.data.account.author,
        selectedDate: state.data.store_reservation.selectedDate,
        recentCallInfo: state.data.store.recentCallInfo,
    }
})(withRouter(Modal))