import * as ActionTypes from '../actionTypes';

export const initialState = {
    invoice: {},
    invoice_list: []
}
/*
*/
export const invoice_reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.INVOICE:
            return Object.assign({}, state, {
                invoice: action.invoice
            });
        case ActionTypes.INVOICE_LIST:
            return Object.assign({}, state, {
                invoice_list: action.invoice_LIST
            });
        default:
            return state;
        }
}
