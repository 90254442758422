import React, {Component} from 'react';
import { withRouter, Route, Link } from 'react-router-dom'
import {connect} from 'react-redux';
import { Helmet } from 'react-helmet';
import Modal from '../../../../Common/Modal';
import ReservationPopup from "../../Reservation/ReservationModal";
import moment from 'moment';

// styles
import styleStore from '../../../../Styles/Components/Store.css'
import styleTable from '@/Service/Styles/Common/Table.css'
import styleBoard from '@/Service/Styles/Components/Board.css'

import SearchBar from '@/Service/Components/Store/Statistics/PeriodSearchBar'
import InfiniteList from '../../../../Common/InfiniteList/index';
import { converter, dateUtil } from '@/Utils'

import * as ActionLogPayment from '../../../../../Data/Action/log_payment';
import { action } from '@/Data'
import styleSeatList from '@/Service/Styles/Components/SeatList.css'
import styleReservationModal from '@/Service/Styles/Components/ReservationModal.css'

class ReservationFeeInquiry extends Component{
  constructor(props){
    super(props);

    this.state = {
      searchQuery: '',
      reservationFeeConfig: {
        COLUMN_NAME: ['가맹점', '예약 정보', '예약금 결제일자', '결제 금액', '가맹점 지급금액', '승인번호', '승인여부', '취소일자' ],
        COLUMN_FIELD: ['name', '', 'createdAt', 'sales','profit', 'acceptNo', 'isApproved', 'cancelledAt'],
        COLUMN_SIZE: ['12.5%', '12.5%', '12.5%', '12.5%', '12.5%', '12.5%', '12.5%', '12.5%'],
      },
    }

    this.getReservationFeeList = this.getReservationFeeList.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.closeReservationModal = this.closeReservationModal.bind(this);
    this.openReservationModal = this.openReservationModal.bind(this);
    this.handleChangingStatus = this.handleChangingStatus.bind(this);
  }

  componentWillMount() {
    this.setState({
      reservationFeeParams: {
        fk_storeId: this.props.store && this.props.store.store ? this.props.store.store.storeId : null,
        order: 'createdAt',
        orderMethod: 'DESC',
        startDate: dateUtil.format('YYYY-MM-DD', new Date(dateUtil.getSubtractedDay(7, 'days'))),
        endDate: dateUtil.format('YYYY-MM-DD', new Date(dateUtil.getSubtractedDay(0, 'days'))),
      },
    })

  }

  getReservationFeeList(list){
    if (!list || list.length === 0) {
      return;
    }
    let checked = [];
      (list || []).forEach(() => {
      checked.push(false);
    })
    this.setState({
      reservationFeeList: list,
      checked: checked,
    })
  }

  handleSearch(params) {
    let reservationFeeParams =  Object.assign(this.state.reservationFeeParams, params)
    this.setState({
      reservationFeeParams: reservationFeeParams
    }, () => {
      this.reservationFeeList.reset();
    })
  }

  closeReservationModal() {
    this.reservationModal.close();
    //this.getStoreValues(); 주석
  }

  openReservationModal(storeReservationId) {
    this.props.dispatch(action.store_reservation.setSelectedReservationId(storeReservationId));
    this.reservationModal.setState({
      isOpened: !this.reservationModal.state.isOpened,
    }, () => {
      this.props.dispatch(action.store_reservation.getById({storeReservationId: this.props.selectedReservationId})).then((res) => {
        this.setState({
          selectedReservationStatus: res.store_reservation.reservationStatus
        })
      })
    });
  }

  handleChangingStatus(text, bool) {
    this.setState({
      isStatusChanging: true,
      alertText: text,
      isPositive: bool
    }, () => {
      setTimeout(() => {
        this.setState({ isStatusChanging: false });
      }, bool ? 1000 : 3000);
    })
  }

  render(){
    const renderReservationFeeListHeader = () => {
      return (
        <div className={styleTable.tableContentHeader}>
          { this.state.reservationFeeConfig.COLUMN_FIELD.map((field, index) => {
            let reservationFeeConfig = this.state.reservationFeeConfig;
            return (
              <div
                className={styleTable.column + ' ' + (field ? styleTable.style2 : styleTable.style3)}
                style={{width: reservationFeeConfig.COLUMN_SIZE[index], height: '52px', lineHeight: index == 4 ? '30px' : '52px'}}
                key={index}
              >
                { reservationFeeConfig.COLUMN_NAME[index] }
                {index == 4 ? <div style={{color: '#98a0ab', fontSize: '12px', lineHeight: '12px'}}>결제금액-수수료</div> : null}
              </div>
            )
          }) }
        </div>
      );
    }

    const renderReservationFeeList = (item, index) => {
      const config = this.state.reservationFeeConfig;

      return (
        <div className={styleTable.tableContentItem + (item.isChecked ? ' ' + styleTable.active : '')}
             key={index} style={{height: '52px', lineHeight: '52px'}}
        >
          <div
            className={styleTable.column}
            style={{width: config.COLUMN_SIZE[0], lineHeight: '30px'}}
          >
            {converter.parseListByType(item['brand'][config.COLUMN_FIELD[0]])}
            <div style={{color: '#98a0ab', fontSize: '12px', lineHeight: '12px'}}>
              {converter.parseListByType(item['store'][config.COLUMN_FIELD[0]])}
            </div>
          </div>
          <div
            className={styleTable.column}
            style={{width: config.COLUMN_SIZE[1], color: '#98a0ab', fontWeight: '700', lineHeight: '52px'}}
            onClick={() => this.openReservationModal(item.log_payment.fk_storeReservationId)}
          >
            정보 보기
          </div>
          <div
            className={styleTable.column}
            style={{width: config.COLUMN_SIZE[2], lineHeight: '52px'}}
          >
            {dateUtil.format('dash', item['log_payment'][config.COLUMN_FIELD[2]])}
          </div>
          <div
            className={styleTable.column}
            style={{width: config.COLUMN_SIZE[3], lineHeight: '52px'}}
          >
            {converter.parseListByType(item['log_payment'][config.COLUMN_FIELD[3]])}
          </div>
          <div
            className={styleTable.column}
            style={{width: config.COLUMN_SIZE[4], lineHeight: '52px'}}
          >
            {converter.parseListByType(item['log_payment'][config.COLUMN_FIELD[4]])}
          </div>
          <div
            className={styleTable.column}
            style={{width: config.COLUMN_SIZE[5], lineHeight: '52px'}}
          >
            {converter.parseListByType(item['log_payment'][config.COLUMN_FIELD[5]])}
          </div>
          <div
            className={styleTable.column}
            style={{width: config.COLUMN_SIZE[6], lineHeight: '52px'}}
          >
            {item['log_payment'][config.COLUMN_FIELD[7]] ? '환불' : '승인'}
          </div>
          <div
            className={styleTable.column}
            style={{width: config.COLUMN_SIZE[7], lineHeight: '52px'}}
          >
            {dateUtil.format('dash', item['log_payment'][config.COLUMN_FIELD[7]])}
          </div>
        </div>
      )
    }

    let setExcelQuery = () => {
        const query = this.state.reservationFeeParams;
        query.excelType = 0;
        return '/log-payment/excel?' + Object.keys(query).map((key) => {
            return key + '=' + query[key];
        }).join('&');
    }

    return(
      <div className={styleStore.homeContainer}>
        <Helmet>
          <title>예약금 조회</title>
        </Helmet>
        <div className={styleStore.homeContent}>
          {this.state.isStatusChanging && !this.state.isPositive ? <div className={styleReservationModal.completedAlert} style={{top: '40%', backgroundColor: '#F08080'}}>{this.state.alertText}</div> : null}
          {this.state.isStatusChanging && this.state.isPositive ? <div className={styleReservationModal.completedAlert} style={{top: '40%'}}>{this.state.alertText}</div> : null}
          <div className={styleBoard.top} style={{margin: '3vh 0 0 0'}}>
            <p className={styleBoard.title} style={{paddingTop: '18px'}}>예약금 조회</p>
            <SearchBar onRef={(ref) => {this.searchBar = ref;}} handleSearch={(params) => this.handleSearch(params)} handleChangingStatus={this.handleChangingStatus}/>
          </div>
          <Link to={setExcelQuery()} target={'_blank'} className={styleBoard.saveExcel}>
            <img className={styleSeatList.funcIcon} src="/Assets/img/ic-excel.png"/>엑셀 저장
          </Link>
          <div className={styleBoard.bottom}>
            <div className={styleTable.tableContentBox + ' ' + styleTable.style1} style={{width: '100%', overflow:'hidden'}}>
              {renderReservationFeeListHeader()}
              <InfiniteList
                onRef={(ref) => this.reservationFeeList = ref}
                ListItem={renderReservationFeeList}
                Get={ActionLogPayment.getList}
                GetParams={this.state.reservationFeeParams}
                GetListData={(list) => this.getReservationFeeList(list)}
                customTableStyle={styleTable.tableContentWrapper}
              />
            </div>
          </div>
        </div>
        <Modal
          closeParams={['storeReservationId', 'isOpened']}
          onRef={(ref) => {
            this.reservationModal = ref;
          }}
          modalBody={
            <ReservationPopup
              onRef={(ref) => {
                this.reservationModalBody = ref;
              }}
              closeModal={() => this.closeReservationModal()}
            />
          }
        />
      </div>
    )
  }
}

export default connect((state) => {
  return {
    author: state.data.account.author,
    store: state.data.store.store,
    selectedReservationId: state.data.store_reservation.selectedReservationId,
  };
})(withRouter(ReservationFeeInquiry));