import React, {Component} from 'react';
import {withRouter, Route} from 'react-router-dom';
import {connect} from 'react-redux';
import * as ActionStoreCallHistory from '../../../../Data/Action/log_store_call_history'

// styles
import styleReservationModal from '../../../Styles/Components/ReservationModal.css'

// components
import InfiniteList from '../../../Common/InfiniteList';

// Utils
import { dateUtil, addDashesToPhoneNumber } from '../../../../Utils'

import {action} from "../../../../Data";


class CallHistoryScroll extends Component{
  constructor(props){
    super(props);

    this.state = {
      storeCallHistory: [],
      storeCallHistoryParams: {},
      selectedIndex: null
    }

    this.getCallHistoryListData = this.getCallHistoryListData.bind(this);
    this.handleCallHistory = this.handleCallHistory.bind(this);
  }


  componentWillMount() {
    if (!this.props.store.store) return;
    let storeCallHistoryParams = {
      fk_storeId: this.props.store.store ? this.props.store.store.storeId : null,
      order: 'createdAt',
      orderMethod: 'DESC',
    };
      storeCallHistoryParams.phoneNumber = this.props.storeCustomer.phoneNumber;

      this.setState({
        storeCallHistoryParams: storeCallHistoryParams
    })
  }

  componentDidUpdate(prevProps) {
    if(prevProps.storeCallHistory !== this.props.storeCallHistory) {
        this.setState({
          storeCallHistory: this.props.storeCallHistory
        })
    }
  }

  getCallHistoryListData(list) {
    if (!list || list.length === 0) {
      return;
    }
    this.setState({
      storeCallHistory: list,
    })
  }

  handleCallHistory(data, index) {
    const customerData = {
      storeCustomer: {},
    };

/*    if (data.store_customer && data.store_customer.storeCustomerId) {
      customerData.storeCustomer = data.store_customer;
    } else {
      customerData.storeCustomer.phoneNumber = data.log_store_call_history.phoneNumber;
      customerData.storeCustomer.name = '';
      customerData.storeCustomer.storeCustomerId = null;
    } 

    console.log(customerData);  */

    this.props.dispatch(action.store_customer.getList({
        phoneNumber: data.log_store_call_history.phoneNumber,
        fk_storeId: data.log_store_call_history.fk_storeId
    })).then((res) => {
      if (res && res.length > 0) {
        customerData.storeCustomer = res[0].store_customer;
      } else {
          customerData.storeCustomer.name = '';
          customerData.storeCustomer.storeCustomerId = null;
      }

        customerData.storeCustomer.phoneNumber = data.log_store_call_history.phoneNumber;
        customerData.phoneNumberDash = addDashesToPhoneNumber(customerData.storeCustomer.phoneNumber)
    
    if (this.props.setStoreCustomer) {
      this.props.setStoreCustomer(customerData);
      this.props.dispatch(action.store.setRecentCallInfo({
          phoneNumber: customerData.storeCustomer.phoneNumber
      }));
      this.props.dispatch(action.store.setOpenModal('customerModal'));
    }

    this.setState({
      selectedIndex: index
    })
  })
  }

  render(){
    let borderStyle = {
      border: '2px solid #ea3e0e',
      boxSizing: 'border-box',
      color: '#5b9fdc'
    }

    let renderCallHistory = (call, index) => {
      return (
        <li key={index} style={this.state.selectedIndex === index ? borderStyle : null}
            onClick={() => this.handleCallHistory(call, index)} onDoubleClick={() => this.props.showCallHistory()}>
          <div className={styleReservationModal.callHistoryDate} style={{color:'#1e2028'}}>{dateUtil.format('YYYY/MM/DD HH:mm:ss', call.log_store_call_history.createdAt)}</div>
          <div className={styleReservationModal.callHistoryPhone}>{call.log_store_call_history.phoneNumber || call.store_customer.phoneNumber}</div>
          <div className={styleReservationModal.callHistoryName}>{call.store_customer.name}</div>
        </li>
      )
    }

    return(
      <ul className={styleReservationModal.callHistory}>
          {
            this.state.storeCallHistoryParams.fk_storeId ?
                <InfiniteList
                    onRef={(ref) => this.callHistoryList = ref}
                    ListItem={renderCallHistory}
                    Get={ActionStoreCallHistory.getList}
                    GetParams={this.state.storeCallHistoryParams}
                    GetListData={(list) => this.getCallHistoryListData(list)}
                    customTableStyle={styleReservationModal.callList}
                /> : null
          }
      </ul>
    )
  }
}

export default connect((state) => {
    return {
        author: state.data.account.author,
        store: state.data.store.store,
        openModal: state.data.store.openModal,
        recentCallInfo: state.data.store.recentCallInfo,
        selectedCustomerId: state.data.store_customer.selectedCustomerId,
    }
})(withRouter(CallHistoryScroll))
