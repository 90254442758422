import React, {Component} from 'react';
import {withRouter, Route, Link} from 'react-router-dom';
import {connect} from 'react-redux';
import moment from 'moment';

// Components
import InfiniteList from '../../../Common/InfiniteList';
import TimeRangePicker from '../../../Common/TimeRangePicker';
import SearchBar from './SearchBar';

// Actions
import * as ActionStoreCustomer from '../../../../Data/Action/store_customer';

// styles
import styleBoard from '../../../Styles/Components/Board.css'
import styleTable from '../../../Styles/Common/Table.css'

// Utils
import { converter, dateUtil } from '../../../../Utils';



class ReceiverList extends Component{
    constructor(){
        super();

        this.state = {
            searchQuery: '',
            searchBarFocused: false,

            customerConfig: {
                COLUMN_NAME: ['', '전화번호', '이름', '방문횟수', '최근 방문일'],
                COLUMN_FIELD: ['', 'phoneNumber', 'name', 'visitAmount', 'visitedAt'],
                COLUMN_SIZE: ['10%', '27.5%', '15%', '15%', '27.5%'],
            },
            selectedConfig: {
                COLUMN_FIELD: ['phoneNumber', 'name'],
                COLUMN_SIZE: ['50%', '50%'],
            },

            checked: [], // customer 리스트 선택 여부

            selectedList: [],
        }

        this.getCustomerListData = this.getCustomerListData.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleSort = this.handleSort.bind(this);
        this.handleCheckBoxAll = this.handleCheckBoxAll.bind(this);
        this.handleCheckBox = this.handleCheckBox.bind(this);
        this.handleSelectedIsChecked = this.handleSelectedIsChecked.bind(this);
        this.handleAddToSelectedList = this.handleAddToSelectedList.bind(this);
        this.handleRemoveFromSelectedList = this.handleRemoveFromSelectedList.bind(this);
    }

    componentWillMount() {
        this.setState({
            customerParams: {
                fk_storeId: this.props.store && this.props.store.store ? this.props.store.store.storeId : null,
                order: 'visitedAt',
                orderMethod: 'DESC',
                dateFrom: dateUtil.format('YYYY-MM-DD', new Date(moment().subtract(7, 'days'))),
                dateTo: dateUtil.format('YYYY-MM-DD', new Date()),
            },
            handleSelectedList: this.handleAddToSelectedList
        })
    }

    componentDidMount() {
        this.props.onRef(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.author !== this.props.author) {
            this.setState({
                customerParams: {
                    fk_storeId: this.props.store.store ? this.props.store.store.storeId : null,
                }
            }, () => {
                this.customerList.reset();
            });
        }
    }

    getCustomerListData(list) {
        if (!list || list.length === 0) {
            return;
        }
        let checked = [];
        list.forEach(() => {
            checked.push(false);
        })
        this.setState({
            customerList: list,
            checked: checked,
        })
    }

    handleSearch(params) {
        let customerParams =  Object.assign(this.state.customerParams, params)

        this.setState({
            customerParams: customerParams
        }, () => {
            this.customerList.reset();
        })
    }

    handleSort(field, value) {
        let customerParams = this.state.customerParams;
        if (customerParams.order === value) {
            if (customerParams.orderMethod === 'DESC') {
                customerParams['orderMethod'] = 'ASC';
            } else if (customerParams.orderMethod === 'ASC') {
                customerParams['orderMethod'] = 'DESC';
            }
        } else {
            customerParams['orderMethod'] = 'DESC';
        }

        customerParams[field] = value;

        this.setState({
            customerParams: customerParams,
        }, () => {
            this.handleSearch({});
        });
    }

    handleCheckBoxAll(e) {
        this.setState({
            checked: this.state.checked.map(() => {
                return e.target.checked
            })
        })
    }

    handleCheckBox(index) {
        let newChecked = this.state.checked;
        newChecked[index] = !newChecked[index];

        if (!newChecked[index]) {
            this.setState({
                handleSelectedList: this.handleRemoveFromSelectedList
            })
        } else {
            this.setState({
                handleSelectedList: this.handleAddToSelectedList
            })
        }

        this.setState({checked: newChecked})
    }

    handleSelectedIsChecked(index) {
        let selectedList = this.state.selectedList;
        selectedList[index].isChecked = !selectedList[index].isChecked;

        if (selectedList[index].isChecked) {
            this.setState({
                handleSelectedList: this.handleRemoveFromSelectedList
            })
        } else {
            this.setState({
                handleSelectedList: this.handleAddToSelectedList
            })
        }

        this.setState({
            selectedList: selectedList,
        })
    }


    handleAddToSelectedList() {
        const list = this.state.customerList;
        const checked = this.state.checked;
        let selectedList = this.state.selectedList;

        const newSelectedList = (list || [])
            .filter((item, index) => {
                if (checked[index]) {
                    checked[index] = false
                    return item;
                }
            }).filter((item, index) => {
                let flag = false;
                selectedList.forEach((selectedItem) => {
                    if (selectedItem['store_customer']['storeCustomerId'] === item['store_customer']['storeCustomerId']) {
                        flag = true;
                    }
                });
                if (!flag) return item;
            }).map((item) => {
                item.isChecked = false;
                return item;
            })

        this.setState({
            selectedList: [...this.state.selectedList, ...newSelectedList],
        }, () => {
            this.props.handleAddToSelectedList();
        })
    }

    handleRemoveFromSelectedList() {
        const selectedList = this.state.selectedList.filter((item) => {
            if (!item.isChecked) return item;
        })

        this.setState({
            selectedList: selectedList,
        }, () => {
            this.props.handleAddToSelectedList();
        })
    }

    render(){
        // 좌측 고객 리스트 헤더
        let renderCustomerListHeader = () => {
            let isAllChecked = (this.state.checked.filter((c) => {
                return c;
            }).length === this.state.checked.length) && this.state.checked.length > 0;
            return (
                <div className={styleTable.tableContentHeader}>
                    { this.state.customerConfig.COLUMN_FIELD.map((field, index) => {
                        let customerConfig = this.state.customerConfig;
                        return (
                            <div
                                className={styleTable.column + ' ' + (field ? styleTable.style2 : styleTable.style3) + (index > 2 ? ' ' + styleTable.clickable : '')}
                                style={{width: customerConfig.COLUMN_SIZE[index]}}
                                key={index}
                                onClick={() => index > 2 ? this.handleSort('order', field) : null}
                            >
                                {
                                    index > 0 ? customerConfig.COLUMN_NAME[index]
                                        : <input type={'checkbox'} checked={isAllChecked} onChange={(e) => this.handleCheckBoxAll(e)} />
                                }
                            </div>
                        )
                    }) }
                </div>
            );
        }
        // 좌측 고객 리스트
        let renderCustomerList = (item, index) => {
            const config = this.state.customerConfig;

            return (
                <div className={styleTable.tableContentItem} key={index} onClick={() => this.handleCheckBox(index)}
                     style={this.state.selectedList.includes(item) ? {backgroundColor : '#dae8f2'} : null} >
                    <div
                        className={styleTable.column}
                        style={{width: config.COLUMN_SIZE[0]}}
                    >
                        <input id={'cb' + index} type={'checkbox'} checked={this.state.checked[index] || false} />
                        <label for={'cb' + index}></label>
                    </div>
                    <div
                        className={styleTable.column}
                        style={{width: config.COLUMN_SIZE[1]}}
                    >
                        {converter.parseListByType(item['store_customer'][config.COLUMN_FIELD[1]])}
                    </div>
                    <div
                        className={styleTable.column}
                        style={{width: config.COLUMN_SIZE[2]}}
                    >
                        {converter.parseListByType(item['store_customer'][config.COLUMN_FIELD[2]])}
                    </div>
                    <div
                        className={styleTable.column}
                        style={{width: config.COLUMN_SIZE[3]}}
                    >
                        {converter.parseListByType(item['store_customer'][config.COLUMN_FIELD[3]])} 회
                    </div>
                    <div
                        className={styleTable.column}
                        style={{width: config.COLUMN_SIZE[4]}}
                    >
                        {dateUtil.format('slash', item['store_customer'][config.COLUMN_FIELD[4]])}
                    </div>
                </div>
            )
        }
        // 우측 선택된 리스트 헤더
        let renderSelectedListHeader = () => {
            return (
                <div className={styleTable.tableContentHeader}>
                    <div className={styleTable.column} style={{width: '100%', fontWeight: 700}}>
                        수신자 리스트
                    </div>
                </div>
            )
        }
        // 우측 선택된 리스트
        let renderSelectedList = () => {
            if (this.state.selectedList.length === 0) {
                return null;
            }

            const config = this.state.selectedConfig;
            return this.state.selectedList.map((item, index) => {
                return (
                    <div
                        className={styleTable.tableContentItem + (item.isChecked ? ' ' + styleTable.active : '')}
                        onClick={() => this.handleSelectedIsChecked(index)}
                        key={index}
                    >
                        <div
                            className={styleTable.column}
                            style={{width: config.COLUMN_SIZE[0]}}
                        >
                            {converter.parseListByType(item['store_customer'][config.COLUMN_FIELD[0]])}
                        </div>
                        <div
                            className={styleTable.column}
                            style={{width: config.COLUMN_SIZE[1]}}
                        >
                            {converter.parseListByType(item['store_customer'][config.COLUMN_FIELD[1]])}
                        </div>
                    </div>
                )
            })
        }

        let renderBackgroundRows = () => {
            let rows = [];
            for (let i=0; i<11; i++) {
                rows.push(<div/>);
            }
            return rows;
        }

        return(
            <div className={styleBoard.left}>
                <div className={styleBoard.top}>
                    <p className={styleBoard.title}>{this.props.pageTitle || ''}</p>
                    <SearchBar onRef={(ref) => {this.searchBar = ref;}} handleSearch={(params) => this.handleSearch(params)} handleChangingStatus={this.props.handleChangingStatus}/>
                </div>
                <div className={styleBoard.bottom}>
                    <div className={styleBoard.tableBox}>
                        <div className={styleBoard.leftTable + ' ' + styleTable.tableContentBox + ' ' + styleTable.style1}>
                            {renderCustomerListHeader()}
                            <div className={styleBoard.backgroundRows}>
                                {renderBackgroundRows()}
                            </div>
                            <InfiniteList
                              onRef={(ref) => this.customerList = ref}
                              ListItem={renderCustomerList}
                              Get={ActionStoreCustomer.getList}
                              GetParams={this.state.customerParams}
                              DataTitle={['store_customer', 'store']}
                              GetListData={(list) => this.getCustomerListData(list)}
                              customTableStyle={styleTable.tableContentWrapper}
                              customStyle={{position: 'absolute', top: '40px', width: '100%'}}
                            />
                        </div>
                        <div className={styleBoard.deleteOrAdd} onClick={this.state.handleSelectedList}>
                            <div>삭제</div>
                            <img className={styleBoard.arrows} src="/Assets/img/delete.png"/>
                            <div>추가</div>
                        </div>
                        <div className={styleBoard.rightTable + ' ' + styleTable.tableContentBox + ' ' + styleTable.style2}>
                            {renderSelectedListHeader()}
                            <div className={styleBoard.backgroundRows}>
                                {renderBackgroundRows()}
                            </div>
                            <div className={styleBoard.rightTableBox}>
                                {renderSelectedList()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect((state) => {
    return {
        author: state.data.account.author,
        store: state.data.store.store,
    }
})(withRouter(ReceiverList))