import * as ActionTypes from '../actionTypes';

export const initialState = {
    comment: {},
    comment_list: []
}
/*
*/
export const comment_reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.COMMENT:
            return Object.assign({}, state, {
                comment: action.comment
            });
        case ActionTypes.COMMENT_LIST:
            return Object.assign({}, state, {
                comment_list: action.comment_LIST
            });
        default:
            return state;
        }
}
