// LOGIN
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';


// ACCOUNT || undefined
export const ACCOUNT = 'ACCOUNT';
export const ACCOUNT_LIST = 'ACCOUNT_LIST';

// ACCOUNT_DELETE || undefined
export const ACCOUNT_DELETE = 'ACCOUNT_DELETE';
export const ACCOUNT_DELETE_LIST = 'ACCOUNT_DELETE_LIST';

// ACCOUNT_EMAIL_VERIFICATION || undefined
export const ACCOUNT_EMAIL_VERIFICATION = 'ACCOUNT_EMAIL_VERIFICATION';
export const ACCOUNT_EMAIL_VERIFICATION_LIST = 'ACCOUNT_EMAIL_VERIFICATION_LIST';

// ACCOUNT_PHONE_VERIFICATION || undefined
export const ACCOUNT_PHONE_VERIFICATION = 'ACCOUNT_PHONE_VERIFICATION';
export const ACCOUNT_PHONE_VERIFICATION_LIST = 'ACCOUNT_PHONE_VERIFICATION_LIST';

// ACCOUNT_SOCIAL || undefined
export const ACCOUNT_SOCIAL = 'ACCOUNT_SOCIAL';
export const ACCOUNT_SOCIAL_LIST = 'ACCOUNT_SOCIAL_LIST';

// BOARD || undefined
export const BOARD = 'BOARD';
export const BOARD_LIST = 'BOARD_LIST';
export const IS_OPEN_COUPON_MODAL = 'IS_OPEN_COUPON_MODAL';

// COMMENT || undefined
export const COMMENT = 'COMMENT';
export const COMMENT_LIST = 'COMMENT_LIST';

// COMMENT_LIKE || undefined
export const COMMENT_LIKE = 'COMMENT_LIKE';
export const COMMENT_LIKE_LIST = 'COMMENT_LIKE_LIST';

// BOARD_LIKE || undefined
export const BOARD_LIKE = 'BOARD_LIKE';
export const BOARD_LIKE_LIST = 'BOARD_LIKE_LIST';

// INVOICE || undefined
export const INVOICE = 'INVOICE';
export const INVOICE_LIST = 'INVOICE_LIST';

// NOTIFICATION || undefined
export const NOTIFICATION = 'NOTIFICATION';
export const NOTIFICATION_LIST = 'NOTIFICATION_LIST';

// LOG_ACCOUNT_LOGINHISTORY || undefined
export const LOG_ACCOUNT_LOGINHISTORY = 'LOG_ACCOUNT_LOGINHISTORY';
export const LOG_ACCOUNT_LOGINHISTORY_LIST = 'LOG_ACCOUNT_LOGINHISTORY_LIST';

// ORDER_PRODUCT || undefined
export const ORDER_PRODUCT = 'ORDER_PRODUCT';
export const ORDER_PRODUCT_LIST = 'ORDER_PRODUCT_LIST';

// PRODUCT || undefined
export const PRODUCT = 'PRODUCT';
export const PRODUCT_LIST = 'PRODUCT_LIST';

// ACCOUNT_ADMIN_SYSTEM || undefined
export const ACCOUNT_ADMIN_SYSTEM = 'ACCOUNT_ADMIN_SYSTEM';
export const ACCOUNT_ADMIN_SYSTEM_LIST = 'ACCOUNT_ADMIN_SYSTEM_LIST';

// BRAND || 브랜드
export const BRAND = 'BRAND';
export const BRAND_LIST = 'BRAND_LIST';

export const CUSTOMER = 'CUSTOMER'
export const CUSTOMER_LIST = 'CUSTOMER_LIST'

// CUSTOMER_PREFER_PLACE || 고객의 가맹점별 선호 매장을 저장한다
export const CUSTOMER_PREFER_PLACE = 'CUSTOMER_PREFER_PLACE';
export const CUSTOMER_PREFER_PLACE_LIST = 'CUSTOMER_PREFER_PLACE_LIST';

// LOG_STORE_CALL_HISTORY || 가맹점 지점별 통화 내역
export const LOG_STORE_CALL_HISTORY = 'LOG_STORE_CALL_HISTORY';
export const LOG_STORE_CALL_HISTORY_LIST = 'LOG_STORE_CALL_HISTORY_LIST';

// LOG_STORE_COUPON_HISTORY || 가맹점 지점별 쿠폰
export const LOG_STORE_COUPON_HISTORY = 'LOG_STORE_COUPON_HISTORY';
export const LOG_STORE_COUPON_HISTORY_LIST = 'LOG_STORE_COUPON_HISTORY_LIST';

// LOG_STORE_RESERVATION_HISTORY || 가맹점 지점별 쿠폰
export const LOG_STORE_RESERVATION_HISTORY = 'LOG_STORE_RESERVATION_HISTORY';
export const LOG_STORE_RESERVATION_HISTORY_LIST = 'LOG_STORE_RESERVATION_HISTORY_LIST';

// STORE || 가맹점 정보
export const STORE = 'STORE';
export const STORE_LIST = 'STORE_LIST';
export const RECENT_CALL_INFO = 'RECENT_CALL_INFO';
export const OPEN_MODAL = 'OPEN_MODAL';
export const TIME_FOR_SEARCH = 'TIME_FOR_SEARCH';

// STORE_CUSTOMER || 가맹점별 고객의 정보
export const STORE_CUSTOMER = 'STORE_CUSTOMER';
export const STORE_CUSTOMER_LIST = 'STORE_CUSTOMER_LIST';

// STORE_CUSTOMER_INFO || 가맹점 고객의 고객 정보 (예약현황_예약추가 화면에서 고객 정보라 써있는 부분, 제플린 v2_0_store_예약현황_예약추가 참조)
export const STORE_CUSTOMER_INFO = 'STORE_CUSTOMER_INFO';
export const STORE_CUSTOMER_INFO_LIST = 'STORE_CUSTOMER_INFO_LIST';

// STORE_MEMO || 가맹점 지점별 예약메모
export const STORE_MEMO = 'STORE_MEMO';
export const STORE_MEMO_LIST = 'STORE_MEMO_LIST';

// STORE_PHONE_NUMBER || 가맹점의 전화번호
export const STORE_PHONE_NUMBER = 'STORE_PHONE_NUMBER';
export const STORE_PHONE_NUMBER_LIST = 'STORE_PHONE_NUMBER_LIST';

// STORE_PLACE || 가맹점 지점별 매장 (ex. 본관, 신관 등)
export const STORE_PLACE = 'STORE_PLACE';
export const STORE_PLACE_LIST = 'STORE_PLACE_LIST';
export const STORE_PLACE_CHANGED = 'STORE_PLACE_CHANGED';

// STORE_MENU || 가맹점 지점별 메뉴
export const STORE_MENU = 'STORE_MENU';
export const STORE_MENU_LIST = 'STORE_MENU_LIST';

// STORE_NOTIFICATION || 가맹점 지점별 문자 내용
export const STORE_NOTIFICATION = 'STORE_NOTIFICATION';
export const STORE_NOTIFICATION_LIST = 'STORE_NOTIFICATION_LIST';

// STORE_RESERVATION || 예약 정보
export const STORE_RESERVATION = 'STORE_RESERVATION';
export const STORE_RESERVATION_LIST = 'STORE_RESERVATION_LIST';

// STORE_RESERVATION_MEMO || 예약에서 예약 메모 정보
export const STORE_RESERVATION_MEMO = 'STORE_RESERVATION_MEMO';
export const STORE_RESERVATION_MEMO_LIST = 'STORE_RESERVATION_MEMO_LIST';

// STORE_PLACE_FLOOR || 가맹점 지점별 층 (본관 1층, 신관 2층 등)
export const STORE_PLACE_FLOOR = 'STORE_PLACE_FLOOR';
export const STORE_PLACE_FLOOR_LIST = 'STORE_PLACE_FLOOR_LIST';

// STORE_RESERVATION_MENU || 예약한 메뉴 정보
export const STORE_RESERVATION_MENU = 'STORE_RESERVATION_MENU';
export const STORE_RESERVATION_MENU_LIST = 'STORE_RESERVATION_MENU_LIST';

// STORE_RESERVATION_TABLE || 예약한 테이블 정보
export const STORE_RESERVATION_TABLE = 'STORE_RESERVATION_TABLE';
export const STORE_RESERVATION_TABLE_LIST = 'STORE_RESERVATION_TABLE_LIST';

// STORE_STAFF || 가맹점 지점 직원 리스트
export const STORE_STAFF = 'STORE_STAFF';
export const STORE_STAFF_LIST = 'STORE_STAFF_LIST';


// STORE_TABLE || 가맹점 층별 테이블
export const STORE_TABLE = 'STORE_TABLE';
export const STORE_TABLE_LIST = 'STORE_TABLE_LIST';


// 지우면 안됨
export const SELECTED_RESERVATION_ID = 'SELECTED_RESERVATION_ID';
export const SELECTED_CUSTOMER_ID = 'SELECTED_CUSTOMER_ID';

export const RESERVATION_DATE = 'RESERVATION_DATE';
export const RESERVATION_TIME = 'RESERVATION_TIME';
export const RESERVATION_UPDATED = 'RESERVATION_UPDATED';


// STORE_DEVICE || 가맹점별 이력관리
export const STORE_HISTORY = 'STORE_HISTORY';
export const STORE_HISTORY_LIST = 'STORE_HISTORY_LIST';

// STORE_DEVICE || 가맹점별 기기관리
export const STORE_DEVICE = 'STORE_DEVICE';
export const STORE_DEVICE_LIST = 'STORE_DEVICE_LIST';

// const셋팅
export const SET_CONST = 'SET_CONST'
export const GET_CONST_NOTIFICATION = 'GET_CONST_NOTIFICATION'
export const SET_CONST_NOTIFICATION = 'SET_CONST_NOTIFICATION'
export const GET_CONST_ADMIN_RESERVATION = 'GET_CONST_ADMIN_RESERVATION'
export const SET_CONST_ADMIN_RESERVATION = 'SET_CONST_ADMIN_RESERVATION'
export const GET_CONST_ADMIN_CUSTOMER = 'GET_CONST_ADMIN_CUSTOMER'
export const SET_CONST_ADMIN_CUSTOMER = 'SET_CONST_ADMIN_CUSTOMER'

// searchQuery
export const SET_SEARCH = 'SET_SEARCH'
export const GET_SEARCH = 'GET_SEARCH'

// 과금내역
export const LOG_SUBSCRIPTION_PAYMENT = 'LOG_SUBSCRIPTION_PAYMENT'
export const LOG_SUBSCRIPTION_PAYMENT_LIST = 'LOG_SUBSCRIPTION_PAYMENT_LIST'