import React, {Component} from 'react';
import onClickOutside from "react-onclickoutside";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import moment from 'moment';

// Components
import TimeRangePicker from '../../../Common/TimeRangePicker';
import DatePicker from "react-datepicker";

// Styles
import styleBoard from '../../../Styles/Components/Board.css'
import "react-datepicker/dist/react-datepicker.css";

// Utils
import {dateUtil} from "../../../../Utils";
import { action } from '../../../../Data'

class SearchBar extends Component  {
    constructor(props) {
        super(props);
        this.today = new Date();
        this.state = {
            isOpened: false,
            store: {},
            searchQuery: '',
            startDate: new Date(moment().subtract(7, 'days')),
            endDate: new Date(),
            lunchStartTime: '',
            lunchEndTime: '',

            everyday: [
                {
                    name: '일',
                    value: '1',
                    isClicked: true,
                }, {
                    name: '월',
                    value: '2',
                    isClicked: true,
                }, {
                    name: ' 화',
                    value: '3',
                    isClicked: true,
                }, {
                    name: '수',
                    value: '4',
                    isClicked: true,
                }, {
                    name: '목',
                    value: '5',
                    isClicked: true,
                }, {
                    name: '금',
                    value: '6',
                    isClicked: true,
                }, {
                    name: '토',
                    value: '7',
                    isClicked: true,
                }
            ],
            isWeekChecked: true,
            isMonthChecked: false,
        }

        this.getStoreValues = this.getStoreValues.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.handleClickInside = this.handleClickInside.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleTimeChange = this.handleTimeChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleSelectDay = this.handleSelectDay.bind(this);
        this.handleSelectDays = this.handleSelectDays.bind(this);
        this.selectOneWeek = this.selectOneWeek.bind(this);
        this.selectOneMonth = this.selectOneMonth.bind(this);
        this.selectStoreTime = this.selectStoreTime.bind(this);
        this.isStoreSelectedTime = this.isStoreSelectedTime.bind(this);
    }

    componentWillMount() {
        this.setState({
            store: this.props.store.store || {},
        }, () => {
            this.getStoreValues();
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.author !== prevProps.author) {
            this.setState({
                store: this.props.store.store,
            }, () => {
                this.getStoreValues();
            })
        }
    }

    getStoreValues() {
        if (!this.state.store.storeId) return;
        this.props.dispatch(action.store.getById({
            storeId: this.state.store.storeId
        })).then((res) => {
            this.setState({
                lunchStartTime: res.store.lunchStartTime,
                lunchEndTime: res.store.lunchEndTime,
                startDate: new Date(moment(this.state.endDate).subtract(7, 'days')),
            })
          let newState = {};

          newState['startTime'] = dateUtil.getTimeStringToDate(this.state.store['lunchStartTime']);
          newState['endTime'] = dateUtil.getTimeStringToDate(this.state.store['dinnerEndTime']);
          this.setState(newState);
        })
    }

    selectStoreTime(startTime, endTime) {
        let newState = {};

        newState['startTime'] = dateUtil.getTimeStringToDate(this.state.store[startTime]);
        newState['endTime'] = dateUtil.getTimeStringToDate(this.state.store[endTime]);
        this.setState(newState);
    }

    selectOneWeek() {
        this.setState({
            startDate: new Date(moment(this.state.endDate).subtract(7, 'days')),
            isWeekChecked: true,
            isMonthChecked: false
        })
    }

    selectOneMonth() {
        this.setState({
            startDate: new Date(moment(this.state.endDate).subtract(1, 'month')),
            isMonthChecked: true,
            isWeekChecked: false
        })
    }

    handleSelectDay(index) {
        let selected = this.state.everyday
        selected[index].isClicked = !selected[index].isClicked

        this.setState({
            everyday: selected,
        })
    }

    handleSelectDays(days) {
        let everyday = this.state.everyday
        everyday.forEach((day) => {
            day.isClicked = false
        })

        days.map((day) => {
            everyday[day].isClicked = !everyday[day].isClicked
        })

        this.setState({
            everyday: everyday
        })
    }

    handleClickOutside(e) {
        this.setState({
            isOpened: false,
        });
    }

    handleClickInside() {
        this.setState({
            isOpened: true,
        });
    }

    handleDateChange(start, end) {
        this.setState({
            startDate: start,
            endDate: end,
            isWeekChecked: false,
            isMonthChecked: false,
        })
    }

    handleTimeChange(time, field) {
        let newState = {};
        newState[field] = time;
        this.setState(newState)
    }

    handleSearch() {
        // if (!this.state.searchQuery) {
        //     alert('검색어를 입력해주세요.');
        //     return;
        // }
        let selectedDays = this.state.everyday.filter((day) => {
            return day.isClicked;
        })

        let params = {
            dateFrom: dateUtil.format('YYYY-MM-DD', this.state.startDate),
            dateTo: dateUtil.format('YYYY-MM-DD', this.state.endDate),
            startTime: dateUtil.format('HH:mm:ss', this.state.startTime),
            endTime: dateUtil.isDayBreak(dateUtil.format('HH:mm:ss', this.state.endTime)),
            dayOfWeek: selectedDays.map((day) => {
                return day.value
            }),
            searchQuery: this.state.searchQuery,
            searchOption: this.props.customParams && this.props.customParams.searchOption || 'customerInfo', // 디폴트는 고객 검색
            order: this.props.customParams && this.props.customParams.order || 'visitedAt',
            orderMethod: this.props.customParams && this.props.customParams.orderMethod || 'DESC'
        }

        this.props.handleSearch(params);
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.handleSearch();
        }
    }

    isStoreSelectedTime(key) {
        if (key !== 'lunch' && key !== 'dinner') return false;

        return (this.state.store[key + 'StartTime'] == dateUtil.format('HH:mm:ss', this.state.startTime)
        && this.state.store[key + 'EndTime'] == dateUtil.format('HH:mm:ss', this.state.endTime));
    }

    render() {
        let activeBtn = { border: '1px solid #f8cf26', color: '#1e2028' }
        let activeBtn2 = { backgroundColor: '#5b9fdc', color: '#fff' }
        let activeBtn3 = { color: '#5b9fdc' }

        let renderEveryday = this.state.everyday.map((day, index) => {
            return (
              <div key={index} className={styleBoard.btn + ' ' + styleBoard.style2} style={this.state.everyday[index].isClicked ? activeBtn : null}
                   onClick={() => this.handleSelectDay(index)}>
                  {day.name}
              </div>
            )
        })

        return (
            <div className={styleBoard.searchBox + (this.state.isOpen ? ' ' + styleBoard.active : '')} >
                <div className={styleBoard.block}>
                    <div className={styleBoard.search}>
                        <input type={'text'} value={this.state.searchQuery} onChange={(e) => this.setState({ searchQuery: e.target.value })} onKeyPress={(e) => this.handleKeyPress(e)} onClick={this.handleClickInside}/>
                        <img className={styleBoard.delete} src="/Assets/img/ic-delete.png"/>
                    </div>
                    <div className={styleBoard.searchBtn}>
                        <img src="/Assets/img/ic-search.png" onClick={this.handleSearch}/>
                    </div>
                </div>
                {
                    this.state.isOpened ?
                        <div className={styleBoard.searchBar}>
                            <div className={styleBoard.row + ' ' + styleBoard.rowStyle1}>
                                <div className={styleBoard.column + ' ' + styleBoard.columnStyle}>
                                    <div className={styleBoard.content + ' ' + styleBoard.contentTitle}>조회 기간</div>
                                    <div className={styleBoard.content}>
                                        <div className={styleBoard.dateBox + ' ' + styleBoard.style1}>
                                            <TimeRangePicker
                                                onRef={(ref) => {this.timeRangePicker = ref}}
                                                startDate={this.state.startDate}
                                                endDate={this.state.endDate}
                                                onUpdate={(start, end) => this.handleDateChange(start, end)}
                                                dateFormat={'yyyy MM/dd'}
                                                handleChangingStatus={this.props.handleChangingStatus}
                                            />
                                        </div>
                                    </div>
                                    <div className={styleBoard.content}>
                                        <div className={styleBoard.btnBox + ' ' + styleBoard.btnBoxStyle1}>
                                            <div className={styleBoard.btn + ' ' + styleBoard.style1} onClick={this.selectOneWeek} style={
                                                this.state.isWeekChecked ? activeBtn2 : null
                                            }>1주</div>
                                            <div className={styleBoard.btn + ' ' + styleBoard.style1} onClick={this.selectOneMonth} style={
                                                this.state.isMonthChecked ? activeBtn2 : null
                                            }>1달</div>
                                        </div>
                                    </div>
                                </div>
                                <div className={styleBoard.column + ' ' + styleBoard.columnStyle}>
                                    <div className={styleBoard.content + ' ' + styleBoard.contentTitle}>방문 시간</div>
                                    <div className={styleBoard.content}>
                                        <div className={styleBoard.timeBox}>
                                            <DatePicker
                                              selected={this.state.startTime}
                                              onChange={(time) => this.handleTimeChange(time, 'startTime')}
                                              showTimeSelect
                                              showTimeSelectOnly
                                              timeIntervals={15}
                                              dateFormat="HH:mm"
                                            />
                                        </div>~
                                        <div className={styleBoard.timeBox}>
                                            <DatePicker
                                              selected={this.state.endTime}
                                              onChange={(time) => this.handleTimeChange(time, 'endTime')}
                                              showTimeSelect
                                              showTimeSelectOnly
                                              timeIntervals={15}
                                              dateFormat="HH:mm"
                                            />
                                        </div>
                                    </div>
                                    <div className={styleBoard.content}>
                                        <div className={styleBoard.btnBox + ' ' + styleBoard.btnBoxStyle}>
                                            <div className={styleBoard.btn} onClick={() => this.selectStoreTime('lunchStartTime', 'lunchEndTime')} style={this.isStoreSelectedTime('lunch') ? {color: '#5b9fdc'} : null}>
                                                {this.isStoreSelectedTime('lunch') ? <img src="/Assets/img/sun.png"/> : <img src="/Assets/img/ic-sun-inactive.png"/>}
                                                점심
                                            </div>
                                            <div className={styleBoard.btn} onClick={() => this.selectStoreTime('dinnerStartTime', 'dinnerEndTime')} style={this.isStoreSelectedTime('dinner') ? {color: '#5b9fdc'} : null}>
                                                {this.isStoreSelectedTime('dinner') ? <img src="/Assets/img/ic-moon.png"/> : <img src="/Assets/img/ic-moon-inactive.png"/>}
                                                저녁
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={styleBoard.row + ' ' + styleBoard.rowStyle2}>
                                <div className={styleBoard.column + ' ' + styleBoard.btnBox + ' ' + styleBoard.btnBoxStyle2}>
                                    <div className={styleBoard.btn + ' ' + styleBoard.style1 + ' ' + styleBoard.style3}
                                         style={this.state.everyday[1].isClicked && this.state.everyday[2].isClicked && this.state.everyday[3].isClicked && this.state.everyday[4].isClicked && this.state.everyday[5].isClicked ? activeBtn2 : null}
                                         onClick={() => this.handleSelectDays([1,2,3,4,5])}>
                                        평일
                                    </div>
                                    <div className={styleBoard.btn + ' ' + styleBoard.style1 + ' ' + styleBoard.style3} style={this.state.everyday[0].isClicked && this.state.everyday[6].isClicked ? activeBtn2 : null}
                                         onClick={() => this.handleSelectDays([0,6])}>
                                        주말
                                    </div>
                                    {renderEveryday}
                                </div>
                            </div>
                        </div> : null
                }
            </div>
        )
    }
}

let EnhancedComponent = onClickOutside(SearchBar);

export default connect((state) => {
    return {
        author: state.data.account.author,
        store: state.data.store.store,
    }
})(withRouter(EnhancedComponent))