import React, {Component} from 'react';
import {withRouter, Route, Link} from 'react-router-dom';
import {connect} from 'react-redux';
import { Helmet } from 'react-helmet'

// Components
import WaitingStatusComponent from './WaitingStatus';
import HeaderComponent from './Header'

// Styles
import styleMobile from '../../Styles/Components/Mobile.css';

class MobileView extends Component {
  constructor(props){
    super(props);
  }

  render(){
    return (
      <div className={styleMobile.mobileContainer}>
        <Helmet
            title={'모바일 대기예약'}
        />
        <HeaderComponent />
        <WaitingStatusComponent />
      </div>
    )
  }
}

export default connect((state) => {
  return { author: state.data.account.author };
})(withRouter(MobileView));