module.exports = {
    "translation": {
        // 공용
        "login": "로그인",
        "id": "아이디",
        "password": "비밀번호",
        "franchise_mode": "가맹점 모드",
        "alarm_mode": "호출 모드",
        "customer_mode": "고객 모드",

        // 로그인화면
        "password_wrong_noti": "비밀번호가 틀렸습니다.",
        "id_wrong_noti": "존재하지 않는 사용자입니다.",
        "auto_login": "자동 로그인",
        "save_id": "ID(아이디) 저장",

        // 회원가입화면

        // 예약 공통
        "customer_phone_number" : "전화번호",
        "customer_name" : "이름",
        "team_unit" : "팀",
        "time_unit" : "분",
        "prefer_place" : "선호매장",
        "menu" : "메뉴",
        "number_of_people" : "인원",
        "baby_seat_amount" : "아기의자",
        "number_of_people_unit" : "명",
        "baby_seat_amount_unit" : "개",
        "direct_input" : "직접입력",
        "confirm" : "확인",
        "all" : "전체",
        "agree" : '동의',

        // 가맹점모드 - 예약현황
        // 헤더
        "reservation" : "예약 현황",
        "board" : "공지 및 이벤트",
        "statistics" : "통계 현황",
        "administrator" : "관리자",
        "today" : "오늘",
        "reservation_menu" : "메뉴별",
        "reservation_tables" : "테이블 배치도",
        "reservation_seatList" : "좌석별 리스트",
        "reservation_print" : "예약현황 출력",
        "reservation_newWindow_tables" : "새창에서 좌석보기",
        "reservation_newWindow_seats" : "새창에서 리스트 보기",
        "board_coupon_send" : "쿠폰 발급",
        "board_event" : "명함이벤트",
        "board_vip" : "단골고객",
        "board_coupon_inquiry" : "쿠폰 사용/조회",
        "board_notice" : "공지",
        "board_event_notice" : "이벤트공지",
        "board_holiday_notice" : "휴무공지",
        "board_extra" : "기타",
        "statistics_sales" : "매출 분석",
        "statistics_sales_total" : "총 매출액",
        "statistics_sales_reservation" : "예약 매출액",
        "statistics_sales_waiting" : "대기손님 매출액",
        "statistics_waiting" : "대기고객 분석",
        "statistics_customer" : "고객 조회",
        "statistics_extra_records" : "기타 이력",
        "statistics_text_records" : "문자 발송 이력",
        "statistics_call_records" : "통화 이력",
        "statistics_reservation_records" : "예약접수 이력",
        "administrator_basic_info" : "기본 정보",
        "administrator_table_setting" : "테이블 관리",
        "administrator_payment" : "예약금 조회",
        "administrator_reservation_manage" : "예약등록 관리",
        //예약리스트
        "add_reservation" : "예약추가",
        "total_reservation" : "전체",
        "lunch_reservation" : "점심",
        "dinner_reservation" : "저녁",
        "reservation_time" : "예약",
        "not_assigned" : "좌석 미지정",
        //대기리스트
        "waiting_customers" : "대기손님",
        "entered" : "입장",
        "waited" : "대기",
        "not_shown" : "없음",
        "cancelled" : "취소",
        "alarm_history" : "호출현황",
        "baby_chair" : "아기의자",
        "alarm" : "호출",
        "leave" : "퇴실",
        "cancel_enter" : "입장취소",
        "remove" : "삭제",
        "restore_wait" : "대기복구",

        //공지 및 이벤트
        "send_coupon" : "쿠폰 발급",
        "event_coupon" : "명함 이벤트 쿠폰",
        "vip_coupon" : "단골고객",
        "left_points" : "잔여구슬",
        "coupon_content" : "쿠폰 내용",
        "select_other" : "다른 내용 선택",
        "send_text" : "문자전송",


        // 고객모드
        "instruction1" : "전화번호를 입력해주시면",
        "instruction2" : "입장이 가능할 때 문자로 알려드려요!",
        "next" : "다음",
        "customer" : "고객",
        "enter_reservation_info" : "님의 예약정보를 입력해주세요.",
        "complete_reservation" : "대기 예약 완료",
        "cancel" : "취소",
        "reservation_completed" : "님의 대기가 접수되었습니다.",
        "waiting_receipt" : "대기접수증",
        "optional" : "선택사항",
        "print_waiting_number" : "대기번호표 출력",

        // 모바일웹
        "show_map" : "지도보기",
        "everyday" : "매일",
        "waiting" : "대기중",
        "make_reservation" : "대기 예약하기",
        "enter_reservation_info_mobile" : "대기 예약 정보 입력",
        "no_results" : "검색결과가 없습니다.",
        "search" : "검색",
        "my_waiting_number" : "내 대기번호",
        "cancel_reservation" : "예약 취소",

    }
}
