import React, {Component} from 'react';
import {
    withRouter, Route
} from 'react-router-dom';
import {connect} from 'react-redux';
import Cookies from 'universal-cookie';

const cookies = new Cookies();


// Containers
import AuthContainer from './Auth/index'
import StoreContainer from './Store/index'
import WaitingContainer from './Waiting/index'
import MobileContainer from './Mobile/index'
import TestContainer from './Test'

// Component
import HeaderComponent from '../Components/Header'
import SinglePageTableComponent from '@/Service/Components/Store/SinglePage/table'
import SinglePageSeatComponent from '@/Service/Components/Store/SinglePage/seat'

// Actions
import { action } from "../../Data/index";

// Styles
import style from '../Styles/style.css';

// Utils
import { parsePathname, isJSON } from '../../Utils';
import {parseUrlParameter} from "@/Utils";


class RootView extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: true,
        }
        this.handleIsLogin = this.handleIsLogin.bind(this);
        this.getSession = this.getSession.bind(this);
        this.checkSession = this.checkSession.bind(this);
        this.load = this.load.bind(this);
    }

    componentWillMount() {
        this.getSession();
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.author) !== JSON.stringify(this.props.author)) {
            this.getSession();
        }
    }

    getSession() {
        this.props.dispatch(action.account.session()).then((session) => { // 세션 설정
            return session;
        }).then((session) => { // store 설정
            let storeId = null;

            if (session.account && session.account.type === 10) { // 관리자일 때
                const params = parseUrlParameter.parse(this.props.location.search);
                if (!params.storeId) {
                    window.location.href = window.location.origin + '/admin';
                    return;
                }
                storeId = params.storeId;
            } else {
                storeId = session && session.store ? session.store.storeId : null;
            }

            const sessionFunc = [];
            if (storeId) {
                sessionFunc.push(this.props.dispatch(action.store.getById({ storeId: storeId })));
            }

            Promise.all(sessionFunc)
                .then((res) => {
                    const store = res ? res[0] : null;
                    if (store) {
                        this.props.dispatch(action.store.setStore(store));
                    }
                    let location = this.checkSession(this.props);
                    if (location && (location !== this.props.location.pathname)) this.props.history.push(location);
                    this.setState({
                        isLoading: false,
                    });
                });
        }).catch((err) => {
            console.log('세션 에러');
            console.log(err);
            this.setState({
                isLoading: false,
            })
        });
    }

    checkSession(props) {
        let currentProps = this.props;
      let location = '';
      if (parsePathname.getPathIndex(currentProps.location.pathname, 1, 1) === '/mobile') return;
      if (currentProps.author.account) {
          if (currentProps.location.pathname === '/') { location = '/auth/login' }
          else { location = currentProps.location.pathname };
      } else {
          location = '/auth/login';
      }
      return location;
    }

    handleIsLogin() {
        let currentPath = this.props.location.pathname;

        return (currentPath !== '/auth/login') && (currentPath !== '/waiting/visit') && (currentPath !== '/waiting/call') && (currentPath !== '/store/') &&
            (currentPath !== '/mobile') && (currentPath !== '/mobile/search') && (currentPath !== '/mobile/book') && (currentPath !== '/mobile/confirmation');
    }

    load(component) {
        return this.state.isLoading ? component : null;
    }

    render() {
        if (location.pathname === '/store/table') {
            return (
              <div className={style.body}>
                  { !this.state.isLoading ? <SinglePageTableComponent /> : null}
              </div>
            );
        }
        if (location.pathname === '/store/seat') {
            return (
              <div className={style.body}>
                  { !this.state.isLoading ? <SinglePageSeatComponent /> : null}
              </div>
            );
        }
        return (
          <div className={style.body}>
              { this.handleIsLogin() && !this.state.isLoading ? <HeaderComponent /> : null }
              { !this.state.isLoading ? <Route path={'/'} exact={true} strict={false} component={StoreContainer}/> : null}
              { !this.state.isLoading ? <Route path={'/auth'} exact={false} strict={false} component={AuthContainer}/> : null}
              { !this.state.isLoading ? <Route path={'/store'} exact={false} strict={false} component={StoreContainer}/> : null}
              { !this.state.isLoading ? <Route path={'/waiting'} exact={false} strict={false} component={WaitingContainer}/> : null}
              { !this.state.isLoading ? <Route path={'/mobile'} exact={false} strict={false} component={MobileContainer}/> : null}

              {/* 이거 꼭 지워야 함 전화 예약 테스트용 */}
              { !this.state.isLoading ? <Route path={'/test'} exact={false} strict={false} component={TestContainer}/> : null}
          </div>
        );
    }
}

export default connect((state) => {
    return {
        author:  state.data.account.author,
        store:  state.data.store.store,
    };
})(withRouter(RootView));

