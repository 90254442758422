const converter = require('./converter')
const dateUtil = require('./date')
const parseUrlParameter = require('./parseUrlParameter')
const parsePathname = require('./parsePathname')
const listUtil = require('./listUtil');
const string = require('./string');
const makeString = require('./makeString');

// 각각의 사용용도를 주석으로 최대한 상세히 달아주세요.
module.exports.converter = converter
module.exports.dateUtil = dateUtil
module.exports.parseUrlParameter = parseUrlParameter
module.exports.parsePathname = parsePathname
module.exports.listUtil = listUtil
module.exports.string = string
module.exports.addDashesToPhoneNumber = makeString.addDashesToPhoneNumber;
