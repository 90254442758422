import React, { Component } from 'react';
import { Switch, Route } from 'react-router';
import { hot } from 'react-hot-loader'
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faStroopwafel } from '@fortawesome/free-solid-svg-icons'


// Routes
import routes from './routes';
import store from "./store";

class App extends Component {
    constructor(props) {
        super(props);
    }
    componentWillMount() {
        // 15분에 한번씩 reload
        //setTimeout(() => {
        //    window.location.reload();
        //}, 1000 * 60 * 60 * 2);
    }
    render() {
        library.add(faStroopwafel);
        return (
            <BrowserRouter>
                <Provider store={store}>
                    <Switch>
                        {routes.map((route, index) => (
                            <Route key={index} {...route} />
                        ))}
                    </Switch>
                </Provider>
            </BrowserRouter>
        );
    }
}

export default hot(module)(App);
