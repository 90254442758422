import * as ActionTypes from '../actionTypes';

export const initialState = {
    order_product: {},
    order_product_list: []
}
/*
*/
export const order_product_reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.ORDER_PRODUCT:
            return Object.assign({}, state, {
                order_product: action.order_product
            });
        case ActionTypes.ORDER_PRODUCT_LIST:
            return Object.assign({}, state, {
                order_product_list: action.order_product_LIST
            });
        default:
            return state;
        }
}
