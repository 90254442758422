import React, {Component} from 'react';
import {
  withRouter, Route ,Link
} from 'react-router-dom';
import {connect} from 'react-redux';
import {dateUtil} from '../../../../Utils';
import moment from 'moment';
import onClickOutside from "react-onclickoutside";
import { withTranslation } from 'react-i18next';



// Action
import { action } from '../../../../Data';

// Utils
import {converter, parseUrlParameter} from "@/Utils";

// CSS
import styleSubHeader from '../../../Styles/Components/SubHeader.css';

class SubHeader extends Component {
  constructor() {
    super();

    this.state = {
      selectedDate: new Date(),
      selectedPlace: {},
      selectedFloor: {},
      storePlace: [],

      showCoupon: false,
      showNotice: false,
      showSales: false,
      showExtra: false,

        mode: '', // 메뉴별 버튼 토글

      seatList: false,
      currentPath: null, // 0: 예약현황, 1: 공지및이벤트, 2: 통계현황, 3: 관리자

      isFullScreen: false,
    }

    this.getStoreValues = this.getStoreValues.bind(this);
    this.handleShowDropdown = this.handleShowDropdown.bind(this);
    this.handleMenu = this.handleMenu.bind(this);
    this.handleGoPage = this.handleGoPage.bind(this);
    this.handleGoMenuBtn = this.handleGoMenuBtn.bind(this);
    this.handleGoCalendar = this.handleGoCalendar.bind(this);
    this.checkUrl = this.checkUrl.bind(this);
    this.showPrevDate = this.showPrevDate.bind(this);
    this.showNextDate = this.showNextDate.bind(this);
    this.handleGoToday = this.handleGoToday.bind(this);
    this.updateProps = this.updateProps.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);

    this.handlePrint = this.handlePrint.bind(this);
  }

  componentWillMount() {
      this.getStoreValues();
    let params = parseUrlParameter.parse(this.props.location.search);

    if (params.date) {
      this.setState({
        selectedDate: new Date(params.date),
      })
    }
  }

  componentDidUpdate(prevProps) {
      if (prevProps.selectedDate !== this.props.selectedDate) {
	      console.log("SubHeader >> componentDidUpdate");
         this.setState({
             selectedDate: this.props.selectedDate,
         })
      }
      if (prevProps.placeChanged !== this.props.placeChanged) {
          this.getStoreValues();
      }
  }

  getStoreValues() {
      let params = parseUrlParameter.parse(this.props.location.search);

      this.setState({
          account: this.props.author.account,
          store: this.props.store.store || {},
          brand: this.props.store.brand || {},
      }, () => {
          if (!this.state.store.storeId) return;
          this.props.dispatch(action.store_place.getList({
              fk_storeId: this.state.store.storeId,
              orderMethod: 'asc'
          })).then((res) => {
              // 층 설정
              let storePlaceId = Number(params.storePlaceId) || null;
              let storePlaceFloorId = Number(params.storePlaceFloorId) || null;

              let selectedItem = {};
              let selectedPlace = {};
              let selectedFloor = {};

              if (res.length > 0) {
                  selectedItem = res.find((item) => {
                      return item.store_place && item.store_place.storePlaceId === storePlaceId;
                  }) || res[0];

                  selectedPlace = Object.assign(selectedItem.store_place, {store_place_floor: selectedItem.store_place_floor || []});
                  selectedFloor = selectedPlace.store_place_floor.length > 0 ?
                      (
                          selectedPlace.store_place_floor.find((item) => {
                              return item.storePlaceFloorId === storePlaceFloorId;
                          }) || selectedPlace.store_place_floor[0]
                      ) : {}
              }

              this.setState({
                  storePlace: res.length > 0 ? res : [],
                  selectedPlace: selectedPlace,
                  selectedFloor: selectedFloor,
              }, () => {
                  // redux 등록
                  this.props.dispatch(action.store_place.setPlace(this.state.selectedPlace));
                  this.props.dispatch(action.store_place_floor.setPlaceFloor(this.state.selectedFloor));
              })
          }).catch((err) => {
              console.log('loading ERR (Reservation.index)', err);
          })
      })
  }

  showPrevDate() {
        this.setState({
          selectedDate: this.props.location.pathname === '/store/calendar' || this.props.isCallReservation ?
            new Date(moment(this.state.selectedDate).subtract(1, 'months')) : new Date(moment(this.state.selectedDate).subtract(1, 'days')),
        }, () => {
          this.updateProps();
        });
  }

  showNextDate() {
      this.setState({
          selectedDate: this.props.location.pathname === '/store/calendar' || this.props.isCallReservation ?
            new Date(moment(this.state.selectedDate).add(1, 'months')) : new Date(moment(this.state.selectedDate).add(1, 'days'))
      }, () => {
          this.updateProps();
      });
  }

  handleMenu(menu) {
    if(menu == 3) {
      this.setState({
        showCoupon : !this.state.showCoupon,
        showNotice: false,
      })
    } else if (menu == 4) {
      this.setState({
          showNotice: !this.state.showNotice,
          showCoupon: false,
      })
    } else if(menu == 5) {
      this.setState({
        showSales: !this.state.showSales,
        showExtra: false,
      })
    } else if(menu == 6) {
      this.setState({
        showExtra: !this.state.showExtra,
        showSales: false,
      })
    }
  }

  handleGoPage(url, query, isNoQuery) {
      let newQuery = {};
      let queryString = '';

      if (query) newQuery = query;

      const params = parseUrlParameter.parse(this.props.location.search);

      if (this.state.account.type === 10) {
          if (isNoQuery) {
              newQuery = {
                  storeId: params.storeId,
              }
          } else {
              newQuery.storeId = params.storeId;
          }
      } else {
          newQuery = isNoQuery ? {} : newQuery;
      }
      if ((url === '/store/board/coupon/send' || url === '/store/board/notice')) {
          this.props.dispatch(action.board.setOpenCouponModal(true))
      }

      queryString = parseUrlParameter.setQueryString(newQuery, isNoQuery ? '' : this.props.location.search, url !== '/store/reservation' ? ['mode'] : null);

      this.props.history.push(url + queryString);
  }

  handleGoMenuBtn() {
      const params = parseUrlParameter.parse(this.props.location.search);
      const query = {};

      switch (params.mode) {
          case 'customer':
              query.mode = 'menu';
              break;
          case 'menu' :
              query.mode = 'customer';
              break;
          default :
              query.mode = 'menu';
              break;
      }

      this.handleGoPage('/store/reservation', query);
      this.setState({ mode: query.mode });
  }

  handleGoCalendar() {
      let queries = {
          date: dateUtil.format('YYYY-MM-DD', this.state.selectedDate),
      }
      this.props.history.push('/store/calendar' + parseUrlParameter.setQueryString(queries, this.props.location.search));
  }

  checkUrl(url) {
    return this.props.location.pathname.indexOf(url) > -1;
  }

  handleGoToday() {
      this.setState({
          selectedDate: new Date(),
      }, () => {
          this.updateProps();
      });
  }

    handleShowDropdown(type) {
        if (type == 1) {
            this.setState({showPlace: !this.state.showPlace, showFloor: false})
        } else if (type == 2) {
            this.setState({showFloor: !this.state.showFloor, showPlace: false})
        }
    }

    // 장소 변경 (신관 -> 본관 등)
    handlePlace(data, floor) {
        let selectedPlace = this.state.selectedPlace;
        let selectedFloor = this.state.selectedFloor;

        if (!floor && data.store_place.storePlaceId === this.state.selectedPlace.storePlaceId) {
            return;
        }

        selectedPlace = data ? Object.assign(data.store_place, {store_place_floor: data.store_place_floor}) : this.state.selectedPlace;
        selectedFloor = data ? selectedPlace.store_place_floor[0] : floor;

        this.setState({
            selectedPlace: selectedPlace,
            selectedFloor: selectedFloor,
        }, () => {
            let queries = {};
            if (selectedPlace.storePlaceId) queries.storePlaceId = selectedPlace.storePlaceId;
            if (selectedFloor.storePlaceFloorId) queries.storePlaceFloorId = selectedFloor.storePlaceFloorId;

            this.props.history.push(this.props.location.pathname + parseUrlParameter.setQueryString(queries, this.props.location.search));

            this.props.dispatch(action.store_place.setPlace(this.state.selectedPlace));
            this.props.dispatch(action.store_place_floor.setPlaceFloor(this.state.selectedFloor));
            this.props.dispatch(action.store_reservation.reservationUpdated(new Date()));
        })
    }

  updateProps() {
      let queries = {
          date: dateUtil.format('YYYY-MM-DD', this.state.selectedDate),
      }
      this.props.dispatch(action.store_reservation.setSelectedDate(this.state.selectedDate))
      this.props.dispatch(action.store_reservation.reservationUpdated(new Date()));
      let path = '/store/reservation';

      if (this.props.location.pathname === '/store/reservation/seatList') {
          path = '/store/reservation/seatList';
      }
      if (this.props.location.pathname === '/store/calendar') {
        path = '/store/calendar';
      }

      this.props.history.push(path + parseUrlParameter.setQueryString(queries, this.props.location.search));
  }

  handleClickOutside() {
    this.setState({
      showCoupon: false,
      showNotice: false,
      showSales: false,
      showExtra: false,
      showPlace: false,
      showFloor: false,
    })
  }

  handleNewWindow() {
    window.open('/store/reservation/seatList');
  }

  handlePrint() {
    if ((screen.availHeight || screen.height-30) <= window.innerHeight) {
      this.setState({ isFullScreen: true }, () => {
        window.print();
        if (this.state.isFullScreen) {
          this.props.handleFullScreen(document.body);
        }
      })
    } else {
      this.setState({ isFullScreen: false }, () => {
        window.print();
      })
    }
  }


  render() {
    const { t, i18n } = this.props;

    let renderPlaceList = this.state.storePlace.length > 0 ? this.state.storePlace.map((place, index) => {
      return <li key={index} onClick={() => this.handlePlace(place)}>{place.store_place.name}</li>
    }) : null;

    let renderFloorList = this.state.selectedPlace.store_place_floor &&
    this.state.selectedPlace.store_place_floor.length > 0
      ? this.state.selectedPlace.store_place_floor.map((floor, index) => {
        return <li key={index} onClick={() => this.handlePlace(null, floor)}>{floor.name}</li>
      }) : null;
    return (
      <div className={styleSubHeader.headerContainer}>
        <div className={styleSubHeader.headerContent}>
          {this.props.currentPath === 0 ?
            <div>
              <div className={styleSubHeader.dateBox}>
                <div className={styleSubHeader.smallArrow + ' ' + styleSubHeader.btn} onClick={this.showPrevDate} style={{marginLeft: '12px'}}>
                  <img src="/Assets/img/arr-before-s.png"/>
                </div>
                  <div className={styleSubHeader.yearDay + ' ' + styleSubHeader.btn}>
                    <div style={this.props.location.pathname === '/store/calendar' || this.props.isCallReservation ? {marginTop: '10px'} : null}>
                      {moment(this.state.selectedDate).format('YYYY')}{this.props.location.pathname === '/store/calendar' || this.props.isCallReservation ? '년' : null}
                    </div>
                    {this.props.location.pathname === '/store/calendar' || this.props.isCallReservation ? '' :
                    <div>{moment(this.state.selectedDate).format('dddd')}</div>}
                  </div>
                <div className={styleSubHeader.monthDate + ' ' + styleSubHeader.btn} onClick={this.handleGoCalendar}>
                  <div>
                    {this.props.location.pathname === '/store/calendar' || this.props.isCallReservation ? moment(this.state.selectedDate).format('M월') : moment(this.state.selectedDate).format('MM')}
                  </div>
                  {this.props.location.pathname === '/store/calendar' || this.props.isCallReservation ? '' :
                    <div>
                      <div style={{color:'#98a0ab', fontSize:'18px'}}>/</div>
                      <div>{moment(this.state.selectedDate).format('DD')}</div>
                    </div>
                  }
                </div>
                <div className={styleSubHeader.smallArrow + ' ' + styleSubHeader.btn} onClick={this.showNextDate}>
                  <img src="/Assets/img/arr-next-s.png"/>
                </div>
                <div className={styleSubHeader.today + ' ' + styleSubHeader.btn} onClick={this.handleGoToday}>{t('today')}</div>
              </div>
              <div className={styleSubHeader.dropdown}>
                <div onClick={() => this.handleShowDropdown(1)}>
                  {this.state.selectedPlace ? this.state.selectedPlace.name : null}
                  {this.state.showPlace ?
                    <ul className={styleSubHeader.menu}>
                      {renderPlaceList}
                    </ul> : null}
                </div>
                <div onClick={() => this.handleShowDropdown(2)}>
                  {this.state.selectedFloor ? this.state.selectedFloor.name : null}
                  {this.state.showFloor ?
                    <ul className={styleSubHeader.menu}>{renderFloorList}</ul> : null}
                </div>
              </div>
              <div>
                <ul className={styleSubHeader.subNav}>
                  <li>
                    <div onClick={this.handleGoMenuBtn} className={this.props.location.pathname === '/store/reservation' && this.state.mode === 'menu' ? ' ' + styleSubHeader.active : ''}>{t('reservation_menu')}</div>
                    {this.props.location.pathname === '/store/reservation' && this.state.mode === 'menu' ? <div className={styleSubHeader.underLine}/> : null}
                  </li>
                  <li>
                    {this.props.location.pathname === '/store/reservation/seatList' ? <div className={styleSubHeader.active} onClick={() => this.handleGoPage("/store/reservation")}>{t('reservation_tables')}</div> : <div onClick={() => this.handleGoPage("/store/reservation/seatList")}>{t('reservation_seatList')}</div>}
                    {this.props.location.pathname === '/store/reservation/seatList' ? <div className={styleSubHeader.underLine}/> : null}
                  </li>
                  <li onClick={this.handlePrint}>
                    <Link>{t('reservation_print')}</Link>
                    {this.props.location.pathname === '' ? <div className={styleSubHeader.underLine}/> : null}
                  </li>
                  {this.props.location.pathname === '/store/reservation' ? <li>
                    <Link to={`/store/table?date=${dateUtil.format('YYYY-MM-DD', this.state.selectedDate)}&storeId=${(this.props.store.store || {}).storeId}&storePlaceId=${this.props.store_place.storePlaceId}&storePlaceFloorId=${this.props.store_place_floor.storePlaceFloorId}&reservationStartTime=${(this.props.timeForSearch.startTime || (this.props.store.store || {}).lunchStartTime)}&reservationEndTime=${this.props.timeForSearch.endTime || (this.props.store.store || {}).dinnerEndTime}`} target={'_blank'}>{t('reservation_newWindow_tables')}</Link>
                    {this.props.location.pathname === '' ? <div className={styleSubHeader.underLine}/> : null}
                  </li> : <li>
                    <Link to={`/store/seat?date=${dateUtil.format('YYYY-MM-DD', this.state.selectedDate)}&storeId=${(this.props.store.store || {}).storeId}&storePlaceId=${this.props.store_place.storePlaceId}&storePlaceFloorId=${this.props.store_place_floor.storePlaceFloorId}&reservationStartTime=${(this.props.timeForSearch.startTime || (this.props.store.store || {}).lunchStartTime)}&reservationEndTime=${this.props.timeForSearch.endTime || (this.props.store.store || {}).dinnerEndTime}`} target={'_blank'}>{t('reservation_newWindow_seats')}</Link>
                    {this.props.location.pathname === '' ? <div className={styleSubHeader.underLine}/> : null}
                  </li>}
                </ul>
              </div>
            </div> : null}
          {this.props.currentPath === 1 ?
            <ul className={styleSubHeader.subNav2}>
              <li onClick={() => this.handleMenu(3)}>
                {t('board_coupon_send')} {this.checkUrl('/board/coupon/send') ? <p className={styleSubHeader.underLine}/> : null}
                {this.state.showCoupon ?
                  <ul className={styleSubHeader.couponMenu}>
                    <li onClick={() => this.handleGoPage('/store/board/coupon/send', { type: 20 })}>{t('board_event')}</li>
                    <li onClick={() => this.handleGoPage('/store/board/coupon/send', { type: 21 })}>{t('board_vip')}</li>
                  </ul> : null
                }
              </li>
              <li onClick={() => this.handleGoPage('/store/board/coupon/inquiry', null, true)}>{t('board_coupon_inquiry')}{this.checkUrl('/board/coupon/inquiry') ? <p className={styleSubHeader.underLine}/> : null}</li>
              <li onClick={() => this.handleMenu(4)}>
                {t('board_notice')}{this.checkUrl('/board/notice') ? <p className={styleSubHeader.underLine}/> : null}
                {this.state.showNotice ?
                  <ul className={styleSubHeader.couponMenu}>
                    <li onClick={() => this.handleGoPage('/store/board/notice', { type: 30 })}>{t('board_event_notice')}</li>
                    <li onClick={() => this.handleGoPage('/store/board/notice', { type: 31 })}>{t('board_holiday_notice')}</li>
                  </ul> : null
                }
              </li>
              <li onClick={() => this.handleGoPage('/store/board/etc', null, true)}>{t('board_extra')}{this.checkUrl('/board/etc') ? <p className={styleSubHeader.underLine}/> : null}</li>
            </ul> : null}
          {this.props.currentPath === 2 ?
            <ul className={styleSubHeader.subNav2}>
              <li onClick={() => this.handleMenu(5)}>
                {t('statistics_sales')} {this.checkUrl('/statistics/salesAnalysis') ? <p className={styleSubHeader.underLine}/> : null}
                {this.state.showSales ?
                  <ul className={styleSubHeader.couponMenu}>
                    <li onClick={() => this.handleGoPage('/store/statistics/salesAnalysis')}>{t('statistics_sales_total')}</li>
                    <li onClick={() => this.handleGoPage('/store/statistics/reservationSales')}>{t('statistics_sales_reservation')}</li>
                    <li onClick={() => this.handleGoPage('/store/statistics/customerSales')}>{t('statistics_sales_waiting')}</li>
                  </ul> : null
                }
              </li>
              <li onClick={() => this.handleGoPage('/store/statistics/waitingAnalysis')}>{t('statistics_waiting')} {this.checkUrl('/statistics/waitingAnalysis') ? <p className={styleSubHeader.underLine}/> : null}</li>
              <li onClick={() => this.handleGoPage('/store/statistics/customerInquiry')}>{t('statistics_customer')} {this.checkUrl('/statistics/customerInquiry') ? <p className={styleSubHeader.underLine}/> : null}</li>
              <li onClick={() => this.handleMenu(6)}>
                {t('statistics_extra_records')} {this.checkUrl('/statistics/extraRecords') || this.checkUrl('/statistics/callRecords') || this.checkUrl('/statistics/reservationRecords') ? <p className={styleSubHeader.underLine}/> : null}
                {this.state.showExtra ?
                  <ul className={styleSubHeader.couponMenu}>
                    <li onClick={() => this.handleGoPage('/store/statistics/extraRecords')}>{t('statistics_text_records')}</li>
                    <li onClick={() => this.handleGoPage('/store/statistics/callRecords')}>{t('statistics_call_records')}</li>
                    <li onClick={() => this.handleGoPage('/store/statistics/reservationRecords')}>{t('statistics_reservation_records')}</li>
                  </ul> : null
                }
              </li>
            </ul> : null}
          {this.props.currentPath === 3 ?
            <ul className={styleSubHeader.subNav2}>
              <li onClick={() => this.handleGoPage('/store/administrator/generalInfo')}>{t('administrator_basic_info')} {this.checkUrl('/administrator/generalInfo') ? <p className={styleSubHeader.underLine}/> : null}</li>
              <li onClick={() => this.handleGoPage('/store/administrator/reservationFeeInquiry')}>{t('administrator_payment')} {this.checkUrl('/administrator/reservationFeeInquiry') ? <p className={styleSubHeader.underLine}/> : null}</li>
              <li onClick={() => this.handleGoPage('/store/administrator/reservationManagement')}>{t('administrator_reservation_manage')} {this.checkUrl('/administrator/reservationManagement') ? <p className={styleSubHeader.underLine}/> : null}</li>
                {this.props.author.account && this.props.author.account.type == 10 ? <li onClick={() => this.handleGoPage('/store/administrator/tableManagement')}>{t('administrator_table_setting')} {this.checkUrl('/administrator/tableManagement') ? <p className={styleSubHeader.underLine}/> : null}</li> : null}
            </ul> : null}
        </div>
      </div>
    );
  }
}

export default connect((state) => {
  return { author: state.data.account.author,
    store: state.data.store.store,
    store_place: state.data.store_place.store_place,
    store_place_floor: state.data.store_place_floor.store_place_floor,
    selectedDate: state.data.store_reservation.selectedDate,
    reservationUpdated: state.data.store_reservation.reservationUpdated,
      isOpenCouponModal: state.data.board.isOpenCouponModal,
      placeChanged: state.data.store_place.placeChanged,
      timeForSearch: state.data.store.timeForSearch
  };
})(withRouter(withTranslation() (onClickOutside(SubHeader))));

