import React, {Component} from 'react';
import {
    withRouter, Route ,Link
} from 'react-router-dom';
import {connect} from 'react-redux';
import { withTranslation } from 'react-i18next';

// Action
import { action } from "../../../../Data/index";

// CSS
import styleMainHeader from '../../../Styles/Components/MainHeader.css';

// Utils
import {parsePathname, parseUrlParameter} from '@/Utils';


class MainHeader extends Component {
    constructor(props) {
        super(props);

        this.state = {
            store: {},
            brand: {},
            account: {},

            storeId: '',
        }
        this.getStoreInfo = this.getStoreInfo.bind(this);
        this.logout = this.logout.bind(this);
        this.handleFullScreen = this.handleFullScreen.bind(this);
        this.handleLanguage = this.handleLanguage.bind(this);
    }

    componentDidMount() {
        this.setState({
            account: this.props.author.account || {}
        })
        this.getStoreInfo();
    }

    componentDidUpdate(prevProps) {
        if(prevProps.author !== this.props.author
        || JSON.stringify(prevProps.store) !== JSON.stringify(this.props.store)) {
            this.getStoreInfo();
        }
    }

    getStoreInfo() {
        let storeId = null;
        const account = this.props.author.account || {};
        if (account.type === 10) {
            const params = parseUrlParameter.parse(this.props.location.search);
            storeId = params.storeId || null;
        } else {
            const store = this.props.author.store || {};
            storeId = store.storeId || null;
        }
        if (storeId) {
            this.props.dispatch(action.store.getById({ storeId: storeId }))
                .then((res) => {
                    this.setState({
                        store: res.store || {},
                        brand: res.brand || {},
                        storeId: storeId,
                    })
                });
        }
    }

    logout() {
        this.props.dispatch(action.account.logout()).then((res) => {
            window.location.href = window.location.origin + '/auth/login';
        }).catch((err) => {
            console.log(err);
        });
    }

    handleFullScreen(element) {
        let requestMethod = element.requestFullScreen || element.webkitRequestFullScreen || element.mozRequestFullScreen || element.msRequestFullScreen;
        if (requestMethod) {
            requestMethod.call(element);
        } else if (typeof window.ActiveXObject !== "undefined") { // Older IE.
            let wscript = new ActiveXObject("WScript.Shell");

            if (wscript !== null) {
                wscript.SendKeys("{F11}");
            }
        }
    }

    handleLanguage() {
        if (this.props.i18n.language === 'ko') {
            this.props.i18n.changeLanguage('en');
        } else {
            this.props.i18n.changeLanguage('ko');
        }
    }

    render() {
        const { t, i18n } = this.props;

        const path = parsePathname.getPathIndex(this.props.location.pathname, 2, 1);
        return (
            <div className={styleMainHeader.headerContainer}>
                <div className={styleMainHeader.headerContent}>
                    <div className={styleMainHeader.leftBox}>
                        <div className={styleMainHeader.arrowBtn}
                             // onClick={this.logout}
                            onClick={() => window.close()}
                        >
                            <img src="/Assets/img/arr-before.png"/>
                        </div>
                        <div className={styleMainHeader.storeLogo} style={{ backgroundImage: "url(" + (this.state.store.profileUrl || '') + ")"}}></div>
                        <div className={styleMainHeader.storeName}>
                            {
                                this.props.store.store ?
                                    <div className={styleMainHeader.branchName}>
                                        {i18n.language === 'ko' ? (this.props.store.store.name || this.props.store.store.nameEn) : (this.props.store.store.nameEn || this.props.store.store.name)}
                                    </div> : null
                            }
                            {
                                this.props.store.store ?
                                    <div className={styleMainHeader.brandName}>
                                        {i18n.language === 'ko' ? (this.props.store.brand.name || this.props.store.brand.nameEn) : (this.props.store.brand.nameEn || this.props.store.brand.name)}
                                    </div> : null
                            }
                        </div>
                        <div className={styleMainHeader.expandIcon} onClick={() => this.props.handleFullScreen(document.body)}><img src="/Assets/img/ic-expand.png"/></div>
                    </div>
                    <div className={styleMainHeader.rightBox}>
                        <ul className={styleMainHeader.mainNav}>
                            <li>
                                <Link to={"/store/reservation" + parseUrlParameter.setQueryString(this.state.account.type == 10 ? { storeId: this.state.storeId } : {}, this.props.location.search, [], true)} className={path === '/reservation' ||
                                path === '/seatList' || path === '/calendar' ?
                                    styleMainHeader.selected : null}>
                                    <img src="/Assets/img/ic-menu-01.png"/>
                                    <span>{t('reservation')}</span>
                                </Link>
                            </li>
                            <li>
                                <Link to={"/store/board/coupon/send"
                               + parseUrlParameter.setQueryString(this.state.account.type == 10 ? { storeId: this.state.storeId, type: 20 } : { type: 20 }, this.props.location.search, [], true)
                                    }
                                      className={path === '/board' ? styleMainHeader.selected : null}>
                                    <img src="/Assets/img/ic-menu-02.png"/>
                                    <span>{t('board')}</span>
                                </Link>
                            </li>
                            <li>
                                <Link to={"/store/statistics/salesAnalysis" + parseUrlParameter.setQueryString(this.state.account.type == 10 ? { storeId: this.state.storeId } : {}, this.props.location.search, [], true)} className={path === '/statistics' ? styleMainHeader.selected : null}>
                                    <img src="/Assets/img/ic-menu-03.png"/>
                                    <span>{t('statistics')}</span>
                                </Link>
                            </li>
                            <li>
                                <Link to={"/store/administrator/generalInfo" + parseUrlParameter.setQueryString(this.state.account.type == 10 ? { storeId: this.state.storeId } : {}, this.props.location.search, [], true)} className={path === '/administrator' ? styleMainHeader.selected : null}>
                                    <img src="/Assets/img/ic-menu-04.png"/>
                                    <span>{t('administrator')}</span>
                                </Link>
                            </li>
                        </ul>
                        <div className={styleMainHeader.lang} onClick={this.handleLanguage}>
                            {i18n.language === 'en' ? <img src="/Assets/img/btn-korea.png"/> : <img src="/Assets/img/btn-english.png"/>}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect((state) => {
    return {
        author: state.data.account.author,
        store: state.data.store.store,
    };
})(withRouter(withTranslation()(MainHeader)));

