import React, {Component} from 'react';
import {withRouter, Route} from 'react-router-dom';
import {connect} from 'react-redux';
import moment from 'moment';
import { MoonLoader } from 'react-spinners';
import ScrollArea from 'react-scrollbar';
import { withTranslation } from 'react-i18next';
import * as Const from '../../../../Data/const';

// styles
import styleReservationList from '../../../Styles/Components/ReservationList.css'

// Actions
import { action } from "../../../../Data/index";

// Utils
import { parseUrlParameter, dateUtil } from '../../../../Utils';

class ReservationList extends Component{
	constructor(props){
		super(props);
		this.state = {
			store: {},
			selectedPlace: {},
			selectedFloor: {},
			selectedDate: new Date(),

			storeReservation: [],
			reservationList: [],
			storeReservationId: 0,

			lunchStartTime: '-',
			lunchEndTime: '-',
			dinnerStartTime: '-',
			dinnerEndTime: '-',
			totalNumberOfPeople: 0,

			isReservationTimeOpened: false,
			timeSection: [],
			currentTimeSection: {},

			isListLoading: false,
			isScroll: true,
		}

		this.getStoreValues = this.getStoreValues.bind(this);
		this.handleToggle = this.handleToggle.bind(this);
		this.changeStatus = this.changeStatus.bind(this);
		this.showReservationDetail = this.showReservationDetail.bind(this);
		this.selectTimeSection = this.selectTimeSection.bind(this);
		this.openTimeSection = this.openTimeSection.bind(this);
		this.setReservationList = this.setReservationList.bind(this);
		this.setReservationTotal = this.setReservationTotal.bind(this);
	}

	componentWillMount() {
		this.setState({
			store: this.props.store.store || {},
		}, () => {
			this.setState({
				timeSection: [
					{
						index: 0,
						name: this.props.t('total_reservation'),
						startTime: this.state.store.lunchStartTime ? this.state.store.lunchStartTime.substr(0,5) : '-',
						endTime: this.state.store.dinnerEndTime ? this.state.store.dinnerEndTime.substr(0,5) : '-',
						src: '/Assets/img/ic-all.png',
						isChecked: true,
						totalNumberOfPeople: 0,
						reservationLength: 0,
					}, {
						index: 1,
						name: this.props.t('lunch_reservation'),
						startTime: this.state.store.lunchStartTime ? this.state.store.lunchStartTime.substr(0,5) : '-',
						endTime: this.state.store.lunchEndTime ? this.state.store.lunchEndTime.substr(0,5) : '-',
						src: '/Assets/img/sun.png',
						isChecked: false,
						totalNumberOfPeople: 0,
						reservationLength: 0,
					}, {
						index: 2,
						name: this.props.t('dinner_reservation'),
						startTime: this.state.store.dinnerStartTime ? this.state.store.dinnerStartTime.substr(0,5) : '-',
						endTime: this.state.store.dinnerEndTime ? this.state.store.dinnerEndTime.substr(0,5) : '-',
						src: '/Assets/img/ic-moon.png',
						isChecked: false,
						totalNumberOfPeople: 0,
						reservationLength: 0,
					}
				],
			})
		})
	}

	componentDidMount() {
		this.props.onRef(this);

		this.getStoreValues();
		setInterval(this.getStoreValues, Const.intervalTime);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if(prevProps.author !== this.props.author
			|| prevProps.reservationUpdated !== this.props.reservationUpdated
		) {
			this.getStoreValues();
		}
	}

	getStoreValues() {
		this.setState({
			lunchStartTime: this.state.store.lunchStartTime ? this.state.store.lunchStartTime : '-',
			lunchEndTime: this.state.store.lunchEndTime ? this.state.store.lunchEndTime : '-',
			dinnerStartTime: this.state.store.dinnerStartTime ? this.state.store.dinnerStartTime : '-',
			dinnerEndTime: this.state.store.dinnerEndTime ? this.state.store.dinnerEndTime : '-',
			selectedPlace: this.props.store_place,
			selectedFloor: this.props.store_place_floor,
			selectedDate: this.props.selectedDate || new Date(),
			//isListLoading: true,
		}, () => {
			if (this.state.store.storeId) {
				this.setReservationList();
				this.setReservationTotal();
			}
		});
	}

	handleToggle(index) {
		this.setState({
			isScroll: !this.state.isScroll,
			scrollIndex: index
		})

		let reservationList = this.state.reservationList
		reservationList[index].isOpened = !reservationList[index].isOpened

		this.setState({
			reservationList: reservationList
		})
	}

	setReservationList() {
        const params = {
            fk_storeId: this.state.store.storeId,
            fk_storePlaceId: this.props.store_place.storePlaceId,
            //fk_storePlaceFloorId: this.props.store_place_floor.storePlaceFloorId,
            reservationStartTime: dateUtil.toISOString(dateUtil.format('dash', this.state.selectedDate)
                + ' ' + (this.props.selectedTime === 0 ? '00:00:00' : this.state.timeSection[this.props.selectedTime].startTime)),
            reservationEndTime: dateUtil.toISOString(dateUtil.format('dash', this.state.selectedDate)
                + ' ' + (this.props.selectedTime === 0 ? '24:00:00'
					: dateUtil.isDayBreak(this.state.timeSection[this.props.selectedTime].endTime))), // 전체일때는 00:00:00 ~ 24:00:00
            order: 'reservedAt',
            orderMethod: 'asc',
            reservationStatus: 0,
            waitingStatus: 1,
						count: 100,
        };
        this.props.dispatch(action.store.setTimeForSearch({
            startTime: this.state.timeSection[this.props.selectedTime].startTime,
            endTime: this.state.timeSection[this.props.selectedTime].endTime
        }));
        this.props.dispatch(action.store_reservation.getList(params)).then((res) => {
					let filteredRes = (res || []).map((item) => {
						return {
							store_reservation: item.store_reservation,
							store_reservation_table: item.store_reservation_table,
						}
					})
					let filteredStoreReservation = (this.state.storeReservation || []).map((item) => {
						return {
							store_reservation: item.store_reservation,
							store_reservation_table: item.store_reservation_table,
						}
					})

        		if (JSON.stringify(filteredRes) != JSON.stringify(filteredStoreReservation)) {
							this.setState({
								storeReservation: res
							}, () => {
								this.setState({
									reservationList: res.length > 0 ? res.map((value) => {
										value.store_reservation.isOpened = false;
										return value.store_reservation;
									}) : [],
									storeReservation: res.length > 0 ? res.map((value) => {
										value.isOpened = false;
										value.visitStatusList = [
											{ value: 0, src: "/Assets/img/label-state-reserv-0.png", isChecked: false },
											{ value: 1, src: "/Assets/img/label-state-reserv-1.png", isChecked: false },
											{ value: 2, src: "/Assets/img/label-state-reserv-2.png", isChecked: false },
											{ value: 10, src: "/Assets/img/label-state-reserv-10.png", isChecked: false },
											{ value: 20, src: "/Assets/img/label-state-reserv-20.png", isChecked: false }
										];

										let visitStatusList = value.visitStatusList.map((list) => {
											if (value.store_reservation.visitStatus == list.value) {
												list.isChecked = true;
											}
											return list;
										});

										value.isOpened = false;
										value.visitStatusList = visitStatusList

										return value;
									}) : [],
									isListLoading: false,
								});
							})
			} else {
			this.setState({
			isListLoading: false,
                        })

			}

        }).catch((err) => {
            this.setState({
                isListLoading: false,
            })
        })
	}

	setReservationTotal() {
		let params = {
			storeId: this.state.store.storeId,
			storePlaceId: this.state.selectedPlace.storePlaceId,
			//storePlaceFloorId: this.state.selectedFloor.storePlaceFloorId,
/*            createdFrom: new Date(dateUtil.format('dash', this.state.selectedDate)
                + ' 00:00:00').toISOString(),
            createdTo: new Date(dateUtil.format('dash', this.state.selectedDate)
                + ' 24:00:00').toISOString(),*/
			createdFrom: dateUtil.toISOString(dateUtil.format('dash', this.state.selectedDate)
                + ' 00:00:00'),
			createdTo: dateUtil.toISOString(dateUtil.format('dash', this.state.selectedDate)
                + ' 24:00:00'),
			reservationStatus: 0,
		}

		this.props.dispatch(action.store_reservation.getTotalByDate(params)).then((res) => {
			const currentPlaceValue = (res || []).find((item) => {
				return item.fk_storePlaceId === this.state.selectedPlace.storePlaceId
					&& dateUtil.format('YYYY-MM-DD', this.props.selectedDate) === item.date;
			});

			const valueMap = {
				0: ['allPeople', 'allCount'],
				1: ['lunchPeople', 'lunchCount'],
				2: ['dinnerPeople', 'dinnerCount'],
			};

			let timeSection = this.state.timeSection.map((item, index) => {
				item.totalNumberOfPeople = currentPlaceValue ? currentPlaceValue[valueMap[index][0]] : 0;
				item.reservationLength = currentPlaceValue ? currentPlaceValue[valueMap[index][1]] : 0;

				return item;
			})

			this.setState({ timeSection: timeSection });
		}).catch((err) => {
			console.log(err);
		})
	}

	showReservationDetail(e, storeReservationId) {
		if (e.target.className.indexOf('preventClick') > -1) return;
		this.props.openReservationModal(storeReservationId);
		let queries = {
			isOpened: true,
			storeReservationId: storeReservationId,
		};

		// 경로변경 하지 말 것. 주석 해제하지 마세요.
		// 주석이 풀려있다면 미리 죄송합니다 - 황준용
		//this.props.history.push('/store/reservation' + parseUrlParameter.setQueryString(queries, this.props.location.search));
	}

	openTimeSection() {
		this.setState({
			isReservationTimeOpened: !this.state.isReservationTimeOpened
		})
	}

	selectTimeSection(index) {
			let timeSection = this.state.timeSection
			timeSection.forEach((section) => {
				section.isChecked = false
			})
			timeSection[this.props.selectedTime].isChecked = true

			this.setState({
				timeSection: timeSection,
			}, () => {
				this.props.dispatch(action.store_reservation.setSelectedTime(index));
				this.props.dispatch(action.store_reservation.reservationUpdated(new Date()));
				this.setReservationList();
			});
	}

	changeStatus(value, index, index2, storeReservationId) {
		let storeReservation = this.state.storeReservation
		storeReservation[index].visitStatusList.forEach((status) => {
			status.isChecked = false
		})

		storeReservation[index].visitStatusList[index2].isChecked = true

		this.setState({
			storeReservation: storeReservation,
		})

		let params = {
			visitStatus: value,
			storeReservationId: storeReservationId
		}

		this.props.dispatch(action.store_reservation.update(params)).then((res) => {
			this.props.updateTableInfo();
		})
	}

	render(){
		const { t, i18n } = this.props;

		let showStatusBox = {
			overflow: 'visible',
			zIndex: 1
		}

    let renderTimeSection = this.state.timeSection ? this.state.timeSection.map((section, index) => {
      if (index !== this.props.selectedTime) {
        return (
          <div key={index} className={styleReservationList.daytime} onClick={() => this.selectTimeSection(index)}>
            <div className={styleReservationList.timeBorder}>
              <img className={section.name === '전체' ? styleReservationList.alltimeIcon : styleReservationList.daytimeIcon} src={section.src}/>
              <span style={{fontWeight: 700}}>{section.name} </span>{t('reservation_time')}
            </div>
            <div className={styleReservationList.numberOfTeams}>
              <span>{section.reservationLength}건</span>/{section.totalNumberOfPeople}명
            </div>
            <div className={styleReservationList.hours}>
              {section.startTime}~{section.endTime}
            </div>
          </div>
        )
      }
    }) : null;

		let renderReservationList = () => {
			return this.state.storeReservation.length > 0 ? this.state.storeReservation.map((reservation, index) => {
				let renderVisitStatusList = reservation.visitStatusList ? reservation.visitStatusList.map((status, index2) => {
					if (!status.isChecked) {
						return (
							<li key={index2} onClick={() => this.changeStatus(status.value, index, index2, reservation.store_reservation.storeReservationId)}>
								<img className={'preventClick'} src={status.src}/>
							</li>
						)
					}
				}) : null
				return (
					<li key={index} className={styleReservationList.reservationItem} onClick={(e) => this.showReservationDetail(e, reservation.store_reservation.storeReservationId)} style={{fontSize: this.props.screenFontSize+'px'}}>
						<div className={styleReservationList.topInfo}>
							<div className={styleReservationList.time}>{dateUtil.format('time', reservation.store_reservation.reservedAt)}</div>
							<div className={styleReservationList.name + ' ' + (reservation.store_customer.isVip ? styleReservationList.isVip : null)}>{reservation.store_customer.name || '-'}</div>
							<div className={styleReservationList.numOfPpl}>{reservation.store_reservation.numberOfPeople}명</div>
							{reservation.store_customer.type != 0 ? (
								<div className={styleReservationList.typeDot + ' ' + styleReservationList['typeDot' + reservation.store_customer.type]}></div>
							) : null}
						</div>
						<div className={styleReservationList.bottomInfo}>
							<div className={styleReservationList.prepayIcon}>
								{reservation.store_reservation.reservationFeeStatus == 1 ? <img src="/Assets/img/label-fin-reserv.png"/> : reservation.store_reservation.reservationFeeStatus == 10 ? <img src="/Assets/img/label-fin-reserved.png"/> : <span className={styleReservationList.noPrepayIcon}></span> }
								{/*{reservation.store_reservation.reservationFeeStatus == 1 || reservation.store_reservation.reservationFeeStatus == 10 ? <img src="/Assets/img/label-fin-reserv.png"/> :*/}
								{/*<span className={styleReservationList.noPrepayIcon}></span>*/}
								{/*}*/}
							</div>
							<div className={styleReservationList.tableNum}>
								{
									reservation.store_reservation_table ?
										((reservation.store_reservation_table || []).map((item) => {
											return item.tableNo
										})).join(',') : <span style={{color:'red'}}>{t('not_assigned')}</span>
								}
							</div>
							<div className={styleReservationList.statusIconBox} onClick={() => this.handleToggle(index)} style={reservation.store_reservation.isOpened ? showStatusBox : null}>
								<ul className={styleReservationList.statusIcon}>
									{reservation.visitStatusList ? reservation.visitStatusList.map((status, index) => {
										if (status.isChecked)
											return (
												<li key={index}>
													<img className={'preventClick'} src={status.src}/>
												</li>
											)
									}) : null}
									{renderVisitStatusList}
								</ul>
							</div>
						</div>
					</li>
				)
			}) : null
		}

    return (
      <div className={styleReservationList.leftBox}>
        <div className={styleReservationList.timeSectionWrapper} onClick={this.openTimeSection} style={this.state.isReservationTimeOpened ? showStatusBox : null}>
					<div className={styleReservationList.timeSection}>
						{this.state.timeSection.map((section, index) => {
							if (section.isChecked) {
								return (
									<div key={index} className={styleReservationList.daytime + ' ' + styleReservationList.selected}>
										<div className={styleReservationList.timeBorder}>
											<img className={this.state.timeSection[this.props.selectedTime].name === '전체' ? styleReservationList.alltimeIcon : styleReservationList.daytimeIcon} src={this.state.timeSection[this.props.selectedTime].src}/>
											<span style={{fontWeight: 700}}>{this.state.timeSection[this.props.selectedTime].name} </span>예약
										</div>
										<div className={styleReservationList.numberOfTeams}>
											<span>{this.state.timeSection[this.props.selectedTime].reservationLength}건</span>/{this.state.timeSection[this.props.selectedTime].totalNumberOfPeople}명
										</div>
										<div className={styleReservationList.hours}>
											{this.state.timeSection[this.props.selectedTime].startTime}~{this.state.timeSection[this.props.selectedTime].endTime}
										</div>
									</div>
								)
							}
						})}
            {renderTimeSection}
          </div>
        </div>
        <div className={styleReservationList.listSection}>
          <ScrollArea
						speed={0.8}
						horizontal={false}
						smoothScrolling={true}
						className={styleReservationList.reservationList}
						style={this.state.reservationList[this.state.scrollIndex] ? {overflow: 'visible'} :  null}
						contentStyle={this.state.reservationList[this.state.scrollIndex] ? {overflow: 'visible'} :  null}
					>
            {this.state.isListLoading ? (
							<div style={{marginLeft: 'auto', marginRight: 'auto', marginTop: '50px', width: '100px'}}>
								<MoonLoader
									size={80}
									color={'#dae8f2'}
								/>
							</div>
						) : renderReservationList()}
          </ScrollArea>
        </div>
      </div>
    )
  }
}

export default connect((state) => {
	return {
		author: state.data.account.author,
		store: state.data.store.store,
		recentCallInfo: state.data.store.recentCallInfo,
		store_place: state.data.store_place.store_place,
		store_place_floor: state.data.store_place_floor.store_place_floor,
		selectedDate: state.data.store_reservation.selectedDate,
		selectedTime: state.data.store_reservation.selectedTime,
		reservationUpdated: state.data.store_reservation.reservationUpdated,
		timeForSearch: state.data.store.timeForSearch,
	}
})(withRouter(withTranslation()(ReservationList)))
