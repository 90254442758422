import React, {Component} from 'react';
import {withRouter, Route} from 'react-router-dom';
import {connect} from 'react-redux';
import {Helmet} from "react-helmet";
import Modal from '../../../../Common/Modal';
import CustomerDetailPopup from './CustomerDetailModal'
import moment from 'moment';

// styles
import styleStore from '../../../../Styles/Components/Store.css'
import styleBoard from '../../../../Styles/Components/Board.css'
import SearchBar from '@/Service/Components/Store/Board/SearchBar'
import styleTable from '@/Service/Styles/Common/Table.css'
import InfiniteList from '../../../../Common/InfiniteList/index';
import { converter, dateUtil } from '@/Utils'
import { action } from '@/Data'

// Actions
import * as ActionStoreCustomer from '../../../../../Data/Action/store_customer';
import styleReservationModal from '@/Service/Styles/Components/ReservationModal.css'

class CustomerInquiry extends Component{
  constructor(){
    super();

    this.state = {
      searchQuery: '',

      customerInquiryConfig: {
        COLUMN_NAME: ['전화번호', '이름', '방문횟수', 'No-Show', '최근 방문일' ],
        COLUMN_FIELD: ['phoneNumber', 'name', 'visitAmount', 'noShowAmount', 'visitedAt'],
        COLUMN_SIZE: ['20%', '20%', '20%', '20%', '20%'],
      },
    }
    this.getCustomerListData = this.getCustomerListData.bind(this)
    this.handleSearch = this.handleSearch.bind(this)
    this.handleCustomerDetailModal = this.handleCustomerDetailModal.bind(this)
    this.getCustomerDetail = this.getCustomerDetail.bind(this);
    this.handleChangingStatus = this.handleChangingStatus.bind(this);
  }

  componentWillMount() {
    this.setState({
      customerParams: {
        fk_storeId: this.props.store && this.props.store.store ? this.props.store.store.storeId : null,
        order: 'visitedAt',
        orderMethod: 'DESC',
        dateFrom: dateUtil.format('YYYY-MM-DD', new Date(moment().subtract(7, 'days'))),
        dateTo: dateUtil.format('YYYY-MM-DD', new Date()),
      },
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.author !== this.props.author) {
      this.setState({
        customerParams: {
          fk_storeId: this.props.author.store.storeId,
        }
      }, () => {
        this.customerInquiryList.reset();
      });
    }
  }

  getCustomerListData(list) {
    if (!list || list.length === 0) {
      return;
    }
    let checked = [];
    list.forEach(() => {
      checked.push(false);
    })
    this.setState({
      storeCustomerList: list,
      checked: checked,
    })
  }

  handleSearch(params) {
    let customerParams =  Object.assign(this.state.customerParams, params)

    this.setState({
      customerParams: customerParams
    }, () => {
      this.customerInquiryList.reset();
    })
  }

  handleCustomerDetailModal(item) {
    this.customerDetailModal.open();
    this.getCustomerDetail(item.store_customer.storeCustomerId)
  }

  getCustomerDetail(storeCustomerId) {
    this.props.dispatch(action.store_customer.getById({
      storeCustomerId: storeCustomerId
    })).then((res) => {
      this.setState({
        customerDetail: {
          name: res.store_customer.name,
          phoneNumber: res.store_customer.phoneNumber,
          visitAmount: res.store_customer.visitAmount,
          noShowAmount: res.store_customer.noShowAmount,
          calledAt: res.store_customer.calledAt,
          type: res.store_customer.type,
          isVip: res.store_customer.isVip,
        }
      })
    })

    this.props.dispatch(action.store_reservation.getList({
      fk_storeCustomerId: storeCustomerId
    })).then((res) => {
      this.setState({
        visitRecords: res
      })
    })

    this.props.dispatch(action.notification.getList({
      fk_storeCustomerId: storeCustomerId
    })).then((res) => {
      this.setState({
        textRecords: res
      })
    })

    this.props.dispatch(action.store_customer_info.getList({
      fk_storeCustomerId: storeCustomerId
    })).then((res) => {
      this.setState({
        storeCustomerInfos: res
      })
    })
  }

  handleChangingStatus(text, bool) {
    this.setState({
      isStatusChanging: true,
      alertText: text,
      isPositive: bool
    }, () => {
      setTimeout(() => {
        this.setState({ isStatusChanging: false });
      }, bool ? 1000 : 3000);
    })
  }

  render(){
    let renderCustomerListHeader = () => {
      return (
        <div className={styleTable.tableContentHeader}>
          { this.state.customerInquiryConfig.COLUMN_FIELD.map((field, index) => {
            let customerInquiryConfig = this.state.customerInquiryConfig;
            return (
              <div
                className={styleTable.column + ' ' + (field ? styleTable.style2 : styleTable.style3)}
                style={{width: customerInquiryConfig.COLUMN_SIZE[index]}}
                key={index}
              >
                { customerInquiryConfig.COLUMN_NAME[index] }
              </div>
            )
          }) }
        </div>
      );
    }

    const renderCustomerInquiryList = (item,index) => {
      const config = this.state.customerInquiryConfig;

      return (
        <div className={styleTable.tableContentItem + (item.isChecked ? ' ' + styleTable.active : '')}
             key={index}
             onClick={() => this.handleCustomerDetailModal(item)}
        >
          <div
            className={styleTable.column}
            style={{width: config.COLUMN_SIZE[0]}}
          >
            {converter.parseListByType(item['store_customer'][config.COLUMN_FIELD[0]])}
          </div>
          <div
            className={styleTable.column}
            style={{width: config.COLUMN_SIZE[1]}}
          >
            {converter.parseListByType(item['store_customer'][config.COLUMN_FIELD[1]])}
          </div>
          <div
            className={styleTable.column}
            style={{width: config.COLUMN_SIZE[2]}}
          >
            {converter.parseListByType(item['store_customer'][config.COLUMN_FIELD[2]])}
          </div>
          <div
            className={styleTable.column}
            style={{width: config.COLUMN_SIZE[3]}}
          >
            {converter.parseListByType(item['store_customer'][config.COLUMN_FIELD[3]])}
          </div>
          <div
            className={styleTable.column}
            style={{width: config.COLUMN_SIZE[4]}}
          >
            {dateUtil.format('slash', item['store_customer'][config.COLUMN_FIELD[4]])}
          </div>
        </div>
      )
    }

    return(
      <div className={styleStore.homeContainer}>
        <Helmet>
          <title>고객 조회</title>
        </Helmet>
        <div className={styleStore.homeContent}>
          {this.state.isStatusChanging && !this.state.isPositive ? <div className={styleReservationModal.completedAlert} style={{top: '40%', backgroundColor: '#F08080'}}>{this.state.alertText}</div> : null}
          {this.state.isStatusChanging && this.state.isPositive ? <div className={styleReservationModal.completedAlert} style={{top: '40%'}}>{this.state.alertText}</div> : null}
          <div className={styleBoard.left}>
            <div className={styleBoard.top}>
              <p className={styleBoard.title}>고객 조회</p>
              <SearchBar onRef={(ref) => {this.searchBar = ref;}} handleSearch={(params) => this.handleSearch(params)} handleChangingStatus={this.handleChangingStatus}/>
            </div>
            <div className={styleBoard.bottom}>
              <div className={styleTable.tableContentBox + ' ' + styleTable.style1}>
                {renderCustomerListHeader()}
                <InfiniteList
                  onRef={(ref) => this.customerInquiryList = ref}
                  ListItem={renderCustomerInquiryList}
                  Get={ActionStoreCustomer.getList}
                  GetParams={this.state.customerParams}
                  DataTitle={['store_customer', 'store']}
                  GetListData={(list) => this.getCustomerListData(list)}
                  customTableStyle={styleTable.tableContentWrapper}
                />
              </div>
            </div>
          </div>
          <Modal
            onRef={(ref) => {this.customerDetailModal = ref;}}
            modalBody={<CustomerDetailPopup onRef={(ref) => {
              this.customerDetailModalBody = ref;
            }}
                                            customerDetail={this.state.customerDetail}
                                            visitRecords={this.state.visitRecords}
                                            textRecords={this.state.textRecords}
                                            storeCustomerInfos={this.state.storeCustomerInfos}
            />}
          />
          <div className={styleBoard.right}>
          </div>
        </div>
      </div>
    )
  }
}

export default connect((state) => {
  return {
    author: state.data.account.author,
      store: state.data.store.store,
  }
})(withRouter(CustomerInquiry))