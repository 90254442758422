import * as ActionTypes from '../actionTypes';

export const initialState = {
    log_store_reservation_history: {},
    log_store_reservation_history_list: []
}
/*
*/
export const log_store_reservation_history_reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.LOG_STORE_RESERVATION_HISTORY:
            return Object.assign({}, state, {
                log_store_reservation_history: action.log_store_reservation_history
            });
        case ActionTypes.LOG_STORE_RESERVATION_HISTORY_LIST:
            return Object.assign({}, state, {
                log_store_reservation_history_list: action.log_store_reservation_history_LIST
            });
        default:
            return state;
        }
}
