import React, {Component} from 'react';
import {withRouter, Route} from 'react-router-dom';
import {connect} from 'react-redux';
import { Helmet } from 'react-helmet'
import DatePicker from "react-datepicker";


// actions
import { action } from '../../../../../Data';

// styles
import style from '../../../../Styles/style.css'
import styleStore from '../../../../Styles/Components/Store.css'
import styleAdministrator from '../../../../Styles/Components/Administrator.css'
import styleReservationModal from '../../../../Styles/Components/ReservationModal.css';

// Utils
import { dateUtil } from '../../../../../Utils'
import Keypad from '../../../../Common/Keypad/index'
import styleKeypad from 'Service/Styles/Components/Keypad.css'

class AdministratorView extends Component{
  constructor(props){
    super(props);

    this.state = {
        isLoading: true,

        store: {},
        brand: {},

        storePhoneNumber: {},

        isSubmit: false,

      isWaitingTimeKeypadOpened: false,
      keypadCustomStyle: {},
      keypadField: '',

      loginId: '',
      loginPwd: '',
    }

    this.getStoreInfo = this.getStoreInfo.bind(this); // 매장 정보 받아오기
    this.getStorePhoneNumber = this.getStorePhoneNumber.bind(this); // 매장 전화번호 받아오기

    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleStateValue = this.handleStateValue.bind(this);
    this.handleAddr = this.handleAddr.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.handleChangingStatus = this.handleChangingStatus.bind(this);
    this.handleKeyPad = this.handleKeyPad.bind(this);
    this.openWaitingTimeKeypad = this.openWaitingTimeKeypad.bind(this);

    this.handleChangeLoginId = this.handleChangeLoginId.bind(this);
    this.handleChangeLoginPwd = this.handleChangeLoginPwd.bind(this);
    this.onApiLogin = this.onApiLogin.bind(this);
    this.onApiLineJoin = this.onApiLineJoin.bind(this);
    this.onApiMyInfo = this.onApiMyInfo.bind(this);
    this.onApiFindPwd = this.onApiFindPwd.bind(this);
    this.onApiHelp = this.onApiHelp.bind(this);
  }

  componentDidMount() {
    this.getStoreInfo();
    this.getStorePhoneNumber();
  }

  getStoreInfo() {
      if (!this.props.store) return;

      this.props.dispatch(action.store.getById({
         storeId: this.props.store.store.storeId,
      })).then((res) => {
         if (res.store) {
           let store = res.store;

           let timeProps = [
               'lunchStartTime',
               'lunchEndTime',
               'breakStartTime',
               'breakEndTime',
               'dinnerStartTime',
               'dinnerEndTime',
               'orderEndTime',
           ];

           timeProps.forEach((time) => {
             store[time] = dateUtil.getTimeStringToDate(store[time]);
           })
           this.setState({
               store: res.store,
               brand: res.brand,
               isLoading: false,
           });
         }
      }).catch((err) => {
        this.setState({
            isLoading: false,
        })
      });
  }

  getStorePhoneNumber() {
      if (!this.props.store) return;

      this.props.dispatch(action.store_phone_number.getList({
          fk_storeId: this.props.store.store.storeId,
      })).then((res) => {
        if (res.length > 0) {
          let storePhoneNumber = res.filter((item) => {
            return item.store_phone_number && item.store_phone_number.isMain;
          });

          this.setState({
              storePhoneNumber: storePhoneNumber[0].store_phone_number || ''
          });
        }
      })
  }

    /*
    * value: 변경된 값
    * field: 변경된 값의 이름
    * state: 변경시킬 state
    */
    handleStateValue(value, field, state) {
        let newState = {};
        newState[state] = this.state[state];

        newState[state][field] = value;

        this.setState(newState);
    }

    // 도로명 주소 호출
    handleAddr() {
        let windowOpen = window.open();
        windowOpen.innerWidth = 570;
        windowOpen.innerHeight = 420;
        windowOpen.location = '/popup/addrSearch'
        window.jusoCallBack = (juso) => {
            this.handleStateValue(juso.roadFullAddr, 'address', 'store');
        };
    }

    handleFileChange(e, field, state){
        let file = e.target.files[0];

        let reader = new FileReader();

        let newState = {};

        newState[state] = this.state[state];

        newState[state][field] = file;
        newState[state][field + 'Name'] = file.name;

        reader.onloadend = () => {
            newState[state][field + 'Preview'] = reader.result;
            this.setState(newState, () => {
                console.log(this.state);
            });
        };
        reader.readAsDataURL(file);
    }

    handleSubmit() {
        if (this.state.isSubmit) return;

        this.setState({
            isSubmit: true,
        }, () => {
          if (this.state.store.phoneNumberInCharge && this.state.store.phoneNumberInCharge.length < 10) {
            this.setState({
              isSubmit: false
            })
            return this.handleChangingStatus('담당자 연락처를 10자리 이상으로 입력해주세요.', false);
          }

            let params = {
                storeId: this.state.store.storeId,
                name: this.state.store.name,
                address: this.state.store.address,
                addressEn: this.state.store.addressEn,
                webAddr: this.state.store.webAddr,
                memo: this.state.store.memo,
                phoneNumberInCharge: this.state.store.phoneNumberInCharge,
                emailInCharge: this.state.store.emailInCharge,
                lunchStartTime: dateUtil.format('HH:mm:ss', this.state.store.lunchStartTime),
                lunchEndTime: dateUtil.format('HH:mm:ss', this.state.store.lunchEndTime),
                breakStartTime: dateUtil.format('HH:mm:ss', this.state.store.breakStartTime),
                breakEndTime: dateUtil.format('HH:mm:ss', this.state.store.breakEndTime),
                dinnerStartTime: dateUtil.format('HH:mm:ss', this.state.store.dinnerStartTime),
                dinnerEndTime: dateUtil.format('HH:mm:ss', this.state.store.dinnerEndTime),
                orderEndTime: dateUtil.format('HH:mm:ss', this.state.store.orderEndTime),
                waitingTime4Over1: this.state.store.waitingTime4Over1,
                waitingTime8Over5: this.state.store.waitingTime8Over5,
                waitingTimeOver9: this.state.store.waitingTimeOver9,
            }

            const uploadFileList = [
                'storeUrl',
                'menuUrl',
                'profileUrl'
            ]

            const uploadFiles = Object.keys(this.state.store).filter((key) => {
                return uploadFileList.indexOf(key) > -1 && this.state.store[key + 'Preview'];
            }).map((key) => {
                return new Promise((resolve, reject) => {
                    action.media.uploadImage(this.state.store[key]).then((res) => {
                        console.log(res);
                        if (res.code === 200 && res.data) {
                            params[key] = res.data.original
                            resolve(null);
                        } else {
                            reject('이미지 업로드 실패');
                        }
                    }).catch((err) => {
                        reject(err);
                    });
                })
            });

            const successFunc = () => {
              this.handleChangingStatus('정보를 저장했습니다.', true);
              setTimeout(() => {
                  window.location.reload();
              }, 1000);
            }

            Promise.all(uploadFiles).then(() => {
                this.props.dispatch(action.store.update(params))
                    .then((res) => {
                        if (res.storeId) {
                            const store = this.props.store || {};
                            store.store = res;

                            this.props.dispatch(action.store.setStore(store));

                            let funcName = '';
                            let phoneNumberParams = {
                                phoneNumber: this.state.storePhoneNumber.phoneNumber || '',
                            }
                            if (this.state.storePhoneNumber.storePhoneNumberId) {
                                phoneNumberParams.storePhoneNumberId = this.state.storePhoneNumber.storePhoneNumberId
                                funcName = 'update'
                            } else {
                                phoneNumberParams.fk_storeId = this.state.store.storeId;
                                funcName = 'create'
                            }

                            if ((phoneNumberParams.phoneNumber && funcName === 'create')
                            || funcName === 'update') {
                                this.props.dispatch(action.store_phone_number[funcName](phoneNumberParams))
                                    .then((res) => {
                                        console.log(res);
                                        if (res.storePhoneNumberId) {
                                            successFunc();
                                        } else {
                                          this.handleChangingStatus('정보를 저장하지 못했습니다.', false);
                                            return new Error('정보를 저장하지 못했습니다.');
                                        }
                                    });
                            } else {
                                successFunc();
                            }
                        } else {
                          this.handleChangingStatus('정보를 저장하지 못했습니다.', false);
                            return new Error('정보를 저장하지 못했습니다.');
                        }
                }).catch((err) => {
                    console.log(err);
                });
            }).catch((err) => {
                this.setState({
                    isSubmit: false,
                });
                console.log('Submit ERR :: ', err);
            });
        });
    }

  handleChangingStatus(text, bool) {
    this.setState({
      isStatusChanging: true,
      alertText: text,
      isPositive: bool
    }, () => {
      setTimeout(() => {
        this.setState({ isStatusChanging: false });
      }, bool ? 1000 : 3000);
    })
  }

  handleKeyPad(value, state, field) {

    let newState = {};
    console.log("<<value>>", value);
    value = value.toString();

    newState[state] = this.state[state];

    newState[state][field] = value;

    this.setState(newState);
  }

  openWaitingTimeKeypad(customStyle, field) {
      this.setState({
        keypadCustomStyle: customStyle,
        keypadField: field,
      })
      this.waitingTimeKeypad.setState({
        numberString: [],
      })
    if (!this.state.isWaitingTimeKeypadOpened) {
      this.waitingTimeKeypad.open();
      this.setState({ isWaitingTimeKeypadOpened: true, })
    } else {
      this.waitingTimeKeypad.open();
      this.setState({ isWaitingTimeKeypadOpened: false, })
    }
  }

  handleChangeLoginId(e) {
    this.setState({
      loginId: e.target.value
    })
  }
  handleChangeLoginPwd(e) {
    this.setState({
      loginPwd: e.target.value
    })
  }
  onApiLogin() {
    //let result = KTOpenAPIX.Login('0', '32d0e193962a9b686cdc65109b7b2ec06c1e0a58', this.state.loginId, this.state.loginPwd);
    let result = KTOpenAPIX.Login('1', 'f64f62c4eafb18557207e9ab6c2c8f06136927b1', this.state.loginId, this.state.loginPwd);

		switch (result)
		{
      case 200:alert("로그인 성공");break;
      case 301:alert("다른 위치에서 로그인");break;
      case 401:alert("미등록 아이디로 로그인"); break;
      case 402:alert("비밀번호 오류 횟수 초과 (5회제한)"); break;
      case 403: alert("임시비밀호 로그인");break;
      case 404:alert("임시비밀번호 설정"); break;
      //case 405:alert("비밀번호 오류"); break;
      case 407:alert("접속 IP 오류"); break;
      case 408: alert("미등록 PC");break;
      case 500: alert("기타(HTTPS/HTTP 요청 실패)");break;
      case 1000:alert("이미 로그인중"); break;
      case 1001:alert("서버 타입 에러"); break;
      case 1502:alert("협정 만료일이 지남"); break;
      case 1503: alert("인증키 유효기간이 지남");break;
      case 1504:alert("인증키 비활성"); break;
      case 1505: alert("인증키 타입이 틀림");break;
      case 1506: alert("개발 서버이나 상용 인증키, 상용 Flag임");break;
      case 1507: alert("상용 서버이나 개발 인증키, 개발 Flag임");break;
      case 1700: alert("API 환경 정보 얻지 못함(실행되는 경로)");break;
      case 1701: alert("KTA_API.dat / KTD_API.dat등의 data 파일 초기화 에러 파일이 존재해야 함"); break;
      case 1702: alert("PC 메모리 부족(API 생성 에러)");break;
      default: alert(result);
		}
  }
  onApiLineJoin() {
    //KTOpenAPIX.LineJoin('32d0e193962a9b686cdc65109b7b2ec06c1e0a58');
    KTOpenAPIX.LineJoin('f64f62c4eafb18557207e9ab6c2c8f06136927b1');
  }
  onApiMyInfo() {
    KTOpenAPIX.SetMyInfo();
  }
  onApiFindPwd() {
    KTOpenAPIX.FindPasswdEx(1);
  }
  onApiHelp() {
    KTOpenAPIX.HelpEX(1);
  }

  render(){
    const { loginId, loginPwd } = this.state;

    return(
      <div>
        <Helmet>
          <title>기본 정보</title>
        </Helmet>
          {
              this.state.isLoading ? null :
                  <div className={styleAdministrator.administratorContainer}>
                    {this.state.isStatusChanging && !this.state.isPositive ? <div className={styleReservationModal.completedAlert} style={{top: '40%', backgroundColor: '#F08080'}}>{this.state.alertText}</div> : null}
                    {this.state.isStatusChanging && this.state.isPositive ? <div className={styleReservationModal.completedAlert} style={{top: '40%'}}>{this.state.alertText}</div> : null}
                      <div className={styleAdministrator.leftContent}>
                          <div className={styleAdministrator.picSection}>
                              <div className={styleAdministrator.picCover} style={{ backgroundImage: "url(" + (this.state.store.storeUrlPreview || this.state.store.storeUrl || "/Assets/img/pic-store-01-pc.png") + ")"}}>
                              {/*
                                  <label className={style.uploadLabel + ' ' + styleAdministrator.uploadLabel} style={{ backgroundImage: "url(/Assets/img/add-pic.png)"}} htmlFor={'storeUrl'}>
                                      <div className={style.uploadIcon}/>
                                      <input className={style.uploadInput} id={'storeUrl'} type={'file'} onChange={(e) => this.handleFileChange(e, 'storeUrl', 'store')}  accept={'image/*'}/>
                                  </label>
                              */}
                              </div>
                              <div className={styleAdministrator.addMenuPic} style={{ backgroundImage: "url(" + (this.state.store.menuUrlPreview || this.state.store.menuUrl || "/Assets/img/pic-store-01-pc.png") + ")"}}>
                              {/*
                                  <label className={style.uploadLabel + ' ' + styleAdministrator.uploadLabel + ' ' + styleAdministrator.style2} style={{ backgroundImage: "url(/Assets/img/add-pic-2.png)"}} htmlFor={'menuUrl'}>
                                      <div className={style.uploadIcon}/>
                                      <input className={style.uploadInput} id={'menuUrl'} type={'file'} onChange={(e) => this.handleFileChange(e, 'menuUrl', 'store')}  accept={'image/*'}/>
                                  </label>
                              */}
                              </div>
                          </div>
                          <div className={styleAdministrator.infoSections}>
                              <div className={styleAdministrator.infoSection + ' ' + styleAdministrator.infoSection1}>
                                  <div className={styleAdministrator.infoTitle}>매장 정보</div>
                                  <div className={styleAdministrator.storeInfo}>
                                      <div className={styleAdministrator.infoSubTitle}>가맹점 지점명</div>
                                      <div className={styleAdministrator.infoInputBox}>
                                          <input type={"text"} placeholder="상호명" value={this.state.store.name || ''} onChange={(e) => this.handleStateValue(e.target.value, 'name', 'store')}/>
                                      </div>
                                      <span className={styleAdministrator.extraInfo}>ex. 본점, 인천점, 홍대입구점</span>
                                  </div>
                                  <div className={styleAdministrator.storeInfo}>
                                      <div className={styleAdministrator.infoSubTitle}>로고 이미지</div>
                                      <div className={styleAdministrator.infoInputBox}>
                                          <input type={"text"} placeholder="이미지 첨부" value={this.state.store.profileUrlName || this.state.store.profileUrl || this.state.brand.profileUrl || ''} onChange={(e) => this.handleStateValue(e.target.value, 'profileUrl', 'store')}/>
                                          <label className={style.uploadLabel + ' ' + styleAdministrator.plusIcon} style={{ backgroundImage: "url(/Assets/img/ic-plus.png)"}} htmlFor={'profileUrl'}>
                                              <div className={style.uploadIcon}/>
                                              <input className={style.uploadInput} id={'profileUrl'} type={'file'} onChange={(e) => this.handleFileChange(e, 'profileUrl', 'store')}  accept={'image/*'}/>
                                          </label>
                                      </div>
                                      <span className={styleAdministrator.extraInfo}>브랜드가 설정되어 있으면 자동으로 등록됩니다.</span>
                                  </div>
                              </div>
                              <div className={styleAdministrator.infoSection + ' ' + styleAdministrator.infoSection2}>
                                  <div className={styleAdministrator.infoTitle}>매장 주소</div>
                                  <div className={styleAdministrator.infoSubSection}>
                                      <div className={styleAdministrator.infoSubTitle}>국문</div>
                                      <div className={styleAdministrator.infoInputBox + ' ' + styleAdministrator.infoInputBox2}>
                                          <input type={"text"} placeholder="주소 검색" value={this.state.store.address || ''} onChange={(e) => this.handleStateValue(e.target.value, 'address', 'store')}/>
                                          <div className={styleAdministrator.searchIconBox} onClick={() => this.handleAddr()}><img src="/Assets/img/ic-search.png"/></div>
                                      </div>
                                      <span className={styleAdministrator.extraInfo}>시/군/구/도로명을 검색하세요.</span>
                                  </div>
                                  <div className={styleAdministrator.infoSubSection}>
                                      <div className={styleAdministrator.infoSubTitle}>영문</div>
                                      <div className={styleAdministrator.infoInputBox + ' ' + styleAdministrator.infoInputBox2}>
                                          <input type={"text"} value={this.state.store.addressEn || ''} onChange={(e) => this.handleStateValue(e.target.value, 'addressEn', 'store')}/>
                                      </div>
                                  </div>
                              </div>
                              <div className={styleAdministrator.infoSection + ' ' + styleAdministrator.infoSection3}>
                                  <div className={styleAdministrator.infoSubSection}>
                                      <div className={styleAdministrator.infoTitle}>매장 전화번호</div>
                                      <div className={styleAdministrator.infoInputBox}>
                                          <input type={"text"} placeholder="매장 전화번호" value={this.state.storePhoneNumber.phoneNumber || ''} onChange={(e) => this.handleStateValue(e.target.value, 'phoneNumber', 'storePhoneNumber')}/>
                                      </div>
                                      <span className={styleAdministrator.extraInfo}>지역번호를 포함해서 적어주세요.</span>
                                  </div>
                                  <div className={styleAdministrator.infoSubSection}>
                                      <div className={styleAdministrator.infoTitle}>홈페이지 주소</div>
                                      <div className={styleAdministrator.infoInputBox}>
                                          <input type={"text"} placeholder="https://" value={this.state.store.webAddr || ''} onChange={(e) => this.handleStateValue(e.target.value, 'webAddr', 'store')}/>
                                      </div>
                                  </div>
                              </div>
                              <div className={styleAdministrator.infoSection + ' ' + styleAdministrator.infoSection3}>
                                  <div className={styleAdministrator.infoTitle}>기타 정보</div>
                                  <div className={styleAdministrator.infoInputBox}>
                                      <input type={"text"} placeholder="기타 정보" value={this.state.store.memo || ''} onChange={(e) => this.handleStateValue(e.target.value, 'memo', 'store')}/>
                                  </div>
                                  <span className={styleAdministrator.extraInfo}>ex. 발렛파킹, 반려동물 동반가능</span>
                              </div>
                          </div>
                          <div className={styleAdministrator.saveChanges} onClick={this.handleSubmit}>정보 저장</div>
                      </div>
                      <div className={styleAdministrator.rightContent}>
                          <div className={styleAdministrator.infoSections}>
                              <div className={styleAdministrator.infoSection}>
                                  <div className={styleAdministrator.infoTitle}>담당자 연락처</div>
                                  <div className={styleAdministrator.infoSubSection}>
                                      <div className={styleAdministrator.infoSubTitle}>전화번호</div>
                                      <div className={styleAdministrator.infoInputBox + ' ' + styleAdministrator.infoInputBox3}>
                                          <input type={"text"} placeholder="전화번호" value={this.state.store.phoneNumberInCharge || ''} onChange={(e) => this.handleStateValue(e.target.value, 'phoneNumberInCharge', 'store')}/>
                                      </div>
                                  </div>
                                  <div className={styleAdministrator.infoSubSection}>
                                      <div className={styleAdministrator.infoSubTitle}>이메일</div>
                                      <div className={styleAdministrator.infoInputBox + ' ' + styleAdministrator.infoInputBox3}>
                                          <input type={"text"} placeholder="이메일" value={this.state.store.emailInCharge || ''} onChange={(e) => this.handleStateValue(e.target.value, 'emailInCharge', 'store')}/>
                                      </div>
                                  </div>
                              </div>
                              <div className={styleAdministrator.infoSection}>
                                  <div className={styleAdministrator.infoTitle}>영업시간</div>
                                  <div className={styleAdministrator.infoSubSection}>
                                      <div className={styleAdministrator.infoSubTitle + ' ' + styleAdministrator.infoSubTitle2}>점심 시간</div>
                                      <div className={styleAdministrator.timeBox}>
                                          <DatePicker
                                              className={styleAdministrator.datePicker}
                                              selected={this.state.store.lunchStartTime ? new Date(this.state.store.lunchStartTime) : new Date()}
                                              onChange={(date) => this.handleStateValue(date, 'lunchStartTime', 'store')}
                                              showTimeSelect
                                              showTimeSelectOnly
                                              timeIntervals={30}
                                              dateFormat="HH:mm"
                                              timeFormat="HH:mm"
                                              timeCaption="Time"
                                          /> ~ <DatePicker
                                              className={styleAdministrator.datePicker}
                                              selected={this.state.store.lunchEndTime ? new Date(this.state.store.lunchEndTime) : new Date()}
                                              onChange={(date) => this.handleStateValue(date, 'lunchEndTime', 'store')}
                                              showTimeSelect
                                              showTimeSelectOnly
                                              timeIntervals={30}
                                              dateFormat="HH:mm"
                                              timeFormat="HH:mm"
                                              timeCaption="Time"
                                          />
                                      </div>
                                  </div>
                                  <div className={styleAdministrator.infoSubSection}>
                                      <div className={styleAdministrator.infoSubTitle + ' ' + styleAdministrator.infoSubTitle2}>브레이크 타임</div>
                                      <div className={styleAdministrator.timeBox}>
                                          <DatePicker
                                              className={styleAdministrator.datePicker}
                                              selected={this.state.store.breakStartTime ? new Date(this.state.store.breakStartTime) : new Date()}
                                              onChange={(date) => this.handleStateValue(date, 'breakStartTime', 'store')}
                                              showTimeSelect
                                              showTimeSelectOnly
                                              timeIntervals={30}
                                              dateFormat="HH:mm"
                                              timeFormat="HH:mm"
                                              timeCaption="Time"
                                          /> ~ <DatePicker
                                              className={styleAdministrator.datePicker}
                                              selected={this.state.store.breakEndTime ? new Date(this.state.store.breakEndTime) : new Date()}
                                              onChange={(date) => this.handleStateValue(date, 'breakEndTime', 'store')}
                                              showTimeSelect
                                              showTimeSelectOnly
                                              timeIntervals={30}
                                              dateFormat="HH:mm"
                                              timeFormat="HH:mm"
                                              timeCaption="Time"
                                          />
                                      </div>
                                  </div>
                                  <div className={styleAdministrator.infoSubSection}>
                                      <div className={styleAdministrator.infoSubTitle + ' ' + styleAdministrator.infoSubTitle2}>저녁 시간</div>
                                      <div className={styleAdministrator.timeBox}>
                                          <DatePicker
                                              className={styleAdministrator.datePicker}
                                              selected={this.state.store.dinnerStartTime ? new Date(this.state.store.dinnerStartTime) : new Date()}
                                              onChange={(date) => this.handleStateValue(date, 'dinnerStartTime', 'store')}
                                              showTimeSelect
                                              showTimeSelectOnly
                                              timeIntervals={30}
                                              dateFormat="HH:mm"
                                              timeFormat="HH:mm"
                                              timeCaption="Time"
                                          /> ~ <DatePicker
                                              className={styleAdministrator.datePicker}
                                              selected={this.state.store.dinnerEndTime ? new Date(this.state.store.dinnerEndTime) : new Date()}
                                              onChange={(date) => this.handleStateValue(date, 'dinnerEndTime', 'store')}
                                              showTimeSelect
                                              showTimeSelectOnly
                                              timeIntervals={30}
                                              dateFormat="HH:mm"
                                              timeFormat="HH:mm"
                                              timeCaption="Time"
                                          />
                                      </div>
                                  </div>
                                  <div className={styleAdministrator.infoSubSection}>
                                      <div className={styleAdministrator.infoSubTitle + ' ' + styleAdministrator.infoSubTitle2}>주문 마감</div>
                                      <div className={styleAdministrator.timeBox}>
                                          ~ <DatePicker
                                              className={styleAdministrator.datePicker}
                                              selected={this.state.store.orderEndTime ? new Date(this.state.store.orderEndTime) : new Date()}
                                              onChange={(date) => this.handleStateValue(date, 'orderEndTime', 'store')}
                                              showTimeSelect
                                              showTimeSelectOnly
                                              timeIntervals={30}
                                              dateFormat="HH:mm"
                                              timeFormat="HH:mm"
                                              timeCaption="Time"
                                          />
                                      </div>
                                  </div>
                              </div>
                            <div className={styleAdministrator.infoSection}>
                              <div className={styleAdministrator.infoTitle} style={{marginBottom: '16px'}}>인원 별 대기시간 설정</div>
                              <div className={styleAdministrator.divBox}>1명 ~ 4명</div>
                              <div className={styleAdministrator.divBox + ' ' + styleAdministrator.minutes}>
                                <input type="text" value={this.state.store.waitingTime4Over1} onClick={() => this.openWaitingTimeKeypad({right: '-10px', top: '690px', width: '250px'}, 'waitingTime4Over1')}/>분
                              </div>
                              <div className={styleAdministrator.divBox}>5명 ~ 8명</div>
                              <div className={styleAdministrator.divBox + ' ' + styleAdministrator.minutes}>
                                <input type="text" value={this.state.store.waitingTime8Over5} onClick={() => this.openWaitingTimeKeypad({right: '-10px', top: '730px', width: '250px'}, 'waitingTime8Over5')}/>분
                              </div>
                              <div className={styleAdministrator.divBox}>9명 ~ </div>
                              <div className={styleAdministrator.divBox + ' ' + styleAdministrator.minutes}>
                                <input type="text" value={this.state.store.waitingTimeOver9} onClick={() => this.openWaitingTimeKeypad({right: '-10px', top: '770px', width: '250px'}, 'waitingTimeOver9')}/>분
                              </div>
                              <Keypad onRef={(ref) => {this.waitingTimeKeypad = ref;}} onChange={(value) => this.handleKeyPad(value,"store",this.state.keypadField)} customStyle={this.state.keypadCustomStyle} customKeypadStyle={styleKeypad.keypadBox1}/>
                            </div>
                            <div className={styleAdministrator.infoSection} style={{marginTop: '150px'}}>
                              <div className={styleAdministrator.infoTitle} style={{marginBottom: '16px'}}>회선 관리</div>
                              <div className={styleAdministrator.divBox}>아이디</div>
                              <div className={styleAdministrator.divBox + ' ' + styleAdministrator.minutes}>
                                <input type="text" name="loginID" value={loginId} onChange={this.handleChangeLoginId}/>
                              </div>
                              <div className={styleAdministrator.divBox}>비밀번호</div>
                              <div className={styleAdministrator.divBox + ' ' + styleAdministrator.minutes}>
                                <input type="password" name="loginPWD" value={loginPwd} onChange={this.handleChangeLoginPwd}/>
                              </div>
                              <div className={styleAdministrator.callApiBtn} onClick={this.onApiLogin}>로그인</div>
                              <div className={styleAdministrator.callApiBtn} onClick={this.onApiLineJoin}>회선청약</div>
                              <div className={styleAdministrator.callApiBtn} onClick={this.onApiMyInfo}>환경설정</div>
                              <div className={styleAdministrator.callApiBtn} onClick={this.onApiFindPwd}>비밀번호 찾기</div>
                              <div className={styleAdministrator.callApiBtn} onClick={this.onApiHelp}>도움말</div>
                            </div>
                          </div>
                      </div>
                  </div>
          }
      </div>
    )
  }
}

export default connect((state) => {
  return {
      author: state.data.account.author,
      store: state.data.store.store,
  };
})(withRouter(AdministratorView));
