import * as ActionTypes from '../actionTypes';

export const initialState = {
    account_email_verification: {},
    account_email_verification_list: []
}
/*
*/
export const account_email_verification_reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.ACCOUNT_EMAIL_VERIFICATION:
            return Object.assign({}, state, {
                account_email_verification: action.account_email_verification
            });
        case ActionTypes.ACCOUNT_EMAIL_VERIFICATION_LIST:
            return Object.assign({}, state, {
                account_email_verification_list: action.account_email_verification_LIST
            });
        default:
            return state;
        }
}
