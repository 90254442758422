import React, {Component} from 'react';
import {withRouter, Route} from 'react-router-dom';
import {connect} from 'react-redux';

// Components
import MobileComponent from '../../Components/Mobile/index'
import SearchComponent from '../../Components/Mobile/Search'
import BookComponent from '../../Components/Mobile/Book'
import ConfirmationComponent from '../../Components/Mobile/Confirmation'

// Actions
import { action } from '../../../Data';

// Utils
import { parseUrlParameter } from '../../../Utils'

class MobileContainer extends Component{
  constructor(){
    super();

    this.state = {
        isLoading: true,
    }

    this.getStoreValues = this.getStoreValues.bind(this);
  }

  componentDidMount() {
      if (this.props.location.pathname !== '/mobile/search') {
          const params = parseUrlParameter.parse(this.props.location.search);

          if (!params.storeId) {
              alert('매장 정보를 불러올 수 없습니다.');
              window.location.href = window.location.origin + '/mobile/search'
              return;
          }

          this.getStoreValues(params.storeId).then((res) => {
              this.setState({
                  isLoading: false,
              });
          }).catch((err) => {
              this.setState({
                  isLoading: false,
              });
              alert('매장 정보를 불러올 수 없습니다.');
          });
      } else {
          this.setState({
              isLoading: false,
          })
      }
  }

  getStoreValues(storeId) {
      return new Promise((resolve, reject) => {
          this.props.dispatch(action.store.getById({ storeId: storeId }))
              .then((res) => {
                  if (!res) { reject(null) }
                  this.props.dispatch(action.store.setStore(res));
                  resolve(null);
              }).catch((err) => {
                  reject(err);
              });
      });
  }

  render(){
    return(
        this.state.isLoading ? null :
      <div>
        { this.props.location.pathname === '/mobile' ? <Route path={'/mobile'} exact={false} strict={false} component={MobileComponent}/>: null }
        <Route path={'/mobile/search'} exact={false} strict={false} component={SearchComponent}/>
        <Route path={'/mobile/book'} exact={false} strict={false} component={BookComponent}/>
        <Route path={'/mobile/confirmation'} exact={false} strict={false} component={ConfirmationComponent}/>
      </div>
    )
  }
}

export default connect((state) => {
    return {
        store: state.data.store.store,
    }
})(withRouter(MobileContainer))