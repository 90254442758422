import * as ActionTypes from '../actionTypes';

export const initialState = {
    log_account_loginHistory: {},
    log_account_loginHistory_list: []
}
/*
*/
export const log_account_loginHistory_reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.LOG_ACCOUNT_LOGINHISTORY:
            return Object.assign({}, state, {
                log_account_loginHistory: action.log_account_loginHistory
            });
        case ActionTypes.LOG_ACCOUNT_LOGINHISTORY_LIST:
            return Object.assign({}, state, {
                log_account_loginHistory_list: action.log_account_loginHistory_LIST
            });
        default:
            return state;
        }
}
