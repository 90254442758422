import React, {Component} from 'react';
import {withRouter, Route, Link} from 'react-router-dom';
import {connect} from 'react-redux';
import { withTranslation } from 'react-i18next';

// Styles
import styleMobile from '../../Styles/Components/Mobile.css';
import Modal from '../../Common/Modal';

// Action
import { action } from "../../../Data/index";

const customStyles = {
  content: {
    width: '100%',
    top: '-100px',
    height: '100%'
  }
};

class MobileHeader extends Component {
  constructor(props){
    super(props);

    this.state = {
      store: this.props.store.store,
      brand: this.props.store.brand,
      brandName: '',

      lunchStartTime: '-',
      dinnerEndTime: '-',

      storePhoneNumber: this.props.store ? this.props.store.store_phone_number : null,
    }

    this.openStoreMenuModal = this.openStoreMenuModal.bind(this)
    this.closeStoreMenuModal = this.closeStoreMenuModal.bind(this)
    this.handleLanguage = this.handleLanguage.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(action.service_system.getServiceSystem()).then((res) => {
      this.setState({
        serviceSystem: res[0]
      })
    })


  }

  openStoreMenuModal() {
    this.storeMenuModal.setState({
      isOpened: !this.storeMenuModal.state.isOpened
    })
  }

  closeStoreMenuModal() {
    this.storeMenuModal.setState({
      isOpened: !this.storeMenuModal.state.isOpened
    })
  }

  handleLanguage() {
    if (this.props.i18n.language === 'ko') {
      this.props.i18n.changeLanguage('en');
    } else {
      this.props.i18n.changeLanguage('ko');
    }
  }

  render(){
    const { t, i18n } = this.props;

    let renderStoreMenuModalBody = (brandName, storeName) => {
      return (
        <div className={styleMobile.storeMenuContent + ' ' + styleMobile.storeMenuModalContent}>
          <div className={styleMobile.menuInfo + ' ' + styleMobile.selectedStoreName}>{brandName} {storeName}</div>
          <div className={styleMobile.deleteIcon} onClick={this.closeStoreMenuModal}><img src="/Assets/img/ic-delete.png"/></div>
          <div className={styleMobile.storeMenuImg} style={{backgroundImage: "url('" + this.state.store.menuUrl + "')"}}>
          </div>
          <div className={styleMobile.kmLogo2}>
            <img src={this.state.serviceSystem ? this.state.serviceSystem.service_system.blackBgLogoUrl : "/Assets/img/km-logo-2-24-px-white.png"}/>
          </div>
        </div>
      )
    }

    return (
        <div className={styleMobile.mobileContent}>
          <div className={styleMobile.storeInfo} style={this.props.location.pathname==='/mobile/book'? this.props.storeInfo : null}>
            <div className={styleMobile.searchIcon}><Link to="/mobile/search"><img src="/Assets/img/ic-search.png"/></Link></div>
            <div className={styleMobile.langIcon} onClick={this.handleLanguage}>
              {i18n.language === 'en' ? <img src="/Assets/img/btn-korea-white.png"/> : <img src="/Assets/img/btn-english-white.png"/>}
            </div>
            <div className={styleMobile.whiteCircle} style={this.props.location.pathname==='/mobile/book' ? this.props.whiteCircle : null}>
              <div className={styleMobile.openTime}>
                <img className={styleMobile.timeIcon} src="/Assets/img/ic-time.png"/>
                <span>{t('everyday')} {this.state.store.lunchStartTime.substr(0,5)} ~ {this.state.store.dinnerEndTime.substr(0,5)}</span>
              </div>
              <div className={styleMobile.whiteCircleInner}>
                <div className={styleMobile.brandName}>{i18n.language === 'ko' ? (this.state.brand.name || this.state.brand.nameEn) : (this.state.brand.nameEn || this.state.brand.name)}</div>
                <div className={styleMobile.branchName}>{i18n.language === 'ko' ? (this.state.store.name || this.state.store.nameEn) : (this.state.store.nameEn || this.state.store.name)}</div>
                <div className={styleMobile.map}><a href={'https://map.naver.com/?query='+this.state.store.address} target={'_blank'}>{t('show_map')}</a></div>
                <div className={styleMobile.address}>{i18n.language === 'ko' ? (this.state.store.address || this.state.store.addressEn) : (this.state.store.addressEn || this.state.store.address)}</div>
                <div className={styleMobile.contact}>
                  <img className={styleMobile.phoneIcon} src="/Assets/img/ic-menu-01-blue.png"/>
                  <span>{this.state.storePhoneNumber ? this.state.storePhoneNumber.phoneNumber : ''}</span>
                </div>
              </div>
            </div>
          </div>
          <div className={styleMobile.storeMenu} style={this.props.location.pathname==='/mobile/book'? this.props.storeMenu : null} onClick={this.openStoreMenuModal}>
            <img src="/Assets/img/ic-menu.png"/>
          </div>
          <Modal onRef={(ref) => {this.storeMenuModal = ref;}} customStyles={customStyles}
                 modalBody={renderStoreMenuModalBody(i18n.language === 'ko' ? (this.state.brand.name || this.state.brand.nameEn) : (this.state.brand.nameEn || this.state.brand.name),
                   i18n.language === 'ko' ? (this.state.store.name || this.state.store.nameEn) : (this.state.store.nameEn || this.state.store.name))}/>
        </div>
    )
  }
}

export default connect((state) => {
  return {
    store: state.data.store.store
  };
})(withRouter(withTranslation()(MobileHeader)));
