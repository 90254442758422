import React, {Fragment, useState, useEffect} from "react";
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom';
import styleTableManagement from "@/Service/Styles/Components/TableManagement";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faMinusCircle, faPlus} from '@fortawesome/free-solid-svg-icons'
import _ from 'underscore';
import empty from 'just-is-empty';
import { action } from '@/Data'
import { listUtil } from '@/Utils'



const Places = (props) => {

    const [loading, setLoading] = useState(true);
    const [store, setStore] = useState(props.store.store);
    const [places, setPlaces] = useState([]);
    const [originalPlaces, setOriginalPlaces] = useState([]);

    const fetchData = () => {
        return new Promise((resolve) => {
            props.dispatch(action.store_place.getList({
                fk_storeId: store.storeId,
                order: 'createdAt',
                orderMethod: 'ASC',
            })).then((res) => {
                console.log('res', res);
                const converted = res.map((item, index) => {
                    const convertedItem = item.store_place;
                    convertedItem.floors = item.store_place_floor && item.store_place_floor.length > 0 ? item.store_place_floor.map((floor, index) => {
                        floor.no = index + 1;
                        return floor;
                    }) : [];
                    convertedItem.no = index + 1;
                    return convertedItem;
                });
                resolve(converted);
            });
        });
    }

    useEffect(() => {
        fetchData().then((result) => {
            if (result) {
                setPlaces(result);
                setOriginalPlaces(JSON.parse(JSON.stringify(result)));
            }
            setLoading(false);
        });
    }, []);

    const [selectFloor, setSelectFloor] = useState({
        storePlaceId: -1,
        storePlaceFloorId: -1
    });
    const [editMode, setEditMode] = useState(false);

    let changeEditMode = () => {
        if(editMode){
            saveDataValidation().then( (validData) => {

                if(validData.places > -1 || validData.floors > -1){
                    alert(`${validData.type === "P" ? "관" : "층"} 이름을 입력해주세요.`);
                } else if ((places || []).filter((place) => {
                        return !place.floors || !place.floors.length;
                    }).length > 0) {
                    alert('층을 최소한 1개 이상 등록해야 합니다.')
                    return;
                } else {
                    saveData().then( (data) => {
                        //저장 후 처리 후 수정 모드 변경
                        setLoading(true);
                        fetchData().then((result) => {
                            console.log('저장', result);
                            if (result) {
                                setPlaces(result);
                                setOriginalPlaces(JSON.parse(JSON.stringify(result)));
                            }
                            setEditMode(!editMode);
                            setLoading(false);
                        });
                    });
                }
            });
        }else{
            setEditMode(!editMode);
        }
    };

    let saveData = () => {
        //API 저장 호출 실행
        // 층의 데이터를 추출한다 (아이디, 이름만)
        return new Promise((resolve, reject) => {
            let floors1 = []; // 원래 있던 관들의 층들만 모아놓은 배열
            let floors2 = []; // 수정된 관들의 층들만 모아놓은 배열
            let places1 = originalPlaces.map((place) => { // 원래 있던 관들에서 아이디, 이름 값만 추출한 배열
                const placeItem = {};
                placeItem.storePlaceId = place.storePlaceId;
                placeItem.name = place.name;
                placeItem.no = place.no;
                (place.floors || []).forEach((floor) => {
                    const floorItem = JSON.parse(JSON.stringify(floor));
                    floorItem.fk_storePlaceId = place.storePlaceId;
                    floorItem.parentNo = place.no;
                    floors1.push(floorItem);
                });
                return placeItem;
            });
            let places2 = places.map((place) => { // 수정된 관들에서 아이디, 이름 값만 추출한 배열
                const placeItem = {};
                placeItem.storePlaceId = place.storePlaceId;
                placeItem.name = place.name;
                placeItem.no = place.no;
                (place.floors || []).forEach((floor) => {
                    const floorItem = JSON.parse(JSON.stringify(floor));
                    floorItem.fk_storePlaceId = place.storePlaceId;
                    floorItem.parentNo = place.no;
                    floors2.push(floorItem);
                });
                return placeItem;
            });

            const changedPlace = listUtil.getJSONArrayChangedValues(places1, places2, 'storePlaceId');

            let placeFunc = [];

            if (changedPlace) {
                const createPlaceFunc = (changedPlace.created || []).map((item) => {
                    return new Promise((resolve, reject) => {
                        props.dispatch(action.store_place.create({
                            fk_storeId: store.storeId,
                            name: item.name,
                        })).then((res) => {
                            res.no = item.no;
                            resolve(res);
                        });
                    });
                });
                const updatePlaceFunc = (changedPlace.updated || []).map((item) => {
                    const updatedItem = places2.find((place) => {
                        return place.storePlaceId === item.storePlaceId;
                    });

                    return new Promise((resolve, reject) => {
                        props.dispatch(action.store_place.update({
                            storePlaceId: item.storePlaceId,
                            name: updatedItem.name,
                        })).then((res) => {
                            resolve(null);
                        });
                    });
                });
                const deletePlaceFunc = (changedPlace.deleted || []).map((item) => {
                    return new Promise((resolve, reject) => {
                        props.dispatch(action.store_place.remove({
                            storePlaceId: item.storePlaceId,
                        })).then((res) => {
                            resolve(null);
                        });
                    });
                });
                placeFunc = placeFunc.concat(createPlaceFunc,updatePlaceFunc,deletePlaceFunc)
            }

            Promise.all(placeFunc).then((res) => {
                const createdPlaces = res.filter((item) => {return item});

                let floorFunc = [];

                console.log(createdPlaces);
                floors2 = floors2.map((floor) => {
                    const createdPlaceOfFloor = createdPlaces.find((place) => {
                        return place.no === floor.parentNo;
                    });

                    if (createdPlaceOfFloor) {
                        floor.fk_storePlaceId = createdPlaceOfFloor.storePlaceId;
                    }
                    return floor;
                });

                const changedFloor = listUtil.getJSONArrayChangedValues(floors1, floors2, 'storePlaceFloorId');

                if (changedFloor) {
                    const createFloorFunc = (changedFloor.created || []).map((item) => {
                        return new Promise((resolve, reject) => {
                            props.dispatch(action.store_place_floor.create({
                                fk_storeId: store.storeId,
                                fk_storePlaceId: item.fk_storePlaceId,
                                name: item.name,
                            })).then((res) => {
                                resolve(null);
                            });
                        });
                    });
                    const updateFloorFunc = (changedFloor.updated || []).map((item) => {
                        const updatedItem = floors2.find((floor) => {
                            return floor.storePlaceFloorId === item.storePlaceFloorId;
                        });

                        return new Promise((resolve, reject) => {
                            props.dispatch(action.store_place_floor.update({
                                storePlaceFloorId: item.storePlaceFloorId,
                                name: updatedItem.name,
                            })).then((res) => {
                                resolve(null);
                            });
                        });
                    });
                    const deleteFloorFunc = (changedFloor.deleted || []).map((item) => {
                        return new Promise((resolve, reject) => {
                            props.dispatch(action.store_place_floor.remove({
                                storePlaceFloorId: item.storePlaceFloorId,
                            })).then((res) => {
                                resolve(null);
                            });
                        });
                    });

                    floorFunc = floorFunc.concat(createFloorFunc,updateFloorFunc,deleteFloorFunc)
                }

                Promise.all(floorFunc).then((res) => {
                    props.dispatch(action.store_place.setPlaceChanged(new Date()));
                    resolve(true);
                })
            }).catch((err) => {
                console.log('층 생성 에러', err);
                reject(err);
            });
        });
    };

    let saveDataValidation = async () => {
        let returnValue;
        let inValidPlaceIndex = -1, inValidFloorIndex = -1, inValidType = "";

        _.find(places, (item, placeIndex) => {
            if(empty(item.name)){
                inValidFloorIndex = placeIndex;
                inValidType = "P";
                return true;
            }else{
                _.find(item.floors, (item, floorIndex) => {
                    if(empty(item.name)){
                        inValidFloorIndex = floorIndex;
                        inValidType = "F";
                        return true;
                    }
                });
            }
        });

        returnValue = {
            places : inValidPlaceIndex,
            floors : inValidFloorIndex,
            type: inValidType
        };

        return returnValue;
    };

    let changeEditMode_ = _.debounce(changeEditMode, 200);

    let addPlace = (e) => {
        let placesDataSet_ = _.clone(places);
        let maxItem = _.max(placesDataSet_, function (item) {
            return item.no;
        });
        placesDataSet_.push({
            no: (maxItem === -Infinity ? 0 : maxItem.no) + 1,
            name: "",
            floors: []
        });
        setPlaces(placesDataSet_);
    };

    let addFloor = (e, placeItem) => {
        let placesDataSet_ = _.clone(places);
        let findItem = _.find(placesDataSet_, (item) => {
            return item.no === placeItem.no;
        });
        if (!empty(findItem)) {
            let maxItem = _.max(findItem.floors, function (item) {
                return item.no;
            });
            findItem.floors.push({
                no: (maxItem === -Infinity ? 0 : maxItem.no) + 1
            });
        }
        setPlaces(placesDataSet_);
    };

    let removePlace = (e, placeIndex) => {
        if (places.length <= 1) {
            alert('관을 최소한 1개 이상 등록해야 합니다.')
            return;
        }
        let placesDataSet_ = _.clone(places);
        if (placeIndex > -1) {
            placesDataSet_.splice(placeIndex, 1);
        }
        setPlaces(placesDataSet_);
    };

    let removeFloor = (e, placeIndex, floorIndex) => {
        let placesDataSet_ = _.clone(places);
        if (placeIndex > - 1 && floorIndex > -1) {
            placesDataSet_[placeIndex].floors.splice(floorIndex, 1);
        }

        setPlaces(placesDataSet_);
    };

    let handleChangePlace = (e, placeItem) => {
        let placesDataSet_ = _.clone(places);
        let findItem = _.find(placesDataSet_, function (item) {
            return item.no === placeItem.no;
        });
        if(!empty(findItem)){
            findItem.name = e.target.value.trim();
            setPlaces(placesDataSet_);
        }
    };
    let handleFloorNameChangeFloor = (e, placeIndex, floorItem) => {
        let placesDataSet_ = _.clone(places);
        let findItem = _.find(placesDataSet_[placeIndex].floors, function (item) {
            return item.no === floorItem.no;
        });
        if(!empty(findItem)){
            findItem.name = e.target.value.trim();
            setPlaces(placesDataSet_);
        }
    };

    let handleSelectFloor = (e, storePlaceId, storePlaceFloorId) => {
        console.log('층을 선택했습니다.', storePlaceId, storePlaceFloorId);
        console.log('층을 선택했습니다.', store, places);
        let selectFloorData = {
            storePlaceId: storePlaceId,
            storePlaceFloorId: storePlaceFloorId,
        };

        //보기 모드에만 처리할 경우 처리(보기/수정 모드 전체 가능하도록 처리 변경)
        // if(!editMode){
        setSelectFloor(selectFloorData);
        //Passing selected floor data to index.jsx
        if(typeof props.setSelectFloorHandler === "function"){
            props.setSelectFloorHandler(selectFloorData)
        }
        // }
    };

    let renderPlaces = () => {
        return loading ? null : (
            <Fragment>
                {
                    places && places.length > 0 ? places.map((placeItem, placeIndex) => {
                        return (
                            <Fragment key={placeIndex}>
                                <div className={styleTableManagement.placeWrapper}>
                                    <div className={styleTableManagement.place}>
                                        {
                                            editMode
                                            &&
                                            <Fragment>
                                                <input type="text" placeholder="관 이름"
                                                       onChange={(e) => handleChangePlace(e, placeItem)}
                                                       value={placeItem.name || ''}/>
                                                <span
                                                    className={styleTableManagement.placeIcon}
                                                ><FontAwesomeIcon
                                                    onClick={(e) => removePlace(e, placeIndex)}
                                                    icon={faMinusCircle}/></span>
                                            </Fragment>
                                        }
                                        {
                                            !editMode
                                            &&
                                            placeItem.name
                                        }

                                    </div>
                                    {
                                        <ul className={styleTableManagement.FloorBox}>
                                            {
                                                placeItem.floors.map((floorItem, floorIndex) => {
                                                    return (
                                                        <li className={styleTableManagement.floor} key={floorIndex}>
                                                            <div
                                                                onClick={(e) => handleSelectFloor(e, placeItem.storePlaceId, floorItem.storePlaceFloorId)}
                                                                className={`${
                                                                    selectFloor.storePlaceId === placeItem.storePlaceId && selectFloor.storePlaceFloorId === floorItem.storePlaceFloorId
                                                                        // && !editMode
                                                                        ? styleTableManagement.floorItemSelected : ""}`}
                                                            >
                                                            <span>
                                                                {
                                                                    editMode
                                                                    &&
                                                                    <input type="text" placeholder="층 이름"
                                                                           onChange={(e) => handleFloorNameChangeFloor(e, placeIndex, floorItem)}
                                                                           value={floorItem.name || ''}/>
                                                                }
                                                                {
                                                                    !editMode
                                                                    &&
                                                                    <span
                                                                    >{floorItem.name || floorItem.no}</span>
                                                                }
                                                            </span>
                                                                {
                                                                    editMode
                                                                    &&
                                                                    <span
                                                                        className={styleTableManagement.floorIcon}
                                                                    ><FontAwesomeIcon
                                                                        onClick={(e) => {
                                                                            if (placeItem.floors.length <= 1) {
                                                                                alert('층을 최소한 1개 이상 등록해야 합니다.')
                                                                                return;
                                                                            }
                                                                            removeFloor(e, placeIndex, floorIndex)
                                                                        }}
                                                                        icon={faMinusCircle}/></span>
                                                                }
                                                            </div>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    }
                                    {
                                        editMode
                                        &&
                                        <div className={styleTableManagement.floorAdd}>
                                            <div className={styleTableManagement.floorAddWrapper}
                                                 onClick={(e) => addFloor(e, placeItem)}>
                                                층 추가
                                                <FontAwesomeIcon icon={faPlus} size="lg"
                                                                     className={styleTableManagement.floorAddIcon}/>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </Fragment>
                        )
                    }) : null
                }
            </Fragment>
        )
    };

    return (
        <Fragment>
            <div className={styleTableManagement.navBox}>
                <div className={styleTableManagement.navTitle}>매장 관리</div>
                <div className={styleTableManagement.navEdit} onClick={changeEditMode_}>
                    {editMode ? '저장' : '편집'}
                </div>
            </div>
            <div className={styleTableManagement.placeFloorBox}>
                <div className={styleTableManagement.placeBox}>
                    {loading ? null : renderPlaces()}
                </div>
            </div>

            {
                editMode
                &&
                <div className={styleTableManagement.placeAdd}>
                    <div className={styleTableManagement.placeAddWrapper}
                         onClick={(e) => addPlace(e)}
                    >
                        관 추가<FontAwesomeIcon icon={faPlus} size="lg"
                                             className={styleTableManagement.floorAddIcon}/>
                    </div>
                </div>
            }

        </Fragment>

    )
};

export default connect((state) => {
    return {
        store: state.data.store.store,
        placeChanged: state.data.store_place.placeChanged
    }
})(withRouter(Places));
