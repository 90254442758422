import React, {Component} from 'react';
import {withRouter, Route} from 'react-router-dom';
import {connect} from 'react-redux';

// FullCalendar
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import './main.scss' // webpack must be configured to do this
import koLocale from '@fullcalendar/core/locales/ko';
import momentPlugin from '@fullcalendar/moment';

// styles
import styleCalendar from '../../../Styles/Components/Calendar.css'
import { action } from '@/Data'
import { dateUtil, parseUrlParameter } from '../../../../Utils'

class CalendarView extends Component{
	constructor(props){
		super(props);
		this.state = {
			date: new Date(),
			events: [],
			store: {},
		};

		this.calendarComponentRef = React.createRef();

		this.onChange = this.onChange.bind(this);
		this.dateClick = this.dateClick.bind(this);
		this.eventCustomMake = this.eventCustomMake.bind(this);
		this.getCalendarValues = this.getCalendarValues.bind(this);
	}

	componentDidMount() {
		this.getCalendarValues();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.store_place !== prevProps.store_place
		|| this.props.store_place_floor !== prevProps.store_place_floor) {
			this.getCalendarValues();
		}
		// if (this.props.selectedDate !== prevProps.selectedDate) {
		// 	this.setState({
		// 		selectedDate: this.props.selectedDate,
		// 	}, () => {
		// 		let calendarComponentRef = this.calendarComponentRef.current.getApi()
		// 		calendarComponentRef.gotoDate(this.state.selectedDate);
		// 	});
		// }
		let calendarComponentRef = this.calendarComponentRef.current.getApi()
		calendarComponentRef.gotoDate(this.props.selectedDate);
	}

	getCalendarValues() {
		this.setState({
			store: this.props.store.store,
			selectedPlace: this.props.store_place || {},
			selectedFloor: this.props.store_place_floor || {},
		}, () => {
            if (!this.state.store
			|| !this.state.selectedPlace.storePlaceId
			|| !this.state.selectedFloor.storePlaceFloorId
			) {
            	return;
            }
            let params = {
                storeId: this.state.store.storeId,
                storePlaceId: this.state.selectedPlace.storePlaceId,
				reservationStatus: 0,
            }

            this.props.dispatch(action.store_reservation.getTotalByDate(params)).then((res) => {
            	this.setState({
					events: (res || []).map((value) => {
						return {
							start: value.date,
							title: value.allCount + ' ' + value.allPeople + ' ' + value.lunchCount + ' ' + value.lunchPeople + ' ' + value.dinnerCount + ' ' + value.dinnerPeople,
						}
					})
				})
            })
		});
	}

	onChange(date) {
		this.setState({ date: date });
	}

	dateClick(info){
//		console.log(info,'?????????')
		let queries = {
			date: info.dateStr
		}
		this.props.dispatch(action.store_reservation.setSelectedDate(info.dateStr));

		// 전화예약 시나리오 변경
		if (this.props.isCallReservation) {
			//this.props.customerModalBody.handleSubmit();
			this.props.closeCalendarModal();
			this.props.closeCustomerModal();
			this.props.closeReservationModal();
		}

/*		console.log('----dateClick');
		console.log(info);*/

		this.props.history.push('/store/reservation' + parseUrlParameter.setQueryString(queries, this.props.location.search));
	}

	/**
	 * 날짜 이벤트 커스텀 처리 재처리 함수를 만들어서 활용
	 * @param info
	 */
	eventCustomMake(info){
//		console.log(info)
		let itemHtml = `<div id='` + dateUtil.format('YYYY-MM-DD', info.dateStr) + `' class='eventItem' style="font-size: 14px; width: 90%; margin: 0 auto; text-indent: 5px; cursor: pointer">
            <div style="width: 100%; height: 50px; line-height: 50px;">
                <div style="width: 33%; float: left; font-weight: 700; color: #1a1a21; font-size: 12px;">총 예약</div>
                <div style="width: 33%; float: left; color: #1e2028; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">`+ info.event.title.split(' ')[0] +` 건</div>
                <div style="width: 33%; float: left; color: #6d7595; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">` + info.event.title.split(' ')[1] +` 명</div>
            </div>
            <div style="background-color: #eceff2; height: 25px; line-height: 25px;">
                <div style="width: 33%; float: left; font-weight: 700; color: #1a1a21; font-size: 12px;">점심</div>
                <div style="width: 33%; float: left; color: #1e2028; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">`+ info.event.title.split(' ')[2] +` 건</div>
                <div style="width: 33%; float: left; color: #6d7595; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">`+ info.event.title.split(' ')[3] +` 명</div>
            </div>
            <div style="background-color: #eceff2; height: 25px; line-height: 25px;">
                <div style="width: 33%; float: left; font-weight: 700; color: #1a1a21; font-size: 12px;">저녁</div>
                <div style="width: 33%; float: left; color: #1e2028; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">`+ info.event.title.split(' ')[4] +` 건</div>
                <div style="width: 33%; float: left; color: #6d7595; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">`+ info.event.title.split(' ')[5] +` 명</div>
            </div>
        </div>`;
		return itemHtml;
	};

	eventRender(info, this_){
		info.dateStr = info.event.start;
		setTimeout(function () {
			if($(info.el).parent().find(".eventItem").length === 0){
				let itemHtml = this_.eventCustomMake(info);
				$(info.el).parent().append(itemHtml);
				$('#' + dateUtil.format('YYYY-MM-DD', info.dateStr)).click(() => {
					this_.dateClick(info)})
			}
			$(info.el).remove();
		}, 0);
	}


	render(){
		return(
			<div className={styleCalendar.calendarContent}>
				<div className={styleCalendar.calendarSection}>
					<FullCalendar id="calendar"
					              defaultView="dayGridMonth"
					              events={this.state.events}
					              header={false}
					              locale={koLocale}
					              columnHeader={false}
					              selectable={true}
					              dateClick={(info) => this.dateClick(info)}
					              ref={this.calendarComponentRef}
					              eventRender={(info) => this.eventRender(info, this)}
					              plugins={[ dayGridPlugin, momentPlugin, interactionPlugin]}
					/>
				</div>
			</div>
		)
	}
}

export default connect((state) => {
	return {
		author: state.data.account.author,
		store: state.data.store.store,
		store_place: state.data.store_place.store_place,
		store_place_floor: state.data.store_place_floor.store_place_floor,
		selectedDate: state.data.store_reservation.selectedDate,
		reservationUpdated: state.data.store_reservation.reservationUpdated
	}
})(withRouter(CalendarView))
