import React, {Fragment, useEffect, useState} from "react";
import styleTableManagement from "@/Service/Styles/Components/TableManagement";
import _ from 'underscore';
import empty from 'just-is-empty';
import jQuery from "jquery";
import reactCSS from 'reactcss'
import {SketchPicker} from 'react-color';

// action
import { action } from '@/Data';

window.$ = window.jQuery = jQuery;

let shapeDataSet = [
    {
        no: 0,
        type: "rect",
        config: {
            width: 48,
            height: 48,
            x: 6,
            y: 6,
            stroke: '#98a0ab',
            strokeWidth: 1,
            strokeDasharray: "",
            fill: "#FFFFFF",
        }
    },
    {
        no: 1,
        type: "circle",
        config: {
            cx: 30,
            cy: 30,
            r: 24,
            stroke: '#98a0ab',
            strokeWidth: 1,
            strokeDasharray: "",
            fill: "#FFFFFF"
        }
    },
    /*{
        no: 2,
        type: "diamond",
        config: {
            width: 38,
            height: 38,
            x: 24,
            y: -20,
            stroke: '#98a0ab',
            strokeWidth: 1,
            strokeDasharray: "",
            fill: "#FFFFFF"
        }
    }*/
];

const Setting = ({editValue, settingData, selectFloorData, saveTableData, deleteTableData, editTableMode}) => {
    const colorLine = "#98a0ab";
    const colorBack = "#FFFFFF";
    const styles = reactCSS({
        'default': {
            colorLine: {
                width: '36px',
                height: '14px',
                borderRadius: '2px',
                background: `${colorLine}`,
            },
            colorBack: {
                width: '36px',
                height: '14px',
                borderRadius: '2px',
                background: `${colorBack}`,
            },
            swatch: {
                padding: '5px',
                background: '#FFFFFF',
                borderRadius: '1px',
                boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                display: 'inline-block',
                cursor: 'pointer',
            },
            popover: {
                position: 'absolute',
                zIndex: '2',
            },
            cover: {
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
            },
        },
    });
    const [backColor, setBackColor] = useState(colorBack);
    const [lineColor, setLineColor] = useState(colorLine);
    const [displayBackColorPicker, setDisplayBackColorPicker] = useState(false);
    const [displayLineColorPicker, setDisplayLineColorPicker] = useState(false);

    const [setting, setSetting] = useState({
        shapeType: 0,
        lineType: 1,
        lineColor: '#98a0ab',
        backColor: '#FFFFFF',
        tableTitleNo: "",
        tableInfo: "",
        storeTableId: -1,
        lockAspectRatio : false,
        width:165,
        height:165,
        x: 250,
        y: 250
    });
    useEffect(() => {
        console.warn("settingData >>>>>>>>>>>>>>>>>>>>>>>>>> ", settingData)
        setSetting(settingData);
    }, [settingData]);

    let selectShape = (e, index) => {
        let setting_ = _.clone(setting);
        setting_.shapeType = index;
        setSetting(setting_);
    };

    let selectLine = (e) => {
        let setting_ = _.clone(setting);
        setting_.lineType = parseInt(e.target.value);
        setSetting(setting_);
    };
    let selectColor = (e) => {
        let setting_ = _.clone(setting);
        setting_.backColor = e.target.value;
        setSetting(setting_);
    };
    let handleChangeTableTitleNo = (e) => {
        let setting_ = _.clone(setting);
        setting_.tableTitleNo = e.target.value;
        setSetting(setting_);
    };

    let handleChangeTableInfo = (e) => {
        let setting_ = _.clone(setting);
        setting_.tableInfo = e.target.value;
        setSetting(setting_);
    };
    let handleSubmit = async (e) => {
        if (submitValidate()) {
	        if (typeof saveTableData === 'function') {
		        let shapeData = _.find(shapeDataSet, (item) => {
			        return item.no === setting.shapeType;
		        });
		        let saveDataSet = {
			        shapeData: shapeData,
			        shapeType: setting.shapeType,
			        lineType: setting.lineType,
			        lineColor: setting.lineColor,
			        backColor: setting.backColor,
			        tableTitleNo: setting.tableTitleNo,
			        storeTableId: setting.storeTableId,
			        tableInfo: setting.tableInfo,
			        width: setting.width,
			        height: setting.height,
			        lockAspectRatio: setting.lockAspectRatio,
			        newItem: setting.storeTableId === -1,
			        x: setting.x,
			        y: setting.y
		        };
		        saveTableData(saveDataSet);
	        }
        }
    };
    let handleDelete = async (e) => {
        if (setting && setting.storeTableId) {
	        deleteTableData(setting.storeTableId)
        }
	    editTableMode();
    }
    let submitValidate = () => {
        let validFlag = true, message = "";
        if (empty(setting.tableTitleNo)) {
            validFlag = false;
            message = "테이블 번호를 입력해주세요."
        }
        if (!validFlag) {
            alert(message);
        }
        return validFlag;
    };

    let calcLineType = () => {
        let returnValue = {
            config: {
                strokeDasharray: "",
                strokeWidth: ""
            }
        };
        if (setting.lineType === 1) {
            returnValue.config.strokeDasharray = "";
            returnValue.config.strokeWidth = "1";
        } else if (setting.lineType === 2) {
            returnValue.config.strokeDasharray = "4";
            returnValue.config.strokeWidth = "1";
        } else if (setting.lineType === 3) {
            returnValue.config.strokeDasharray = "6 4";
            returnValue.config.strokeWidth = "2";
        } else {
            returnValue.config.strokeDasharray = "";
            returnValue.config.strokeWidth = "1";
        }
        return returnValue;
    };

    let handleClickBack = () => {
        setDisplayBackColorPicker(!displayBackColorPicker);
    };

    let handleCloseBack = () => {
        setDisplayBackColorPicker(false);
    };

    let handleChangeBack = (color) => {
        let setting_ = _.clone(setting);
        setting_.backColor = color.hex;
        setSetting(setting_);

        setBackColor(color.hex);
    };

    let handleClickLine = () => {
        setDisplayLineColorPicker(!displayLineColorPicker);
    };

    let handleCloseLine = () => {
        setDisplayLineColorPicker(false);
    };

    let handleChangeLine = (color) => {
        let setting_ = _.clone(setting);
        setting_.lineColor = color.hex;
        setSetting(setting_);

        setLineColor(color.hex);
    };

    let handleLockAspectRatio = (e) => {
        let setting_ = _.clone(setting);
        setting_.lockAspectRatio = e.target.checked;
        setSetting(setting_);
    };
    // console.error("setting >> ", setting);
    return (
        <div className={styleTableManagement.setting}>
            <div className={styleTableManagement.settingInner}>
                <div className={styleTableManagement.settingTitle}>테이블 설정</div>
                <div className={styleTableManagement.settingSection}>
                    <div className={styleTableManagement.settingSubTitle}>모양</div>
                    <div className={styleTableManagement.tableShapeWrapper}>
                        <ul>
                            {
                                shapeDataSet.map((item, i) => {
                                    return (
                                        <li key={i}>
                                            <svg width="60" height="60" viewBox="0 0 60 60"
                                                 xmlns="http://www.w3.org/2000/svg"
                                                 onClick={(e) => selectShape(e, i)}
                                                 className={
                                                     `${setting.shapeType === i ? styleTableManagement.shapeItemSelected : ""} ${styleTableManagement.tableShape}`}>
                                                {
                                                    item.type === 'rect'
                                                    &&
                                                    <Fragment>
                                                        <rect width={item.config.width} height={item.config.height}
                                                              x={item.config.x} y={item.config.y}
                                                              stroke={setting.lineColor || item.config.stroke}
                                                              strokeWidth={calcLineType().config.strokeWidth}
                                                              strokeDasharray={calcLineType().config.strokeDasharray}
                                                              strokeLinecap={"round"}
                                                              fill={empty(setting.backColor) ? item.config.fill : `${!setting.backColor.includes("#") ? '#' : ''}${setting.backColor}`}
                                                              className={`${setting.shapeType === i ? styleTableManagement.shapeItemSelected : ""} ${styleTableManagement.shapeItem}`}/>
                                                    </Fragment>
                                                }
                                                {
                                                    item.type === 'circle'
                                                    &&
                                                    <Fragment>
                                                        <circle cx={item.config.cx} cy={item.config.cy}
                                                                r={item.config.r}
                                                                stroke={setting.lineColor || item.config.stroke}
                                                                strokeWidth={calcLineType(item).config.strokeWidth}
                                                                strokeDasharray={calcLineType(item).config.strokeDasharray}
                                                                strokeLinecap={"round"}
                                                                fill={empty(setting.backColor) ? item.config.fill : `${!setting.backColor.includes("#") ? '#' : ''}${setting.backColor}`}
                                                                className={`${setting.shapeType === i ? styleTableManagement.shapeItemSelected : ""} ${styleTableManagement.shapeItem}`}/>
                                                    </Fragment>

                                                }
                                                {
                                                    item.type === 'diamond'
                                                    &&
                                                    <rect width={item.config.width} height={item.config.height}
                                                          x={item.config.x} y={item.config.y}
                                                          stroke={setting.lineColor || item.config.stroke}
                                                          strokeWidth={calcLineType(item).config.strokeWidth}
                                                          strokeDasharray={calcLineType(item).config.strokeDasharray}
                                                          strokeLinecap={"round"}
                                                          transform='rotate(45)'
                                                          fill={empty(setting.backColor) ? item.config.fill : `${!setting.backColor.includes("#") ? '#' : ''}${setting.backColor}`}
                                                          className={`${setting.shapeType === i ? styleTableManagement.shapeItemSelected : ""} ${styleTableManagement.shapeItem}`}/>
                                                }
                                            </svg>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
                <div className={styleTableManagement.settingSection}>
                    <div className={styleTableManagement.settingSubTitle}>테두리</div>
                    <div className={styleTableManagement.selectBox}>
                        <select onChange={selectLine} value={setting.lineType}>
                            <option value={1}>(실선)-------------</option>
                            <option value={2}>(점선1)------------</option>
                            <option value={3}>(점선2)------------</option>
                        </select>
                        <div style={{marginTop:'10px'}}>
                            <div style={styles.swatch} onClick={handleClickLine}>
                                <div style={{
                                    width: '36px',
                                    height: '14px',
                                    borderRadius: '2px',
                                    background: `#${setting.lineColor.replace('#','')}`
                                }}/>
                            </div>
                            {displayLineColorPicker ? <div style={styles.popover}>
                                <div style={styles.cover} onClick={handleCloseLine}/>
                                <SketchPicker color={lineColor} onChange={handleChangeLine}/>
                            </div> : null}
                        </div>
                    </div>
                </div>
                <div className={styleTableManagement.settingSection}
                     style={{height:'20px', fontSize:"12px", textAlign:"center", marginTop:"10px"}}>
                    <input type="checkbox" onChange={handleLockAspectRatio}
                           style={{width:'15px', height:'15px'}} id={"lockAspectRatio"}
                           checked={setting.lockAspectRatio || false} value={'lockAspectRatio'}/>
                    <label htmlFor={"lockAspectRatio"}>비율유지</label>
                </div>
                <div className={styleTableManagement.settingSection} style={{height:"70px"}}>
                    <div className={styleTableManagement.settingSubTitle} style={{marginBottom:"5px"}}>배경색</div>
                    <div className={styleTableManagement.selectBox}>
                        <div style={styles.swatch} onClick={handleClickBack}>
                            <div style={{
                                width: '36px',
                                height: '14px',
                                borderRadius: '2px',
                                background: `#${setting.backColor.replace('#','')}`,
                            }}/>
                        </div>
                        {displayBackColorPicker ? <div style={styles.popover}>
                            <div style={styles.cover} onClick={handleCloseBack}/>
                            <SketchPicker color={backColor} onChange={handleChangeBack}/>
                        </div> : null}
                    </div>
                </div>
                <div className={styleTableManagement.settingSection}>
                    <div className={styleTableManagement.settingSubTitle}>테이블 번호</div>
                    <div className={styleTableManagement.inputBox}>
                        <input type="text" value={setting.tableTitleNo} onChange={handleChangeTableTitleNo}/>
                    </div>
                </div>
                <div className={styleTableManagement.settingSection}>
                    <div className={styleTableManagement.settingSubTitle}>테이블 정보</div>
                    <div className={styleTableManagement.inputBox}>
                        <input type="text" value={setting.tableInfo} onChange={handleChangeTableInfo}/>
                    </div>
                </div>
	            <div className={styleTableManagement.saveButton} onClick={handleSubmit}>저장</div>
	            <div className={styleTableManagement.deleteButton} onClick={handleDelete}>삭제</div>
            </div>
        </div>
    )
};


export default Setting;
