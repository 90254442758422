import React, {Component} from 'react';
import {
  withRouter, Route ,Link
} from 'react-router-dom';
import {connect} from 'react-redux';

// Components
import Main from './Main'
import Sub from './Sub'

// CSS
import { parsePathname } from '../../../Utils'

class HeaderView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPath: 0,
    }

    this.handleMenuItems = this.handleMenuItems.bind(this);
    this.handleFullScreen = this.handleFullScreen.bind(this);
  }

  componentWillMount() {
    this.handleMenuItems();
  }

  componentDidUpdate(prevProps, prevState) {
    console.log('여기프롭')
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.handleMenuItems();
    }
  }

  handleMenuItems() {
    let currentPath = this.state.currentPath;
    let pathname = this.props.location.pathname;

    if (parsePathname.getPathIndex(pathname, 2) === 'reservation') {
      currentPath = 0;
    } else if (parsePathname.getPathIndex(pathname, 2) === 'board') {
      currentPath = 1;
    } else if (parsePathname.getPathIndex(pathname, 2) === 'statistics') {
      currentPath = 2;
    } else if (parsePathname.getPathIndex(pathname, 2) === 'administrator') {
      currentPath = 3;
    }

    this.setState({
      currentPath: currentPath,
    })
  }

  handleFullScreen(element) {
    let requestMethod = element.requestFullScreen || element.webkitRequestFullScreen || element.mozRequestFullScreen || element.msRequestFullScreen;
    if (requestMethod) {
      requestMethod.call(element);
    } else if (typeof window.ActiveXObject !== "undefined") { // Older IE.
      let wscript = new ActiveXObject("WScript.Shell");

      if (wscript !== null) {
        wscript.SendKeys("{F11}");
      }
    }
  }

  render() {
    return (
      <div>
        {this.props.isCallReservation ? null : <Main handleFullScreen={this.handleFullScreen}/> }
        <Sub currentPath={this.state.currentPath} isCallReservation={this.props.isCallReservation} handleFullScreen={this.handleFullScreen}/>
      </div>
    );
  }
}

export default connect((state) => {
  return { author: state.data.account.author,};
})(withRouter(HeaderView));

