import { combineReducers } from 'redux';
import { account_reducer } from './Reducer/account'
import { account_email_verification_reducer } from './Reducer/account_email_verification'
import { account_phone_verification_reducer } from './Reducer/account_phone_verification'
import { account_delete_reducer } from './Reducer/account_delete'
import { account_social_reducer } from './Reducer/account_social'
import { board_reducer } from './Reducer/board'
import { board_like_reducer } from './Reducer/board_like'
import { comment_like_reducer } from './Reducer/comment_like'
import { comment_reducer } from './Reducer/comment'
import { invoice_reducer } from './Reducer/invoice'
import { log_account_loginHistory_reducer } from './Reducer/log_account_loginHistory'
import { notification_reducer } from './Reducer/notification'
import { order_product_reducer } from './Reducer/order_product'
import { product_reducer } from './Reducer/product'
import { brand_reducer } from './Reducer/brand'
import { customer_reducer } from './Reducer/customer'
import { customer_prefer_place_reducer } from './Reducer/customer_prefer_place'
import { log_store_call_history_reducer } from './Reducer/log_store_call_history'
import { log_store_reservation_history_reducer } from './Reducer/log_store_reservation_history'
import { log_subscription_payment_reducer } from './Reducer/log_subscription_payment'
import { store_reducer } from './Reducer/store'
import { store_customer_reducer } from './Reducer/store_customer'
import { store_customer_info_reducer } from './Reducer/store_customer_info'
import { store_history_reducer } from './Reducer/store_history'
import { store_device_reducer } from './Reducer/store_device'
import { store_memo_reducer } from './Reducer/store_memo'
import { store_notification_reducer } from './Reducer/store_notification'
import { store_phone_number_reducer } from './Reducer/store_phone_number'
import { store_menu_reducer } from './Reducer/store_menu'
import { store_place_reducer } from './Reducer/store_place'
import { store_place_floor_reducer } from './Reducer/store_place_floor'
import { store_reservation_reducer } from './Reducer/store_reservation'
import { store_reservation_table_reducer } from './Reducer/store_reservation_table'
import { store_reservation_menu_reducer } from './Reducer/store_reservation_menu'
import { store_reservation_memo_reducer } from './Reducer/store_reservation_memo'
import { store_staff_reducer } from './Reducer/store_staff'
import { store_table_reducer } from './Reducer/store_table'

export const action = {
   account: require('./Action/account'),
   account_email_verification: require('./Action/account_email_verification'),
   account_phone_verification: require('./Action/account_phone_verification'),
   account_delete: require('./Action/account_delete'),
   account_social: require('./Action/account_social'),
   board: require('./Action/board'),
   board_like: require('./Action/board_like'),
   comment_like: require('./Action/comment_like'),
   comment: require('./Action/comment'),
   constData: require('./Action/const'),
   invoice: require('./Action/invoice'),
   log_account_loginHistory: require('./Action/log_account_loginHistory'),
   media: require('./Action/media'),
   notification: require('./Action/notification'),
   payment: require('./Action/payment'),
   order_product: require('./Action/order_product'),
   product: require('./Action/product'),
   brand: require('./Action/brand'),
   customer_prefer_place: require('./Action/customer_prefer_place'),
	log_store_alarm_history: require('./Action/log_store_alarm_history'),
   log_store_call_history: require('./Action/log_store_call_history'),
   log_store_reservation_history: require('./Action/log_store_reservation_history'),
	log_subscription_payment: require('./Action/log_subscription_payment'),
   store: require('./Action/store'),
   store_customer: require('./Action/store_customer'),
   store_customer_info: require('./Action/store_customer_info'),
   store_history: require('./Action/store_history'),
   store_device: require('./Action/store_device'),
   store_memo: require('./Action/store_memo'),
   store_phone_number: require('./Action/store_phone_number'),
   store_menu: require('./Action/store_menu'),
   store_notification: require('./Action/store_notification'),
   store_place: require('./Action/store_place'),
   store_place_floor: require('./Action/store_place_floor'),
   store_reservation: require('./Action/store_reservation'),
   store_reservation_table: require('./Action/store_reservation_table'),
   store_reservation_menu: require('./Action/store_reservation_menu'),
   store_reservation_memo: require('./Action/store_reservation_memo'),
   store_reservation_place: require('./Action/store_reservation_place'),
   store_staff: require('./Action/store_staff'),
   store_table: require('./Action/store_table'),
   service_system: require('./Action/system'),
   service_docs: require('./Action/service_docs'),
}

export const reducer = combineReducers({
   account: account_reducer,
   account_email_verification: account_email_verification_reducer,
   account_phone_verification: account_phone_verification_reducer,
   account_delete: account_delete_reducer,
   account_social: account_social_reducer,
   board: board_reducer,
   board_like: board_like_reducer,
   comment_like: comment_like_reducer,
   comment: comment_reducer,
   invoice: invoice_reducer,
   log_account_loginHistory: log_account_loginHistory_reducer,
   notification: notification_reducer,
   order_product: order_product_reducer,
   product: product_reducer,
   brand: brand_reducer,
   customer_prefer_place: customer_prefer_place_reducer,
   log_store_call_history: log_store_call_history_reducer,
   log_store_reservation_history: log_store_reservation_history_reducer,
	log_subscription_payment: log_subscription_payment_reducer,
   store: store_reducer,
   store_customer: store_customer_reducer,
   store_customer_info: store_customer_info_reducer,
   store_history: store_history_reducer,
   store_device: store_device_reducer,
   store_memo: store_memo_reducer,
   store_notification: store_notification_reducer,
   store_phone_number: store_phone_number_reducer,
   store_menu: store_menu_reducer,
   store_place: store_place_reducer,
   store_place_floor: store_place_floor_reducer,
   store_reservation: store_reservation_reducer,
   store_reservation_table: store_reservation_table_reducer,
   store_reservation_menu: store_reservation_menu_reducer,
   store_reservation_memo: store_reservation_memo_reducer,
   store_staff: store_staff_reducer,
   store_table: store_table_reducer,

});
