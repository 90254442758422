import React, {Component} from 'react';
import {withRouter, Route} from 'react-router-dom';
import {connect} from 'react-redux';
import {Helmet} from "react-helmet";
import moment from 'moment';

// styles
import styleStore from '../../../../Styles/Components/Store.css'
import styleBoard from '../../../../Styles/Components/Board.css'
import SearchBar from '@/Service/Components/Store/Statistics/PeriodSearchBar'
import styleTable from '@/Service/Styles/Common/Table.css'
import InfiniteList from '../../../../Common/InfiniteList/index';
import { converter, dateUtil } from '@/Utils'

// Actions
import * as ActionNotification from '../../../../../Data/Action/notification';
import styleReservationModal from '@/Service/Styles/Components/ReservationModal.css'

//기타 이력
class ExtraRecords extends Component{
  constructor(){
    super();

    this.state = {
      extraRecordConfig: {
        COLUMN_NAME: ['발송일자', '발송시간', '이름', '연락처', '내용', '형식', '발송결과' ],
        COLUMN_FIELD: ['sendAt', 'sendAt', 'name', 'phoneNumber', 'message','type', 'msgResult'],
        COLUMN_SIZE: ['15%', '10%', '10%', '15%', '30%', '10%', '10%'],
      },
        msgResult: {
          1: '발송',
          2: '발송실패'
        }
    }

    this.getNotificationListData = this.getNotificationListData.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChangingStatus = this.handleChangingStatus.bind(this);
  }

  componentWillMount() {
    this.setState({
      extraRecordsParams: {
        fk_storeId: this.props.store && this.props.store.store ? this.props.store.store.storeId : null,
        order: 'createdAt',
        orderMethod: 'DESC',
        dateFrom: dateUtil.format('YYYY-MM-DD', new Date(moment().subtract(7, 'days'))),
        dateTo: dateUtil.format('YYYY-MM-DD', new Date()),
      }
    })
  }

  getNotificationListData(list){
    if (!list || list.length === 0) {
      return;
    }
    let checked = [];
    list.forEach(() => {
      checked.push(false);
    })
    this.setState({
      notificationList: list,
      checked: checked,
    })
  }

  handleSearch(params) {
    let extraRecordsParams =  Object.assign(this.state.extraRecordsParams, params)

    this.setState({
      extraRecordsParams: extraRecordsParams
    }, () => {
      this.extraRecordsList.reset();
    })
  }

  handleChangingStatus(text, bool) {
    this.setState({
      isStatusChanging: true,
      alertText: text,
      isPositive: bool
    }, () => {
      setTimeout(() => {
        this.setState({ isStatusChanging: false });
      }, bool ? 1000 : 3000);
    })
  }

  render(){
    const renderExtraRecordListHeader = () => {
      return (
        <div className={styleTable.tableContentHeader}>
          { this.state.extraRecordConfig.COLUMN_FIELD.map((field, index) => {
            let extraRecordConfig = this.state.extraRecordConfig;
            return (
              <div
                className={styleTable.column + ' ' + (field ? styleTable.style2 : styleTable.style3)}
                style={{width: extraRecordConfig.COLUMN_SIZE[index]}}
                key={index}
              >
                {extraRecordConfig.COLUMN_NAME[index] }
              </div>
            )
          }) }
        </div>
      );
    }

    const renderExtraRecordsList = (item, index) => {
      const config = this.state.extraRecordConfig;

      return (
        <div className={styleTable.tableContentItem + (item.isChecked ? ' ' + styleTable.active : '')} key={index}>
          <div
            className={styleTable.column}
            style={{width: config.COLUMN_SIZE[0]}}
          >
            {dateUtil.format('slash', item['notification'][config.COLUMN_FIELD[0]])}
          </div>
          <div
            className={styleTable.column}
            style={{width: config.COLUMN_SIZE[1]}}
          >
            {dateUtil.format('HH:mm', item['notification'][config.COLUMN_FIELD[1]])}
          </div>
          <div
            className={styleTable.column}
            style={{width: config.COLUMN_SIZE[2]}}
          >
            {converter.parseListByType(item['store_customer'][config.COLUMN_FIELD[2]])}
          </div>
          <div
            className={styleTable.column}
            style={{width: config.COLUMN_SIZE[3]}}
          >
            {converter.parseListByType(item['store_customer'][config.COLUMN_FIELD[3]])}
          </div>
          <div
            className={styleTable.column}
            style={{width: config.COLUMN_SIZE[4]}}
          >
            {converter.parseListByType(item['notification'][config.COLUMN_FIELD[4]])}
          </div>
          <div
            className={styleTable.column}
            style={{width: config.COLUMN_SIZE[5]}}
          >
            {converter.parseListByType(item['notification'][config.COLUMN_FIELD[5]]) == 0 ? '비즈' : ''}
            {converter.parseListByType(item['notification'][config.COLUMN_FIELD[5]]) == 1 || converter.parseListByType(item['notification'][config.COLUMN_FIELD[5]]) == 2 || converter.parseListByType(item['notification'][config.COLUMN_FIELD[5]]) == 3 ? '예약' : ''}
            {(converter.parseListByType(item['notification'][config.COLUMN_FIELD[5]]) >= 10 && converter.parseListByType(item['notification'][config.COLUMN_FIELD[5]]) <= 13) || converter.parseListByType(item['notification'][config.COLUMN_FIELD[5]]) == 40 ? '대기' : ''}
            {converter.parseListByType(item['notification'][config.COLUMN_FIELD[5]]) == 20 || converter.parseListByType(item['notification'][config.COLUMN_FIELD[5]]) == 21 ? '쿠폰' : ''}
            {converter.parseListByType(item['notification'][config.COLUMN_FIELD[5]]) == 30 || converter.parseListByType(item['notification'][config.COLUMN_FIELD[5]]) == 31 ? '공지' : ''}
            {converter.parseListByType(item['notification'][config.COLUMN_FIELD[5]]) == 50 ? '예약금' : ''}
            {converter.parseListByType(item['notification'][config.COLUMN_FIELD[5]]) == 99 ? '기타' : ''}
          </div>
          <div
            className={styleTable.column}
            style={{width: config.COLUMN_SIZE[6]}}
          >
            {this.state.msgResult[item['notification'][config.COLUMN_FIELD[6]]] || '-'}
          </div>
        </div>
      )
    }

    return(
      <div className={styleStore.homeContainer}>
        <Helmet>
          <title>기타 이력</title>
        </Helmet>
        <div className={styleStore.homeContent}>
          {this.state.isStatusChanging && !this.state.isPositive ? <div className={styleReservationModal.completedAlert} style={{top: '40%', backgroundColor: '#F08080'}}>{this.state.alertText}</div> : null}
          {this.state.isStatusChanging && this.state.isPositive ? <div className={styleReservationModal.completedAlert} style={{top: '40%'}}>{this.state.alertText}</div> : null}
          <div className={styleBoard.left}>
            <div className={styleBoard.top}>
              <p className={styleBoard.title}>문자 발송 이력</p>
              <SearchBar onRef={(ref) => {this.extraSearchBar = ref;}} handleSearch={(params) => this.handleSearch(params)} handleChangingStatus={this.handleChangingStatus}/>
            </div>
            <div className={styleBoard.bottom}>
              <div className={styleTable.tableContentBox + ' ' + styleTable.style1}>
                {renderExtraRecordListHeader()}
                <InfiniteList
                  onRef={(ref) => this.extraRecordsList = ref}
                  ListItem={renderExtraRecordsList}
                  Get={ActionNotification.getList}
                  GetParams={this.state.extraRecordsParams}
                  GetListData={(list) => this.getNotificationListData(list)}
                  customTableStyle={styleTable.tableContentWrapper}
                />
              </div>
            </div>
          </div>
          <div className={styleBoard.right}>
          </div>
        </div>
      </div>
    )
  }
}

export default connect((state) => {
  return {
    author: state.data.account.author,
      store: state.data.store.store,
  }
})(withRouter(ExtraRecords))