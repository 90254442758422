import React, {Component} from 'react';
import {
    withRouter, Route
} from 'react-router-dom';
import {connect} from 'react-redux';


// Containers

// Component

// Actions
import { action } from "../../Data/index";



class TestView extends Component {
    constructor() {
        super();
        this.state = {
            phoneNumber: '',
        }
        this.call = this.call.bind(this);
    }

    componentWillMount() {
        this.setState({
            store: this.props.author.store || {}
        })
    }

    call() {
        if (!this.state.phoneNumber) {
            alert('전화번호를 입력해주세요.');
            return;
        }
        this.props.dispatch(action.store.testCall({
            phoneNumber: this.state.phoneNumber,
            storeId: this.state.store.storeId,
        })).then((res) => {
            alert('전화를 걸었습니다.');
        });
    }

    render() {
        return (
            <div style={{width: '100%'}}>
                <input style={{margin: '100px auto 0', display: 'block'}}
                       type={'text'} value={this.state.phoneNumber}
                       onChange={(e) => this.setState({ phoneNumber: e.target.value })}
                       onKeyPress={(e) => {
                           if (e.key === 'Enter') {
                               this.call();
                           }
                       }}
                />
                <button style={{margin: '10px auto 0', display: 'block'}} onClick={this.call}>전화걸기</button>
            </div>
        );
    }
}

export default connect((state) => {
    return {
        author:  state.data.account.author,
        store:  state.data.store.store,
    };
})(withRouter(TestView));

