import * as ActionTypes from '../actionTypes';

// API
import * as HttpApi from '../Api/index';

export const actionTypes = ActionTypes;

//////////////////
///// GET ////////
//////////////////
export const getList = (params) => {
	return (dispatch) => {
		return HttpApi.get('GET_BOARD_LIST', params)
			.then((response) => {
				const data = [
					{ value1: '2019/3/17', value2: 2, value3: 4, value4: 1000000, value5: 200000, value6: 400000},
					{ value1: '2019/3/18', value2: 2, value3: 4, value4: 2000000, value5: 300000, value6: 800000},
					{ value1: '2019/3/19', value2: 2, value3: 4, value4: 3000000, value5: 400000, value6: 400000},
					{ value1: '2019/3/20', value2: 2, value3: 4, value4: 4000000, value5: 500000, value6: 800000},
					{ value1: '2019/3/21', value2: 2, value3: 4, value4: 5000000, value5: 600000, value6: 1000000},
					{ value1: '2019/3/22', value2: 2, value3: 4, value4: 6000000, value5: 700000, value6: 2000000},
					{ value1: '2019/3/23', value2: 2, value3: 4, value4: 7000000, value5: 800000, value6: 7000000},
					{ value1: '2019/3/24', value2: 2, value3: 4, value4: 8000000, value5: 900000, value6: 5000000},
					{ value1: '2019/3/25', value2: 2, value3: 4, value4: 9000000, value5: 1000000, value6: 9000000},
					{ value1: '2019/3/26', value2: 2, value3: 4, value4: 10000000, value5: 1100000, value6: 10000000},
					{ value1: '2019/3/27', value2: 2, value3: 4, value4: 11000000, value5: 1200000, value6: 2000000},
					{ value1: '2019/3/28', value2: 2, value3: 4, value4: 12000000, value5: 1300000, value6: 2000000},
				]
				return Promise.resolve(data);
				//return Promise.resolve(response.data);
			})
			.catch((err) => {
				return Promise.reject(err);
			});
	};
};

export const getTest = (params) => {
	return (dispatch) => {
		return HttpApi.get('GET_BOARD_LIST', params)
			.then((response) => {
				const data = [
					{ value1: '2019/3/17', value2: 16, value3: 12, value4: 4 },
					{ value1: '2019/3/18', value2: 20, value3: 15, value4: 5 },
					{ value1: '2019/3/19', value2: 10, value3: 12, value4: 0 },
					{ value1: '2019/3/20', value2: 14, value3: 13, value4: 1 },
					{ value1: '2019/3/21', value2: 0, value3: 0, value4: 0 },
					{ value1: '2019/3/22', value2: 8, value3: 8, value4: 0 },
					{ value1: '2019/3/23', value2: 0, value3: 0, value4: 0 },
					{ value1: '2019/3/24', value2: 21, value3: 0, value4: 0 },
					{ value1: '2019/3/25', value2: 24, value3: 20, value4: 6 },
					{ value1: '2019/3/26', value2: 3, value3: 2, value4: 5 },
					{ value1: '2019/3/27', value2: 1, value3: 10, value4: 3 },
					{ value1: '2019/3/28', value2: 4, value3: 29, value4: 1 },
				]
				return Promise.resolve(data);
				//return Promise.resolve(response.data);
			})
			.catch((err) => {
				return Promise.reject(err);
			});
	};
};

export const getCount = (params) => {
	return (dispatch) => {
		return HttpApi.get('GET_BOARD_COUNT', params)
			.then((response) => {
				return Promise.resolve(response.data);
			})
			.catch((err) => {
				return Promise.reject(err);
			});
	};
};

export const getById = (params) => {
	return (dispatch) => {
		return HttpApi.get('GET_BOARD_DETAIL', params)
			.then((response) => {
				return Promise.resolve(response.data);
			})
			.catch((err) => {
				return Promise.reject(err);
			});
	};
};

//////////////////
///// POST ///////
//////////////////
export const create = (params) => {
	return (dispatch) => {
		return HttpApi.post('POST_BOARD_CREATE', params)
			.then((response) => {
				return Promise.resolve(response.data);
			})
			.catch((err) => {
				return Promise.reject(err);
			});
	};
};

//////////////////
///// PUT  ///////
//////////////////
export const update = (params) => {
	return (dispatch) => {
		return HttpApi.put('PUT_BOARD_UPDATE', params)
			.then((response) => {
				return Promise.resolve(response.data);
			})
			.catch((err) => {
				return Promise.reject(err);
			});
	};
};

//////////////////
///// DELETE /////
//////////////////
export const remove = (params) => {
    return (dispatch) => {
        return HttpApi.del('DELETE_BOARD_DELETE', params)
            .then((response) => {
                return Promise.resolve(response.data);
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };
};

//////////////////
/// FUNCTIONS ////
//////////////////

export const setOpenCouponModal = (params) => {
    return (dispatch) => {
        dispatch({ type: ActionTypes.IS_OPEN_COUPON_MODAL, isOpenCouponModal: params });
        return true;
    }
};