import * as ActionTypes from '../actionTypes';

export const initialState = {
    store_reservation_menu: {},
    store_reservation_menu_list: []
}
/*
*/
export const store_reservation_menu_reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.STORE_RESERVATION_MENU:
            return Object.assign({}, state, {
                store_reservation_menu: action.store_reservation_menu
            });
        case ActionTypes.STORE_RESERVATION_MENU_LIST:
            return Object.assign({}, state, {
                store_reservation_menu_list: action.store_reservation_menu_LIST
            });
        default:
            return state;
        }
}
