import * as ActionTypes from '../actionTypes';

export const initialState = {
    store_history: {},
    store_history_list: []
}
/*
*/
export const store_history_reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.STORE_HISTORY:
            return Object.assign({}, state, {
                store_history: action.store_history
            });
        case ActionTypes.STORE_HISTORY_LIST:
            return Object.assign({}, state, {
                store_history_list: action.store_history_LIST
            });
        default:
            return state;
        }
}
