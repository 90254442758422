import React, {Component} from 'react';
import {Route, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import { withTranslation } from 'react-i18next';

// styles
import styleReservation from '../../../Styles/Components/Reservation.css'
import styleReservationModal from '@/Service/Styles/Components/ReservationModal.css'

// component
import WaitingList from './WaitingList';
import ReservationList from './ReservationList';
import Tables from '@/Service/Common/TableManager/Tables';

// Utils
import { converter, parseUrlParameter, string } from '../../../../Utils'
import _ from 'underscore'
import empty from 'just-is-empty'


class ReservationComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            store : {},
            isStatusChanging: false,
            alertText: '',
        }
        this.tableRef = null;

        this.handleChangingStatus = this.handleChangingStatus.bind(this);
    }

    componentDidMount() {
 //       console.log('ReservationComponent >> componentDidMount')
        this.setState({
            store: this.props.store && this.props.store.store ? this.props.store.store : {}
        })
    }

    handleChangingStatus(text) {
        this.setState({
            isStatusChanging: true,
            alertText: text
        }, () => {
            setTimeout(() => {
                this.setState({ isStatusChanging: false });
            }, 1000);
        })
    }

    render() {
        const { t, i18n } = this.props;

        return (
            <div className={styleReservation.overflowXHidden}>
                {this.state.isStatusChanging ? <div className={styleReservationModal.completedAlert} style={{top: '40%'}}>{this.state.alertText}</div> : null}
                <div className={styleReservation.addBtn} onClick={() => this.props.openReservationModal(null)}>
                    + {t('add_reservation')}
                </div>
                <div id={styleReservation.reservationPrintArea}>
                    <ReservationList
                      openReservationModal={this.props.openReservationModal}
                      onRef={(ref) => { this.reservationList = ref;}}
                      screenFontSize={this.state.store.screenFontSize || 14}
                      updateTableInfo={this.props.updateTableInfo}
                    />
                    <div className={styleReservation.tableContent}>
                      <div className={styleReservation.tableBgText1}>{this.props.parentState.selectedPlace.name}</div>
                      <div className={styleReservation.tableBgText2}>{this.props.parentState.selectedFloor.name}</div>
                      <Tables
                        editValue={{index : -1}}
                        floorTableData={this.props.parentState.tableDataValue.floorTableData}
                        selectTableShape={() => {}}
                        setTablePosition={() => {}}
                        ref={this.tableRef}
                        tableStyle={this.props.parentState.store.screenFontSize}
                      />
                    </div>
                    {this.props.store.system_payment.isVisitReservation ?
                      <div id="waitingListDisplay"><WaitingList screenFontSize={this.state.store.screenFontSize} handleChangingStatus={this.handleChangingStatus}/></div>
                      : null}
                </div>
            </div>
        )
    }
}

export default connect((state) => {
    return {
        author: state.data.account.author,
        store: state.data.store.store,
        store_place: state.data.store_place.store_place,
        store_place_floor: state.data.store_place_floor.store_place_floor,
        selectedDate: state.data.store_reservation.selectedDate,
        reservationUpdated: state.data.store_reservation.reservationUpdated,
    }
})(withRouter(withTranslation()(ReservationComponent)))
