import React, {Component} from 'react';
import {withRouter, Route} from 'react-router-dom';
import {connect} from 'react-redux';
import {Helmet} from "react-helmet";
import moment from 'moment';
import {dateUtil} from '../../../Utils';
import _ from 'underscore';
import { withTranslation } from 'react-i18next';
import Modal from '../../Common/Modal';

// styles
import styleStore from '../../Styles/Components/Store.css'
import styleVisit from '../../Styles/Components/Visit.css'

// components
import { action } from '../../../Data'
import Keypad from '../../Common/Keypad/index'
import { listUtil, parseUrlParameter, string } from '../../../Utils'
import InputWithTooltip from '../../Common/InputWithTooltip/index'
import ReservationPopup from '@/Service/Components/Store/Reservation'
import stylesTable from 'Admin/Styles/Common/Table.css'
import styleReservationModal from '@/Service/Styles/Components/ReservationModal.css'

class VisitView extends Component{
  constructor(props){
    super(props);

    this.state = {
      isSubmit: false,
      currentAltInput: null, //보조 입력장치 reference (tooltip / keypad)

      store: {},
      brand: {},
      systemPaymentDefault: {},
      storePlace: [],
      storeWaitingTeamCount: 0,
      step: 0,
      originalPhoneNumber: '',

      phoneNumber: '',
      name: '',
      numberOfPeople: 0,
      babySeatAmount: 0,

      storeCustomer: {},
      masked: {},
      storeReservation: {},

      isAgreeTermsAndConditions: false,
      isAgreePersonalInfo: false,
      isAgreeMarketing: false,

      storeMenus: [],
      originalStoreMenus: [],

      preferPlaces: [],
      reservationPlaces: [], // 예약된 장소들과 대기번호

      isStoreMenuEmpty: true,
      isStorePlaceEmpty: true,

      isPrinting: false,

      isAndroid: false,

      serviceDocId: 0,
      docCode: '',

      isFullScreen: false,
    }

    this.initPage = this.initPage.bind(this);

    this.handleBtn = this.handleBtn.bind(this);
    this.getStoreValues = this.getStoreValues.bind(this);
    this.getStoreReservation = this.getStoreReservation.bind(this);
    this.getStoreCustomerByPhoneNumber = this.getStoreCustomerByPhoneNumber.bind(this);

    this.handleReservationValues = this.handleReservationValues.bind(this);
    this.handleTooltipItems = this.handleTooltipItems.bind(this);
    this.handleSubmitCustomer = this.handleSubmitCustomer.bind(this);
    this.handleSubmitReservation = this.handleSubmitReservation.bind(this);
    this.handleSubmitMenus = this.handleSubmitMenus.bind(this);
    this.handleSubmitPreferPlace = this.handleSubmitPreferPlace.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.openKeypad = this.openKeypad.bind(this);
    this.handleKeyPad = this.handleKeyPad.bind(this);
    this.handleMaskedKeyPad = this.handleMaskedKeyPad.bind(this);
    this.handleMaskedValues = this.handleMaskedValues.bind(this);
    this.handleNameMask = this.handleNameMask.bind(this);
    this.handleNameUnmask = this.handleNameUnmask.bind(this);

    this.replaceAt = this.replaceAt.bind(this);
    this.handleFullScreen = this.handleFullScreen.bind(this);

    this.pageTransitionInterval = null;

    this.handleEmptyValue = this.handleEmptyValue.bind(this);
    this.handleLanguage = this.handleLanguage.bind(this);

    this.handlePrint = this.handlePrint.bind(this);
    this.openPreviewModal = this.openPreviewModal.bind(this);

    this.handleChangingStatus = this.handleChangingStatus.bind(this);
  }

  componentDidMount() {
    this.getStoreValues();
    // 삭제 필요
    // this.getStoreReservation();
    this.pageTransitionInterval = setInterval(this.getStoreValues, 10000);
    
    if (navigator.maxTouchPoints !== 0) this.setState({isAndroid: true});
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.author !== prevProps.author
        || prevProps.reservationUpdated !== this.props.reservationUpdated
    ) {
        this.getStoreValues();
    }
  }

  initPage() {
    this.setState({
      storeCustomer: {},
      masked: {},
      storeReservation: {},
      currentAltInput: null,

      isAgreeTermsAndConditions: false,
      isAgreePersonalInfo: false,
      isAgreeMarketing: false,

      storeMenus: [],
      originalStoreMenus: [],
      preferStorePlaces: [],
      originalPreferStorePlaces: [],

      reservationPlaces: [],

      step: 0,

      isAndroid: false,

      isStoreMenuEmpty: true,
      isStorePlaceEmpty: true,
    });
  }

  getStoreValues() {
    if (!this.props.author.store) {
      return;
    }
    this.props.dispatch(action.store.getById({
        storeId: this.props.author.store.storeId
    })).then((res) => {
        this.setState({
            store: res.store,
            brand: res.brand,
            systemPaymentDefault: res.system_payment_default,
        });
        return this.props.dispatch(action.store_place.getList({
            fk_storeId: res.store.storeId,
            orderMethod: 'asc',
        }));
    }).then((res) => {

        const curnWaitingTeamCount = res.reduce((prev, curr) => {
          return prev.store_place ? prev.store_place.waitingTeams : prev + curr.store_place.waitingTeams;
          }, 0);
        if(this.state.storeWaitingTeamCount > 0) {
          if(this.state.storeWaitingTeamCount > curnWaitingTeamCount) {
            // const audio = new Audio(this.state.systemPaymentDefault.notificationUrl);
            // audio.play();
          }
        }

        this.setState({
            storePlace: res,
            storeWaitingTeamCount: curnWaitingTeamCount,
        })
    });
    this.props.dispatch(action.service_system.getServiceSystem()).then((res) => {
      this.setState({
        serviceSystem: res[0]
      })
    })

    let params = {
      order: 'serviceDocId',
      orderMethod: 'ASC',
    }

    this.props.dispatch(action.service_docs.getList(params)).then((res) => {
      this.setState({
        serviceDocs: res
      })
    })
  }

  getStoreReservation() {
    const params = parseUrlParameter.parse(this.props.location.search);
    if (params.storeReservationId) {
      this.props.dispatch(action.store_reservation.getById({ storeReservationId: params.storeReservationId }))
        .then((res) => {
          console.log("<<first result>>", res);
          if (!res) return;
          this.setState({
            storeReservation: res.store_reservation || {},
            storeCustomer: res.store_customer || {},
          }, () => {
            const storeMenus = this.props.dispatch(action.store_reservation_menu.getList({ fk_storeReservationId: this.state.storeReservation.storeReservationId })).then((res) => {
              let customKey = 99999;  //give the custom menu a custom menu ID
              return res.map((item, index) => {
                if(item.store_menu.storeMenuId === null) {
                  customKey += 1;
                  let customMenuData = {
                    storeMenuId: item.store_reservation_menu.storeMenuId || customKey,
                    fk_storeId: item.store_reservation_menu.fk_storeId,
                    menu: item.store_reservation_menu.menu
                  }
                  return Object.assign({ storeReservationMenuId: item.store_reservation_menu.storeReservationMenuId }, customMenuData);
                }
                return Object.assign({ storeReservationMenuId: item.store_reservation_menu.storeReservationMenuId }, item.store_menu);
              })
            })
            Promise.all([
              storeMenus,
            ]).then((res) => {
              this.setState({
                storeMenus: res[0],
                originalStoreMenus: res[0],
                // 삭제 필요
                // step: 2
              });
              this.menuTooltip.setCheckedItems(res[0], 'storeMenuId');
            }).catch((err) => {
            })
          });
        })
    }
  }

    getStoreCustomerByPhoneNumber() {
        if (!this.state.storeCustomer.phoneNumber
            || (this.state.storeCustomer.phoneNumber === this.state.originalPhoneNumber)) return;

        this.props.dispatch(action.store_customer.getList({
            count: 1,
            fk_storeId: this.state.store.storeId,
            phoneNumber: this.state.storeCustomer.phoneNumber,
        })).then((res) => {
            if (res.length > 0) {
                // this.setState({
                //     storeCustomer: res[0].store_customer || {},
                //     masked: res[0].store_customer || {}
                // }, () => {
                //   this.handleNameMask(this.state.masked.name, 'masked', 'name');
                // })

                this.setState({
                storeCustomer: res[0].store_customer || {},
                masked: {
                  name: res[0].store_customer.name || ''
                }
                }, () => {
                this.handleNameMask(this.state.masked.name, 'masked', 'name');
                })
            }
        }).catch((err) => {
            console.log('핸드폰번호로 고객 정보 못받아옴 ::: ', err);
        })
    }

  handleReservationValues(value, field, state) {
    let newState = {};
    newState[state] = this.state[state];

    let originalPhoneNumber = this.state.masked.phoneNumber || '';
    if (value.length > originalPhoneNumber.length && field === 'phoneNumber' && (value.length == 3 || value.length == 8)) {
      newState[state][field] = value + '-';
    } else {
      newState[state][field] = value;
    }

    this.setState(newState)
  }

  handleNameUnmask(state, field) {
    //Case - a value was never there in the first place
    if(!this.state[state][field]) return;

    let newState = this.state;
    newState["masked"][field] = newState[state][field]

    this.setState(newState);
  }

  handleNameMask(value, state, field) {
    let newState = {};
    newState[state] = Object.assign({},this.state[state]);
    newState[state]["field"] = Object.assign({},this.state["masked"][field]); //Srsly?.... Kill me now
    newState[state][field] = value;
    newState["masked"] = Object.assign({},this.state["masked"]);

    //console.log("<<newState[state][field]>>", newState[state][field]);
    //replace 2nd and 3rd value of string to *
    if(newState["masked"][field].length >= 3) {
      newState["masked"][field] = newState["masked"][field].substr(0,1) + "*" + newState["masked"][field].substr(2,newState["masked"][field].length);
    } //3글자 이상
    else if(newState["masked"][field].length >= 2) //외자
      newState["masked"][field] = newState["masked"][field][0] + "*";
    //Debugging
    /*
    console.log("<<newState>>", newState);
    console.log("<<newState[\"masked\"][field]>>",newState["masked"][field])
    console.log("<<newState[state][field]>>", newState[state][field])
    */

    this.setState(newState);
  }

  //takes an array of 2 values, the first being the beginning of the mask, the latter being the end(exclusive)
  //[6,8] will mask the 6th and 7th values(not the 8th)
  handleMaskedValues(field, state, range) {
    //Debugging
    console.log("masked - " + this.state.masked[field]);
    console.log("unmasked - " + this.state[state][field]);

    //case - has not hit mask range, just apply to both masked and state
    if(this.state.masked[field].length < range[0]) return this.handleReservationValues(this.state.masked[field], field, state);

    //TODO in order to fix issue v0.3 5.1.2
    //case - user is inserting or using korean, key count does not increase but input is done


    //input key count >= range[0]
    //case - user has deleted text, just remove from both mask and state
    if(this.state["masked"][field].length < this.state[state][field].length) {
      let newState = {};
      newState[state] = this.state[state];
      let diff = this.state[state][field].length - this.state["masked"][field].length

      newState[state][field] = newState[state][field].substring(0,newState[state][field].length-(diff+1))
      return this.setState(newState);
    }

    let newState = {};
    newState[state] = this.state[state];
    newState["masked"] = this.state["masked"];

    //case - inside mask range, character increase, but can be more than 2
    //ex) 010-66 ctrl+v -> 010-66**-8187
    //Ah the paradise of exception handling. So sweet.
    if(this.state[state][field].length - 1 <= range[1]) {

      let oriLength = newState[state][field].length;

      //get increased characters
      let newChars = this.state["masked"][field].substring(oriLength, newState["masked"][field].length);

      //add increased characters  to non-masked field
      newState[state][field] = newState[state][field] + newChars;

      //mask newChars if need be
      let maskedChars = "";
      if(this.state["masked"][field].length - 1 >= range[0]) {
        //probably room for optimization but might not be worth the workload, replace characters in range into "*"
        for(let cnt=0; cnt < newChars.length; cnt++) {
          //if(oriLength + cnt >= range[0] && oriLength + cnt < range[1]) maskedChars = maskedChars + "*";
          //else maskedChars = maskedChars + newChars[cnt];
          maskedChars = maskedChars + newChars[cnt];
        }
        newState["masked"][field] = newState["masked"][field].substring(0, this.state["masked"][field].length - maskedChars.length) + maskedChars
      }

      //replace original chars with masked chars
      return this.setState(newState);
    }

    //past mask range - just add the extra characters
    let newChars = newState["masked"][field].substring(newState[state][field].length, newState["masked"][field].length);

    newState[state][field] = newState[state][field] + newChars;
    return this.setState(newState);
  }

  handleTooltipItems(item, listName) {
    const newState = {};
    newState[listName] = item;
    this.setState(newState);
  }

  handleSubmitCustomer(params) {
    let customerFunc = null;
    params.name = this.state.storeCustomer.name;
    params.phoneNumber = this.state.storeCustomer.phoneNumber;


    return new Promise((resolve, reject) => {
        this.props.dispatch(action.store_customer.getList({
            fk_storeId: this.state.store.storeId,
            phoneNumber: this.state.storeCustomer.phoneNumber,
            count: 1,
        })).then((res) => {
            let customerFunc = null;
            if (res.length > 0) {
                params.storeCustomerId = res[0].store_customer.storeCustomerId;
                customerFunc = action.store_customer.update(params);
            } else {
                customerFunc = action.store_customer.create(params);
            }

            this.props.dispatch(customerFunc).then((res) => {
                if (res.storeCustomerId) {
                    resolve(res);
                } else {
                    reject(res);
                }
            })
        });
    })
  }

  handleSubmitReservation(params) {
    let reservationFunc = null;
    if (this.state.storeReservation.storeReservationId) {
      params.storeReservationId = this.state.storeReservation.storeReservationId;
      reservationFunc = action.store_reservation.update(params);
    } else {
      reservationFunc = action.store_reservation.create(params);
    }
    return new Promise((resolve, reject) => {
      this.props.dispatch(reservationFunc).then((res) => {
        if (res.storeReservationId) {
          resolve(res);
        } else {
          reject(res);
        }
      })
    })
  }

  handleSubmitMenus(params) {
    let menus = this.state.storeMenus;
    let originalMenus = this.state.originalStoreMenus;

    let changed = listUtil.getJSONArrayChangedValues(originalMenus, menus, 'storeMenuId');
    if (changed) {
      const createFunc = changed.created.length > 0 ? changed.created.map((item) => {
        let createParams = JSON.parse(JSON.stringify(params));
        createParams.fk_storeMenuId = item.storeMenuId;
        createParams.menu = item.menu;
        return new Promise((resolve, reject) => {
          this.props.dispatch(action.store_reservation_menu.create(createParams)).then((res) => {
            if (res.storeReservationMenuId) {
              resolve(res);
            } else {
              console.log('RESERVATION MENU ERR::');
              reject(res);
            }
          })
        });
      }) : [];
      const deleteFunc = changed.deleted.length > 0 ? changed.deleted.map((item) => {
        return new Promise((resolve, reject) => {
          this.props.dispatch(action.store_reservation_menu.remove({
            storeReservationMenuId: item.storeReservationMenuId
          })).then((res) => {
            if (res) {
              resolve(res);
            } else {
              console.log('RESERVATION MENU ERR::');
              reject(res);
            }
          })
        });
      }) : [];
      return Promise.all(createFunc.concat(deleteFunc));
    } else {
      return [];
    }
  }

  handleSubmitPreferPlace() {
    return new Promise((resolve, reject) => {
      let storePlaces = [];
      const result = [];
      if (this.state.preferPlaces.length > 0) {
         storePlaces = this.state.preferPlaces.map((item) => {
           return item.storePlaceId;
         });
      } else {
         storePlaces = this.state.storePlace.map((item) => {
           return item.store_place.storePlaceId;
         });
      }
        storePlaces.forEach((item) => {
            let storePlaceId = item;

            let storePlaceFloors = this.state.storePlace.filter((item) => {
                return item.store_place.storePlaceId === storePlaceId;
            }).map((item) => {
                return item.store_place_floor;
            });

            storePlaceFloors.forEach((items) => {
                items.forEach((item) => {
                    result.push({
                        fk_storePlaceId: item.fk_storePlaceId,
                        fk_storePlaceFloorId: item.storePlaceFloorId
                    });
                });
            });
      });
      resolve(result);
    })
  }

  // 최종 제출 함수
  handleSubmit() {
      if (this.state.isSubmit) return;

      this.setState({
          isSubmit: true,
      }, () => {
          // validation
          let reservationState = this.state;

          const customerParams = {
              fk_storeId: this.state.store.storeId,
              name: this.state.name,
              phoneNumber: this.state.phoneNumber,
          }

          const reservationParams = {
              fk_brandId: this.state.brand.brandId,
              fk_storeId: this.state.store.storeId,
              fk_storeStaffId: this.state.storeReservation.fk_storeStaffId || 0,
              numberOfPeople: this.state.storeReservation.numberOfPeople || 0,
              babySeatAmount: this.state.storeReservation.babySeatAmount || 0,
              waitingStatus: 0,
              reservationStatus: 1,
          }

          this.handleSubmitCustomer(customerParams).then((response) => {
              // 선호 매장 추가
              reservationParams['fk_storeCustomerId'] = response.storeCustomerId;

              return this.handleSubmitPreferPlace();
          }).then((storePlaceIdMap) => {
              reservationParams['storePlaceIdMap'] = storePlaceIdMap;
              return this.handleSubmitReservation(reservationParams);
          }).then((response) => {
              let groupedByStorePlaceIdResponse = _.groupBy(response.store_reservation_place, 'fk_storePlaceId');

              if (!response) { return new Error('예약에 실패했습니다.') }
              this.setState({
                  reservedAt: response.reservedAt,
                  storeReservation: response,
                  reservationPlaces: Object.keys(groupedByStorePlaceIdResponse).map((key) => {
                      return groupedByStorePlaceIdResponse[key][0];
                  })
              })

              const optionParams = {
                  fk_storeId: response.fk_storeId,
                  fk_storeReservationId: response.storeReservationId,
                  fk_storeCustomerId: response.fk_storeCustomerId,
                  fk_storePlaceId: response.fk_storePlaceId
              }

              Promise.all([
                  this.handleSubmitMenus(optionParams),
                  this.handleSubmitPreferPlace(optionParams)
              ]).then((res)=> {
                  this.setState({
                      step: 2,
                      preferPlaces: [],
                      isSubmit: false,
                  }, () => {
                      setTimeout(() => {
                          this.initPage();
                      }, 5000);
                      this.props.dispatch(action.store_reservation.reservationUpdated(new Date()));
                  });
              }).catch((err) => {
                  console.log('RESERVATION OPTIONS ERR::', err);
              })
          }).catch((err) => {
              this.handleChangingStatus('예약에 실패했습니다.', false);
              this.setState({
                  preferPlaces: [],
                  isSubmit: false,
              })
              console.log('ERR => ', err);
          })
      })
  }

  handleKeyPad(value, field, state) {
    if (field === 'numberOfPeople' && (value > this.props.store.store.maximumReservation)) {
      this.handleChangingStatus(this.props.store.store.maximumReservation+'명 이하로 입력해주세요.', false);
      this.numberOfPeopleKeypad.setState({
        numberString: []
      })
      this.setState({
        storeReservation: {
          numberOfPeople: 0
        }
      })
      return;
    } else if (field === 'babySeatAmount' && (value > this.props.store.store.babySeatAmount)) {
      this.handleChangingStatus(this.props.store.store.babySeatAmount+'개 이하로 입력해주세요.', false);
      this.babySeatAmountKeypad.setState({
        numberString: []
      })
      this.setState({
        storeReservation: {
          babySeatAmount: 0
        }
      })
      return;
    }

    let newState = {};
    newState[state] = this.state[state];

    newState[state][field] = value;

    this.setState(newState)
  }

  handleMaskedKeyPad(value, field, state) {
    let newState = {};
    newState[state] = this.state[state];

    // if(newState[state][field]) {
    //   if (value.length >= newState[state][field].length) newState[state][field] = newState[state][field] + value.substring(value.length - 1, value.length);
    //   else newState[state][field] = newState[state][field].substring(0, newState[state][field].length - 1)
    // } else //first input through keypad

      newState[state][field] = value;

    if(value ==="")
      newState[state][field] = "";

    this.setState(newState)
  }

  openKeypad(keypad) {
    console.log("keypad opened, currentAltInput", this.state.currentAltInput);
    if(this.state.currentAltInput !== null && this.state.currentAltInput !== keypad) {
      //case - another altInput was open, closing
      console.log("closing altInput");
      this.state.currentAltInput.close();
    }
    keypad.open();
    this.setState({currentAltInput: keypad});
  }

  handleBtn(step) {
    if (step === 0) {
        const isValid = [
            { value: this.state.storeCustomer.phoneNumber, message: '핸드폰번호를 입력하세요.' },
            { value: this.props.store.store_system.isName ? this.state.storeCustomer.name : true, message: '이름을 입력하세요.' },
            { value: this.state.isAgreeTermsAndConditions, message: '이용약관에 동의해야 합니다.' },
            { value: this.state.isAgreePersonalInfo, message: '개인정보 수집/이용에 동의해야 합니다.' }
        ].filter((item) => {
          return !item.value;
        });
        if (isValid.length > 0) {
          this.handleChangingStatus(isValid[0].message, false);
          return;
        }
        if (this.state.storeCustomer.phoneNumber.length < 10) {
          this.handleChangingStatus('10자리 이상의 전화번호를 입력하세요.', false);
          return ;
        }

        this.setState({
            step: step + 1
        });
    } else if (step === 1) {
      if (this.state.storeReservation.numberOfPeople < 1 || !this.state.storeReservation.numberOfPeople) {
        this.handleChangingStatus('인원을 입력하세요.', false);
        return;
      }
      this.handleSubmit();
    }
  }

  replaceAt(original, index, replacement){
    return original ? original.substr(0, index) + replacement+ original.substr(index + replacement.length) : '';
  }

  handleFullScreen(element) {
    let requestMethod = element.requestFullScreen || element.webkitRequestFullScreen || element.mozRequestFullScreen || element.msRequestFullScreen;
    if (requestMethod) {
      requestMethod.call(element);
    } else if (typeof window.ActiveXObject !== "undefined") { // Older IE.
      let wscript = new ActiveXObject("WScript.Shell");

      if (wscript !== null) {
        wscript.SendKeys("{F11}");
      }
    }
  }

  handleEmptyValue(name, bool) {
    if (name === 'store_menu') {
      this.setState({
        isStoreMenuEmpty: bool
      })
    } else if(name === 'store_place') {
      this.setState({
        isStorePlaceEmpty: bool
      })
    }
  }

  handleLanguage() {
    if (this.props.i18n.language === 'ko') {
      this.props.i18n.changeLanguage('en');
    } else {
      this.props.i18n.changeLanguage('ko');
    }
  }

  handlePrint() {
    this.setState({
      isPrinting: true
    }, () => {
      if (this.state.isPrinting) {
        if ((screen.availHeight || screen.height-30) <= window.innerHeight) {
          this.setState({ isFullScreen: true }, () => {
            window.print();
            if (this.state.isFullScreen) {
              this.handleFullScreen(document.body);
            }
            this.setState({ isPrinting: false });
          })
        } else {
          this.setState({ isFullScreen: false }, () => {
            window.print();
            this.setState({ isPrinting: false });
          })
        }
      }
    })
  }

  openPreviewModal(docCode) {
    this.setState({
      docCode: docCode
    })
    this.previewModal.open();
  }

  handleChangingStatus(text, bool) {
    this.setState({
      isStatusChanging: true,
      alertText: text,
      isPositive: bool
    }, () => {
      setTimeout(() => {
        this.setState({ isStatusChanging: false });
      }, bool ? 3000 : 4000);
    })
  }

  render(){
    const { t, i18n } = this.props;

    let renderWaitingStatus = this.state.storePlace ? this.state.storePlace.map((place, index) => {
        return (
          <div key={index} className={styleVisit.building}>
            <div className={styleVisit.location} style={this.props.store.store_system.isWaitingTime ? null : {width: '50%', textAlign:'center'}}>
              {i18n.language === 'ko' ? (place.store_place.name || place.store_place.nameEn) : (place.store_place.nameEn || place.store_place.name)}
            </div>
            <div className={styleVisit.teams} style={i18n.language === 'en' ? {fontSize: '50px'} : null}>
              {place.store_place.waitingTeams} {t('team_unit')}
            </div>
              <div className={styleVisit.timeBox} style={this.props.store.store_system.isWaitingTime ? {display: 'block'} : {display:'none'}}>
                <img className={styleVisit.timeIcon} src="/Assets/img/ic-time.png"/>
                <span className={styleVisit.time} style={i18n.language === 'en' ? {fontSize: '29px'} : null}>{place.store_place.waitingTime} {t('time_unit')}</span>
              </div>
          </div>
        )
    }) : null;

      let renderReservedPlaces = this.state.reservationPlaces.length > 0
          ? this.state.reservationPlaces.map((place, index) => {
          return (
              <div className={styleVisit.middle} key={index}>
                  <div className={styleVisit.outputBuilding}>{i18n.language === 'ko' ? (place.name || place.nameEn) : (place.nameEn || place.name)}</div>
                  <div className={styleVisit.number}>{place.waitingNumber}</div>
              </div>
          )
      }) : null;

      let renderServiceDocs = this.state.serviceDocs ? this.state.serviceDocs.map((list, index) => {
        if (list.service_docs.docCode == 'UA') {
          return (
            <div key={index}>
              <input className={'preventClick'} type="checkbox" onChange={(e) => {this.setState({ isAgreeTermsAndConditions: e.target.checked })}} checked={this.state.isAgreeTermsAndConditions} value={this.state.isAgreeTermsAndConditions}/>
              <label>{i18n.language === 'ko' ? (list.service_docs.title || list.service_docs.titleEn) : (list.service_docs.titleEn || list.service_docs.title)}</label>
              <span style={{color: 'red'}} onClick={() => this.openPreviewModal(list.service_docs.docCode)}> (필수)</span>
            </div>
          )
        } else if (list.service_docs.docCode == 'PA') {
          return (
            <div key={index}>
              <input type="checkbox" onChange={(e) => {this.setState({ isAgreePersonalInfo: e.target.checked })}} checked={this.state.isAgreePersonalInfo} value={this.state.isAgreePersonalInfo}/>
              <label>{i18n.language === 'ko' ? (list.service_docs.title || list.service_docs.titleEn) : (list.service_docs.titleEn || list.service_docs.title)}</label>
              <span style={{color: 'red'}}  onClick={() => this.openPreviewModal(list.service_docs.docCode)}> (필수)</span>
            </div>
          )
        } else if (list.service_docs.docCode == 'PU') {
          return (
            <div key={index}>
              <input type="checkbox" onChange={(e) => {this.setState({ isAgreeMarketing: e.target.checked })}} checked={this.state.isAgreeMarketing} value={this.state.isAgreeMarketing}/>
              <label>{i18n.language === 'ko' ? (list.service_docs.title || list.service_docs.titleEn) : (list.service_docs.titleEn || list.service_docs.title)}</label>
              <span style={{color: 'red'}}  onClick={() => this.openPreviewModal(list.service_docs.docCode)}> (선택)</span>
            </div>
          )
        }
      }) : null

    return (
      <div className={styleVisit.visitContainer}>
        <Helmet
            title={'방문 대기모드'}
        />
        <Modal
          onRef={(ref) => {
            this.previewModal = ref;
          }}
          modalBody={
            this.state.serviceDocs ? this.state.serviceDocs.map((doc) => {
              if (doc.service_docs.docCode == this.state.docCode) {
                return doc.service_docs.docUrl.indexOf('xlsx') > -1 || doc.service_docs.docUrl.indexOf('doc') > -1 || doc.service_docs.docUrl.indexOf('pdf') > -1?
                  <iframe className={styleVisit.docFile} src={"https://docs.google.com/gview?url=" + doc.service_docs.docUrl + "&embedded=true"}/>
                  : <img src={doc.service_docs.docUrl} alt={"service_docs"}/>
              }
            }) : null
          }
        />
        <div className={styleStore.homeContent + ' ' + styleVisit.visitContent}>
          {this.state.isStatusChanging && !this.state.isPositive ? <div className={styleReservationModal.completedAlert} style={{top: '40%', backgroundColor: '#F08080'}}>{this.state.alertText}</div> : null}
          {this.state.isStatusChanging && this.state.isPositive ? <div className={styleReservationModal.completedAlert} style={{top: '40%'}}>{this.state.alertText}</div> : null}
          <div className={styleVisit.expandIcon} onClick={() => this.handleFullScreen(document.body)}><img src="/Assets/img/ic-expand.png"/></div>
          { this.state.step === 0 ?
          <div className={styleVisit.left}>

            <div className={styleVisit.leftContent}>
              <div className={styleVisit.bubble}>
                <p className={styleVisit.text}>
                  {t('instruction1')}<br/>{t('instruction2')}
                </p>
              </div>
              <div className={styleVisit.inputBox + ' ' + styleVisit.phone}>
                <div>{t('customer_phone_number')}</div>
                <input
                   value={this.state.masked.phoneNumber}
                   className={styleVisit.inputText}
                   type="text"
                   readOnly={this.state.isAndroid}
                   onChange={(e) => {
                     this.handleReservationValues(e.target.value, 'phoneNumber', 'masked');
                     this.handleMaskedValues( "phoneNumber", "storeCustomer", [4,8]); //exclusive range
                   }}
                   onClick={() => this.openKeypad(this.phoneNumberKeypad)}
                   onFocus={() => {this.setState({ originalPhoneNumber: this.state.storeCustomer.phoneNumber}, () => blur())}}
                   onBlur={() => {this.getStoreCustomerByPhoneNumber();}}
                />
                <Keypad onRef={(ref) => {this.phoneNumberKeypad = ref;}} onChange={(value) => {
                  this.handleMaskedKeyPad(value, 'phoneNumber', 'masked');
                  this.handleMaskedValues( "phoneNumber", "storeCustomer", [4,8]); //exclusive range
                  this.phoneNumberKeypad.setState({numberString: this.state.masked.phoneNumber.split("")});
                }} customStyle={{right: '50px', top: '240px'}} isString={true}
                        getStoreCustomerByPhoneNumber={this.getStoreCustomerByPhoneNumber}
                />
              </div>
              <div className={styleVisit.inputBox + ' ' + styleVisit.name + ' ' + (this.props.store.store_system.isName ? '' : styleVisit.hide)}>
                <div>{t('customer_name')}</div>
                <input className={styleVisit.inputText}
                   type="text"
                   value={this.state.masked.name}
                   onChange={(e) => {
                     this.handleReservationValues(e.target.value, 'name', 'masked');
                   }}
                   onFocus={() => {
                     this.handleNameUnmask("storeCustomer", "name");
                   }}
                   onBlur={(e) => {
                     this.handleNameMask(e.target.value,"storeCustomer", "name");
                   }}
                   onKeyPress={(e) => {
                    console.log("<<input>>", e.target.value);
                    }}
                />
              </div>
              <div className={styleVisit.agreement}>
                <div className={styleVisit.agreeAll} onClick={() => {
                  this.setState({
                      isAgreeTermsAndConditions: true,
                      isAgreePersonalInfo: true,
                      isAgreeMarketing: true,
                  })
                }}>
                  <div>{t('all')}<br/>{t('agree')}</div>
                </div>
                <div className={styleVisit.agreeCheck}>
                  {renderServiceDocs}
                </div>
              </div>
              <div className={styleVisit.nextBtn} onClick={() => this.handleBtn(this.state.step)}>
                {t('next')}
              </div>
            </div>
          </div> : null }
          { this.state.step === 1 ?
          <div className={styleVisit.left}>
            <div className={styleVisit.leftContent}>
              <div className={styleVisit.bubble}>
                <div className={styleVisit.text + ' ' + styleVisit.style1}>
                  <div className={styleVisit.textName}>{this.props.store.store_system.isName ? string.sensorName(this.state.storeCustomer.name) : t('customer')}</div>
                  <div className={styleVisit.textNameInfo}>{t('enter_reservation_info')}</div>
                </div>
              </div>
              <div className={styleVisit.inputBox + ' ' + styleVisit.inputBox2}>
                <div style={this.state.storeReservation.numberOfPeople == null || this.state.storeReservation.numberOfPeople == '' ? null : {fontWeight: 700}}>{t('number_of_people')}</div>
                <span className={styleVisit.unit}>{t('number_of_people_unit')}</span>
                <input readOnly={this.state.isAndroid} className={styleVisit.inputText} type="text" value={this.state.storeReservation.numberOfPeople}
                       onChange={(e) => this.handleReservationValues(e.target.value, 'numberOfPeople', 'storeReservation')} onClick={() => this.openKeypad(this.numberOfPeopleKeypad)}/>
                <Keypad onRef={(ref) => {this.numberOfPeopleKeypad = ref;}} onChange={(value) => this.handleKeyPad(value, 'numberOfPeople', 'storeReservation')} customStyle={{left: '-50px'}}/>
              </div>
              <div className={styleVisit.inputBox + ' ' + styleVisit.inputBox2} style={this.props.store.store.babySeatAmount === 0 ? {display: 'none'} : null}>
                <div style={this.state.storeReservation.babySeatAmount == null || this.state.storeReservation.babySeatAmount == '' ? null : {fontWeight: 700}}>{t('baby_seat_amount')}</div>
                <span className={styleVisit.unit}>{t('baby_seat_amount_unit')}</span>
                <input className={styleVisit.inputText} type="text" value={this.state.storeReservation.babySeatAmount}
                       onChange={(e) => this.handleReservationValues(e.target.value, 'babySeatAmount', 'storeReservation')} onClick={() => this.props.store.store.babySeatAmount !== 0 ? this.openKeypad(this.babySeatAmountKeypad) : null}
                       readOnly={this.state.isAndroid}
                />
                {this.props.store.store.babySeatAmount !== 0 ?
                  this.props.store.store.babySeatAmount === 0 ? null : <Keypad onRef={(ref) => {this.babySeatAmountKeypad = ref;}} onChange={(value) => this.handleKeyPad(value, 'babySeatAmount', 'storeReservation')} customStyle={{left: '-50px'}}/>
                : null}
              </div>
              {this.props.store.store_system.isMenu ?
              <div className={styleVisit.inputBox + ' ' + styleVisit.inputBox3}>
                <div style={this.state.isStoreMenuEmpty ? null : {fontWeight: 700}}>{t('menu')}</div>
                <div className={styleVisit.inputText + ' ' + styleVisit.inputText2}>
                  <InputWithTooltip
                    onRef={(ref) => {this.menuTooltip = ref;}}
                    getList={action.store_menu.getList}
                    params={{
                        fk_storeId: this.state.store.storeId,
                        order: 'createdAt',
                        orderMethod: 'ASC'
                    }}
                    handleEmptyValue = {this.handleEmptyValue}
                    onUpdate={(item) => this.handleTooltipItems(item, 'storeMenus')}
                    listName={'store_menu'}
                    itemName={'menu'}
                    disableInput={true}
                    isMenu={this.props.store.store_system.isMenu}
                  />
                </div>
              </div> : null}
              {this.props.store.store_system.isPreferPlace ?
              <div className={styleVisit.inputBox + ' ' + styleVisit.inputBox3}>
                <div style={this.state.isStorePlaceEmpty ? null : {fontWeight: 700}}>{t('prefer_place')}</div>
                <div className={styleVisit.inputText + ' ' + styleVisit.inputText2}>
                  <InputWithTooltip
                    onRef={(ref) => {this.placeTooltip = ref;}}
                    getList={action.store_place.getList}
                    params={{ fk_storeId: this.state.store.storeId, orderMethod: 'ASC' }}
                    handleEmptyValue ={this.handleEmptyValue}
                    onUpdate={(item) => this.handleTooltipItems(item, 'preferPlaces')}
                    listName={'store_place'}
                    itemName={'name'}
                    disableInput={true}
                  />
                </div>
              </div> : null}
              <div className={styleVisit.finishBtn} onClick={() => this.handleBtn(this.state.step)}>{t('complete_reservation')}</div>
              <div className={styleVisit.cancelBtn} onClick={this.initPage}>{t('cancel')}</div>
            </div>
          </div> : null }
          { this.state.step === 2 ?
          <div className={styleVisit.left} id={styleVisit.printArea}>
            <div className={styleVisit.leftContent}>
              <div className={styleVisit.bubble}>
                <div className={styleVisit.text + ' ' + styleVisit.style1 + ' ' + styleVisit.style2}>
                  <div className={styleVisit.textName}>{this.props.store.store_system.isName ? this.replaceAt(this.state.storeCustomer.name, 1, '*') : t('customer')}</div>
                  <div className={styleVisit.textNameInfo}>{t('reservation_completed')}</div>
                </div>
              </div>
              <div className={styleVisit.outputBox}>
                <div className={styleVisit.top}>
                  <div className={styleVisit.outputTitle}>{t('waiting_receipt')}</div>
                  <div className={styleVisit.outputDate}>
                    {this.state.storeReservation.reservedAt && i18n.language === 'ko'? moment().format('YYYY년 MM월 DD일', this.state.storeReservation.reservedAt) : moment().format('MM/DD YYYY', this.state.storeReservation.reservedAt)}
                  </div>
                  <div className={styleVisit.outputStore}>
                    {i18n.language === 'ko' ? (this.state.brand.name || this.state.brand.nameEn) : (this.state.brand.nameEn || this.state.brand.name)} {i18n.language === 'ko'? (this.state.store.name || this.state.store.nameEn) : (this.state.store.nameEn || this.state.store.name)}
                  </div>
                </div>
                <div className={styleVisit.overflowBox}>
                  {renderReservedPlaces}
                </div>
                {this.props.store.store_system.isTicket ?
                  <div className={styleVisit.bottom}>
                    <div className={styleVisit.option} style={this.state.isPrinting ? {opacity: 0} : null}>({t('optional')})</div>
                    <div className={styleVisit.printBtn } style={this.state.isPrinting ? {opacity: 0} : null} onClick={this.handlePrint}>{t('print_waiting_number')}</div>
                    {this.state.isPrinting ? '입력한 전화번호로 접수증이 발송됩니다.' : null}
                  </div>
                : null}
              </div>
              <p className={styleVisit.information}>
                {i18n.language === 'ko' ? (this.state.store.guideText || this.state.store.guideTextEn) : (this.state.store.guideText || this.state.store.guideTextEn)}
              </p>
              <div className={styleVisit.nextBtn} onClick={this.initPage}>{t('confirm')}</div>
            </div>
          </div> : null }
          <div className={styleVisit.right}>
            <p className={styleVisit.langIcon} onClick={this.handleLanguage}>
              {i18n.language === 'en' ? <img src="/Assets/img/btn-korea.png"/> : <img src="/Assets/img/btn-english.png"/>}
            </p>
            <div className={styleVisit.rightContent}>
              <div className={styleVisit.brandInfo}>
                <div className={styleVisit.brandIcon} style={{backgroundImage: "url('" + (this.state.store.profileUrl || this.state.brand.profileUrl) + "')"}}></div>
                <div>{i18n.language === 'ko' ? (this.state.store.name || this.state.store.nameEn) : (this.state.store.nameEn || this.state.store.name)}</div>
              </div>
              {renderWaitingStatus}
            </div>
            <div className={styleVisit.kmLogo}>
              <img src={this.state.serviceSystem ? this.state.serviceSystem.service_system.whiteBgLogoUrl : "/Assets/img/km-logo-2-16-px.png"}/>
            </div>
          </div>
        </div>
      </div>
    )
  }
}



export default connect((state) => {
  return {
    author: state.data.account.author,
    store: state.data.store.store,
    store_place: state.data.store_place.store_place,
    store_place_floor: state.data.store_place_floor.store_place_floor,
    reservationUpdated: state.data.store_reservation.reservationUpdated,
  };
})(withRouter(withTranslation()(VisitView)));
