import * as ActionTypes from '../actionTypes';

// API
import * as HttpApi from '../Api/index';

export const actionTypes = ActionTypes;

//////////////////
///// GET ////////
//////////////////
export const getList = (params) => {
	return (dispatch) => {
		return HttpApi.get('GET_LOG_SUBSCRIPTION_PAYMENT_LIST', params)
			.then((response) => {
				return Promise.resolve(response.data);
			})
			.catch((err) => {
				return Promise.reject(err);
			});
	};
};

export const getCount = (params) => {
	return (dispatch) => {
		return HttpApi.get('GET_LOG_SUBSCRIPTION_PAYMENT_COUNT', params)
			.then((response) => {
				return Promise.resolve(response.data);
			})
			.catch((err) => {
				return Promise.reject(err);
			});
	};
};
