import React, {Component} from 'react';
import {withRouter, Route} from 'react-router-dom';
import {connect} from 'react-redux';
import DatePicker from "react-datepicker";

import { MoonLoader } from 'react-spinners';
import moment from 'moment';

// styles
import styleReservationModal from '../../../Styles/Components/ReservationModal.css';

// component
import CallHistoryScroll from './CallHistoryScroll';
import Keypad from '../../../Common/Keypad/index'
import InputWithTooltip from '../../../Common/InputWithTooltip/index'
import CustomTimePicker from '../../../Common/ccTimePicker'

// action
import { action } from '../../../../Data/index';

// Utils
import { parseUrlParameter, listUtil, dateUtil, addDashesToPhoneNumber } from '../../../../Utils/index'
import styleReservation from '@/Service/Styles/Components/Reservation.css'


class ReservationModal extends Component{
	constructor(props){
		super(props);
		this.state = {
			isLoaded: false,
			showCallHistory: false,
			originalPhoneNumber: '',
			name: '',

			storeReservationId: null,

			store: {},
			brand: {},

			numberOfPeople: 0,
			storeCustomerId: 5,
			storeCustomer: {
				type: 0
			},
			storeReservation: {},
			storeReservationPlace: [],
			storeTableIds: [],
			originalStoreTableIds: [],
			currentAltInput: null,

			storeMenus: [],
			storeMemos: [],
			storeStaffs: [],
			storeCustomerInfos: [],

			originalStoreReservation: {},
			originalStoreCustomerInfos: [],
			originalStoreMenus: [],
			originalStoreMemos: [],

			directInput:'',
			isDirectInput: false,

			selectedColor: {
				0: { color: '#ffffff', active: '/Assets/img/label-inactive-blacklist.png', inactive: '/Assets/img/label-inactive-blacklist.png', isChecked: false },
				1: { color: '#22d256', active: '/Assets/img/label-guest-state-01.png', inactive: '/Assets/img/label-guest-state-01-selected.png', isChecked: false },
				2: { color: '#f8cf26', active: '/Assets/img/label-guest-state-02.png', inactive: '/Assets/img/label-guest-state-02-selected.png', isChecked: false },
				3: { color: '#ec5126', active: '/Assets/img/label-guest-state-03.png', inactive: '/Assets/img/label-guest-state-03-selected.png', isChecked: false }
			},
			showColors: false,
			isVip: false,

			isSendText: true,
			reservationFeeStatus: false,
			isProcessing: false,

			isAndroid: false,

			isNumberKeypadOpened: false,
			isPhoneNumberKeypadOpened: false,
			isReservationFeeKeypadOpened: false,
    };

		this.handleChangeDate = this.handleChangeDate.bind(this);
		this.handleChangeTime = this.handleChangeTime.bind(this);
		this.showCallHistory = this.showCallHistory.bind(this);
		this.getStoreValues = this.getStoreValues.bind(this);
		this.getStoreReservation = this.getStoreReservation.bind(this);
		this.getStoreCustomerByPhoneNumber = this.getStoreCustomerByPhoneNumber.bind(this);
		this.setStoreTableIds = this.setStoreTableIds.bind(this);

		this.handleReservationValues = this.handleReservationValues.bind(this);
		this.handleKeyPad = this.handleKeyPad.bind(this);
		this.handleTooltipItems = this.handleTooltipItems.bind(this);
		this.handleSubmitCustomer = this.handleSubmitCustomer.bind(this);
		this.handleSubmitCustomerInfos = this.handleSubmitCustomerInfos.bind(this);
		this.handleSubmitMenus= this.handleSubmitMenus.bind(this);
		this.handleSubmitMemos = this.handleSubmitMemos.bind(this);
		this.handleSubmitReservation = this.handleSubmitReservation.bind(this);
		this.handleSubmitTable = this.handleSubmitTable.bind(this);

		this.handleSubmit = this.handleSubmit.bind(this); // 최종 제출 함수
		this.handleCancel = this.handleCancel.bind(this); // 예약 취소

		this.deleteItem = this.deleteItem.bind(this);
		this.directInputType = this.directInputType.bind(this);
		this.onKeyPress = this.onKeyPress.bind(this);
		this.showDirectInput = this.showDirectInput.bind(this);

		this.handleSelectType = this.handleSelectType.bind(this);
		this.handleSelectTable = this.handleSelectTable.bind(this);
		this.cancelPayment = this.cancelPayment.bind(this);

		this.openNumberKeypad = this.openNumberKeypad.bind(this);
		this.openPhoneNumberKeypad = this.openPhoneNumberKeypad.bind(this);

		this.handleChangingStatus = this.handleChangingStatus.bind(this);

		this.openReservationFeeKeypad = this.openReservationFeeKeypad.bind(this);

		this.handlePhoneNumberDash = this.handlePhoneNumberDash.bind(this);
	}

	componentWillMount() {
		this.setState({
			store: this.props.store.store || {},
			brand: this.props.store.brand || {},
		}, () => {
			this.getStoreValues();
			this.getStoreReservation();
		});
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.author !== prevProps.author) {
			this.setState({
				store: this.props.store.store,
				brand: this.props.store.brand
			}, () => {
				this.getStoreValues();
				this.getStoreReservation();
			})
		}
	}

	componentDidMount() {
		this.props.onRef(this);

		if (navigator.maxTouchPoints !== 0) this.setState({isAndroid: true});
	}

	getStoreValues() {
		// 전화예약시, 해달날짜에서 예약추가를 눌렀을때 고객정보 불러옴
		if(this.props.recentCallInfo) {
			this.setState({
				storeCustomer: {
					name: this.props.recentCallInfo.name,
					phoneNumber: this.props.recentCallInfo.phoneNumber,
					calledAt: this.props.recentCallInfo.calledAt || null
				},
				phoneNumberDash: addDashesToPhoneNumber(this.props.recentCallInfo.phoneNumber)
			})
		}

		if (!this.state.store.storeId) {
			return;
		}
		this.props.dispatch(action.store_staff.getList({
			fk_storeId: this.state.store.storeId
		})).then((res) => {
			this.setState({
				storeStaffs: res
			})
		})
	}

	getStoreReservation() {
		const params = parseUrlParameter.parse(this.props.location.search);

		// + 예약추가로 생성하는 경우
		if (!params.storeReservationId && !this.props.selectedReservationId) {
			let date = new Date();
			date.setHours(0,0);
			return this.setState({
				storeReservation: {
					reservedAtTime: date,
					reservedAtDate: new Date(this.props.selectedDate)
				},
				isLoaded: true
			}, () => {
				this.props.dispatch(action.store.setRecentCallInfo(null));
			})
		}

		// 기존의 예약정보를 보는 경우
		this.props.dispatch(action.store_reservation.getById({
			storeReservationId: params.storeReservationId || this.props.selectedReservationId
		}))
			.then((res) => {
//				console.log("<<first result>>", res);
				if (!res) return;
				const storeTableIds = res.store_reservation_table.length > 0 ? res.store_reservation_table.map((item) => {
                    return item.fk_storeTableId;
                }) : [];
				let newState = {
					storeReservation: res.store_reservation || {},
					storeReservationPlace: res.store_reservation_place || [],
					storeCustomer: res.store_customer || {},
					storeTable: res.store_reservation_table || [],
					storeTableIds: storeTableIds,
					originalStoreTableIds: JSON.parse(JSON.stringify(storeTableIds))
				};
				newState.storeReservation.reservedAtDate = new Date(newState.storeReservation.reservedAt);
				newState.storeReservation.reservedAtTime = new Date(newState.storeReservation.reservedAt);
//				console.log("<<storeRes reservedAt>>", newState.storeReservation.reservedAt, newState.storeReservation.reservedAtDate, newState.storeReservation.reservedAtDate);

				// ex) 010-1234-1234
				let phoneNumber = res.store_customer.phoneNumber;
				phoneNumber = phoneNumber.slice(0,3) + '-' + phoneNumber.slice(3,7) + '-' + phoneNumber.slice(7)
				this.setState({
					phoneNumberDash: phoneNumber
				})

				this.setState(newState, () => {
					// Reservation/index.jsx에 테이블 아이디 넘겨주기
					let reservationModalItems = {
						storeTableIds: this.state.storeTableIds,
					};
					if (this.props.setReservationModalItems) {
                        this.props.setReservationModalItems(reservationModalItems);
                    }
					/////////////////
					this.setState({
						leftPosition: 120 + ((this.state.storeCustomer.name ? this.state.storeCustomer.name.length : 0) * 20)
					})

					const storeMenus = this.props.dispatch(action.store_reservation_menu.getList({ fk_storeReservationId: this.state.storeReservation.storeReservationId })).then((res) => {
						let customKey = 99999; //give the custom menu a custom menu ID
						return res.map((item, index) => {
							if(item.store_menu.storeMenuId === null) {
								customKey += 1; //increase custom menu ID to prevent duplicates
								let customMenuData = {
									storeMenuId: item.store_reservation_menu.storeMenuId || customKey,
									fk_storeId: item.store_reservation_menu.fk_storeId,
									menu: item.store_reservation_menu.menu
								}
								return Object.assign({ storeReservationMenuId: item.store_reservation_menu.storeReservationMenuId }, customMenuData);
							}
							else return Object.assign({ storeReservationMenuId: item.store_reservation_menu.storeReservationMenuId }, item.store_menu);
						})
					});
					const storeMemos = this.props.dispatch(action.store_reservation_memo.getList({ fk_storeReservationId: this.state.storeReservation.storeReservationId })).then((res) => {
	//					console.log("<<res object>>", res);
						let customKey = 99999; //give the custom memo a custom menu ID
						return res.map((item, index) => {
	//						console.log(`index ${index}`,item)
							if(item.store_memo.fk_storeId === null) {
								customKey += 1;
								let customMemoData = {
									fk_storeId: item.store_reservation_memo.fk_storeId,
									storeMemoId: customKey,
									memo: item.store_reservation_memo.memo
								}
								return Object.assign({ storeReservationMemoId: item.store_reservation_memo.storeReservationMemoId }, customMemoData);
							}
							return Object.assign({ storeReservationMemoId: item.store_reservation_memo.storeReservationMemoId }, item.store_memo);
						})
					})
					const storeCustomerInfos = this.props.dispatch(action.store_customer_info.getList({ fk_storeCustomerId: this.state.storeCustomer.storeCustomerId, order: 'createdAt', orderMethod: 'asc' })).then((res) => {
						return res.map((item, index) => {
							return Object.assign({ storeCustomerInfoId: item.storeCustomerInfoId }, item.store_customer_info);
						})
					})
					Promise.all([
						storeMenus,
						storeMemos,
						storeCustomerInfos
					]).then((res) => {
						this.setState({
							isLoaded: true,
							storeMenus: res[0],
							originalStoreMenus: res[0],

							storeMemos: res[1],
							originalStoreMemos: res[1],

							storeCustomerInfos: res[2],
							originalStoreCustomerInfos: res[2],
						});
						this.menuTooltip.setCheckedItems(res[0], 'storeMenuId'); //the reason why custom tags didn't work
						this.memoTooltip.setCheckedItems(res[1], 'storeMemoId');
					}).catch((err) => {
						//console.log('예약 관련 정보 에러 :: ', err);
					})
				});
			})
	}

	getStoreCustomerByPhoneNumber() {
		if (!this.state.storeCustomer.phoneNumber
			|| (this.state.storeCustomer.phoneNumber === this.state.originalPhoneNumber)) return;

		this.props.dispatch(action.store_customer.getList({
			count: 1,
			fk_storeId: this.state.store.storeId,
			phoneNumber: this.state.storeCustomer.phoneNumber,
		})).then((res) => {
			if (res.length > 0) {
				this.setState({
					storeCustomer: res[0].store_customer || {},
				}, () => {
					this.setState({
						leftPosition: 120 + ((this.state.storeCustomer.name ? this.state.storeCustomer.name.length : 0) * 20)
					})
				})
				const storeCustomerInfos = this.props.dispatch(action.store_customer_info.getList({ fk_storeCustomerId: res[0].store_customer.storeCustomerId })).then((res) => {
					return res.map((item, index) => {
						return Object.assign({ storeCustomerInfoId: item.storeCustomerInfoId }, item.store_customer_info);
					})
				});
				return storeCustomerInfos
			} else {
				return null;
			}
		}).then((res) => {
			this.setState({
				storeCustomerInfos: res || [],
				originalStoreCustomerInfos: res || [],
			})
		}).catch((err) => {
			console.log('핸드폰번호로 고객 정보 못받아옴 ::: ', err);
		})
	}

	setStoreTableIds(storeTableIds) {
		//alert(storeTable.tableNo + '를 선택하셨습니다.');
		this.setState({ storeTableIds: storeTableIds }, () => {
//			console.log('테이블 선택 최종 결과');
//			console.log(this.state.storeTableIds);
		});
	}

	handleChangeDate(date) {
		if(date === null) return;
		this.setState({
			selectedReservationDate: date
		})

		// 현재 내일 날짜를 선택해도, 시간/분 만 보고 alert가 뜨는 문제가 있어서 주석처리했습니다.
		/*if (new Date(date) < new Date()) {
			alert('선택한 시간이 현재 시간보다 이전입니다.')
			return;
		}*/
		this.handleReservationValues(date, 'reservedAtDate', 'storeReservation');
	}

	handleChangeTime(date) {
		if(date === null) return;
		this.setState({
			selectedReservationTime: date
		})

		this.handleReservationValues(date, 'reservedAtTime', 'storeReservation');
	}

	showCallHistory() {
		this.setState({
			showCallHistory: !this.state.showCallHistory
		})
	}

	/*
	* value: 변경된 값
	* field: 변경된 값의 이름
	* state: 변경시킬 state
	*/
	handleReservationValues(value, field, state) {
		let newState = {};
		newState[state] = this.state[state];

		newState[state][field] = value;

		this.setState(newState);

		if (field === 'name') {
			this.setState({
				leftPosition: 120 + (this.state.storeCustomer.name.length * 20)
			})
		}
	}

	handlePhoneNumberDash(value, field, state) {
		let originalPhoneNumber = this.state.phoneNumberDash || '';

		if (value.length > originalPhoneNumber.length && (value.length == 3 || value.length == 8)) {
			this.setState({ phoneNumberDash: value + '-' })
		} else {
			this.setState({ phoneNumberDash: value })
		}

		if (field && state) {
            		let newState = {};
            		newState[state] = this.state[state];
            		newState[state][field] = value;

            		this.setState(newState);
		}
	}

	handleKeyPad(value, state, field) {
		if (field === 'numberOfPeople' && (value > this.props.store.store.maximumReservation)) {
			this.reservationModalKeypad.setState({
				numberString: []
			})
			this.setState({
				storeReservation: { numberOfPeople: 0 }
			})

			return this.handleChangingStatus(this.props.store.store.maximumReservation + '명 이하로 입력해주세요.', false);
		}

//		console.log("<<value>>", value);

		if (field === 'phoneNumber') {
			this.setState({
				phoneNumberDash: value.toString()
			})
			value = value.replace('-','').toString();
		} else {
			value = value.toString();
		}

		let newState = {};
		newState[state] = this.state[state];
		newState[state][field] = value;

		if (value === "") {
			newState[state][field] = "";
		}

		this.setState(newState);
	}

	handleTooltipItems(item, listName) {
		let newState = {};
		newState[listName] = item;
		this.setState(newState);
	}

	handleSubmitCustomer(params) {
		params.fk_storeId = this.state.store.storeId;
		params.name = this.state.storeCustomer.name;
		params.phoneNumber = this.state.storeCustomer.phoneNumber;
		params.isVip = this.state.storeCustomer.isVip;
		params.type = this.state.storeCustomer.type || 0;

		return new Promise((resolve, reject) => {
			this.props.dispatch(action.store_customer.getList({
				fk_storeId: this.state.store.storeId,
				phoneNumber: this.state.storeCustomer.phoneNumber,
				count: 1,
			})).then((res) => {
				let customerFunc = null;
				if (res.length > 0) {
					params.storeCustomerId = res[0].store_customer.storeCustomerId;
					customerFunc = action.store_customer.update(params);
				} else {
					customerFunc = action.store_customer.create(params);
				}

				this.props.dispatch(customerFunc).then((res) => {
					if (res.storeCustomerId) {
						resolve(res);
					} else {
						reject(res);
					}
				})
			});
		});
	}

	handleSubmitReservation(params) {
		let reservationFunc = null;
		if (this.state.storeReservation.storeReservationId) {
			params.storeReservationId = this.state.storeReservation.storeReservationId;
			reservationFunc = action.store_reservation.update(params);
		} else {
			params['storePlaceIdMap'] = [{
				fk_storePlaceId: this.props.store_place.storePlaceId,
				fk_storePlaceFloorId: this.props.store_place_floor.storePlaceFloorId,
			}];
			params['waitingStatus'] = 1;
			reservationFunc = action.store_reservation.create(params);
		}

      return new Promise((resolve, reject) => {
          this.props.dispatch(reservationFunc).then((res) => {
              if (res.storeReservationId) {
                  resolve(res);
              } else {
                  reject(res);
              }
          })
      })
  }

	handleSubmitCustomerInfos(params) {
		let infos = this.state.storeCustomerInfos;
		let originalInfos = this.state.originalStoreCustomerInfos;

		let changed = listUtil.getJSONArrayChangedValues(originalInfos, infos, 'storeCustomerInfoId');

		const createFunc = infos.length > 0 ? infos.map((item) => {
			return !item.storeCustomerInfoId ? new Promise((resolve, reject) => {
				this.props.dispatch(action.store_customer_info.create({
					fk_storeCustomerId: params.fk_storeCustomerId,
					content: item.content,
				})).then((res) => {
					if (res.storeCustomerInfoId) {
						resolve(res);
					} else {
						console.log('STORE CUSTOMER INFO ERR::');
						reject(res);
					}
				})
			}) : null;
		}) : [];
		if (changed) {
			const deleteFunc = changed.deleted.length > 0 ? changed.deleted.map((item) => {
				return new Promise((resolve, reject) => {
					this.props.dispatch(action.store_customer_info.remove({
						storeCustomerInfoId: item.storeCustomerInfoId
					})).then((res) => {
						if (res) {
							resolve(res);
						} else {
							console.log('STORE CUSTOMER INFO ERR::');
							reject(res);
						}
					})
				});
			}) : [];
			return Promise.all(createFunc.concat(deleteFunc));
		} else {
			return [];
		}
	}

	handleSubmitMenus(params) {
		let menus = this.state.storeMenus;
		let originalMenus = this.state.originalStoreMenus;

		let changed = listUtil.getJSONArrayChangedValues(originalMenus, menus, 'storeMenuId');
	//	console.log("<<submit_changed_menus>>",changed);
		let createFunc;
		if(changed === null) createFunc = [];
		else createFunc = changed.created.length > 0 ? changed.created.map((item) => {
			const createParams = JSON.parse(JSON.stringify(params))
			createParams.fk_storeMenuId = item.storeMenuId;
			createParams.menu = item.menu;
			return new Promise((resolve, reject) => {
				this.props.dispatch(action.store_reservation_menu.create(createParams)).then((res) => {
					if (res.storeReservationMenuId) {
						resolve(res);
					} else {
						console.log('RESERVATION MENU ERR::');
						reject(res);
					}
				})
			});
		}) : [];
		if (changed) {
			const deleteFunc = changed.deleted.length > 0 ? changed.deleted.map((item) => {
				return new Promise((resolve, reject) => {
					this.props.dispatch(action.store_reservation_menu.remove({
						storeReservationMenuId: item.storeReservationMenuId
					})).then((res) => {
						if (res) {
							resolve(res);
						} else {
							console.log('RESERVATION MENU ERR::');
							reject(res);
						}
					})
				});
			}) : [];

			return Promise.all(createFunc.concat(deleteFunc));
		} else {
			return [];
		}
	}

	handleSubmitMemos(params) {
		let memos = this.state.storeMemos;
//		console.log("<<submitMemos - memos>>", memos);
		const originalMemos = this.state.originalStoreMemos;

		let changed = listUtil.getJSONArrayChangedValues(originalMemos, memos, 'storeMemoId');
//		console.log("<<submit_changed_memos>>",changed);
		if (changed) {
			const createFunc = changed.created.length > 0 ? changed.created.map((item) => {
				const createParams = JSON.parse(JSON.stringify(params))
				createParams.fk_storeMemoId = item.storeMemoId;
				createParams.memo = item.memo;
				return new Promise((resolve, reject) => {
					this.props.dispatch(action.store_reservation_memo.create(createParams)).then((res) => {
						if (res.storeReservationMemoId) {
							resolve(res);
						} else {
							console.log('RESERVATION MEMO ERR::', res);
							reject(res);
						}
					})
				});
			}) : [];
			const deleteFunc = changed.deleted.length > 0 ? changed.deleted.map((item) => {
				return new Promise((resolve, reject) => {
					this.props.dispatch(action.store_reservation_memo.remove({
						storeReservationMemoId: item.storeReservationMemoId
					})).then((res) => {
						if (res) {
							resolve(res);
						} else {
							console.log('RESERVATION MEMO ERR::', res);
							reject(res);
						}
					})
				});
			}) : [];
			return Promise.all(createFunc.concat(deleteFunc));
		} else {
			return [];
		}
	}
	// 테이블 저장
	handleSubmitTable(params) {
		if (!this.state.storeTableIds.length && !this.state.originalStoreTableIds.length)
			return new Promise((resolve, reject) => { resolve(true); });

		const createdIds = this.state.storeTableIds.filter((storeTableId) => {
			return this.state.originalStoreTableIds.indexOf(storeTableId) === -1;
		});
        const deletedIds = this.state.originalStoreTableIds.filter((storeTableId) => {
            return this.state.storeTableIds.indexOf(storeTableId) === -1;
        });

        const createTable = (createdIds || []).map((storeTableId) => {
        	const createParams = JSON.parse(JSON.stringify(params));
            createParams.fk_storeTableId = storeTableId;
            return new Promise((resolve, reject) => {
                this.props.dispatch(action.store_reservation_table.create(createParams)).then((res) => {
                    if (res.storeReservationTableId) {
                        resolve(res);
                    } else {
                        console.log('handleSubmitTable ERR::', res);
                        reject(res);
                    }
                })
            });
        }) || [];

        const deleteTable = (deletedIds || []).map((storeTableId) => {
        	const selectedStoreTable = this.state.storeTable.find((item) => {
        		return item.fk_storeTableId === storeTableId;
			});
        	console.log(selectedStoreTable, '삭제된 테이블');
            return new Promise((resolve, reject) => {
                this.props.dispatch(action.store_reservation_table.remove({
                    storeReservationTableId: selectedStoreTable.storeReservationTableId
				})).then((res) => {
                    resolve(res);
                })
            });
        }) || [];

        Promise.all(createTable.concat(deleteTable))
			.then((res) => {
				return this.props.dispatch(action.store_reservation.updateGroupNum({
					fk_storeReservationId: params.fk_storeReservationId,
					fk_storeId: params.fk_storeId,
					fk_storePlaceId: params.fk_storePlaceId
				}));
			}).then((res) => {
   //         	console.log('테이블 done', res)
				if (this.props.updateTableInfo()) {
            		this.props.updateTableInfo()
				}
        	}).catch((err) => {
        		alert(err);
			});
	}
	// 최종 제출 함수
	handleSubmit() {
		if (this.state.isProcessing) return;
		this.setState({
			isProcessing: true,
		}, () => {
			// validation
			const validateProps = [
				{ state: 'storeCustomer', value: 'phoneNumber', message: '고객의 핸드폰번호를 입력하세요.'},
				{ state: 'storeCustomer', value: 'name', message: '고객의 이름을 입력하세요.'},
				{ state: 'storeReservation', value: 'numberOfPeople', message: '예약 인원을 지정하세요.'},
			]

			new Promise((resolve) => {
				const data = validateProps.filter((props) => {
					return !this.state[props['state']][props['value']];
				});
				if (this.state.storeReservation.reservationFeeStatus &&
					this.state.storeReservation.reservationFee <= 0
				) {data.push({ message: '예약금을 입력하세요.' });}
				if (this.state.storeReservation.numberOfPeople &&
					this.state.storeReservation.numberOfPeople <= 0
				) {data.push({ message: '예약 인원을 지정하세요.' });}
				if (this.ccTimePicker && !this.ccTimePicker.state.isTimeSelected){
					data.push({ message: '시간을 지정하세요.' });
				}
				resolve(data);
			}).then((res) => {
				if (res.length > 0) {
					this.handleChangingStatus(res[0].message, false);
					//alert(res[0].message)
					this.setState({ isProcessing: false })
					return;
				}

				const customerParams = {
					fk_storeId: this.state.store.storeId,
					name: this.state.name,
					phoneNumber: this.state.phoneNumber,
					type: this.state.selectedColor.type
				}
				this.handleSubmitCustomer(customerParams).then((response) => {
					//combine the date of reservedAtDate and time of reservedAtTime
					let reservedAtDate = new Date(this.state.storeReservation.reservedAtDate);
					if(isNaN(reservedAtDate)) {
						reservedAtDate = new Date();
					}
		//			console.log("<<reservedAtDate>>", reservedAtDate);
					let reservedAtTime = new Date(this.state.storeReservation.reservedAtTime);
					if(isNaN(reservedAtTime)) {
						throw new Error("reservedAtTime error");
					}
		//			console.log("<<reservedAtTime>>", reservedAtTime);
					const reservedAt = new Date(reservedAtDate.getFullYear(), reservedAtDate.getMonth(), reservedAtDate.getDate(), reservedAtTime.getHours(), reservedAtTime.getMinutes(), reservedAtTime.getSeconds());
		//			console.log("<<reservedAt>>", reservedAt);


		//			console.log("<<storeRes reservedAt>>", reservedAt);
					const reservationParams = {
						fk_storeCustomerId: response.storeCustomerId,
						fk_brandId: this.state.brand.brandId,
						fk_storeId: this.state.store.storeId,
						fk_storeStaffId: this.state.storeReservation.fk_storeStaffId || 0,
						numberOfPeople: this.state.storeReservation.numberOfPeople || 0,
						babySeatAmount: this.state.storeReservation.babySeatAmount || 0,
						reservedAt: reservedAt || new Date(),
						isSendNoti: this.state.storeReservation.isSendNoti || 0,
						reservationFeeStatus: this.state.storeReservation.reservationFeeStatus,
						reservationFee: this.state.storeReservation.reservationFeeStatus ? this.state.storeReservation.reservationFee : 0,
						reservationStatus: 0,
					}

					return this.handleSubmitReservation(reservationParams)
				}).then((response) => {

					if (!response) { return new Error('예약에 실패했습니다.') }

					const optionParams = {
						fk_storeId: response.fk_storeId,
						fk_storeReservationId: response.storeReservationId,
						fk_storeCustomerId: response.fk_storeCustomerId,
						fk_storePlaceId: this.props.store_place.storePlaceId,
					}
					Promise.all([
						this.handleSubmitMenus(optionParams),
						this.handleSubmitMemos(optionParams),
						this.handleSubmitCustomerInfos(optionParams),
						this.handleSubmitTable(optionParams)
					]).then((res)=> {
						//alert('예약을 완료했습니다.');

						this.props.dispatch(action.store.setRecentCallInfo(null));

						this.handleChangingStatus('예약을 완료했습니다.', true);

						setTimeout(() => {
							this.props.dispatch(action.store_reservation.reservationUpdated(new Date()));
							this.setState({isProcessing: false });
							this.props.closeModal();
						}, 1000);

					}).catch((err) => {
						this.handleChangingStatus('예약에 실패했습니다', false);
						setTimeout(() => {
							this.props.dispatch(action.store_reservation.reservationUpdated(new Date()));
							this.setState({isProcessing: false });
						}, 3000);

						console.log('RESERVATION OPTIONS ERR::', err);
					})
				}).catch((err) => {
					this.handleChangingStatus('예약에 실패했습니다', false);
					setTimeout(() => {
						this.props.dispatch(action.store_reservation.reservationUpdated(new Date()));
						this.setState({isProcessing: false });
					}, 3000);

					console.log('ERR => ', err);
				})
			});
		});
	}

	handleCancel() {
		if (this.state.storeReservationPlace.length === 0) {
			alert('예약을 먼저 완료해주세요.');
			return;
		}
		if (confirm('예약을 취소하시겠습니까?')) {
			const selectedStorePlace = this.state.storeReservationPlace.find((item) => {
				return item.fk_storePlaceId === this.props.store_place.storePlaceId;
				// 아래 주석부분이 store_reservation_place에 storePlaceFloorId가 null이게 되면서 부터 문제 발생
					//&& item.fk_storePlaceFloorId === this.props.store_place_floor.storePlaceFloorId;
			})
	//		console.log(this.state.storeReservationPlace);
	//		console.log(this.props.store_place);
	//		console.log(this.props.store_place_floor);
	//		console.log(selectedStorePlace);
			this.props.dispatch(action.store_reservation_place.update({
				storeReservationPlaceId: selectedStorePlace.storeReservationPlaceId,
				fk_storePlaceId: selectedStorePlace.fk_storePlaceId,
				fk_storePlaceFloorId: selectedStorePlace.fk_storePlaceFloorId,
				waitingStatus: 20
			})).then((res) => {
				this.handleChangingStatus('예약을 취소했습니다.', true);
				setTimeout(() => {
					this.props.dispatch(action.store_reservation.reservationUpdated(new Date()));
					this.props.closeModal();
				}, 1000);
			}).catch((err) => {
				this.handleChangingStatus('예약 취소에 실패했습니다.', false);
				setTimeout(() => {
					this.props.closeModal();
				}, 3000);
			})
		}
	}

	openNumberKeypad() {
		if (!this.state.isNumberKeypadOpened) {
			this.reservationModalKeypad.open();
			this.setState({ isNumberKeypadOpened: true })
		} else {
			this.reservationModalKeypad.open();
			this.setState({ isNumberKeypadOpened: false })
		}
	}

	openPhoneNumberKeypad() {
		if (!this.state.isPhoneNumberKeypadOpened) {
			this.phoneNumberKeypad.open();
			this.setState({ isPhoneNumberKeypadOpened: true })
		} else {
			this.phoneNumberKeypad.open();
			this.setState({ isPhoneNumberKeypadOpened: false })
		}
	}

	openReservationFeeKeypad() {
		if (!this.state.isReservationFeeKeypadOpened) {
			this.reservationModalKeypad2.open();
			this.setState({ isReservationFeeKeypadOpened: true })
		} else {
			this.reservationModalKeypad2.open();
			this.setState({ isReservationFeeKeypadOpened: false })
		}
	}

	deleteItem(index) {
		let storeCustomerInfos = this.state.storeCustomerInfos.filter((item, idx) => {
			return idx !== index
		});

		this.setState({
			storeCustomerInfos: storeCustomerInfos
		});
	}

	onKeyPress(e) {
		if (e.key === 'Enter') {
			this.directInputType();
			this.setState({
				directInput: ''
			})
		}
	}

	directInputType() {
		if (this.state.directInput) {
			let flag = false;
			this.state.storeCustomerInfos.forEach((info) => {
				if (this.state.directInput === info.content) {
					flag = true
				}
			});
			if (!flag) {
				this.setState({
					storeCustomerInfos: [...this.state.storeCustomerInfos, {
						content: this.state.directInput,
					}]
				})
			}
		}
	}

	showDirectInput() {
		this.setState({
			isDirectInput: !this.state.isDirectInput
		})
	}

	handleSelectType(type, color) {
        this.setState({
            selectedColor: {
                type: type,
                color: color
            },
            showColors: false
        })
    }

    handleSelectTable() {
			this.handleChangingStatus('좌석이 선택되었습니다.', true);
    }

	cancelPayment() {
		// 예약금 환불로직
		if(confirm("정말 환불처리 하시겠습니까?")) {
			this.props.dispatch(action.payment.billCancel({ storeReservationId: this.state.storeReservation.storeReservationId  }))
				.then((res) => {
					this.handleChangingStatus('환불처리 되었습니다', true);
					setTimeout(() => {
						this.props.closeModal();
					}, 1000);
				})
				.catch((err) => {})
		}
	}

	handleChangingStatus(text, bool) {
		this.setState({
			isStatusChanging: true,
			alertText: text,
			isPositive: bool
		}, () => {
			setTimeout(() => {
				this.setState({ isStatusChanging: false });
			}, bool ? 1000 : 3000);
		})
	}

	render(){
		let renderStaffs = this.state.storeStaffs.length > 0 ? this.state.storeStaffs.map((staff, index) => {
			return (
				<option key={index} value={staff.store_staff.storeStaffId}>{staff.store_staff.name} {staff.store_staff.position}</option>
			)
		}) : null

		let renderStoreCustomerInfos = this.state.storeCustomerInfos.length > 0 ? this.state.storeCustomerInfos.map((info, index) => {
			return (
				<li key={index}>
					{info.content}
					<img className={styleReservationModal.deleteIcon} src="/Assets/img/ic-delete-item.png" onClick={() => this.deleteItem(index)}/>
				</li>
			)
		}) : null

		let vipStyle = {
			color: '#1e2028',
			border: '1px solid #7434cc'
		}
		if (!this.state.isLoaded) {
			return (
				<div style={{marginLeft: 'auto', marginRight: 'auto', marginTop: '150px', width: '190px'}}>
					<MoonLoader
						size={150}
						color={'#dae8f2'}
					/>
				</div>
			);
		}

		let customStyle = {
			right: '410px',
			top: '190px',
		}

		let customStyle2 = {
			marginTop: '-115px',
			marginRight: "88px"
		}

		let get0900Time = () => {
			let date = new Date();
			date.setHours(0,0);
			return date
		}

		const CustomInput = React.forwardRef((props,ref) => {
			return (
				<input
					className={styleReservationModal.datePicker}
					onClick={props.onClick}
					value={props.value}
					type="text"
					readOnly={true}
					ref={ref}
				/>
			)
		})

		return(
			<div>
				{this.state.isStatusChanging && !this.state.isPositive ? <div className={styleReservationModal.completedAlert} style={{backgroundColor: '#F08080'}}>{this.state.alertText}</div> : null}
				{this.state.isStatusChanging && this.state.isPositive ? <div className={styleReservationModal.completedAlert} >{this.state.alertText}</div> : null}
				<div className={styleReservationModal.modalLeft}>
					<div className={styleReservationModal.visitNum}>
						방문횟수 <span>{ this.state.storeCustomer.visitAmount || 0 }</span>
					</div>
					<div className={styleReservationModal.noShow}>
						No Show <span>{ this.state.storeCustomer.noShowAmount || 0 }</span>
					</div>
					<div className={styleReservationModal.recentCall}>
						최근통화 { dateUtil.getFromNow(this.state.storeCustomer.calledAt)}
					</div>
					<div className={styleReservationModal.contactInfo}>
						<div className={styleReservationModal.phone}>
							<input type="text" placeholder="010-1234-1235" value={this.state.phoneNumberDash}
										 onChange={(e) => this.handlePhoneNumberDash(e.target.value, 'phoneNumber', 'storeCustomer')}
										 // onChange={(e) => this.handleReservationValues(e.target.value, 'phoneNumber', 'storeCustomer')}
							       onFocus={() => {this.setState({ originalPhoneNumber: this.state.storeCustomer.phoneNumber })}} onBlur={this.getStoreCustomerByPhoneNumber}
										 onClick={this.openPhoneNumberKeypad}
										 readOnly={this.state.isAndroid}
							/><span className={styleReservationModal.titleStar}>*</span>
							<Keypad isString={true} customStyle={customStyle} onRef={(ref) => {this.phoneNumberKeypad = ref;}} onChange={(value) => this.handleKeyPad(value,"storeCustomer","phoneNumber")} getStoreCustomerByPhoneNumber={this.getStoreCustomerByPhoneNumber}/>
						</div>
						<div className={styleReservationModal.name}>
							<input id="customerName" type="text" placeholder="미입력" value={this.state.storeCustomer.name} onChange={(e) => {
								this.handleReservationValues(e.target.value, 'name', 'storeCustomer')}}
										 style={this.state.storeCustomer.isVip ? {color: '#7434cc'} : null}/><span className={styleReservationModal.titleStar}>*</span>
							{this.state.storeCustomer.name ?
							<div className={styleReservationModal.blackList} style={{backgroundColor: this.state.selectedColor[this.state.storeCustomer.type || 0].color, left: this.state.leftPosition + 'px'}}/>
							: null}
						</div>
					</div>
					<div>
						<ul className={styleReservationModal.stateBtn}>
							<li onClick={() => this.handleReservationValues(!this.state.storeCustomer.isVip, 'isVip', 'storeCustomer')} style={this.state.storeCustomer.isVip ? vipStyle : null}>VIP</li>
							<li onClick={() => {this.setState({showColors: !this.state.showColors, type: 0})}}>
								<img src={this.state.selectedColor[this.state.storeCustomer.type || 0].inactive} />
							</li>
						</ul>
						{this.state.showColors ?
							<div className={styleReservationModal.colorList}>
								{
									Object.keys(this.state.selectedColor).map((key, index) => {
										return this.state.storeCustomer.type != key ?
											<li key={index} onClick={() => this.setState({ showColors: false }, () => {
												this.handleReservationValues(key, 'type', 'storeCustomer')
											})}>
												<img src={this.state.selectedColor[key].active} />
											</li> : null
									})
								}
							</div>
							: null}
					</div>
					<div className={styleReservationModal.callHistoryBtn} onClick={this.showCallHistory}>통화 이력 조회</div>
				</div>
				{this.state.showCallHistory ? <CallHistoryScroll showCallHistory={this.showCallHistory} setStoreCustomer={(customer) => {this.setState(customer)}} storeCallHistory={this.state.storeCallHistory} storeCustomer={this.state.storeCustomer || {}}/> : null}
				<div className={styleReservationModal.modalRight}>
					{this.state.storeReservation && this.state.storeReservation.reservationFeeStatus === 0 ?
						<div className={styleReservationModal.reservationFee}>
							예약금<br/>없음
						</div> : null}
					{this.state.storeReservation && this.state.storeReservation.reservationFeeStatus === 1 ?
						<div className={styleReservationModal.reservationFee}>
							예약금<br/>결제
						</div> : null}
					{this.state.storeReservation && this.state.storeReservation.reservationFeeStatus === 10 ?
						<div className={styleReservationModal.reservationFee + ' ' + styleReservationModal.isBtn} onClick={this.cancelPayment}>
							예약금<br/>환불
						</div> : null}
					{this.state.storeReservation && this.state.storeReservation.reservationFeeStatus === 20 ?
						<div className={styleReservationModal.reservationFee}>
							환불<br/>완료
						</div> : null}
					{this.state.storeReservation && this.state.storeReservation.reservationFeeStatus === 21 ?
						<div className={styleReservationModal.reservationFee}>
							예약금<br/>결제실패
						</div> : null}
					<div className={styleReservationModal.customerInfo}>
						<div className={styleReservationModal.infoTitle}>고객 정보</div>
						<ul className={styleReservationModal.listBox} style={{paddingBottom: '10px'}}>
							{renderStoreCustomerInfos}
							{this.state.isDirectInput ?
								<li className={styleReservationModal.directInput}>
									<input type="text" onKeyPress={this.onKeyPress} value={this.state.directInput} onChange={(e) => this.setState({ directInput: e.target.value })}/>
								</li> : null}
							<li className={styleReservationModal.addIconBox} onClick={this.showDirectInput}><img className={styleReservationModal.addIcon} src="/Assets/img/btn-add.png"/></li>
						</ul>
					</div>
					<div className={styleReservationModal.bookInfo}>
						<div className={styleReservationModal.infoTitle + ' ' + styleReservationModal.infoTitle2}>예약 정보</div>
						<select className={styleReservationModal.selectStaff} value={this.state.storeReservation.fk_storeStaffId || null} onChange={(e) => this.handleReservationValues(e.target.value, 'fk_storeStaffId', 'storeReservation')}>
							<option defaultValue>담당자를 선택해주세요.</option>
							{renderStaffs}
						</select>
						<div className={styleReservationModal.dateInfo}>
							<div className={styleReservationModal.infoSmallTitle}>날짜 <span className={styleReservationModal.titleStar}>*</span></div>
							<div className={styleReservationModal.datePicker2 + ' ' + styleReservationModal.datePickerWrap}>
								<div className={styleReservationModal.datePicker3}>
									<div className={styleReservationModal.datePickerDiv + ' ' +styleReservationModal.style2}>
										{dateUtil.format('YYYY', this.state.storeReservation.reservedAtDate || new Date())}
									</div>
									<div className={styleReservationModal.datePickerDiv + ' ' + styleReservationModal.style1}>
										<div>
											{dateUtil.format('MM', this.state.storeReservation.reservedAtDate || new Date())}
										</div>
										<div style={{fontSize: '18px', color: '#98a0ab', margin:'0 5px'}}>/</div>
										<div>{dateUtil.format('DD', this.state.storeReservation.reservedAtDate || new Date())}</div>
									</div>
									<div className={styleReservationModal.datePickerDiv}>
										{dateUtil.format('dddd', this.state.storeReservation.reservedAtDate || new Date())}
									</div>
								</div>
								<DatePicker
									customInput={<CustomInput />}
									//disabledKeyboardNavigation={this.state.isAndroid}
									className={styleReservationModal.datePicker}
									dateFormat="yyyy-eeee-MM-dd"
									minDate={new Date()}
									selected={this.state.storeReservation.reservedAtDate ? new Date(this.state.storeReservation.reservedAtDate) : new Date()}
									onChange={this.handleChangeDate}
								/>
							</div>
						</div>
						<div className={styleReservationModal.timeInfo}>
							<div className={styleReservationModal.infoSmallTitle}>시간 <span className={styleReservationModal.titleStar}>*</span></div>
								<CustomTimePicker
									onRef={(ref) => {this.ccTimePicker = ref}}
									reservationTime={this.state.storeReservation.reservedAt ? new Date(this.state.storeReservation.reservedAt) : get0900Time() }
									onChange={this.handleChangeTime}
									handleChangingStatus={this.handleChangingStatus}
								/>
						</div>
						<div className={styleReservationModal.numInfo}>
							<div className={styleReservationModal.infoSmallTitle}>인원 <span className={styleReservationModal.titleStar}>*</span></div>
							<div className={styleReservationModal.datePickerBox} onClick={this.openNumberKeypad} >
								<input className={styleReservationModal.datePicker2} type="text" value={this.state.storeReservation.numberOfPeople || 0} readOnly={this.state.isAndroid}/>명
							</div>
							<Keypad onRef={(ref) => {this.reservationModalKeypad = ref;}} onChange={(value) => this.handleKeyPad(value,"storeReservation","numberOfPeople")}
							/>
						</div>
						<div className={styleReservationModal.menuInfo}>
							<div className={styleReservationModal.infoSmallTitle}>메뉴</div>
							<InputWithTooltip
								onRef={(ref) => {this.menuTooltip = ref;}}
								getList={action.store_menu.getList}
								params={{ fk_storeId: this.state.store.storeId, order: 'storeMenuId', orderMethod: 'asc' }}
								onUpdate={(item) => this.handleTooltipItems(item, 'storeMenus')}
								listName={'store_menu'}
								itemName={'menu'}
							/>
						</div>
						<div className={styleReservationModal.memoInfo}>
							<div className={styleReservationModal.infoSmallTitle}>메모</div>
							<InputWithTooltip
								onRef={(ref) => {this.memoTooltip = ref;}}
								getList={action.store_memo.getList}
								params={{ fk_storeId: this.state.store.storeId, order: 'storeMemoId', orderMethod: 'asc' }}
								onUpdate={(item) => this.handleTooltipItems(item, 'storeMemos')}
								listName={'store_memo'}
								itemName={'memo'}
							/>
						</div>
						<div className={styleReservationModal.checkSend}>
							<input type="checkbox" onChange={(e) => this.handleReservationValues(e.target.checked, 'isSendNoti', 'storeReservation')} checked={this.state.storeReservation.isSendNoti}/><label>문자 및 알림톡 발송</label>
						</div>
						{this.props.store.store_system.isReservationFee ?
							<div className={styleReservationModal.prepay}>
								<input type="checkbox" onChange={(e) => this.handleReservationValues(Number(e.target.checked), 'reservationFeeStatus', 'storeReservation')} checked={this.state.storeReservation.reservationFeeStatus || false}/><label>예약금</label>
								{this.state.storeReservation.reservationFeeStatus ?
									<div className={styleReservationModal.prepayInputBox}>
										<input type="text" autoFocus={true} value={this.state.storeReservation.reservationFee} onChange={(e) => this.handleReservationValues(e.target.value, 'reservationFee', 'storeReservation')} onClick={this.openReservationFeeKeypad} readOnly={this.state.isAndroid}/> 원
										<Keypad flip={true} customStyle={customStyle2} onRef={(ref) => {this.reservationModalKeypad2 = ref;}} onChange={(value) => this.handleKeyPad(value,"storeReservation","reservationFee")} />
									</div>
									: null}
							</div>
						: null}
					</div>
				</div>
				{this.state.storeCustomer.phoneNumber && this.state.storeCustomer.name && this.state.storeReservation.numberOfPeople ? null :
				<div className={styleReservation.saveReservation}
						 style={{position: 'absolute', bottom: '-60px', right: '183px', cursor: 'auto', height: '40px', zIndex: 10, opacity: '0.7'}}/>
				}
			</div>
		);
	}
}

export default connect((state) => {
  return {
      author: state.data.account.author,
      store: state.data.store.store,
      store_place: state.data.store_place.store_place,
      store_place_floor: state.data.store_place_floor.store_place_floor,
      selectedReservationId: state.data.store_reservation.selectedReservationId,
      reservationUpdated: state.data.store_reservation.reservationUpdated,
			selectedDate: state.data.store_reservation.selectedDate,
	    recentCallInfo: state.data.store.recentCallInfo,
  }
})(withRouter(ReservationModal))
