
// API
import * as HttpApi from '../Api/index';

//////////////////
///// POST ////////
//////////////////
export const billCancel = (params) => {
	return (dispatch) => {
		return HttpApi.post('POST_BILL_CANCEL', params)
			.then((response) => {
				return Promise.resolve(response.data);
			})
			.catch((err) => {
				return Promise.reject(err);
			});
	};
};
