import * as ActionTypes from '../actionTypes';

export const initialState = {
    brand: {},
    brand_list: []
}
/*
*/
export const brand_reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.BRAND:
            return Object.assign({}, state, {
                brand: action.brand
            });
        case ActionTypes.BRAND_LIST:
            return Object.assign({}, state, {
                brand_list: action.brand_LIST
            });
        default:
            return state;
        }
}
