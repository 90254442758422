import React, {Component} from 'react';
import {withRouter, Route} from 'react-router-dom';
import {connect} from 'react-redux';

// styles
import styleReservationModal from '../../../Styles/Components/ReservationModal.css'
import CallHistoryScroll from './CallHistoryScroll';
import { action } from '../../../../Data'
import { dateUtil, parseUrlParameter, addDashesToPhoneNumber } from '@/Utils'
import ReservationPopup from "./ReservationModal";
import Modal from '../../../Common/Modal';
import styleReservation from 'Service/Styles/Components/Reservation.css'
import _ from 'underscore'
import empty from 'just-is-empty'

class CustomerModal extends Component{
  constructor(props){
    super(props);
    this.state = {
      store: {},
      brand: {},
      storeCustomer: {},
      storeReservation: [],

      showCallHistory: false,
      startDate: new Date(),

      selectedColor: {
        0: { color: '#ffffff', active: '/Assets/img/label-inactive-blacklist.png', inactive: '/Assets/img/label-inactive-blacklist.png', isChecked: false },
        1: { color: '#22d256', active: '/Assets/img/label-guest-state-01.png', inactive: '/Assets/img/label-guest-state-01-selected.png', isChecked: false },
        2: { color: '#f8cf26', active: '/Assets/img/label-guest-state-02.png', inactive: '/Assets/img/label-guest-state-02-selected.png', isChecked: false },
        3: { color: '#ec5126', active: '/Assets/img/label-guest-state-03.png', inactive: '/Assets/img/label-guest-state-03-selected.png', isChecked: false }
      },
      showColors: false,
      isVip: false,

      // 전화예약 테스트
      phoneNumber: '133133',
      isReservationCompleted: false,

      isAndroid: false,
    };

    this.showCallHistory = this.showCallHistory.bind(this);
    this.getStoreCustomer = this.getStoreCustomer.bind(this);
    this.setStoreReservation = this.setStoreReservation.bind(this);
    this.handleReservationFee = this.handleReservationFee.bind(this);
    this.handleReservationValues = this.handleReservationValues.bind(this);
  }

  // componentWillMount() {
  //   this.getStoreCustomer();
  // }

  componentDidMount() {
    this.props.onRef(this);
    this.getStoreCustomer();
    this.setStoreReservation();
    if (navigator.maxTouchPoints !== 0) this.setState({isAndroid: true});
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.author !== prevProps.author
      || prevProps.reservationUpdated !== this.props.reservationUpdated
      // || prevProps.recentCallInfo !== this.props.recentCallInfo
      // 전화예약시 null로 업데이트되는 문제때문에 주석 달아놈
    ) {
      this.getStoreCustomer();

      // 전화예약중 다른 예약내용 수정시 업데이트
      this.setStoreReservation();
    }
  }

  getStoreCustomer() {
    if (!this.props.store.store) return;

    // 통화이력에서 해당이력을 클릭했을때 정보 채우기
    if (this.props.recentCallInfo && this.props.recentCallInfo.phoneNumber) {
      const recentCallInfo = this.props.recentCallInfo;
        // 통화이력을 클릭해서 열렸을 경우 openModal 이란 값이 customerModal로 설정되어 있고
        // recentCallInfo도 누른 이력의 전화번호를 바탕으로 설정
        // 만약 이런 케이스로 customerModal이 열렸을 경우 recentCallInfo를 비워준다
        if (this.props.openModal === 'customerModal') {
            this.props.dispatch(action.store.setOpenModal(null));
            this.props.dispatch(action.store.setRecentCallInfo(null));
        }
      this.props.dispatch(action.store_customer.getList({
        phoneNumber: recentCallInfo.phoneNumber,
        fk_storeId: this.props.store.store.storeId,
      })).then((res) => {
        this.setState({
            storeCustomer: res.length ? res[0].store_customer : {
            phoneNumber: recentCallInfo.phoneNumber,
            calledAt: new Date(),
          }
        }, () => {
          this.setState({
            leftPosition: 120 + ((this.state.storeCustomer.name ? this.state.storeCustomer.name.length : 0) * 20),
            phoneNumberDash: addDashesToPhoneNumber(this.state.storeCustomer.phoneNumber) || ''
          })
          this.setStoreReservation();

          //this.props.dispatch(action.store.setRecentCallInfo(null));
          // if (this.state.storeCustomer.storeCustomerId) {
          //   let params = {
          //     fk_storeId: this.props.store.store.storeId,
          //     fk_storeCustomerId: this.state.storeCustomer.storeCustomerId,
          //     order: 'reservedAt',
          //     orderMethod: 'desc',
          //   }
          //   this.props.dispatch(action.store_reservation.getList(params)).then((res) => {
          //     this.setState({
          //       storeReservation: res
          //     })
          //   })
          // }
        })
      })
    }
  }

  setStoreReservation() {
    if (this.state.storeCustomer.storeCustomerId) {
      let params = {
        fk_storeId: this.props.store.store.storeId,
        fk_storeCustomerId: this.state.storeCustomer.storeCustomerId,
        order: 'reservedAt',
        orderMethod: 'desc',
      }
      this.props.dispatch(action.store_reservation.getList(params)).then((res) => {
        this.setState({
          storeReservation: res
        })
      })
    }
  }

  showCallHistory() {
    this.setState({
      showCallHistory: !this.state.showCallHistory
    })
  }

  handleReservationFee(storeReservationId, reservationFeeStatus) {
    if (confirm('예약금을 환불 하시겠습니까?')) {
      let params = {
        storeReservationId: storeReservationId,
        reservationFeeStatus: reservationFeeStatus
      }
      this.props.dispatch(action.store_reservation.update(params)).then((res) => {
        this.getStoreCustomer();
      })
    }
  }

    /*
  * value: 변경된 값
  * field: 변경된 값의 이름
  * state: 변경시킬 state
  */
    handleReservationValues(value, field, state) {
        let newState = {};
        newState[state] = this.state[state];

        newState[state][field] = value;

        this.setState(newState);
    }


  render(){
    let vipStyle = {
      color: '#1e2028',
      border: '1px solid #7434cc'
    }

    let renderReservationRecords = this.state.storeReservation ? this.state.storeReservation.map((value, index) => {
      return (
        <ul key={index} className={styleReservationModal.recordsRow} onClick={() => this.props.openReservationModal(value.store_reservation.storeReservationId)}>
          <li>{dateUtil.format('YYYY-MM-DD', value.store_reservation.reservedAt)}</li>
          <li>{dateUtil.format('hh:mm', value.store_reservation.reservedAt)}</li>
          <li>{value.store_reservation.numberOfPeople}명</li>
          <li>
            {value.store_reservation.reservationFeeStatus == 1 ? <img className={styleReservationModal.repayIcon} src="/Assets/img/btn-repay.png"
                                                                      onClick={() => this.handleReservationFee(value.store_reservation.storeReservationId, 10)}/> : null}
            {value.store_reservation.reservationFeeStatus == 10 ? <img className={styleReservationModal.repayIcon} src="/Assets/img/btn-repay-done.png"/> : null}
          </li>
        </ul>
      )
    }) : null

    return(
      <div>
        {this.state.isReservationCompleted ? <div className={styleReservationModal.completedAlert}>예약이 저장되었습니다</div> : null}
        <div className={styleReservationModal.modalLeft}>
          <div className={styleReservationModal.visitNum}>
            방문횟수 <span>{ this.state.storeCustomer.visitAmount || 0 }</span>
          </div>
          <div className={styleReservationModal.noShow}>
            No Show <span>{ this.state.storeCustomer.noShowAmount || 0 }</span>
          </div>
          <div className={styleReservationModal.recentCall}>
            최근통화 { dateUtil.getFromNow(this.state.storeCustomer.calledAt)}
          </div>
          <div className={styleReservationModal.contactInfo}>
            <div className={styleReservationModal.phone}>
              <input type="text" placeholder="010-1234-1235" value={this.state.phoneNumberDash} readOnly={this.state.isAndroid}
                     onChange={(e) => this.handlePhoneNumberDash(e.target.value, 'phoneNumber', 'storeCustomer')} />
            </div>
            <div className={styleReservationModal.name}>
              <input id="customerName" type="text" placeholder="미입력" onChange={(e) => this.handleReservationValues(e.target.value, 'name', 'storeCustomer')} value={this.state.storeCustomer.name} style={this.state.storeCustomer.isVip ? {color: '#7434cc'} : null}/>
              {this.state.storeCustomer.name ?
                <div className={styleReservationModal.blackList} style={{backgroundColor: this.state.selectedColor[this.state.storeCustomer.type || 0].color, left: this.state.leftPosition + 'px'}}/>
                : null}
            </div>
          </div>
          <div>
            <ul className={styleReservationModal.stateBtn}>
              <li onClick={() => this.handleReservationValues(!this.state.storeCustomer.isVip, 'isVip', 'storeCustomer')} style={this.state.storeCustomer.isVip ? vipStyle : null}>VIP</li>
              <li onClick={() => {this.setState({showColors: !this.state.showColors, type: 0})}}>
                <img src={this.state.selectedColor[this.state.storeCustomer.type || 0].inactive} />
              </li>
            </ul>
            {this.state.showColors ?
              <div className={styleReservationModal.colorList}>
                {
                  Object.keys(this.state.selectedColor).map((key, index) => {
                    return this.state.storeCustomer.type != key ?
                      <li key={index} onClick={() => this.setState({ showColors: false }, () => {
                        this.handleReservationValues(key, 'type', 'storeCustomer')
                      })}>
                        <img src={this.state.selectedColor[key].active} />
                      </li> : null
                  })
                }
              </div>
              : null}
          </div>
          <div className={styleReservationModal.callHistoryBtn} onClick={this.showCallHistory}>통화 이력 조회</div>
        </div>
        {this.state.showCallHistory ? <CallHistoryScroll showCallHistory={this.showCallHistory} storeCallHistory={this.state.storeCallHistory} storeCustomer={this.state.storeCustomer || {}}/> : null}
        <div className={styleReservationModal.modalRight}>
          <div className={styleReservationModal.modalRightInner}>
            <div className={styleReservationModal.recordTitle}>예약현황</div>
            <ul className={styleReservationModal.recordsRow} style={{borderBottom: '1px solid #c2c8d1'}}>
              <li>날짜</li>
              <li>시간</li>
              <li>예약인원</li>
              <li>예약금</li>
            </ul>
            <div className={styleReservationModal.recordsRowBox}>
              {renderReservationRecords}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect((state) => {
  return {
    author: state.data.account.author,
    store: state.data.store.store,
    store_place: state.data.store_place.store_place,
    store_place_floor: state.data.store_place_floor.store_place_floor,
    selectedDate: state.data.store_reservation.selectedDate,
    recentCallInfo: state.data.store.recentCallInfo,
    reservationUpdated: state.data.store_reservation.reservationUpdated,
    selectedCustomerId: state.data.store_customer.selectedCustomerId,
    openModal: state.data.store.openModal,
  }
})(withRouter(CustomerModal))