import * as ActionTypes from '../actionTypes';

export const initialState = {
    product: {},
    product_list: []
}
/*
*/
export const product_reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.PRODUCT:
            return Object.assign({}, state, {
                product: action.product
            });
        case ActionTypes.PRODUCT_LIST:
            return Object.assign({}, state, {
                product_list: action.product_LIST
            });
        default:
            return state;
        }
}
