import React, {Component} from 'react';
import {withRouter, Route} from 'react-router-dom';
import {connect} from 'react-redux';
import moment from 'moment';
import ScrollArea from 'react-scrollbar';
import { withTranslation } from 'react-i18next';
import * as Const from '../../../../Data/const';

// Action
import { action } from "../../../../Data/index";

// styles
import styleWaitingList from '../../../Styles/Components/WaitingList.css'
import {dateUtil, addDashesToPhoneNumber} from "../../../../Utils";
import styleReservationModal from '@/Service/Styles/Components/ReservationModal.css'

class WaitingListDetail extends Component{
  constructor(props){
    super(props);
    this.state = {
        store: {},
        selectedPlace: {},
        selectedFloor: {},
        selectedDate: new Date(),

        storeReservation: [],
        storeReservationMenu: [],

        waitingStatus: 0,

        lastAlarmMilliseconds: new Date().getTime() - 3000,
        isAlarmHistoryOpened: false,
        alarmHistory: [],

        reservationMenus:[],
    }

    this.getStoreValues = this.getStoreValues.bind(this);
    this.getCurrentPlaceWaiting = this.getCurrentPlaceWaiting.bind(this);
    this.getWaitingTime = this.getWaitingTime.bind(this);

    this.handleAlarm = this.handleAlarm.bind(this);
    this.handleVisitStatus = this.handleVisitStatus.bind(this);
    this.handleWaitingStatus = this.handleWaitingStatus.bind(this);
    this.handleAlarmHistory = this.handleAlarmHistory.bind(this);
  }

  componentDidMount() {
    this.props.onRef(this);

    setInterval(this.getStoreValues, Const.intervalTime);
    this.getStoreValues();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location.search
     || prevProps.selectedDate !== this.props.selectedDate
     || prevProps.selectedTime !== this.props.selectedTime
    || (prevProps.reservationUpdated !== this.props.reservationUpdated)
    ) {
      this.getStoreValues();
    }
  }

  getStoreValues() {
    this.setState({
        store: this.props.store.store || {},
        selectedPlace: this.props.store_place || {},
        selectedFloor: this.props.store_place_floor || {},
        selectedDate: this.props.selectedDate || new Date(),
    }, () => {
        this.setState({
            timeSection: [
                {
                    index: 0,
                    name: '전체',
                    startTime: this.state.store.lunchStartTime ? this.state.store.lunchStartTime.substr(0,5) : '-',
                    endTime: this.state.store.dinnerEndTime ? this.state.store.dinnerEndTime.substr(0,5) : '-',
                    src: '/Assets/img/ic-all.png',
                    isChecked: true
                }, {
                    index: 1,
                    name: '점심',
                    startTime: this.state.store.lunchStartTime ? this.state.store.lunchStartTime.substr(0,5) : '-',
                    endTime: this.state.store.lunchEndTime ? this.state.store.lunchEndTime.substr(0,5) : '-',
                    src: '/Assets/img/sun.png',
                    isChecked: false
                }, {
                    index: 2,
                    name: '저녁',
                    startTime: this.state.store.dinnerStartTime ? this.state.store.dinnerStartTime.substr(0,5) : '-',
                    endTime: this.state.store.dinnerEndTime ? this.state.store.dinnerEndTime.substr(0,5) : '-',
                    src: '/Assets/img/sun.png',
                    isChecked: false
                }
            ],
        }, () => {
            let params = {
                fk_storeId: this.state.store.storeId,
                fk_storePlaceId: this.state.selectedPlace.storePlaceId,
                //fk_storePlaceFloorId: this.state.selectedFloor.storePlaceFloorId,
                reservationStartTime: new Date(dateUtil.format('dash', this.state.selectedDate)
                    + ' ' + (this.props.selectedTime === 0 ? '00:00:00' : this.state.timeSection[this.props.selectedTime].startTime)),
                reservationEndTime: new Date(dateUtil.format('dash', this.state.selectedDate)
                    + ' ' + (this.props.selectedTime === 0 ? '24:00:00'
                        : dateUtil.isDayBreak(this.state.timeSection[this.props.selectedTime].endTime))), // 전체일때는 00:00:00 ~ 24:00:00
                reservationStatus: [1,2],
                order: 'reservedAt',
                orderMethod: 'asc',
                count: 100,
                waitingStatus: this.state.waitingStatus != 40 ? this.state.waitingStatus : null,
            }

            this.props.dispatch(action.store_reservation.getList(params)).then((res) => {
                this.setState({
                    storeReservation: res
                }, () => {
                  this.setState({
                    storeReservation: res.length > 0 ? res.map((value) => {
                      value.isAlarmHistoryOpened = false;
                      return value;
                    }) : [],
                  })
                })
            });
        })
    })
  }

  getCurrentPlaceWaiting(data, field) {
      let result = null;
      if (!data.store_reservation_place) {
          return;
      }

      const storeReservationPlace = data.store_reservation_place;

      storeReservationPlace.forEach((item) => {
          if (item.fk_storePlaceId === this.state.selectedPlace.storePlaceId) {
              result = item[field];
          }
      });
      return result;
  }

  getWaitingTime(reservation) {

  }

  handleAlarm(item, index) {

    if(this.state.lastAlarmMilliseconds + 3000 > new Date().getTime()) {
      // Todo Notifications..  >> this.props.handleChangingStatus('호출 하였습니다.');
      return;
    }

    const currentStorePlace = (item.store_reservation_place || []).find((item) => {
        return item.fk_storePlaceId === this.state.selectedPlace.storePlaceId
    });

    if (!currentStorePlace) return;
    this.props.dispatch(action.log_store_alarm_history.create({
        fk_storeReservationId: item.store_reservation.storeReservationId,
        fk_storeReservationPlaceId: currentStorePlace.storeReservationPlaceId,
        fk_storeCustomerId: item.store_reservation.fk_storeCustomerId,
        fk_storeId: item.store_reservation.fk_storeId,
        fk_storePlaceId: this.state.selectedPlace.storePlaceId
    })).then((res) => {
        const storeReservation = this.state.storeReservation;
        const placeIndex = storeReservation[index].store_reservation_place.findIndex((item) => {
            return item.storeReservationPlaceId === currentStorePlace.storeReservationPlaceId;
        })
        storeReservation[index].store_reservation_place[placeIndex].alarmAmount += 1;
        this.setState({
            storeReservation: storeReservation,
            lastAlarmMilliseconds: new Date().getTime(),
        })
    })

    // fullScreen 해제
    //document.exitFullscreen();

    this.props.handleChangingStatus('호출 하였습니다.');
  }

  handleVisitStatus(item, status) {
      if (!this.props.reservationConst.RESERVATION_VISIT_STATUS[status] || !item.store_reservation || !item.store_reservation_place) return;

          if (item.store_reservation.visitStatus === status) { return; }
          const params = {
              storeReservationId: item.store_reservation.storeReservationId,
              fk_storeId: this.state.store.storeId,
              visitStatus: status,
          }

          //document.exitFullscreen();

          this.props.handleChangingStatus(this.props.reservationConst.RESERVATION_WAITING_STATUS[status] + '상태로 변경하였습니다.');
          setTimeout(() => {
            this.props.dispatch(action.store_reservation.update(params)).then((res) => {
              this.props.dispatch(action.store_reservation.reservationUpdated(new Date()));
            })
          }, 3000);
  }

  handleWaitingStatus(item, status) {
      if (!this.props.reservationConst.RESERVATION_WAITING_STATUS[status] || !item.store_reservation || !item.store_reservation_place) return;
          const params = {
              fk_storeReservationId: item.store_reservation.storeReservationId,
              fk_storeId: this.state.store.storeId,
              waitingStatus: status,
          }

          const selectedReservationPlace = item.store_reservation_place.filter((item) => {
              return item.fk_storePlaceId === this.state.selectedPlace.storePlaceId;});
          if (selectedReservationPlace.length === 0) return;

          params['fk_storePlaceId'] = selectedReservationPlace[0].fk_storePlaceId;
          params['storeReservationPlaceId'] = selectedReservationPlace[0].storeReservationPlaceId;

          //document.exitFullscreen();

        this.props.handleChangingStatus(this.props.reservationConst.RESERVATION_WAITING_STATUS[status] + '상태로 변경하였습니다.');
        setTimeout(() => {
          this.props.dispatch(action.store_reservation_place.update(params)).then((res) => {
            this.props.dispatch(action.store_reservation.reservationUpdated(new Date()));
          })
        }, 50);
  }

  handleAlarmHistory(item, index) {
    let storeReservation = this.state.storeReservation
    storeReservation[index].isAlarmHistoryOpened = !storeReservation[index].isAlarmHistoryOpened
    storeReservation.map((item, index2) => {
      if (index2 !== index) return item.isAlarmHistoryOpened = false
    })

    this.setState({
      storeReservation: storeReservation
    }, () => {
      const currentStorePlace = item.store_reservation_place.filter((item) => {
        return item.fk_storePlaceId === this.state.selectedPlace.storePlaceId
      });

      let params = {
        fk_storeReservationId: item.store_reservation.storeReservationId,
        fk_storeReservationPlaceId: currentStorePlace[0].storeReservationPlaceId,
        fk_storeCustomerId: item.store_customer.storeCustomerId,
        fk_storeId: item.store.storeId,
        fk_storePlaceId: item.store_place.storePlaceId,
      }

      this.props.dispatch(action.log_store_alarm_history.getList(params)).then((res) => {
        this.setState({
          alarmHistory: res
        })
      })
    })

    this.props.handleOverflow();
  }

  render(){
    const { t, i18n } = this.props;

    let renderAlarmHistoryList = this.state.alarmHistory.map((history, index) => {
      return (
        <li key={index}>{moment(history.log_store_alarm_history.createdAt).format('a hh:mm')}</li>
      )
    })

    let renderTimeByWaitingStatus = (data) => {
        let string = null;
        const currentStorePlace = (data.store_reservation_place || []).find((item) => {
            return item.fk_storePlaceId === this.state.selectedPlace.storePlaceId;
        });

        const admitStorePlace = (data.store_reservation_place || []).find((item) => {
            return item.waitingStatus === 1;
        }) || {};

        if (currentStorePlace.waitingStatus === 0 || (this.state.waitingStatus == 40 && currentStorePlace.waitingStatus == 0)) {
            string = dateUtil.getTimeLeft(currentStorePlace.createdAt) + ' ' + t('waiting');
        } else if (currentStorePlace.waitingStatus === 1 || (this.state.waitingStatus == 40 && currentStorePlace.waitingStatus == 1)) {
            string = dateUtil.format('a hh:mm', currentStorePlace.admitAt) + ' ' + t('entered') +' (' + dateUtil.getTimeLeft(currentStorePlace.createdAt, currentStorePlace.admitAt) + ' '+ t('waited') + ')'
        } else if (currentStorePlace.waitingStatus === 10 || (this.state.waitingStatus == 40 && currentStorePlace.waitingStatus == 10)) {
            string = dateUtil.format('a hh:mm', currentStorePlace.notComeAt) + ' ' + t('not_shown')
        } else if (currentStorePlace.waitingStatus === 20 || (this.state.waitingStatus == 40 && currentStorePlace.waitingStatus == 20)) {
          string = dateUtil.format('a hh:mm', currentStorePlace.cancelledAt) + ' ' + t('cancelled')
        } else if (currentStorePlace.waitingStatus === 30 || (this.state.waitingStatus == 40 && currentStorePlace.waitingStatus == 30)) {
            string = dateUtil.getTimeLeft(currentStorePlace.createdAt, admitStorePlace.admitAt) + ' ' + t('waited') + ' (' + dateUtil.format('a hh:mm', currentStorePlace.deletedAt) + ' ' + t('not_shown') + ')'
        }
        return (
            <div className={styleWaitingList.waitingTime}>
                {string}
            </div>
        )
    }

    let renderWaitingList = this.state.storeReservation ? this.state.storeReservation.map((item, index) => {
      const currentStorePlace = item.store_reservation_place.find((value) => {
        return value.fk_storePlaceId === this.state.selectedPlace.storePlaceId;
      });
      return(
        <li key={index} className={styleWaitingList.list}>
          {item.isAlarmHistoryOpened ?
                <ul className={styleWaitingList.alarmHistory}>
                  <li>{t('alarm_history')}</li>
                  {renderAlarmHistoryList}
                </ul>
            : null}
          <div className={styleWaitingList.waitingInfo} style={{fontSize: this.props.screenFontSize + 'px'}}>
            <div className={styleWaitingList.topInfo}>
                <div className={styleWaitingList.title}>
                    <div className={styleWaitingList.numberBox}><p className={styleWaitingList.number}>{this.getCurrentPlaceWaiting(item, 'waitingNumber')}</p></div>
                    <div className={styleWaitingList.waitingName}>{this.props.store.store_system.isName ? item.store_customer.name : t('customer')}</div>
                    <div className={styleWaitingList.waitingNum}>{item.store_reservation.numberOfPeople} 명</div>
                </div>
              <div className={styleWaitingList.namePhoneTime}>
                <div className={styleWaitingList.waitingPhone}>{addDashesToPhoneNumber(item.store_customer.phoneNumber)}</div>
                  {renderTimeByWaitingStatus(item)}
                </div>
            </div>
            <div className={styleWaitingList.extraInfo}>
                {
                    item.store_reservation.babySeatAmount ?
                        <div className={styleWaitingList.babyChair}>
                            <div className={styleWaitingList.babyChairIcon}>
                                <img src="/Assets/img/ic-babychair.png"/>
                                <span className={styleWaitingList.babyChairText}>{t('baby_chair')}</span>
                            </div>
                            <div className={styleWaitingList.babyChairNum}>{item.store_reservation.babySeatAmount}</div>
                        </div>
                        : null
                }
                {
                    item.store_reservation_menu && item.store_reservation_menu.length > 0 ?
                        <div className={styleWaitingList.memo}>
                            {item.store_reservation_menu.map((item) => {
                                return item.menu;
                            }).join(',')}
                        </div> : null
                }
            </div>
          </div>
          {this.state.waitingStatus == 0 || (this.state.waitingStatus == 40 && currentStorePlace.waitingStatus == 0)?
          <ul className={styleWaitingList.waitingStatus}>
            <li className={item.isAlarmHistoryOpened ? styleWaitingList.active : ''} onClick={() => this.handleAlarmHistory(item, index)}>{t('alarm_history')}</li>
            <li onClick={() => this.handleAlarm(item, index)}>{t('alarm')} <span>{currentStorePlace.alarmAmount || 0}</span></li>
            <li onClick={() => this.handleWaitingStatus(item, 1)}>{t('entered')}</li>
            <li onClick={() => this.handleWaitingStatus(item, 10)}>{t('not_shown')}</li>
          </ul>: null}
          {this.state.waitingStatus == 1 || (this.state.waitingStatus == 40 && currentStorePlace.waitingStatus == 1) ?
            <ul className={styleWaitingList.waitingStatus}>
              <li className={item.isAlarmHistoryOpened ? styleWaitingList.active : ''} onClick={() => this.handleAlarmHistory(item, index)}>{t('alarm_history')}</li>
              <li onClick={() => this.handleVisitStatus(item, 10)}>{t('leave')}</li>
              <li onClick={() => this.handleWaitingStatus(item, 20)}>{t('cancel_enter')}</li>
              <li onClick={() => this.handleWaitingStatus(item, 30)}>{t('remove')}</li>
            </ul>: null}
          {(this.state.waitingStatus == 10 || (this.state.waitingStatus == 40 && currentStorePlace.waitingStatus == 10)) ?
            <ul className={styleWaitingList.waitingStatus}>
              <li className={item.isAlarmHistoryOpened ? styleWaitingList.active : ''} onClick={() => this.handleAlarmHistory(item, index)}>{t('alarm_history')}</li>
              <li></li>
              <li onClick={() => this.handleWaitingStatus(item, 0)}>{t('restore_wait')}</li>
              <li onClick={() => this.handleWaitingStatus(item, 30)}>{t('remove')}</li>
            </ul>: null}
          {(this.state.waitingStatus == 20 || (this.state.waitingStatus == 40 && currentStorePlace.waitingStatus == 20)) ?
            <ul className={styleWaitingList.waitingStatus}>
              <li className={item.isAlarmHistoryOpened ? styleWaitingList.active : ''} onClick={() => this.handleAlarmHistory(item, index)}>{t('alarm_history')}</li>
              <li></li>
              <li onClick={() => this.handleWaitingStatus(item, 0)}>{t('restore_wait')}</li>
              <li onClick={() => this.handleWaitingStatus(item, 30)}>{t('remove')}</li>
            </ul>: null}
          {this.state.waitingStatus == 30 || (this.state.waitingStatus == 40 && currentStorePlace.waitingStatus == 30) ?
            <ul className={styleWaitingList.waitingStatus}>
              <li className={item.isAlarmHistoryOpened ? styleWaitingList.active : ''} onClick={() => this.handleAlarmHistory(item, index)}>{t('alarm_history')}</li>
              <li></li>
              <li></li>
              <li></li>
            </ul>: null}
        </li>
      )
    }) : null

    return (
      <ScrollArea
        speed={0.8}
        className={styleWaitingList.waitingList}
      >
        {renderWaitingList}

      </ScrollArea>
    )
  }
}

export default connect((state) => {
    return {
        author: state.data.account.author,
        store: state.data.store.store,
        store_place: state.data.store_place.store_place,
        store_place_floor: state.data.store_place_floor.store_place_floor,
        selectedDate: state.data.store_reservation.selectedDate,
        selectedTime: state.data.store_reservation.selectedTime,
        reservationUpdated: state.data.store_reservation.reservationUpdated,
    }
})(withRouter(withTranslation()(WaitingListDetail)))
