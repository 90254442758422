import React, {Component} from 'react';
import {withRouter, Route, Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {Helmet} from "react-helmet";

// styles
import styleMobile from '../../Styles/Components/Mobile.css'
import styleSearch from '../../Styles/Components/Search.css'
import { action } from '../../../Data'

import { parseUrlParameter } from '../../../Utils'
import { withTranslation } from 'react-i18next'

class SearchView extends Component{
  constructor(props){
    super(props);

    this.state = {
      searchQuery: '',
      searchedStores: [],
    }

    this.searchStores = this.searchStores.bind(this)
    this.handleGoPage = this.handleGoPage.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
    this.closeSearch = this.closeSearch.bind(this)
  }

  componentDidMount() {
    this.props.dispatch(action.service_system.getServiceSystem()).then((res) => {
      this.setState({
        serviceSystem: res[0]
      })
    })
  }

  closeSearch() {
    this.props.history.goBack();
  }

  handleGoPage(store) {
      this.props.dispatch(action.store.getById({
        storeId: store.store.storeId,
      })).then((res) => {
        let queries = {
          storeId: store.store.storeId
        }
          this.props.dispatch(action.store.setStore(res));
          this.props.history.push('/mobile' + parseUrlParameter.setQueryString(queries, this.props.location.search));
        }).catch((err) => {
          console.log('검색 에러', err);
          alert('매장 정보를 불러올 수 없습니다.');
          window.location.href = window.location.origin + '/mobile/search'
        });
  }

  searchStores() {
    this.props.dispatch(action.store.getList({brandName: this.state.searchQuery})).then((res) => {
      if (!res.length) {
        this.props.dispatch(action.store.getList({name: this.state.searchQuery})).then((res) => {
          this.setState({
            searchedStores: res
          })
        })
      } else {
        this.setState({
          searchedStores: res
        })
      }
    })
  }

  onKeyPress(e) {
    if (e.key === 'Enter') {
      this.searchStores();
    }
  };

  render(){
    const { t, i18n } = this.props;

    let renderSearchResult = this.state.searchedStores ? this.state.searchedStores.map((store, index) => {
      return (
        <li key={index} onClick={() => this.handleGoPage(store)}>
          <div className={styleSearch.storeName}>{i18n.language === 'ko' ? (store.brand.name || store.brand.nameEn) : (store.brand.nameEn || store.brand.name)}</div>
          <div>{i18n.language === 'ko' ? (store.store.name || store.store.nameEn) : (store.store.nameEn || store.store.name)}</div>
        </li>
      )
    }) : <div className={styleSearch.noResult}>{t('no_results')}</div>

    return (
      <div className={styleMobile.mobileContainer}>
        <Helmet
          title={'모바일 매장검색'}
        />
        <div className={styleMobile.mobileContent + ' ' + styleSearch.searchContent}>
          <div className={styleMobile.bgLogoBox}>
            {/*<img className={styleMobile.bgLogoImg} src={this.state.serviceSystem ? this.state.serviceSystem.service_system.mainLogoUrl : "/Assets/img/km-logo-2-56-px.png"}/>*/}
            <img src="/Assets/img/km-logo-white.png"/>
          </div>
          <div className={styleSearch.search}>{t('search')}</div>
          <div className={styleSearch.deleteIcon} onClick={this.closeSearch}><img src="/Assets/img/ic-delete.png"/></div>
          <div className={styleSearch.searchArea}>
            <div className={styleSearch.searchInput}>
              <input type="text" placeholder={t('search')} value={this.state.searchQuery} onKeyPress={this.onKeyPress} onChange={(e) => this.setState({ searchQuery: e.target.value })}/>
            </div>
            <div className={styleSearch.searchIcon} onClick={this.searchStores}><img src="/Assets/img/ic-search-gray.png"/></div>
          </div>
          <div className={styleSearch.resultArea}>
            {this.state.searchedStores.length > 0 ? <ul className={styleSearch.storeList}>{renderSearchResult}</ul> : <div className={styleSearch.noResult}>{t('no_results')}</div>}
          </div>
        </div>
      </div>
    )
  }
}

export default connect((state) => {
    return {
        store: state.data.store.store,
    }
})(withRouter(withTranslation()(SearchView)));