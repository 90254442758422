
export const getByteLength = (str) => {
    if (!str) return;

    var l= 0;

    for(var idx=0; idx < str.length; idx++) {
        var c = escape(str.charAt(idx));

        if( c.length==1 ) l ++;
        else if( c.indexOf("%u")!=-1 ) l += 2;
        else if( c.indexOf("%")!=-1 ) l += c.length/3;
    }

    return l;
}

export const isJson = (str) => {
    try {
        let o = JSON.parse(JSON.stringify(str))

        if (o && typeof o === 'object') {
            return true;
        } else {
            return false;
        }
    } catch (e) {
        return false;
    }
}


export const isJsonString = (str) => {
    try {
        let o = JSON.parse(str)

        if (o && typeof o === 'object') {
            return true;
        } else {
            return false;
        }
    } catch (e) {
        return false;
    }
}

export const sensorName = (str) => {
    try {
        let name = [...str.trim()];

        return name.length > 1 ? name.map((item, index) => {
            if (index < 1 || (name.length === 2 && index !== 1) || (name.length > 2 && index === name.length - 1)) {
                return item;
            } else {
                return '*'
            }
        }).join('') : name;


    } catch (e) {
        return '';
    }
}