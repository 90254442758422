import * as ActionTypes from '../actionTypes';

export const initialState = {
    board_like: {},
    board_like_list: []
}
/*
*/
export const board_like_reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.BOARD_LIKE:
            return Object.assign({}, state, {
                board_like: action.board_like
            });
        case ActionTypes.BOARD_LIKE_LIST:
            return Object.assign({}, state, {
                board_like_list: action.board_like_LIST
            });
        default:
            return state;
        }
}
