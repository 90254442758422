import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {Helmet} from 'react-helmet'
import update from 'immutability-helper';
import axios from 'axios';

class DemoView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            phoneNumber: "01020388914",
            message: "테스트 메세지"
        };

        this.inputValueChange = this.inputValueChange.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
    }

    componentDidMount() {

    }
    sendMessage(){
        let data = {
            phoneNumber: this.state.phoneNumber,
            message: this.state.message
        };
        axios.get('http://localhost:8080/service-sms/send',
            {
                params: data
        }).then((res) => {
            if(res.data.result === "200"){
                alert("발송 성공");
            }else{
                alert("발송 실패");
            }
        }).catch( (e) => {
            console.log(e)
            alert("발송 실패");
        })
    }

    inputValueChange(e) {
        let targetKey = e.target.name;
        this.setState({
            [targetKey] :  e.target.value
        })
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>기본 정보</title>
                </Helmet>
                <div>
                    <div>
                        전화번호 : <input type={"text"} name={"phoneNumber"} value={this.state.phoneNumber}
                                      onChange={(e) => this.inputValueChange(e)}
                    />
                    </div>
                    <div>
                        내 용 : <textarea rows={2} name={"message"} value={this.state.message}
                                        onChange={(e) => this.inputValueChange(e)}
                    />
                    </div>
                    <button onClick={this.sendMessage}>문자 보내기</button>
                </div>
            </div>
        )
    }
}

export default connect((state) => {
    return {
        author: state.data.account.author,
        store: state.data.store.store,
    };
})(withRouter(DemoView));
