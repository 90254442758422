import React, {useState, useEffect, forwardRef, useRef, useMemo, Fragment, createRef} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';


// styles
import styleStore from '../../../../Styles/Components/Store.css'
import styleTableManagement from '../../../../Styles/Components/TableManagement.css'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPlus} from '@fortawesome/free-solid-svg-icons'
import _ from 'underscore';
import empty from 'just-is-empty';
import Places from './Components/Places';
import Setting from './Components/Setting';
import Tables from './Components/Tables';

// action
import { action } from '@/Data';

// Utils
import { string } from '@/Utils';
import styleReservationModal from '@/Service/Styles/Components/ReservationModal.css'

let tableDataSet = [];

/*let tableDataItemScheme = {
    storePlaceId: -1,
    storePlaceFloorId: -1,
    storeTableId: -1,
    tableInfo: {
        type: "rect",
        config: {
            width: 48,
            height: 48,
            x: 6,
            y: 6,
            stroke: '#98a0ab',
            strokeWidth: 1,
            strokeDasharray: "",
            fill : "#fff"
        },
        position: {
            x: 0,
            y: 0
        }
    }
};*/


let outsideTableAreaClickListener;

const TableManagement = (props, ref) => {
    const tableRef = useRef();
    const settingRef = useRef();
    const [store, setStore] = useState(props.store.store)
	const [editValue, setEditMode] = useState({
		index : -1,
		floorTableData: []
	});
	const [resizeValue, setResizeMode] = useState({
		index : -1,
		floorTableData: []
	});
    const [selectFloor, setSelectFloor] = useState({
        storePlaceId: -1,
        storePlaceFloorId: -1
    });
    const [setting, setSetting] = useState({
        shapeType: 0,
        lineType: 1,
        lineColor: '#98a0ab',
        backColor: '#FFFFFF',
        tableTitleNo: "",
        tableInfo: "",
        width:165,
        height:165,
	    x: 250,
	    y: 250,
        storeTableId: -1
    });

    // 알림 팝업
    const [alert, setAlert] = useState({
      alertText: '',
      isPositive: true,
      isStatusChanging: false,
    })

    let handleChangingStatus = (text, bool) => {
      setAlert({
        alertText: text,
        isPositive: bool,
        isStatusChanging: true,
      })

      setTimeout(() => {
        setAlert({
          isStatusChanging: false,
        })
      }, bool ? 1000 : 3000);
    }

    useEffect(() => {
        document.removeEventListener('click', outsideTableAreaClickListener);
        hideOnTableAreaClickOutside(`.${styleTableManagement.tableItemWrapper}, .${styleTableManagement.tableItem}, .${styleTableManagement.setting}, .${styleTableManagement.addTables}, .${styleTableManagement.selectBox}`, `.${styleTableManagement.setting}`);
    });

    let hideOnTableAreaClickOutside = (selector, targetSelector) => {
        outsideTableAreaClickListener = (event) => {
            console.warn("$(event.target).closest(selector).length >>", $(event.target).closest(selector).length);

            if($(event.target).closest(selector).length === 0
                && $($(event)[0].srcElement).next(".sketch-picker").length === 0
            ) {
	            setEditMode({
		            index: -1,
		            floorTableData: []
	            });
	            setResizeMode({
		            index: -1,
		            floorTableData: []
	            });
            }
        };
        document.addEventListener('click', outsideTableAreaClickListener)
    };
    // 테이블 리스트
    const [tableData, setTableDataValue] = useState({
        tableDataList : tableDataSet,
        floorTableData: []
    });

    let editTableMode = () => {
        if(selectFloor.storePlaceId > -1 && selectFloor.storePlaceFloorId > -1){
            setEditMode({
                index: null,
                floorTableData: []
            });
            setSetting({
                shapeType: 0,
                lineType: 1,
                lineColor: '#98a0ab',
                backColor: '#FFFFFF',
                tableTitleNo: "",
                tableInfo: "",
                width:165,
                height:165,
	            x: 250,
	            y: 250,
                storeTableId: -1
            });
        }else{
          handleChangingStatus('층을 선택하세요.', false);
        }
    };
	let openTableShape = (storeTableId, floorTableData) => {
		if(storeTableId > -1){
			setEditMode({
				index: storeTableId,
				floorTableData: floorTableData
			});
			let findItem = _.find(floorTableData, (item) => {
				return item.storePlaceId === selectFloor.storePlaceId
					&& item.storePlaceFloorId === selectFloor.storePlaceFloorId
					&& item.storeTableId === storeTableId
			});
			let settingItem = {
				shapeType: findItem.tableInfo.shapeType,
				lineType: findItem.tableInfo.lineType,
				lineColor: findItem.tableInfo.lineColor,
				backColor: findItem.tableInfo.backColor,
				tableTitleNo: findItem.tableInfo.tableTitleNo,
				tableInfo: findItem.tableInfo.tableInfo,
				width:findItem.tableInfo.width,
				height:findItem.tableInfo.height,
				x: findItem.tableInfo.x,
				y: findItem.tableInfo.y,
				storeTableId: findItem.storeTableId
			};
			setSetting(settingItem);

			//TODO : Store 설정 정보 저장 처리 필요(Setting.jsx 데이터 넘김 처리)
			console.warn("openTableShape >> ", floorTableData)
		}
	};
    let selectTableShape = (storeTableId, floorTableData) => {
        if(storeTableId > -1){
	        setResizeMode({
		        index: storeTableId,
		        floorTableData: floorTableData
            })
            let findItem = _.find(floorTableData, (item) => {
                return item.storePlaceId === selectFloor.storePlaceId
                && item.storePlaceFloorId === selectFloor.storePlaceFloorId
                && item.storeTableId === storeTableId
            });
            let settingItem = {
                shapeType: findItem.tableInfo.shapeType,
                lineType: findItem.tableInfo.lineType,
                lineColor: findItem.tableInfo.lineColor,
                backColor: findItem.tableInfo.backColor,
                tableTitleNo: findItem.tableInfo.tableTitleNo,
                tableInfo: findItem.tableInfo.tableInfo,
                width:findItem.tableInfo.width,
                height:findItem.tableInfo.height,
	            x: findItem.tableInfo.x,
	            y: findItem.tableInfo.y,
                storeTableId: findItem.storeTableId
            };
            setSetting(settingItem);

            //TODO : Store 설정 정보 저장 처리 필요(Setting.jsx 데이터 넘김 처리)
            console.warn("selectTableShape >> ", floorTableData)
        }
    };
    // Place.jsx에서 층을 선택했을때 selectData를 넘겨받는 함수
    let setSelectFloorHandler = (selectFloorData) => {
        setSelectFloor(selectFloorData);
        props.dispatch(action.store_table.getList({
            count: 100,
            fk_storeId: store.storeId,
            fk_storePlaceId: selectFloorData.storePlaceId,
            fk_storePlaceFloorId: selectFloorData.storePlaceFloorId,
        })).then((result) => {
            const storeTableList = result.map((item) => {
                const storeTable = parsingTableData(item.store_table, 'get');
                return storeTable;
            });
            console.log("setSelectFloorHandler RESULT: ", storeTableList);
            setTableDataValue({
                tableDataList:storeTableList,
                floorTableData: updateSelectFloorData(storeTableList, selectFloorData)
            });
        });
    };

    let updateSelectFloorData = (tableData, selectFloor) => {
        let returnValue = [];
        let findFloorData = _.filter(tableData, (item) => {
            return item.storePlaceId === selectFloor.storePlaceId && item.storePlaceFloorId === selectFloor.storePlaceFloorId;
        });
        if(!empty(findFloorData)){
            returnValue = [].concat(findFloorData);
        }
        return returnValue;
    };

    let calcLineType = (lineType) => {
        let returnValue = {
            strokeDasharray: "",
            strokeWidth: ""
        };
        if(lineType === 1){
            returnValue.strokeDasharray = "";
            returnValue.strokeWidth = "1";
        }else if(lineType === 2){
            returnValue.strokeDasharray = "4";
            returnValue.strokeWidth = "1";
        }else if(lineType === 3){
            returnValue.strokeDasharray = "6 4";
            returnValue.strokeWidth = "2";
        }else{
            returnValue.strokeDasharray = "";
            returnValue.strokeWidth = "1";
        }
        return returnValue;
    };
    let setTablePosition = (position, tableInfo) => {
        console.warn("setTablePosition >> ", position, tableInfo);
        let tableData_ = JSON.parse(JSON.stringify(tableData.tableDataList));
        let findIndex = _.findIndex(tableData_, (item) => {
            return item.storePlaceFloorId ===  tableInfo.storePlaceFloorId && item.storePlaceId === tableInfo.storePlaceId
                && item.storeTableId === tableInfo.storeTableId;
        });
        let findPositionIndex = _.findIndex(position, (item) => {
            return item.storePlaceFloorId ===  tableInfo.storePlaceFloorId && item.storePlaceId === tableInfo.storePlaceId
            && item.storeTableId === tableInfo.storeTableId;
        })
        console.log('포지션 업데이트합니다.', findIndex, findPositionIndex);
        if(findIndex > -1 && findPositionIndex > -1){
            tableData_[findIndex].tableInfo = position[findPositionIndex].tableInfo
        }
        setTableDataValue({
            tableDataList: tableData_,
            floorTableData: updateSelectFloorData(tableData_, selectFloor)
        });
    };
    // Setting.jsx에서 table data를 받아서 table.jsx에 렌더링
    let saveTableData = (saveDataSet) => {
	    console.log("saveTableData > ", saveDataSet);
        let calcLineTypeResult = calcLineType(saveDataSet.lineType);
        let config  = saveDataSet.shapeData.config;
        config.fill = `${!saveDataSet.backColor.includes("#") ? '#' : ''}${saveDataSet.backColor}`;
        config.strokeDasharray = calcLineTypeResult.strokeDasharray;
        config.strokeWidth = calcLineTypeResult.strokeWidth;
        let saveTableData = {
            storePlaceId: selectFloor.storePlaceId,
            storePlaceFloorId: selectFloor.storePlaceFloorId,
            tableNo : saveDataSet.tableTitleNo,
            tableInfo : {
                type: saveDataSet.shapeData.type,
                shapeType:saveDataSet.shapeType,
                lineType: saveDataSet.lineType,
                lineColor: saveDataSet.lineColor,
                backColor: saveDataSet.backColor,
                tableTitleNo: saveDataSet.tableTitleNo,
                tableInfo: saveDataSet.tableInfo,
                config: config,
                width:saveDataSet.width,
                height:saveDataSet.height,
                x : saveDataSet.x,
                y : saveDataSet.y,
            }
        };
        if (saveDataSet.storeTableId) {
	        saveTableData.storeTableId = saveDataSet.storeTableId;
        }
	    sendToDB(saveTableData).then((result) => {
	    	setTimeout(() => {
          handleChangingStatus('저장되었습니다.', true);
		    }, 100);
        });
    };
    // 테이블 정보 저장 처리
    let sendToDB = (saveTableData) => {
        return new Promise((resolve, reject) => {
            //저장 처리 부분
	        const params = parsingTableData(saveTableData, 'set');
	        let tableData_ = JSON.parse(JSON.stringify(tableData.tableDataList));
	        let findIndex = -1;
            let funcName = null;
            params.fk_storeId = store.storeId;
            if (params.storeTableId > -1) {
                funcName = action.store_table.update(params);
	            findIndex = _.findIndex(tableData_, (item) => {
		            return item.storeTableId === params.storeTableId;
	            });
            } else {
                funcName = action.store_table.create(params);
            }
            props.dispatch(funcName)
                .then((result) => {
                    const parsed = parsingTableData(result, 'get');
					if (findIndex > -1){
		                tableData_[findIndex] = parsed
	                } else {
		                tableData_.push(parsed);
	                }
	                setTableDataValue({
		                tableDataList: tableData_,
		                floorTableData: updateSelectFloorData(tableData_, selectFloor)
	                });
	                console.log("sendToDB result: ", parsed);
                    resolve(parsed);
                }).catch((err) => {
                	console.log(err);
                    reject(err);
                });
        });
    };
    let deleteTableData = (storeTableId) => {
	    return new Promise((resolve, reject) => {
		    let tableData_ = JSON.parse(JSON.stringify(tableData.tableDataList));
		    let findIndex = _.findIndex(tableData_, (item) => {
			    return item.storeTableId === storeTableId;
		    });
		    props.dispatch(action.store_table.remove({ storeTableId: storeTableId }))
			    .then((result) => {
				    tableData_.splice(findIndex, 1);
				    setTableDataValue({
					    tableDataList: tableData_,
					    floorTableData: updateSelectFloorData(tableData_, selectFloor)
				    });
				    setTimeout(() => {
              handleChangingStatus('삭제하였습니다.', true);
				    }, 100);
				    resolve(true);
			    }).catch((err) => {
			    console.log(err);
			    reject(err);
		    });
	    });
    }

    const parsingTableData = (tableData, mode) => {
        const params = {};
        let parsingMap = null;
        parsingMap = {
            "storeTableId" : "storeTableId",
            "fk_storeId" : "fk_storeId",
            "tableNo" : "tableNo",
            "tableInfo" : "tableInfo"
        }
	    console.log("=============");
	    console.log(tableData);
        if (mode === 'get') { // db의 데이터를 TableManagement/index.jsx에서 쓸 수 있게 파싱
            parsingMap.fk_storePlaceId = "storePlaceId";
            parsingMap.fk_storePlaceFloorId = "storePlaceFloorId";
            if (tableData.tableInfo && string.isJsonString(tableData.tableInfo)) {
                tableData.tableInfo = JSON.parse(tableData.tableInfo);
            }
        } else if (mode === 'set') { // TableManagement/index.jsx에서 쓰이는 데이터를 db의 데이터 저장 형태로 파싱
            parsingMap.storePlaceId = "fk_storePlaceId";
            parsingMap.storePlaceFloorId = "fk_storePlaceFloorId";
            if (tableData.tableInfo && string.isJson(tableData.tableInfo)) {
                tableData.tableInfo = JSON.stringify(tableData.tableInfo);
            }
        }
        Object.keys(tableData).forEach((key) => {
            if (parsingMap[key]) {
                params[parsingMap[key]] = tableData[key];
            }
        });
        return params;
    }

    return (
        <div className={styleStore.homeContainer}>
          {alert.isStatusChanging && !alert.isPositive ? <div className={styleReservationModal.completedAlert} style={{top: '40%', backgroundColor: '#F08080'}}>{alert.alertText}</div> : null}
          {alert.isStatusChanging && alert.isPositive ? <div className={styleReservationModal.completedAlert} style={{top: '40%'}}>{alert.alertText}</div> : null}
            <div className={styleTableManagement.tableManagementContainer}>
                <div className={styleTableManagement.placeFloorNav}>
                    <Places
                        setSelectFloorHandler={setSelectFloorHandler}/>
                </div>
                <div className={styleTableManagement.tableContent}>
                    <Tables
	                    resizeValue={resizeValue}
                        floorTableData={tableData.floorTableData}
	                    selectTableShape={selectTableShape}
	                    openTableShape={openTableShape}
                        setTablePosition={setTablePosition}
                        sendToDB={sendToDB}
                        ref={tableRef}
                    />

                    <div style={{display: editValue.index !== -1 ?"" :"none"}}>
                        <Setting
                            editValue={editValue}
                            settingData={setting}
                            selectFloorData={selectFloor}
                            setTablePosition={setTablePosition}
                            saveTableData={saveTableData}
                            editTableMode={editTableMode}
                            deleteTableData={deleteTableData} />
                    </div>

                    <div className={`${styleTableManagement.addTables} ${editValue.index  !== -1 ? styleTableManagement.addTablesEdit : ""}`}
                         onDoubleClick={editTableMode}
                    >
                        <span><FontAwesomeIcon icon={faPlus} size="lg"/></span>
                        <span>테이블</span>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default connect((state) => {
    return {
        author: state.data.account.author,
        store: state.data.store.store,
    };
})(withRouter(TableManagement));
