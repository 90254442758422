import React, {Component} from 'react';
import {withRouter, Route} from 'react-router-dom';
import {connect} from 'react-redux';
import {Helmet} from "react-helmet";
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend
} from 'recharts';
import moment from 'moment';

// Actions
import * as ActionStore from '../../../../../Data/Action/store';

// styles
import styleStore from '../../../../Styles/Components/Store.css'
import styleBoard from '../../../../Styles/Components/Board.css'
import SearchBar from '@/Service/Components/Store/Statistics/PeriodSearchBar'
import InfiniteList from '../../../../Common/InfiniteList/index';
import styleTable from '@/Service/Styles/Common/Table.css'
import { converter, dateUtil } from '@/Utils'
import styleReservationModal from '@/Service/Styles/Components/ReservationModal.css'

class SalesAnalysis extends Component{
  constructor(){
    super();

    this.state = {
      salesAnalysisConfig: {
        COLUMN_NAME: ['매출일자', '영수 건수', '고객 수', '총 매출', '예약 매출' , '대기 매출'],
        COLUMN_FIELD: ['date', 'salesCount', 'numberOfPeople', 'totalSales', 'reservationSales', 'waitingSales'],
        COLUMN_SIZE: ['20%', '10%', '10%', '20%', '20%', '20%'],
      },
      // chartData: [],
    }

    this.getStoreSalesListData = this.getStoreSalesListData.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChangingStatus = this.handleChangingStatus.bind(this);
  }

  componentWillMount() {
    this.setState({
      salesAnalysisParams: {
        fk_storeId: this.props.store && this.props.store.store ? this.props.store.store.storeId : null,
        order: 'reservedAt',
        orderMethod: 'DESC',
        startDate: dateUtil.format('YYYY-MM-DD', new Date(moment().subtract(7, 'days'))),
        endDate: dateUtil.format('YYYY-MM-DD', new Date()),
      },
    })
  }

  getStoreSalesListData(list) {
    if (!list || list.length === 0) {
      return;
    }
    let checked = [];
    list.forEach(() => {
      checked.push(false);
    })
    this.setState({
      storeSalesList: list,
      checked: checked,
    }, () => {
      let chartData = [];
      this.state.storeSalesList ? this.state.storeSalesList.reverse().forEach((value) => {
        chartData.push({
          name: value.date,
          '총 매출': value.totalSales,
          '예약 매출': value.reservationSales,
          '대기 매출': value.waitingSales,
        })
      }) : null;

      this.setState({
        chartData: chartData
      })
    })
  }

  handleSearch(params) {
    let salesAnalysisParams =  Object.assign(this.state.salesAnalysisParams, params)

    this.setState({
      salesAnalysisParams: salesAnalysisParams
    }, () => {
      this.salesAnalysisList.reset();
    })
  }

  handleChangingStatus(text, bool) {
    this.setState({
      isStatusChanging: true,
      alertText: text,
      isPositive: bool
    }, () => {
      setTimeout(() => {
        this.setState({ isStatusChanging: false });
      }, bool ? 1000 : 3000);
    })
  }

  render(){
    let renderSalesListHeader = () => {
      return (
        <div className={styleTable.tableContentHeader}>
          { this.state.salesAnalysisConfig.COLUMN_FIELD.map((field, index) => {
            let salesAnalysisConfig = this.state.salesAnalysisConfig;
            return (
              <div
                className={styleTable.column + ' ' + (field ? styleTable.style2 : styleTable.style3)}
                style={{width: salesAnalysisConfig.COLUMN_SIZE[index], height: '52px', lineHeight: index == 1 || index == 2 || index == 4 || index == 5? '38px' : '52px'}}
                key={index}
              >
                { salesAnalysisConfig.COLUMN_NAME[index] }
                {index == 1 || index == 2 ? <div style={{color: '#98a0ab', fontSize: '12px', lineHeight: '1px'}}>단가</div> : null}
                {index == 4 || index == 5 ? <div style={{color: '#98a0ab', fontSize: '12px', lineHeight: '1px'}}>비중</div> : null}
              </div>
            )
          }) }
        </div>
      );
    }

    let renderSalesList = (item, index) => {
      const config = this.state.salesAnalysisConfig;

      return (
        <div className={styleTable.tableContentItem + (item.isChecked ? ' ' + styleTable.active : '')}
             key={index} style={{height: '52px', lineHeight: '52px'}}
             >
          <div
            className={styleTable.column}
            style={{width: config.COLUMN_SIZE[0], lineHeight: '52px'}}
          >
            {dateUtil.format('slash', item[config.COLUMN_FIELD[0]])}
          </div>
          <div
            className={styleTable.column}
            style={{width: config.COLUMN_SIZE[1], lineHeight: '38px'}}
          >
            {converter.parseListByType(item[config.COLUMN_FIELD[1]])}
            <div style={{color: '#98a0ab', fontSize: '12px', lineHeight: '1px'}}>
              ₩ {converter.parseListByType(item['totalSalesUnit'])}
            </div>
          </div>
          <div
            className={styleTable.column}
            style={{width: config.COLUMN_SIZE[2], lineHeight: '38px'}}
          >
            {converter.parseListByType(item[config.COLUMN_FIELD[2]])}
            <div style={{color: '#98a0ab', fontSize: '12px', lineHeight: '1px'}}>
              ₩ {(converter.parseListByType(item[config.COLUMN_FIELD[3]])/(converter.parseListByType(item[config.COLUMN_FIELD[2]]))).toFixed(0) === 'NaN'
            || (converter.parseListByType(item[config.COLUMN_FIELD[3]])/(converter.parseListByType(item[config.COLUMN_FIELD[2]]))).toFixed(0) === 'infinity' ?
              0: (converter.parseListByType(item[config.COLUMN_FIELD[3]])/(converter.parseListByType(item[config.COLUMN_FIELD[2]]))).toFixed(0)
            }
            </div>
          </div>
          <div
            className={styleTable.column}
            style={{width: config.COLUMN_SIZE[3], lineHeight: '52px'}}
          >
            ₩ {converter.parseListByType(item[config.COLUMN_FIELD[3]])}
          </div>
          <div
            className={styleTable.column}
            style={{width: config.COLUMN_SIZE[4], lineHeight: '38px'}}
          >
            ₩ {converter.parseListByType(item[config.COLUMN_FIELD[4]])}
            <div style={{color: '#98a0ab', fontSize: '12px', lineHeight: '1px'}}>
              {converter.parseListByType(item['reservationSalesRatio'])} %
            </div>
          </div>
          <div
            className={styleTable.column}
            style={{width: config.COLUMN_SIZE[5], lineHeight: '38px'}}
          >
            ₩ {converter.parseListByType(item[config.COLUMN_FIELD[5]])}
            <div style={{color: '#98a0ab', fontSize: '12px', lineHeight: '1px'}}>
              {converter.parseListByType(item['waitingSalesRatio'])} %
            </div>
          </div>
        </div>
      )
    }

    return(
      <div className={styleStore.homeContainer}>
        <Helmet>
          <title>매출 분석</title>
        </Helmet>
        <div className={styleStore.homeContent}>
          {this.state.isStatusChanging && !this.state.isPositive ? <div className={styleReservationModal.completedAlert} style={{top: '40%', backgroundColor: '#F08080'}}>{this.state.alertText}</div> : null}
          {this.state.isStatusChanging && this.state.isPositive ? <div className={styleReservationModal.completedAlert} style={{top: '40%'}}>{this.state.alertText}</div> : null}
          <div className={styleBoard.left}>
            <div className={styleBoard.top}>
              <p className={styleBoard.title}>총 매출</p>
              <SearchBar onRef={(ref) => {this.salesSearchBar = ref;}} handleSearch={(params) => this.handleSearch(params)} handleChangingStatus={this.handleChangingStatus}/>
            </div>
            <div className={styleBoard.bottom}>
              <div className={styleTable.tableContentBox + ' ' + styleTable.style1}>
                {renderSalesListHeader()}
                <InfiniteList
                  onRef={(ref) => this.salesAnalysisList = ref}
                  ListItem={renderSalesList}
                  Get={ActionStore.getSalesByDate}
                  GetParams={this.state.salesAnalysisParams}
                  DataTitle={['store_sales', 'store']}
                  GetListData={(list) => this.getStoreSalesListData(list)}
                  customTableStyle={styleTable.tableContentWrapper}
                />
              </div>
            </div>
          </div>
          <div className={styleBoard.right}>
            <div className={styleBoard.graphTitle}>총 매출 그래프</div>
            <div className={styleBoard.graphBox}>
              <BarChart width={300} height={300} data={this.state.chartData}
                        margin={{top: 5, right: 30, left: 20, bottom: 5}}>
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey="name"/>
                <YAxis/>
                <Tooltip/>
                <Legend/>
                <Bar dataKey="총 매출" fill="#8884d8" />
                <Bar dataKey="예약 매출" fill="#82ca9d" />
                <Bar dataKey="대기 매출" fill="#ff845c" />
              </BarChart>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect((state) => {
  return {
    author: state.data.account.author,
      store: state.data.store.store,
  }
})(withRouter(SalesAnalysis))