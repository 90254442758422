import React, {Component} from 'react';
import {withRouter, Route} from 'react-router-dom';
import {connect} from 'react-redux';

// styles
import styleTableSelectModal from '../../../Styles/Components/TableSelectModal.css';
import styleReservation from '@/Service/Styles/Components/Reservation.css'

// component
import Tables from '@/Service/Common/TableManager/Tables';

// action
import { action } from '../../../../Data/index';

// Utils
import { parseUrlParameter, listUtil, dateUtil } from '../../../../Utils/index';

class TableSelectModal extends Component{
  constructor(props){
    super(props);
    this.state = {
        storeTableIds: [],
    };

    this.handleSelect = this.handleSelect.bind(this);
  }

  componentWillMount() {
      if (this.props.parentState.storeTableIds.length) {
          this.setState({
              storeTableIds: this.props.parentState.storeTableIds,
          });
      }
  }

  componentDidMount() {
    this.props.onRef(this);
  }

  handleSelect() {
      this.props.closeModal();
  }

  render(){

    return(
      <div className={styleTableSelectModal.modalContent}>
	      <div className={styleReservation.tableBgText1}>{this.props.parentState.selectedPlace.name}</div>
	      <div className={styleReservation.tableBgText2}>{this.props.parentState.selectedFloor.name}</div>
          <div className={styleTableSelectModal.selectBtn} onClick={this.handleSelect}>
              <p>선택</p>
              <p>완료</p>
          </div>
          <Tables
		      editValue={{index : -1}}
              onClick={this.props.onClick}
		      floorTableData={this.props.parentState.tableDataValue.floorTableData}
		      selectTableShape={() => {}}
		      setTablePosition={() => {}}
              selectedTableIds={this.state.storeTableIds}
		      ref={this.tableRef}
	      />
      </div>
    );
  }
}

export default connect((state) => {
  return {
      author: state.data.account.author,
      store: state.data.store.store,
      store_place: state.data.store_place.store_place,
      store_place_floor: state.data.store_place_floor.store_place_floor,
      reservationUpdated: state.data.store_reservation.reservationUpdated
  }
})(withRouter(TableSelectModal))