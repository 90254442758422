import * as ActionTypes from '../actionTypes';

export const initialState = {
    store_place_floor: {},
    store_place_floor_list: []
}
/*
*/
export const store_place_floor_reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.STORE_PLACE_FLOOR:
            return Object.assign({}, state, {
                store_place_floor: action.store_place_floor
            });
        case ActionTypes.STORE_PLACE_FLOOR_LIST:
            return Object.assign({}, state, {
                store_place_floor_list: action.store_place_floor_LIST
            });
        default:
            return state;
        }
}
