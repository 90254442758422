import * as ActionTypes from '../actionTypes';

export const initialState = {
    store_customer_info: {},
    store_customer_info_list: []
}
/*
*/
export const store_customer_info_reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.STORE_CUSTOMER_INFO:
            return Object.assign({}, state, {
                store_customer_info: action.store_customer_info
            });
        case ActionTypes.STORE_CUSTOMER_INFO_LIST:
            return Object.assign({}, state, {
                store_customer_info_list: action.store_customer_info_LIST
            });
        default:
            return state;
        }
}
