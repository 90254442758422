import React, {Component} from 'react';
import {withRouter, Route} from 'react-router-dom';
import {connect} from 'react-redux';
import {Helmet} from "react-helmet";
import moment from 'moment';

// styles
import styleStore from '../../../../Styles/Components/Store.css'
import styleBoard from '../../../../Styles/Components/Board.css'
import SearchBar from '@/Service/Components/Store/Statistics/PeriodSearchBar'
import styleTable from '@/Service/Styles/Common/Table.css'
import InfiniteList from '../../../../Common/InfiniteList/index';
import { converter, dateUtil } from '@/Utils'

// Actions
import * as ActionCallHistory from '../../../../../Data/Action/log_store_call_history';
import styleReservationModal from '@/Service/Styles/Components/ReservationModal.css'

//기타 이력
class CallRecords extends Component{
  constructor(){
    super();

    this.state = {
      extraRecordConfig: {
        COLUMN_NAME: ['이름', '연락처', '통화일자', '통화시간'],
        COLUMN_FIELD: ['name', 'phoneNumber', 'createdAt', 'createdAt'],
        COLUMN_SIZE: ['25%', '25%', '25%', '25%'],
      },
    }

    this.getCallHistoryListData = this.getCallHistoryListData.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChangingStatus = this.handleChangingStatus.bind(this);
  }

  componentWillMount() {
    this.setState({
      extraRecordsParams: {
        fk_storeId: this.props.store && this.props.store.store ? this.props.store.store.storeId : null,
        startDate: dateUtil.format('YYYY-MM-DD', new Date(moment().subtract(7, 'days'))),
        endDate: dateUtil.format('YYYY-MM-DD', new Date()),
        order: 'createdAt',
        orderMethod: 'DESC',
      }
    })
  }

  getCallHistoryListData(list){
    if (!list || list.length === 0) {
      return;
    }
    let checked = [];
    list.forEach(() => {
      checked.push(false);
    })
    this.setState({
      callHistoryList: list,
      checked: checked,
    })
  }

  handleSearch(params) {
    let extraRecordsParams =  Object.assign(this.state.extraRecordsParams, params)

    this.setState({
      extraRecordsParams: extraRecordsParams
    }, () => {
      this.extraRecordsList.reset();
    })
  }

  handleChangingStatus(text, bool) {
    this.setState({
      isStatusChanging: true,
      alertText: text,
      isPositive: bool
    }, () => {
      setTimeout(() => {
        this.setState({ isStatusChanging: false });
      }, bool ? 1000 : 3000);
    })
  }

  render(){
    const renderExtraRecordListHeader = () => {
      return (
        <div className={styleTable.tableContentHeader}>
          { this.state.extraRecordConfig.COLUMN_FIELD.map((field, index) => {
            let extraRecordConfig = this.state.extraRecordConfig;
            return (
              <div
                className={styleTable.column + ' ' + (field ? styleTable.style2 : styleTable.style3)}
                style={{width: extraRecordConfig.COLUMN_SIZE[index]}}
                key={index}
              >
                {extraRecordConfig.COLUMN_NAME[index] }
              </div>
            )
          }) }
        </div>
      );
    }

    const renderExtraRecordsList = (item, index) => {
      const config = this.state.extraRecordConfig;

      return (
        <div className={styleTable.tableContentItem + (item.isChecked ? ' ' + styleTable.active : '')}
             key={index}
        >
          <div
            className={styleTable.column}
            style={{width: config.COLUMN_SIZE[0]}}
          >
            {converter.parseListByType(item['store_customer'][config.COLUMN_FIELD[0]])}
          </div>
          <div
            className={styleTable.column}
            style={{width: config.COLUMN_SIZE[1]}}
          >
            {converter.parseListByType(item['log_store_call_history'][config.COLUMN_FIELD[1]])}
          </div>
          <div
            className={styleTable.column}
            style={{width: config.COLUMN_SIZE[2]}}
          >
            {dateUtil.format('slash', item['log_store_call_history'][config.COLUMN_FIELD[2]])}
          </div>
          <div
            className={styleTable.column}
            style={{width: config.COLUMN_SIZE[3]}}
          >
            {dateUtil.format('HH:mm', item['log_store_call_history'][config.COLUMN_FIELD[3]])}
          </div>
        </div>
      )
    }

    return(
      <div className={styleStore.homeContainer}>
        <Helmet>
          <title>기타 이력</title>
        </Helmet>
        <div className={styleStore.homeContent}>
          {this.state.isStatusChanging && !this.state.isPositive ? <div className={styleReservationModal.completedAlert} style={{top: '40%', backgroundColor: '#F08080'}}>{this.state.alertText}</div> : null}
          {this.state.isStatusChanging && this.state.isPositive ? <div className={styleReservationModal.completedAlert} style={{top: '40%'}}>{this.state.alertText}</div> : null}
          <div className={styleBoard.left}>
            <div className={styleBoard.top}>
              <p className={styleBoard.title}>통화 이력</p>
              <SearchBar onRef={(ref) => {this.extraSearchBar = ref;}} handleSearch={(params) => this.handleSearch(params)} handleChangingStatus={this.handleChangingStatus} />
            </div>
            <div className={styleBoard.bottom}>
              <div className={styleTable.tableContentBox + ' ' + styleTable.style1}>
                {renderExtraRecordListHeader()}
                <InfiniteList
                  onRef={(ref) => this.extraRecordsList = ref}
                  ListItem={renderExtraRecordsList}
                  Get={ActionCallHistory.getList}
                  GetParams={this.state.extraRecordsParams}
                  GetListData={(list) => this.getCallHistoryListData(list)}
                  customTableStyle={styleTable.tableContentWrapper}
                />
              </div>
            </div>
          </div>
          <div className={styleBoard.right}>
          </div>
        </div>
      </div>
    )
  }
}

export default connect((state) => {
  return {
    author: state.data.account.author,
      store: state.data.store.store,
  }
})(withRouter(CallRecords))