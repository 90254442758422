import * as ActionTypes from '../actionTypes';

export const initialState = {
    store_place: {},
    store_place_list: [],
    placeChanged: null,
}
/*
*/
export const store_place_reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.STORE_PLACE:
            return Object.assign({}, state, {
                store_place: action.store_place
            });
        case ActionTypes.STORE_PLACE_LIST:
            return Object.assign({}, state, {
                store_place_list: action.store_place_LIST
            });
        case ActionTypes.STORE_PLACE_CHANGED:
            return Object.assign({}, state, {
                placeChanged: action.placeChanged
            });
        default:
            return state;
        }
}
