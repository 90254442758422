exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".Modal__closeBtn--3Ax7q {\n    width: 32px;\n    height: 32px;\n    position: absolute;\n    right: -130px;\n    top: -80px;\n    cursor: pointer;\n}\n\n.Modal__modalContainer--1VyVv {\n    position: relative;\n    margin: 100px auto;\n    width: 706px;\n    height: 520px;\n    transition: all .15s ease-in-out;\n    z-index:5000;\n    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2);\n}\n\n.Modal__modalContainer--1VyVv:focus {outline:0;}\n\n.Modal__modalBody--3Qtum {\n    position: absolute;\n    top: 0;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    padding: 0;\n    border-radius: 0;\n    background-color: #fff;\n    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);\n}\n\n.Modal__overlay--2SBdo {\n    background-color: rgba(0,0,0,0.6);\n    position: fixed;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    transition: all .15s ease-in-out;\n    z-index: 9999;\n}\n", ""]);

// Exports
exports.locals = {
	"closeBtn": "Modal__closeBtn--3Ax7q",
	"modalContainer": "Modal__modalContainer--1VyVv",
	"modalBody": "Modal__modalBody--3Qtum",
	"overlay": "Modal__overlay--2SBdo"
};