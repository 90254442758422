import * as ActionTypes from '../actionTypes';

export const initialState = {
    account_delete: {},
    account_delete_list: []
}
/*
*/
export const account_delete_reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.ACCOUNT_DELETE:
            return Object.assign({}, state, {
                account_delete: action.account_delete
            });
        case ActionTypes.ACCOUNT_DELETE_LIST:
            return Object.assign({}, state, {
                account_delete_list: action.account_delete_LIST
            });
        default:
            return state;
        }
}
