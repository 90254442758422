import React, {Component} from 'react';
import {withRouter, Route} from 'react-router-dom';
import {connect} from 'react-redux';
import { withTranslation } from 'react-i18next';

// actions
import { action } from '../../../../Data';

// styles
import styleWaitingList from '../../../Styles/Components/WaitingList.css'
import WaitingListDetail from './WaitingListDetail'


class WaitingList extends Component{
  constructor(){
    super();
    this.state = {
        showList: false,
        waitingStatus: 0,
        reservationConst: {},
        isLoading: true,

        isVisible: false,
    }

    this.handleWaitingStatus = this.handleWaitingStatus.bind(this);
    this.showWaitingList = this.showWaitingList.bind(this);
    this.handleOverflow = this.handleOverflow.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(action.constData.getReservation()).then((res) => {
      this.setState({
          reservationConst: res,
          isLoading: false,
      });
    }).catch((e) => {
      console.log('CONST ERR WaitingList.jsx ::: ', e);
      this.setState({
          isLoading: false,
      })
    });
  }

  handleWaitingStatus(e) {
    this.setState({
        waitingStatus: e.target.value,
    }, () => {
      this.waitingListDetail.setState({
          waitingStatus: this.state.waitingStatus,
      }, () => {
        this.waitingListDetail.getStoreValues();
      });
    });
  }

  showWaitingList() {
    this.setState({
      showList: !this.state.showList,
      waitingListDetail: this.waitingListDetail
    })

    if(this.state.showList === false ) {
      this.setState({ right: '0' })
    } else if (this.state.showList === true ) {
      this.setState({ right: '-261px' })
    }
  }

  handleOverflow() {
    this.setState({ isVisible: !this.state.isVisible })
  }

  render(){
      const { t, i18n } = this.props;

      let waitingStatusArray = this.state.reservationConst.RESERVATION_WAITING_STATUS;

      let renderWaitingStatus = waitingStatusArray ? Object.keys(waitingStatusArray).map((key, index) => {
          return <option key={index} value={key} defaultValue={index === 0}>{waitingStatusArray[key]} {key === 30 ? '(타매장입장)' : null}</option>
      }) : null;

      return (
          <div className={styleWaitingList.waitingSection} style={{right: this.state.right}}>
            <p className={styleWaitingList.waitingBtn} onClick={this.showWaitingList}>{t('waiting_customers')}</p>

              <div className={styleWaitingList.waitingContent}>
                <div className={styleWaitingList.waitingDropdown}>
                  <select onChange={(e) => this.handleWaitingStatus(e)} className={styleWaitingList.waitingDropdownList}>
                      { renderWaitingStatus }
                  </select>
                </div>
                  { this.state.isLoading ? '리스트를 불러오고 있습니다.' :
                      <WaitingListDetail
                          screenFontSize={this.props.screenFontSize}
                          reservationConst={this.state.reservationConst}
                          onRef={(ref) => {this.waitingListDetail = ref;}}
                          handleOverflow={this.handleOverflow}
                          handleChangingStatus={this.props.handleChangingStatus}
                      />}
              </div>

          </div>
      )
  }
}

export default connect((state) => {
    return {
        author: state.data.account.author,
        store: state.data.store.store,
    }
})(withRouter(withTranslation()(WaitingList)))
