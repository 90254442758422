import * as ActionTypes from '../actionTypes';

// API
import * as HttpApi from '../Api/index';

export const actionTypes = ActionTypes;

//////////////////
///// GET ////////
//////////////////
export const getReservation = () => {
	return (dispatch) => {
		return HttpApi.get('GET_CONST_RESERVATION')
			.then((response) => {
				return Promise.resolve(response.data);
			})
			.catch((err) => {
				return Promise.reject(err);
			});
	};
};

export const getConstNotification = () => {
	return async(dispatch) => {
		return HttpApi.get('GET_CONST_NOTIFICATION')
			.then((response) => {
				if(response.data) {
					dispatch({
						type: ActionTypes.SET_CONST_NOTIFICATION,
						notification: response.data,
					});
				}
				return Promise.resolve(response.data);
			})
			.catch((err) => {
				return Promise.reject(err);
			});
	};
};

export const getConstReservation = () => {
	return async(dispatch) => {
		return HttpApi.get('GET_CONST_ADMIN_RESERVATION')
			.then((response) => {
				if(response.data) {
					dispatch({
						type: ActionTypes.SET_CONST_ADMIN_RESERVATION,
						reservation: response.data,
					});
				}
				return Promise.resolve(response.data);
			})
			.catch((err) => {
				return Promise.reject(err);
			});
	};
};

export const getConstCustomer = () => {
    return async(dispatch) => {
        return HttpApi.get('GET_CONST_ADMIN_CUSTOMER')
            .then((response) => {
            	console.log(response);
                if(response.data) {
                    dispatch({
                        type: ActionTypes.SET_CONST_ADMIN_CUSTOMER,
                        customer: response.data,
                    });
                }
                return Promise.resolve(response.data);
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };
};



//////////////////
/// FUNCTIONS ////
//////////////////