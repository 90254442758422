import * as ActionTypes from '../actionTypes';

export const initialState = {
    account_phone_verification: {},
    account_phone_verification_list: []
}
/*
*/
export const account_phone_verification_reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.ACCOUNT_PHONE_VERIFICATION:
            return Object.assign({}, state, {
                account_phone_verification: action.account_phone_verification
            });
        case ActionTypes.ACCOUNT_PHONE_VERIFICATION_LIST:
            return Object.assign({}, state, {
                account_phone_verification_list: action.account_phone_verification_LIST
            });
        default:
            return state;
        }
}
