exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".Search__searchContent--3Y1DN {\n    width: 288px;\n    padding: 16px;\n    position: relative;\n}\n\n.Search__deleteIcon--FfXpm {\n    width: 16px;\n    height: 16px;\n    position: absolute;\n    right: 16px;\n    top: 16px;\n    cursor: pointer;\n}\n\n.Search__search--3pkYa {\n    height: 20px;\n    text-align: center;\n    font-size: 18px;\n    margin-bottom: 24px;\n}\n\n.Search__searchArea--hIKcB {\n    width: 100%;\n    height: 50px;\n    border-bottom: 1px solid #98a0ab;\n    margin-bottom: 40px;\n}\n\n.Search__searchInput--xAlw3 {\n    width: 80%;\n    height: 90%;\n    float: left;\n}\n\n.Search__searchInput--xAlw3 input {\n    width: 256px;\n    height: 47px;\n    border: none;\n    font-size: 24px;\n    padding: 0;\n}\n\n.Search__searchIcon--eqGXf {\n    width: 32px;\n    height: 32px;\n    float: left;\n    margin-top: 10px;\n    margin-left: 20px;\n    cursor: pointer;\n}\n\n.Search__resultArea--3eIuZ {\n    height: 490px;\n    position: relative;\n    z-index: 1;\n}\n\n.Search__noResult--1jOHL {\n    text-align: center;\n    color: #98a0ab;\n    font-size: 18px;\n    position: absolute;\n    top: 33%;\n    left: 50%;\n    margin-left: -77px;\n    /*line-height: 490px;*/\n}\n\n.Search__storeList--32MLr li {\n    height: 52px;\n    line-height: 52px;\n    background-color: transparent;\n    cursor: pointer;\n}\n\n.Search__storeList--32MLr li:nth-child(2n) {\n    background-color: #f9f9f9;\n}\n\n.Search__storeList--32MLr li div {\n    width: 50%;\n    float: left;\n    text-align: center;\n    font-size: 18px;\n}\n\n.Search__storeName--2SGjO {\n    color: #5b9fdc;\n    font-weight: 700;\n}\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n", ""]);

// Exports
exports.locals = {
	"searchContent": "Search__searchContent--3Y1DN",
	"deleteIcon": "Search__deleteIcon--FfXpm",
	"search": "Search__search--3pkYa",
	"searchArea": "Search__searchArea--hIKcB",
	"searchInput": "Search__searchInput--xAlw3",
	"searchIcon": "Search__searchIcon--eqGXf",
	"resultArea": "Search__resultArea--3eIuZ",
	"noResult": "Search__noResult--1jOHL",
	"storeList": "Search__storeList--32MLr",
	"storeName": "Search__storeName--2SGjO"
};