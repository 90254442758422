/*
* @params  {string} search
*/
export const getPathIndex = ( path, index, length ) => {
    if (!path) return;
    let pathArr = path.split('/');

    let result = '';

    if (Number(length) > 0 && (length + index) <= pathArr.length) {
        let pathParseArr = [];
        for (let i=index; i<length + index; i++) {
            pathParseArr.push(pathArr[i]);
        }
        result = '/' + pathParseArr.join('/');
    } else {
        result = pathArr[index]
    }

    return result || null;
}
