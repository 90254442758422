import React, {Component} from 'react';
import { withRouter, Route, Link } from 'react-router-dom'
import {connect} from 'react-redux';
import {Helmet} from "react-helmet";
import Modal from '../../Common/Modal';

// Components
import HeaderComponent from './Header'

// styles
import styleMobile from '../../Styles/Components/Mobile.css'
import styleBook from '../../Styles/Components/Book.css'

// action
import { action } from '../../../Data/index';

// Utils
import { parseUrlParameter } from '../../../Utils';
import { withTranslation } from 'react-i18next'
import styleReservationModal from '@/Service/Styles/Components/ReservationModal.css'

const customStyles = {
    content: {
        width: '100%',
        top: '-100px',
        height: '100%'
    }
};

class BookView extends Component{
    constructor(props){
        super(props);

        this.state = {
            store: this.props.store.store,
            brand: this.props.store.brand,
            lunchStartTime: '',
            dinnerEndTime: '',
            name: '',
            phoneNumber: '',
            numberOfPeople: 0,
            babySeatAmount: 0,

            storeCustomer: {},
            storeReservation: {},

            preferPlaces: [],
            storePlace: [],
            storeMenus: [],
            originalStoreMenus: [],

            selectedStoreMenus: [],
            resultStorePlaceValues: '',
            resultStoreMenuValues: '',

            resultValues: '',
            directInput: '',
            isDirectInput: false,

            isAgreeTermsAndConditions: false,
            isAgreePersonalInfo: false,
            isAgreeMarketing: false,
        }

        this.showBookModal = this.showBookModal.bind(this)
        this.closeBookModal = this.closeBookModal.bind(this)
        this.selectItem = this.selectItem.bind(this)
        this.onKeyPress = this.onKeyPress.bind(this)
        this.directInputType = this.directInputType.bind(this)
        this.showDirectInput = this.showDirectInput.bind(this)

        this.getStoreValues = this.getStoreValues.bind(this);

        this.searchStoreCustomer = this.searchStoreCustomer.bind(this);

        this.handleReservationValues = this.handleReservationValues.bind(this);
        this.handleSubmitMenus = this.handleSubmitMenus.bind(this);
        this.handleSubmitReservation = this.handleSubmitReservation.bind(this);
        this.handleSubmitPreferPlace = this.handleSubmitPreferPlace.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this); // 최종 제출 함수
        this.handleBtn = this.handleBtn.bind(this);
        this.handleChangingStatus = this.handleChangingStatus.bind(this);
    }

    componentWillMount() {
        this.getStoreValues();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.store !== prevProps.store) {
            this.getStoreValues();
        }
    }

    getStoreValues() {
        if (!this.state.store.storeId) {
            return;
        }

        let params = {
            fk_storeId : this.state.store.storeId
        }

        this.props.dispatch(action.store_place.getList({fk_storeId: this.state.store.storeId, orderMethod: 'asc'})).then((res) => {
            res.forEach((place) => {
                this.setState({
                    storePlace: [...this.state.storePlace, place]
                })
            })
        })
        this.props.dispatch(action.store_menu.getList(params)).then((res) => {
            res.forEach((menu) => {
                this.setState({
                    storeMenus: [...this.state.storeMenus, Object.assign(menu.store_menu, { isChecked: false })]
                })
            })
        })
        this.props.dispatch(action.service_system.getServiceSystem()).then((res) => {
            this.setState({
                serviceSystem: res[0]
            })
        })

        let params2 = {
            order: 'serviceDocId',
            orderMethod: 'ASC',
        }
        this.props.dispatch(action.service_docs.getList(params2)).then((res) => {
            this.setState({
                serviceDocs: res
            })
        })
    }

    handleChangingStatus(text, bool) {
        this.setState({
            isStatusChanging: true,
            alertText: text,
            isPositive: bool
        }, () => {
            setTimeout(() => {
                this.setState({ isStatusChanging: false });
            }, bool ? 1000 : 3000);
        })
    }

    searchStoreCustomer(params) {
        return new Promise((resolve, reject) => {
            this.props.dispatch(action.store_customer.getList(params)).then((res) => {
                resolve(res);
            })
        });
    }

    handleChangeDate(date) {
        this.handleReservationValues(date, 'reservedAt', 'storeReservation');
    }

    handleReservationValues(value, field, state) {
        if (field === 'phoneNumber') {
            let originalPhoneNumber = this.state.phoneNumberDash || '';

            if (value.length > originalPhoneNumber.length && (value.length == 3 || value.length == 8)) {
                this.setState({ phoneNumberDash: value + '-' })
            } else {
                this.setState({ phoneNumberDash: value })
            }
        }

        if (field === 'numberOfPeople' && value > this.state.store.maximumReservation) {
            return this.handleChangingStatus(this.state.store.maximumReservation + '이하로 입력해주세요', false);
            // alert(this.state.store.maximumReservation + '이하로 입력해주세요');
        } else if (field === 'babySeatAmount' && value > this.state.store.babySeatAmount) {
            return this.handleChangingStatus(this.state.store.babySeatAmount + '이하로 입력해주세요', false);
            // alert(this.state.store.babySeatAmount + '이하로 입력해주세요');
        }

        let newState = {};
        newState[state] = this.state[state];

        newState[state][field] = value;

        this.setState(newState)
    }

    handleSubmitCustomer(params) {
        return new Promise((resolve, reject) => {
            let customerFunc = null;
            params.name = this.state.storeCustomer.name;
            params.phoneNumber = this.state.storeCustomer.phoneNumber;

            this.props.dispatch(action.store_customer.getList({
                fk_storeId: this.state.store.storeId,
                phoneNumber: this.state.storeCustomer.phoneNumber
            })).then((res) => {
                if (!res || res.length === 0) {
                    customerFunc = action.store_customer.create(params);
                } else {
                    params.storeCustomerId = res[0].store_customer.storeCustomerId;
                    customerFunc = action.store_customer.update(params);
                }

                return this.props.dispatch(customerFunc);
            }).then((res) => {
                if (res && res.storeCustomerId) {
                    resolve(res);
                } else {
                    reject(res);
                }
            }).catch((err) => {
                reject(err);
            })
        });
    }

    handleSubmitReservation(params) {
        let reservationFunc = null;
        if (this.state.storeReservation.storeReservationId) {
            params.storeReservationId = this.state.storeReservation.storeReservationId;
            reservationFunc = action.store_reservation.update(params);
        } else {
            reservationFunc = action.store_reservation.create(params);
        }

        return new Promise((resolve, reject) => {
            this.props.dispatch(reservationFunc).then((res) => {
                if (res.storeReservationId) {
                    resolve(res);
                } else {
                    reject(res);
                }
            })
        })
    }

    handleSubmitMenus(params) {
        let selectedStoreMenus = this.state.selectedStoreMenus;
        if (selectedStoreMenus.length === 0) {
            return;
        }

        const createFunc = selectedStoreMenus.map((item) => {
            let newParams = {
                fk_storeId: params.fk_storeId,
                fk_storeReservationId: params.fk_storeReservationId,
                fk_storeCustomerId: params.fk_storeCustomerId,
                fk_storeMenuId: item.storeMenuId,
                menu: item.menu,
            }

            return new Promise((resolve, reject) => {
                this.props.dispatch(action.store_reservation_menu.create(newParams)).then((res) => {
                    if (res.storeReservationMenuId) {
                        resolve(res);
                    } else {
                        console.log('RESERVATION MENU ERR::');
                        reject(res);
                    }
                })
            });
        });

        return Promise.all(createFunc);
    }

    handleBtn() {
        const isValid = [
            { value: this.state.storeCustomer.phoneNumber, message: '핸드폰번호를 입력하세요.' },
            { value: this.props.store.store_system.isName ? this.state.storeCustomer.name : true, message: '이름을 입력하세요.' },
            { value: this.state.storeReservation.numberOfPeople, message: '인원을 입력하세요.' },
            { value: this.state.isAgreeTermsAndConditions, message: '이용약관에 동의해야 합니다.' },
            { value: this.state.isAgreePersonalInfo, message: '개인정보 수집/이용에 동의해야 합니다.' }
        ].filter((item) => {
            return !item.value;
        });

        if (isValid.length > 0) {
            return this.handleChangingStatus(isValid[0].message, false);
            // alert(isValid[0].message);
        } else {
            this.handleSubmit();
        }
    }

    handleSubmitPreferPlace() {
        return new Promise((resolve, reject) => {
            let storePlaces = [];
            const result = [];
            if (this.state.preferPlaces.length > 0) {
                storePlaces = this.state.preferPlaces.map((item) => {
                    return item.storePlaceId;
                });
            } else {
                storePlaces = this.state.storePlace.map((item) => {
                    return item.store_place.storePlaceId;
                });
            }
            storePlaces.forEach((item) => {
                let storePlaceId = item;

                let storePlaceFloors = this.state.storePlace.filter((item) => {
                    return item.store_place.storePlaceId === storePlaceId;
                }).map((item) => {
                    return item.store_place_floor;
                });

                storePlaceFloors.forEach((items) => {
                    items.forEach((item) => {
                        result.push({
                            fk_storePlaceId: item.fk_storePlaceId,
                            fk_storePlaceFloorId: item.storePlaceFloorId
                        });
                    });
                });
            });
            resolve(result);
        })
    }

    // 최종 제출 함수
    handleSubmit() {
        // validation
        let reservationState = this.state;

        const customerParams = {
            fk_storeId: this.state.store.storeId,
            name: this.state.name,
            phoneNumber: this.state.phoneNumber,
        }

        const reservationParams = {
            fk_brandId: this.state.brand.brandId,
            fk_storeId: this.state.store.storeId,
            fk_storePlaceId: 1,
            fk_storePlaceFloorId: 1,
            fk_storeStaffId: this.state.storeReservation.fk_storeStaffId || 0,
            numberOfPeople: this.state.storeReservation.numberOfPeople || 0,
            babySeatAmount: this.state.storeReservation.babySeatAmount || 0,
            reservationStatus: 2,
            waitingStatus: 0,
        }

        this.handleSubmitCustomer(customerParams).then((response) => {
            // 선호 매장 추가
            reservationParams['fk_storeCustomerId'] = response.storeCustomerId;

            return this.handleSubmitPreferPlace();
        }).then((storePlaceIdMap) => {
            reservationParams['storePlaceIdMap'] = storePlaceIdMap;

            return this.handleSubmitReservation(reservationParams)
        }).then((response) => {
            if (!response) { return new Error('예약에 실패했습니다.') }
            const optionParams = {
                fk_storeId: response.fk_storeId,
                fk_storeReservationId: response.storeReservationId,
                fk_storeCustomerId: response.fk_storeCustomerId,
            }
            Promise.all([
                this.handleSubmitMenus(optionParams),
            ]).then((res)=> {
                this.handleChangingStatus('예약을 완료했습니다.', true);
                // alert('예약을 완료했습니다.');

                setTimeout(() => {
                    let queries = {
                        storeId: this.state.store.storeId,
                        storeReservationId: response.storeReservationId,
                    };
                    this.props.history.push('/mobile/confirmation'+ parseUrlParameter.setQueryString(queries, this.props.location.search));
                }, 1000);


            }).catch((err) => {
                console.log('RESERVATION OPTIONS ERR::', err);
            })
        }).catch((err) => {
            console.log('ERR => ', err);
            // alert('예약 실패');
            this.handleChangingStatus('예약을 실패했습니다.', false);
        });
    }

    showBookModal() {
        this.bookModal.setState({
            isOpened: !this.bookModal.state.isOpened
        })
    }

    closeBookModal() {
        this.bookModal.setState({
            isOpened: !this.bookModal.state.isOpened,
        })

        let selectedStorePlaceValues = []
        let selectedStoreMenuValues = []

        this.state.preferPlaces.forEach((place) => {
            selectedStorePlaceValues = [...selectedStorePlaceValues, place.name]
        })
        this.state.selectedStoreMenus.forEach((menu) => {
            selectedStoreMenuValues = [...selectedStoreMenuValues, menu.menu]
        })
        this.setState({
            resultStorePlaceValues: selectedStorePlaceValues.join(' '),
            resultStoreMenuValues: selectedStoreMenuValues.join(' ')
        })
    }

    selectItem(num, item, index) {
        if (num === 1) {
            let preferPlaces = this.state.preferPlaces
            if (preferPlaces.includes(item.store_place)) {
                delete preferPlaces[index]
                this.setState({
                    preferPlaces: preferPlaces
                })
            } else {
                preferPlaces[index] = item.store_place
                this.setState({
                    preferPlaces: preferPlaces
                })
            }
        } else if (num === 2) {
            let storeMenus = this.state.storeMenus;
            storeMenus[index].isChecked = !storeMenus[index].isChecked;
            let selectedStoreMenus = storeMenus.length > 0 ? storeMenus.filter((item) => {
                return item.isChecked;
            }) : null;
            this.setState({
                selectedStoreMenus: selectedStoreMenus,
            })
        }
    }

    onKeyPress(e) {
        if (e.key === 'Enter') {
            this.directInputType();
            this.setState({
                directInput: ''
            })
        }
    }

    directInputType() {
        if (this.state.directInput) {
            let flag = false
            this.state.storeMenus.forEach((menu) => {
                if (menu.menu === this.state.directInput) {
                    flag = true
                }
            })

            if (!flag) {
                this.setState({
                    storeMenus: [...this.state.storeMenus, {
                        menu: this.state.directInput,
                        isChecked: true,
                    }],
                    selectedStoreMenus: [...this.state.selectedStoreMenus, {
                        menu: this.state.directInput,
                        isChecked: true,
                        fk_storeId: this.state.store.storeId
                    }]
                })
            }
        }
    }

    showDirectInput() {
        this.setState({
            isDirectInput: !this.state.isDirectInput
        })
    }

    render(){
        const { t, i18n } = this.props;

        let storeInfo = {
            backgroundImage: 'url("/Assets/img/img-store-blur.png")',
            height: '174px'
        }

        let whiteCircle = {
            marginTop: '-30px'
        }

        let storeMenu = {
            top: '144px'
        }

        const selectedStyle = {
            border: 'solid 1px #f8cf26',
            color: '#1e2028'
        }

        let renderPreferStorePlaceList = this.state.storePlace ? this.state.storePlace.map((place, index) => {
            return (
                <li key={index} onClick={() => this.selectItem(1, place, index)} style={this.state.preferPlaces.includes(place.store_place) ? selectedStyle : null}>
                    {i18n.language === 'ko' ? (place.store_place.name || place.store_place.nameEn) : (place.store_place.nameEn || place.store_place.name)}
                </li>
            )
        }) : null

        let renderStoreMenuList = this.state.storeMenus ? this.state.storeMenus.map((menu, index) => {
            return (
                <li key={index} onClick={() => this.selectItem(2, menu, index)} style={menu.isChecked ? selectedStyle : null}>
                    {i18n.language === 'ko' ? (menu.menu || menu.menuEn) : (menu.menu || menu.menuEn)}
                </li>
            )
        }) : null

        let bookModalBody = () => {
            return (
                <div className={styleBook.modalBody}>
                    <div className={styleMobile.bgLogoBox}><img src="/Assets/img/km-logo-white.png"/></div>
                    <div className={styleBook.deleteIcon} onClick={this.closeBookModal}><img src="/Assets/img/ic-delete.png"/></div>
                    {!this.props.store.store_system.isPreferPlace ? null :
                      <div className={styleBook.preferStorePlaceBox}>
                          <div className={styleBook.boxTitle}>{t('prefer_place')}</div>
                          <ul className={styleBook.listBox}>
                              {renderPreferStorePlaceList}
                          </ul>
                      </div>}
                    {!this.props.store.store_system.isMenu ? null :
                      <div className={styleBook.storeMenuBox}>
                          <div className={styleBook.boxTitle}>{t('menu')}</div>
                          <ul className={styleBook.listBox + ' ' + styleBook.listBox2}>
                              {renderStoreMenuList}
                          </ul>
                          {/*{this.state.isDirectInput ?*/}
                          {/*<div className={styleBook.directInput}>*/}
                          {/*<input type="text" autoFocus={true} onKeyPress={this.onKeyPress} value={this.state.directInput} onChange={(e) => this.setState({ directInput: e.target.value })}/>*/}
                          {/*</div> : null}*/}
                          {/*<div className={styleBook.addIconBox} onClick={this.showDirectInput}><img className={styleBook.addIcon} src="/Assets/img/btn-add.png"/> {t('direct_input')}</div>*/}
                      </div>}
                    <div className={styleBook.okBtn + ' ' + styleBook.okBtn2} onClick={this.closeBookModal}>{t('confirm')}</div>
                    <div className={styleBook.kmLogo}><img src="/Assets/img/km-logo-2-16-px.png"/></div>
                </div>
            )
        }

        let renderServiceDocs = this.state.serviceDocs ? this.state.serviceDocs.map((list, index) => {
            if (list.service_docs.serviceDocId == 1) {
                return (
                  <div key={index}>
                      <input type="checkbox" onChange={(e) => {this.setState({ isAgreeTermsAndConditions: e.target.checked })}} checked={this.state.isAgreeTermsAndConditions} value={this.state.isAgreeTermsAndConditions}/>
                      <label>{this.state.isKoreanLang ? (list.service_docs.title || list.service_docs.titleEn) : (list.service_docs.titleEn || list.service_docs.title)}</label>
                      <span style={{color: 'red'}} > (필수)</span>
                  </div>
                )
            } else if (list.service_docs.serviceDocId == 2) {
                return (
                  <div key={index}>
                      <input type="checkbox" onChange={(e) => {this.setState({ isAgreePersonalInfo: e.target.checked })}} checked={this.state.isAgreePersonalInfo} value={this.state.isAgreePersonalInfo}/>
                      <label>{this.state.isKoreanLang ? (list.service_docs.title || list.service_docs.titleEn) : (list.service_docs.titleEn || list.service_docs.title)}</label>
                      <span style={{color: 'red'}} > (필수)</span>
                  </div>
                )
            } else if (list.service_docs.serviceDocId == 3) {
                return (
                  <div key={index}>
                      <input type="checkbox" onChange={(e) => {this.setState({ isAgreeMarketing: e.target.checked })}} checked={this.state.isAgreeMarketing} value={this.state.isAgreeMarketing}/>
                      <label>{this.state.isKoreanLang ? (list.service_docs.title || list.service_docs.titleEn) : (list.service_docs.titleEn || list.service_docs.title)}</label>
                      <span style={{color: 'red'}} > (선택)</span>
                  </div>
                )
            }
        }) : null

        return (
            <div>
                <Helmet>
                    <title>모바일 대기예약</title>
                </Helmet>
                <HeaderComponent storeInfo={storeInfo} whiteCircle={whiteCircle} storeMenu={storeMenu}/>
                <div className={styleMobile.mobileContent} style={{borderTop: 'none'}}>
                    {this.state.isStatusChanging && !this.state.isPositive ? <div className={styleReservationModal.completedAlert} style={{top: '40%', backgroundColor: '#F08080'}}>{this.state.alertText}</div> : null}
                    {this.state.isStatusChanging && this.state.isPositive ? <div className={styleReservationModal.completedAlert} style={{top: '40%'}}>{this.state.alertText}</div> : null}
                    <div className={styleBook.reservationInfo}>
                        <div className={styleBook.customerInfo}>
                            <div className={styleBook.title}>{t('enter_reservation_info_mobile')}</div>
                            <div className={styleBook.inputBox2}>
                                <label>{t('customer_phone_number')}</label>
                                <div>
                                    <input type="text" value={this.state.phoneNumberDash} onChange={(e) => this.handleReservationValues(e.target.value, 'phoneNumber', 'storeCustomer')}/>
                                </div>
                            </div>
                            <div className={styleBook.inputBox2 + ' ' + (this.props.store.store_system.isName ? '' : styleBook.hide)}>
                                <label>{t('customer_name')}</label>
                                <div>
                                    <input type="text" value={this.state.storeCustomer.name} onChange={(e) => this.handleReservationValues(e.target.value, 'name', 'storeCustomer')}/>
                                </div>
                            </div>
                            <div className={styleBook.inputBox2}>
                                <label>{t('number_of_people')}</label>
                                <div><input type="text" value={this.state.storeReservation.numberOfPeople} style={{width: '255px', marginRight: '10px'}}
                                            onChange={(e) => this.handleReservationValues(e.target.value, 'numberOfPeople', 'storeReservation')}/>{t('number_of_people_unit')}</div>
                            </div>
                            <div className={styleBook.inputBox2}>
                                <label>{t('baby_seat_amount')}</label>
                                <div><input type="text" value={this.state.storeReservation.babySeatAmount} style={{width: '255px', marginRight: '10px'}}
                                            onChange={(e) => this.handleReservationValues(e.target.value, 'babySeatAmount', 'storeReservation')}/>{t('baby_seat_amount_unit')}</div>
                            </div>
                            {!this.props.store.store_system.isMenu && !this.props.store.store_system.isPreferPlace ?
                            null : <div  onClick={this.showBookModal}>
                                  {this.props.store.store_system.isMenu ?
                                    <div className={styleBook.inputBox2}>
                                        <label>{t('menu')}</label>
                                        <div><input type="text" value={this.state.resultStoreMenuValues}/></div>
                                    </div> : null}
                                  {this.props.store.store_system.isPreferPlace ?
                                    <div className={styleBook.inputBox2}>
                                        <label>{t('prefer_place')}</label>
                                        <div><input type="text" value={this.state.resultStorePlaceValues}/></div>
                                    </div> : null}
                              </div>}
                        </div>
                        <div className={styleBook.agreeInfo}>
                            {renderServiceDocs}
                            <div className={styleBook.allAgree} onClick={() => {
                                this.setState({
                                    isAgreeTermsAndConditions: true,
                                    isAgreePersonalInfo: true,
                                    isAgreeMarketing: true,
                                })
                            }}>
                                <div>{t('all')}<br/>{t('agree')}</div>
                            </div>
                        </div>
                        {/*<p className={styleBook.extraInfo}>*/}
                            {/*{i18n.language === 'ko' ? (this.state.store.guideText || this.state.store.guideTextEn) : (this.state.store.guideText || this.state.store.guideTextEn)}*/}
                        {/*</p>*/}
                        <div className={styleBook.okBtn}>
                            <div onClick={this.handleBtn}>{t('confirm')}</div>
                        </div>
                        <div className={styleMobile.kmLogo}>
                            <img src={this.state.serviceSystem ? this.state.serviceSystem.service_system.whiteBgLogoUrl : "/Assets/img/km-logo-2-16-px.png"}/>
                        </div>
                    </div>
                    <Modal onRef={(ref) => {this.bookModal = ref;}} customStyles={customStyles} modalBody={bookModalBody()} />
                </div>
            </div>
        )
    }
}

export default connect((state) => {
    return {
        store: state.data.store.store
    };
})(withRouter(withTranslation()(BookView)));