/**
 * @providesModule ActionAuth
 */

// API
import * as HttpApi from '../Api/index';

/*
* other constants
*/
/*
* action creators
*/

//////////////////
///// GET ////////
//////////////////

//////////////////
///// POST ///////
//////////////////
/*
* @params {FILE} query.file
*/
export const uploadImage = (file) => {
    return HttpApi.upload('UPLOAD_IMAGE', {
        file: file,
    }).then((response) => {
        return Promise.resolve(response.data);
    }).catch((err) => {
        // 실패하면 한번만 더 시도할 것
        return HttpApi.upload('UPLOAD_IMAGE', {
            file: file,
        }).then((response) => {
            return Promise.resolve(response.data);
        }).catch((err) => {
            return Promise.reject(err);
        });
    });
};


/*
* @params {FILE} query.file
*/
export const uploadRemoteImage = (file) => {
    return HttpApi.upload('UPLOAD_REMOTE_IMAGE', {
        file: file,
    }).then((response) => {
        return Promise.resolve(response.data);
    }).catch((err) => {
        // 실패하면 한번만 더 시도할 것
        return HttpApi.upload('UPLOAD_REMOTE_IMAGE', {
            file: file,
        }).then((response) => {
            return Promise.resolve(response.data);
        }).catch((err) => {
            return Promise.reject(err);
        });
    });
};

/*
* @params {FILE} query.file
*/
export const uploadFile = (file) => {
    return HttpApi.upload('UPLOAD_FILE', {
        file: file,
    }).then((response) => {
        return Promise.resolve(response.data);
    }).catch((err) => {
        // 실패하면 한번만 더 시도할 것
        return HttpApi.upload('UPLOAD_FILE', {
            file: file,
        }).then((response) => {
            return Promise.resolve(response.data);
        }).catch((err) => {
            return Promise.reject(err);
        });
    });
};

/*
* @params {FILE} query.file
*/
export const uploadRemoteFile = (file) => {
  return HttpApi.upload('UPLOAD_REMOTE_FILE', {
    file: file,
  }).then((response) => {
    return Promise.resolve(response.data);
  }).catch((err) => {
    // 실패하면 한번만 더 시도할 것
    return HttpApi.upload('UPLOAD_REMOTE_FILE', {
      file: file,
    }).then((response) => {
      return Promise.resolve(response.data);
    }).catch((err) => {
      return Promise.reject(err);
    });
  });
};


/*
* @params {FILE} query.file
*/
export const uploadVideo = (file) => {
    return HttpApi.upload('UPLOAD_VIDEO', {
        file: file,
    }).then((response) => {
        return Promise.resolve(response.data);
    }).catch((err) => {
        // 실패하면 한번만 더 시도할 것
        return HttpApi.upload('UPLOAD_VIDEO', {
            file: file,
        }).then((response) => {
            return Promise.resolve(response.data);
        }).catch((err) => {
            return Promise.reject(err);
        });
    });
};

/*
* @params {FILE} query.file
*/
export const uploadAudio = (file) => {
    return HttpApi.upload('UPLOAD_AUDIO', {
        file: file,
    }).then((response) => {
        return Promise.resolve(response.data);
    }).catch((err) => {
        // 실패하면 한번만 더 시도할 것
        return HttpApi.upload('UPLOAD_AUDIO', {
            file: file,
        }).then((response) => {
            return Promise.resolve(response.data);
        }).catch((err) => {
            return Promise.reject(err);
        });
    });
};