import * as ActionTypes from '../actionTypes';

export const initialState = {
    board: {},
    board_list: [],
    isOpenCouponModal: true,
}
/*
*/
export const board_reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.BOARD:
            return Object.assign({}, state, {
                board: action.board
            });
        case ActionTypes.BOARD_LIST:
            return Object.assign({}, state, {
                board_list: action.board_LIST
            });
        case ActionTypes.IS_OPEN_COUPON_MODAL:
            return Object.assign({}, state, {
                isOpenCouponModal: action.isOpenCouponModal
            });
        default:
            return state;
        }
}
