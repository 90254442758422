import * as ActionTypes from '../actionTypes';

export const initialState = {
    account_social: {},
    account_social_list: []
}
/*
*/
export const account_social_reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.ACCOUNT_SOCIAL:
            return Object.assign({}, state, {
                account_social: action.account_social
            });
        case ActionTypes.ACCOUNT_SOCIAL_LIST:
            return Object.assign({}, state, {
                account_social_list: action.account_social_LIST
            });
        default:
            return state;
        }
}
