/**
 * @providesModule Converter
 */

/*
* @params  {number} amount
*/
export const parseListByType = (value) => {
    let default_value = null;
    if (typeof value === 'number') {
        default_value = 0;
    } else {
        default_value = '-';
    }

    return value !== null ? value : default_value;
}

export const getQueryString = (arr) => {
    try {
        return '?' + arr.join('&');
    } catch (e) {
        return ''
    }
}