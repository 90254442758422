import * as ActionTypes from '../actionTypes';

export const initialState = {
    store_device: {},
    store_device_list: []
}
/*
*/
export const store_device_reducer = (state = initialState, action) => {
    switch (action.type) {
      case ActionTypes.STORE_DEVICE:
        return Object.assign({}, state, {
          store_device: action.store_device
        });
      case ActionTypes.STORE_DEVICE_LIST:
        return Object.assign({}, state, {
          store_device_list: action.store_device_LIST
        });
      default:
        return state;
    }
}
