import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import onClickOutside from "react-onclickoutside";


// Styles
import styleInputWithTooltip from '../../Styles/Components/InputWithTooltip.css';

// Utils
import { listUtil } from '../../../Utils/index';
import styleBook from '@/Service/Styles/Components/Book.css'

class Tooltip extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpened: false,

            list: [],
            checked: [],
            selectedItems: [],

            isDirectInput: false,
            directInput: '',
        };

        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.loadList = this.loadList.bind(this);
        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
        this.submit = this.submit.bind(this);
        this.selectItem = this.selectItem.bind(this);
        this.deleteItem = this.deleteItem.bind(this);

        this.setCheckedItems = this.setCheckedItems.bind(this);
        this.showDirectInput = this.showDirectInput.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
        this.directInputType = this.directInputType.bind(this);
    }

    componentWillMount() {
        this.loadList();
    }

    componentDidMount() {
        this.props.onRef(this);

    }

    handleClickOutside(e) {
        this.close();
    }

    loadList() {
        if (!this.props.getList) return;

        this.props.dispatch(this.props.getList(this.props.params)).then((data) => {
            if (data.length > 0) {
                let list = null;
                let checked = this.state.checked;
                list = data.map((item) => {
                    let resultItem = null;
                    if (this.props.listName) {
                        if (Object.keys(item).indexOf(this.props.listName) > -1) {
                            resultItem = item[this.props.listName];
                        }
                    } else {
                        resultItem = item;
                    }
                    resultItem.isChecked = false;
                    checked.push(false);
                    return resultItem;
                })
                this.setState({
                    list: list,
                    checked: checked,
                }, () => {
                    console.log("<<loadedToolTip>>",this.state.list)
                })
            }
        }).catch((err) => {
            console.log('List ERR :: ', err);
        });
    }

    open(e) {
        if (e.target.className.indexOf('preventClick') === -1) this.setState({ isOpened: true });
    }

    close() {
        let list = this.state.list.map((item, index) => {
            item.isChecked = this.state.checked[index];
            return item;
        })
        this.setState({
            list: list,
            isOpened: false
        });
    }

    submit() {
        console.log('<<Tooltip.submit>>',this.state.list)
        let checked = this.state.checked;
        const result = this.state.list.filter((item, index) => {
            if (item.isChecked) {
                checked[index] = true;
                return item;
            }
        });
        this.setState({
            selectedItems: result,
            checked: checked,
            isOpened: false,
        }, () => {
            this.props.onUpdate(this.state.selectedItems);
        })

        if (result.length > 0) {
            this.props.handleEmptyValue(this.props.listName, false)
        } else {
            this.props.handleEmptyValue(this.props.listName, true)
        }
    }

    selectItem(index) {
        let newList = this.state.list;
        newList[index].isChecked = !newList[index].isChecked;
        this.setState({
            list: newList,
        })
    }

    deleteItem(index) {
        let deletedItem = this.state.selectedItems[index];
        let selectedItems = this.state.selectedItems.filter((item, idx) => {
            if (idx !== index) return item;
        });
        let checked = this.state.checked;
        const list = this.state.list.map((item, idx) => {
            if (item[this.props.itemName] === deletedItem[this.props.itemName]) {
                checked[idx] = false;
                item.isChecked = false;
            }
            return item;
        })
        this.setState({
            selectedItems: selectedItems,
            list: list,
            checked: checked,
            isOpened: this.state.isOpened,
        }, () => {
            this.props.onUpdate(this.state.selectedItems);
        });

        if (selectedItems.length > 0) {
            this.props.handleEmptyValue(this.props.listName, false)
        } else {
            this.props.handleEmptyValue(this.props.listName, true)
        }
    }

    async setCheckedItems(list, keyField)  {
        if (!list || !keyField || list.length === 0) {
            return;
        }
        console.log("<<list,keyField>>",list,keyField)
        let selectedItems = [];
        let checked = this.state.checked;
        let newList = this.state.list;

        await list.map((item1,index) => {
            if(!newList.find((item2) => {
                if(item1[keyField] === item2[keyField]) {
                    item2.isChecked = true;
                    return true;
                }
            })) {
                let newItem = item1;
                newItem.isChecked = true;
                console.log("push newItem",newItem);
                newList.push(newItem);
                checked.push(true);
            }
            item1.isChecked=true;
            selectedItems.push(item1);

          });
        console.log("<<newList>>",newList)
        console.log("<<selectedItems>>",selectedItems)
        //update checked list
        await newList.map((item,index) => {
            console.log(`item ${index}`, item)
            if(item.isChecked === true) checked[index] = true;
        });

        this.setState({
            list: newList,
            selectedItems: selectedItems,
            checked: checked,
        }, () => {
            console.log("<<tooltipState>>",this.state);
        })

    };

    showDirectInput() {
        this.setState({
            isDirectInput: !this.state.isDirectInput
        })
    }

    onKeyPress(e) {
        if (e.key === 'Enter') {
            this.directInputType();
            this.setState({
                directInput: ''
            })
        }
    }

    directInputType() {
        if (this.state.directInput) {
            let flag = false
            this.state.list.forEach((item) => {
                if (item[this.props.itemName] === this.state.directInput) {
                    flag = true
                }
            })

            if (!flag) {
                let listItem = {};

                listItem[this.props.itemName] = this.state.directInput;
                listItem.storeMenuId = 9999; //prevent error when calling handleSubmitMenus() in service/Components/Store/Reservation/ReservationModal.js
                listItem.isChecked = true;
                this.setState({
                    list: [...this.state.list, listItem],
                }, () => {
                    let selectedItem = this.state.list.length > 0 ? this.state.list.filter((item) => {
                        return item.isChecked;
                    }) : null;
                    this.setState({
                        selectedItem: selectedItem,
                    })
                })
            }
        }
    }

    render() {
        let renderSelectedItems = this.props.customInput ? this.props.customInput : () => {
            return this.state.selectedItems.map((item, index) => {
                return (
                    <li key={index}>
                        {item[this.props.itemName]}
                        <img className={styleInputWithTooltip.deleteIcon + ' preventClick'} src="/Assets/img/ic-delete-item.png" onClick={() => this.deleteItem(index)}/>
                    </li>
                )
            })
        }

        let renderPropsItems = this.props.customTooltip ? this.props.customBody : () => {
           return this.state.list.map((item, index) => {
                const selectedStyle = {
                    border: 'solid 1px #f8cf26',
                    color: '#1e2028'
                }
                return (
                    <li key={index} onClick={() => this.selectItem(index)} style={item.isChecked ? selectedStyle : null}>
                        {item[this.props.itemName]}
                    </li>
                )
            })
        }

        let renderTooltip = () => {
            return (
              <div className={styleInputWithTooltip.tooltipContainer}>
                  <div className={styleInputWithTooltip.tooltipPoint}/>
                  <div className={styleInputWithTooltip.tooltipContent}>
                      <div className={styleInputWithTooltip.tooltipBoxCon}>
                          <ul className={styleInputWithTooltip.tooltipBox}>
                              {renderPropsItems()}
                          </ul>
                          <div style={this.props.disableInput ? {display: 'none'} : null}>
                              {this.state.isDirectInput?
                                <div className={styleBook.directInput} style={{clear: 'none'}}>
                                    <input type="text" autoFocus={true} onKeyPress={this.onKeyPress} value={this.state.directInput} style={{marginTop: 0, marginLeft: '10px'}}
                                           onChange={(e) => this.setState({ directInput: e.target.value })}
                                    />
                                </div> : null}

                          </div>
                      </div>
                      <div className={styleBook.addIconBox} style={{position: 'static', margin: '10px 30px 8px'}} onClick={this.showDirectInput}>
                          <img className={styleBook.addIcon} src="/Assets/img/btn-add.png"/> 직접입력
                      </div>
                      <div className={styleInputWithTooltip.okBtn} style={{clear: 'both'}} onClick={this.submit}>확인</div>
                  </div>
              </div>
            )
        }

        return (
          <div className={styleInputWithTooltip.componentContainer}>
              <div className={styleInputWithTooltip.inputContainer} onClick={(e) => this.open(e)}>
                  <ul className={styleInputWithTooltip.inputBox}>
                      {renderSelectedItems()}
                  </ul>
              </div>
              {this.state.isOpened ? renderTooltip() : null}
          </div>
        )
    }
}

export default connect()(withRouter(onClickOutside(Tooltip)))