module.exports.makeInsertValue = function(value) {
    if (!value || value.length === 0) {
        return null
    }

    return _.map(value.split(','), item => {
        let val = parseInt(item, 10)
        if (Number.isNaN(val)) {
            val = "'" + val + "'"
        }
        return '(' + val + ')'
    }).join(',')
}

module.exports.makeCommaString = function(value) {
    if (!value || value.length === 0) {
        return null
    }

    return _.map(value.split(','), item => {
        let val = parseInt(item, 10)
        if (Number.isNaN(val)) {
            val = "'" + val + "'"
        }
        return val
    }).join(',')
}

module.exports.makeNoDashPhoneNumber = function(value) {
    if (!value || value.length === 0) {
        return null
    }
    return value.replace(/-/g, '');
}

module.exports.numberWithCommas = (x) => {
    if (x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    return 0;
}

module.exports.addDashesToPhoneNumber = function(phoneNumber) {
    if (!phoneNumber) return '-'

    let result = phoneNumber;

    if (phoneNumber.length == 11) {
        result = phoneNumber.slice(0,3) + '-' + phoneNumber.slice(3,7) + '-' + phoneNumber.slice(7);
    } else if (phoneNumber.length == 10) {
        if (phoneNumber.charAt(1) == "2") {
            result = phoneNumber.slice(0,2) + '-' + phoneNumber.slice(2,6) + '-' + phoneNumber.slice(6);
        }
        else {
        result = phoneNumber.slice(0,3) + '-' + phoneNumber.slice(3,6) + '-' + phoneNumber.slice(6);
        }
    } else if (phoneNumber.length == 9) {
        result = phoneNumber.slice(0,2) + '-' + phoneNumber.slice(2,5) + '-' + phoneNumber.slice(5);
    }

    return result;
}
