import React, {Component} from 'react';
import {withRouter, Route, Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {Helmet} from "react-helmet";
import { withTranslation } from 'react-i18next'

// styles
import styleMobile from '../../Styles/Components/Mobile.css'
import { action } from '../../../Data'

// util
import * as parseUrlParameter from '../../../Utils/parseUrlParameter'


class WaitingStatus extends Component{
  constructor(props){
    super(props);

    this.state = {
      store: this.props.store.store,
      storePlace: [],
      storeReservationPlace: []
    }

    this.getStoreValues = this.getStoreValues.bind(this)
    this.refreshStatus = this.refreshStatus.bind(this)
  }

  componentDidMount() {
    this.getStoreValues();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.store !== prevProps.store
    || this.props.reservationUpdated !== prevProps.reservationUpdated) {
      this.getStoreValues();
    }
  }

  getStoreValues() {
      this.props.dispatch(action.store_place.getList({fk_storeId: this.state.store.storeId, orderMethod: 'asc'})).then((res) => {
          this.setState({
              storePlace: res
          })
      })

    this.props.dispatch(action.service_system.getServiceSystem()).then((res) => {
      this.setState({
        serviceSystem: res[0]
      })
    })

    let params = parseUrlParameter.parse(this.props.location.search);

    if (!params.storeReservationId) return;

    this.props.dispatch(action.store_reservation.getById({
        storeReservationId: params.storeReservationId
    })).then((res) => {
      console.log(res);
        this.setState({
          storeReservationPlace: res.store_reservation_place
        });
    });
  }

  refreshStatus() {
    this.props.dispatch(action.store_reservation.reservationUpdated(new Date()))
  }

  render(){
    const { t, i18n } = this.props;
    console.log('storeReservationPlace', this.state.storeReservationPlace);
    let params = parseUrlParameter.parse(this.props.location.search);
    const waitingTeams = this.state.storeReservationPlace?.length > 0 ? this.state.storeReservationPlace[0].waitingTeams : 0;

    let renderWaitingStatus = this.state.storePlace ? this.state.storePlace.map((place, index) => {
      if (index === 0) {
        return (
          <div className={styleMobile.mainWaiting} key={index}>
            <div className={styleMobile.borderCircle} style={this.props.store.store_system.isWaitingTime == 0 ? {paddingTop: '10px', height: '122px'} : null}>
              <div className={styleMobile.buildingName}>{i18n.language === 'ko' ? (place.store_place.name || place.store_place.nameEn) : (place.store_place.nameEn || place.store_place.name)}</div>
              <div className={styleMobile.waitingTeams}>{params.storeReservationId ? waitingTeams : place.store_place.waitingTeams } {t('team_unit')}</div>
              <div className={styleMobile.waiting}>{t('waiting')}...</div>
              {this.props.store.store_system.isWaitingTime == 0 ? null : <div className={styleMobile.waitingTime}>{place.store_place.waitingTime} {t('time_unit')}</div>}
              <div className={styleMobile.refreshIcon} onClick={this.refreshStatus}><img src="/Assets/img/ic-refresh.png"/></div>
            </div>
          </div>
        )
      } else {
        return (
          <div key={index} className={styleMobile.otherWaiting} style={index == 1 ? {marginTop: '90px'} : null}>
            <div style={this.props.store.store_system.isWaitingTime == 0 ? {width: '50%'} : null}>{i18n.language === 'ko' ? (place.store_place.name || place.store_place.nameEn) : (place.store_place.nameEn || place.store_place.name)}</div>
            <div style={this.props.store.store_system.isWaitingTime == 0 ? {width: '50%'} : null}>{place.store_place.waitingTeams} {t('team_unit')}</div>
            {this.props.store.store_system.isWaitingTime == 0 ? null : <div>{place.store_place.waitingTime} {t('time_unit')}</div>}
          </div>
        )}
    }) : null

    let emptyWaitingStatus =
        <div key='1' className={styleMobile.otherWaiting} style={{marginTop: '142px'}}>
        </div>;

    return (
      <div className={styleMobile.mobileContent} style={{borderTop:'none', paddingBottom:'24px'}}>
        <div style={{overflow: 'hidden'}}>
          <div className={styleMobile.storeImgBlur} style={{ backgroundImage: "url(" + (this.state.store ? this.state.store.storeUrl : "") + ")"}}/>
        </div>
        <div className={styleMobile.storeImg} style={{ backgroundImage: "url(" + (this.state.store ? this.state.store.storeUrl : "") + ")"}}/>
        {renderWaitingStatus}
        {this.state.storePlace.length > 1 ? null : emptyWaitingStatus}
        {!this.props.store.store_system.isMobile || this.props.store.system_payment.isVisitReservation == 0 ? null
        : <div className={styleMobile.reservationBtn} style={this.props.location.pathname==='/mobile/confirmation' ? this.props.customWaitingStatus: null}>
            <Link to="/mobile/book">{t('make_reservation')}</Link>
          </div>}
        <div className={styleMobile.kmLogo} style={this.props.location.pathname==='/mobile/confirmation' ? this.props.customWaitingStatus: null}>
          <img src={this.state.serviceSystem ? this.state.serviceSystem.service_system.whiteBgLogoUrl : "/Assets/img/km-logo-2-16-px.png"}/>
        </div>
      </div>
    )
  }
}

export default connect((state) => {
  return {
      store: state.data.store.store,
      reservationUpdated: state.data.store_reservation.reservationUpdated
  };
})(withRouter(withTranslation()(WaitingStatus)));