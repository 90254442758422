import React from 'react';

// Styles
import styleKeypad from '../../Styles/Components/Keypad.css';
import { connect } from 'react-redux'
import { withRouter } from 'react-router';

import onClickOutside from "react-onclickoutside";

class Keypad extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpened: false,
            numberString: []
        };

        this.close = this.close.bind(this)
        this.cancel = this.cancel.bind(this)
        this.delete = this.delete.bind(this)
        this.handleClick = this.handleClick.bind(this)
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }
    componentWillMount() {

    }
    componentDidMount() {
        this.props.onRef(this);
    }

    open() {
        this.setState({ isOpened: true });
    }

    close() {
        this.setState({ isOpened: false })
        console.log("<<keypad props>>", this.props);
        if(this.props.getStoreCustomerByPhoneNumber !== undefined)
            this.props.getStoreCustomerByPhoneNumber();
        else console.log("<<keypad getStoreCustomerByPhoneNumber props MIA>>");
    }

    handleClickOutside(e) {
        this.close();
    }

    cancel() {
        this.setState({
            numberString: []
        }, () => {
            let resultNum = '';
            if (this.state.numberString.length > 0) {
                resultNum = this.state.numberString.join('');
            }

            this.props.onChange(resultNum ? Number(resultNum) : '');
        })

        this.close()
    }

    delete() {
        const deletedNum = this.state.numberString.pop()

        this.setState({
            numberString: this.state.numberString
        }, () => {
            let resultNum = '';
            if (this.state.numberString.length > 0) {
                resultNum = this.state.numberString.join('');
            }

            if (!this.props.isString) {resultNum = Number(resultNum);}
            this.props.onChange(resultNum !== '' ? resultNum : '');
        })
    }

    handleClick(num) {
        this.setState({
            numberString: [...this.state.numberString, num],
        },() => {
            let resultNum = '';
            if (this.props.isString && (this.state.numberString.length == 3 || this.state.numberString.length == 8)) {
                this.state.numberString.push('-');
            }
            if (this.state.numberString.length > 0) {
                resultNum = this.state.numberString.join('');
            }

            if (!this.props.isString) {resultNum = Number(resultNum);}
            this.props.onChange(resultNum !== '' ? resultNum : '');
        })
    }

    render() {
        let flipStyle = {
            marginTop: "339px",
            transform: "rotate(225deg)"
        };
        let keypadStyle = {
            fontSize: "24px",
            fontWeight: "400"
        };
        if (this.state.isOpened) {
            return (
              <div className={styleKeypad.keypadContainer} style={this.props.customStyle}>
                  <div className={styleKeypad.keypadPoint} style={this.props.flip ? flipStyle : null}/>
                  <div className={styleKeypad.keypadContent}>
                      <ul className={styleKeypad.keypadBox + ' ' + this.props.customKeypadStyle} style={keypadStyle}>
                          <li onClick={() => this.handleClick(1)}>1</li><li onClick={() => this.handleClick(2)}>2</li><li onClick={() => this.handleClick(3)}>3</li>
                          <li onClick={() => this.handleClick(4)}>4</li><li onClick={() => this.handleClick(5)}>5</li><li onClick={() => this.handleClick(6)}>6</li>
                          <li onClick={() => this.handleClick(7)}>7</li><li onClick={() => this.handleClick(8)}>8</li><li onClick={() => this.handleClick(9)}>9</li>
                          <li onClick={this.cancel}>취소</li><li onClick={() => this.handleClick(0)}>0</li><li onClick={this.delete}>지우기</li>
                      </ul>
                      <div className={styleKeypad.okBtn} onClick={this.close}>확인</div>
                  </div>
              </div>
            )
        } else {
            return null
        }
    }
}

export default connect()(withRouter(onClickOutside(Keypad)));