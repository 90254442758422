import * as ActionTypes from '../actionTypes';

export const initialState = {
    author: {

    },
    account: {

    },
    account_list: [

    ],
    isUserLoggedIn: false
}
/*
*/
export const account_reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.LOGIN:
            return Object.assign({}, state, { author: action.author, isUserLoggedIn: true });
        case ActionTypes.LOGOUT:
            return Object.assign({}, state, { author: initialState.author, isUserLoggedIn: false });
        case ActionTypes.ACCOUNT:
            return Object.assign({}, state, { account: action.account });
        case ActionTypes.ACCOUNT_LIST:
            return Object.assign({}, state, { account_list: initialState.account_list });
        default:
            return state;
    }
}


