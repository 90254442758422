import React from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router';
import onClickOutside from "react-onclickoutside";

//Styles
import styleInputWithTooltip from '../../Styles/Components/InputWithTooltip.css';
import styles from './ccTimePicker.css';
import { parseUrlParameter } from '@/Utils'

//분명히 내가 짰는데 뭔 소린지 1도 모르겠다. 주석을 생활화하자
class ccTimePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpened: false,

      generalTime: ["오전", "오후"],
      hours: [...Array(13).keys()].slice(1),
      minutes: [...Array(6).keys()].map(x => x*10),
      reservationTime: props.reservationTime ? props.reservationTime : new Date(),
      selected: {
        generalTime: 0,
        hours: 0,
        minutes: 0
      },
      isTimeSelected: false,
    };

    this.selectItem = this.selectItem.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.submit = this.submit.bind(this);

    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
  }

  componentDidMount() {
    if(this.props.onRef !== undefined) this.props.onRef(this);

    if (this.props.selectedReservationId) {
      this.setState({
        isTimeSelected: true,
      })
    }
  }

  selectItem(type, index) {
    let newState = {};
    newState.selected = this.state.selected;
    //if same, unselect
    newState.selected[type] = (newState.selected[type] === index) ?  null : index;
    this.setState(newState);
  }

  submit() {
    if(this.state.selected.generalTime === null || this.state.selected.hours === null || this.state.selected.minutes === null)
      return this.props.handleChangingStatus('예약 시간을 지정하세요.', false);
    let newState = {reservationTime: this.state.reservationTime};

    //set hours, add 12 if after noon
    (this.state.selected.generalTime === 0 && this.state.selected.hours == 11) ? newState.reservationTime.setHours(this.state.selected.hours + 13) :
    (this.state.selected.generalTime === 1 && this.state.selected.hours == 11) ? newState.reservationTime.setHours(this.state.selected.hours + 1) :
    (this.state.selected.generalTime === 1 && !(this.state.selected.generalTime === 0 && this.state.selected.hours == 11) && !(this.state.selected.generalTime === 1 && this.state.selected.hours == 11)) ?
      newState.reservationTime.setHours(this.state.selected.hours + 13) : newState.reservationTime.setHours(this.state.selected.hours + 1)

    newState.reservationTime.setMinutes(this.state.selected.minutes*10);
    this.setState(newState, () => {
      this.props.onChange(this.state.reservationTime);
      this.close();
    });

    this.setState({
      isTimeSelected: true
    })
  }

  handleClickOutside(e) {
    this.close();
  }

  open(e) {
    //actually I have no idea why this if statement is there but I'm just copying from /InputWithTooltip/index.jsx
    //and the guy who wrote that seems to know what he/she is doing so sue me '_'. Actually that guy. /s
    if (e.target.className.indexOf('preventClick') === -1) this.setState({ isOpened: true });
  }

  close() {
    this.setState({
      isOpened: false
    });
  }


  render() {
    let renderItems = () => {
      let selectedStyle = {
        fontWeight: "bold",
        color: "#ffffff",
        width: "64px",
        marginLeft: "-10px",
        height: "28px",
        borderRadius: "16px",
        backgroundColor: "#5b9fdc"
      };
      let unselectedStyle = {};
      return (
      <div className={styles.row}>
        <div className={styles.column} style={{marginLeft: "24px"}}>
          {this.state.generalTime.map((item,index) => {
              const type = "generalTime";
              return (
                <li className={styles.primaryText} key={index} style={this.state.selected[type] === index ? selectedStyle : unselectedStyle} onClick={(e) => this.selectItem(type,index)}>
                  {item}
                </li>
              )
            })
          }
        </div>
        <div className={styles.columnHalf} style={{marginTop: "14px",marginLeft: "30px"}}>
          {this.state.hours.map((item,index) => {
            selectedStyle = {
              fontWeight: "bold",
              color: "#ffffff",
              width: "38px",
              height: "28px",
              borderRadius: "16px",
              backgroundColor: "#5b9fdc",
            }
            unselectedStyle.marginLeft = "-4px";
            const type = "hours";
            if(index < 6) return (
              <li className={styles.primaryText} key={index} style={this.state.selected[type] === index ? selectedStyle : unselectedStyle} onClick={(e) => this.selectItem(type,index)}>
                {item}
              </li>
            )
          })
          }
        </div>
        <div className={styles.columnHalf} style={{marginTop: "14px",marginLeft: "14px"}}>
          {this.state.hours.map((item,index) => {
            const type = "hours";
            if(index >= 6)return (
              <li className={styles.primaryText} key={index} style={this.state.selected[type] === index ? selectedStyle : unselectedStyle} onClick={(e) => this.selectItem(type,index)}>
                {item}
              </li>
            )
          })
          }
        </div>
        <div className={styles.column} style={{marginTop: "13px",marginLeft:"41px",}}>
          {this.state.minutes.map((item,index) => {
            const type = "minutes";
            return (
              <li className={styles.primaryText} key={index} style={this.state.selected[type] === index ? selectedStyle : unselectedStyle} onClick={(e) => this.selectItem(type,index)}>
                {(item === 0) ? "00" : item}
              </li>
            )
          })
          }
        </div>
      </div>
      )
    }

    let renderOriginal = () => {
      //korean time + 9
      let hour = this.state.reservationTime.getUTCHours()+9;
      hour = hour < 24 ? hour : hour - 24;
      if(hour < 10) hour = "0" + hour.toString();

      let minutes = this.state.reservationTime.getUTCMinutes();
      if(minutes < 10) minutes = "0" + minutes.toString();

      //let text = hour + ":" + minutes;

      return (
        <div className={styles.txtBox}>
          <div>{this.state.isTimeSelected ? hour : null}</div>
          <div style={{margin: '0 4px'}}>:</div>
          <div>{this.state.isTimeSelected ? minutes : null}</div>
        </div>
      )
    }

    let renderTimePicker = () => {
      return (
        <div className={styleInputWithTooltip.tooltipContainer} style={{transform: "translateX(-180px)", height: '354px',width: '288px'}}>
          <div className={styleInputWithTooltip.tooltipPoint} style={{left: "50%"}}/>
          <div className={styleInputWithTooltip.tooltipContent} style={{width: '288px' }}>
            <ul>
              {renderItems()}
            </ul>
            <div className={styleInputWithTooltip.okBtn} onClick={this.submit}>확인</div>
          </div>
        </div>
      )
    }

    return (
      <div className = {styleInputWithTooltip.componentContainer}>
        <div className = {styleInputWithTooltip.inputContainer} style={{width: "100px", height: "50px"}} onClick={(e) => this.open(e)}>
          {renderOriginal()}
        {this.state.isOpened ? renderTimePicker() : null}
        </div>
      </div>
    )
  }
}

export default connect((state) => {
  return {
    selectedReservationId: state.data.store_reservation.selectedReservationId,
  }
})(withRouter(onClickOutside(ccTimePicker)))
//
// export default connect()(withRouter(onClickOutside(ccTimePicker)))
