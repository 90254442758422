import React, {Component} from 'react';
import {withRouter, Route} from 'react-router-dom';
import {connect} from 'react-redux';

// Components
import VisitComponent from '../../Components/Waiting/Visit'
import CallComponent from '../../Components/Waiting/Call'

class WaitingContainer extends Component{
  constructor(props){
    super(props);

    this.state = {
      isLoading: true,
    }
  }

  componentDidMount() {
    if (!this.props.store.store_system.isScreen && !this.props.store.store_system.isScreenMenu
    && this.props.location.pathname === '/waiting/call') {
      alert('호출모니터에 접근 할 수 없습니다.');
      return;
    }

    ///////isVisitReservation
    if (this.props.store.system_payment.isVisitReservation == 0 && this.props.location.pathname === '/waiting/visit') {
      alert('대기예약모드에 접근 할 수 없습니다.');
      return;
    }

    this.setState({
      isLoading: false
    })
  }

  render(){
    return(
      !this.state.isLoading ?
      <div>
        <Route path={'/waiting/visit'} exact={false} strict={false} component={VisitComponent}/>
        <Route path={'/waiting/call'} exact={false} strict={false} component={CallComponent}/>
      </div> : null
    )
  }
}

export default connect((state) => {
  return {
    store: state.data.store.store,
  }
})(withRouter(WaitingContainer))