import * as ActionTypes from '../actionTypes';

export const initialState = {
    comment_like: {},
    comment_like_list: []
}
/*
*/
export const comment_like_reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.COMMENT_LIKE:
            return Object.assign({}, state, {
                comment_like: action.comment_like
            });
        case ActionTypes.COMMENT_LIKE_LIST:
            return Object.assign({}, state, {
                comment_like_list: action.comment_like_LIST
            });
        default:
            return state;
        }
}
