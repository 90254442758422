import * as ActionTypes from '../actionTypes';

// API
import * as HttpApi from '../Api/index';

export const actionTypes = ActionTypes;

//////////////////
///// GET ////////
//////////////////
export const getServiceSystem = (params) => {
  return (dispatch) => {
    return HttpApi.get('GET_SERVICE_SYSTEM', params)
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };
}

export const getServiceDocs = (params) => {
  return (dispatch) => {
    return HttpApi.get('GET_SERVICE_DOCS', params)
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };
}

export const getSystemPayment = (params) => {
  return (dispatch) => {
    return HttpApi.get('GET_SYSTEM_PAYMENT', params)
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };
}

//////////////////
///// POST ///////
//////////////////
export const createServiceDocs = (params) => {
  return (dispatch) => {
    return HttpApi.post('POST_SERVICE_DOCS', params)
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };
}



//////////////////
///// PUT ////////
//////////////////
export const updateBasicSetting = (params) => {
  return (dispatch) => {
    return HttpApi.put('PUT_SERVICE_SYSTEM', params)
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };
};


export const updateServiceDocs = (params) => {
  return (dispatch) => {
    return HttpApi.put('UPDATE_SERVICE_DOCS', params)
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };
};


export const updateSystemPayment = (params) => {
  return (dispatch) => {
    return HttpApi.put('UPDATE_SYSTEM_PAYMENT', params)
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };
};

export const updateSystemPaymentWithPromise = async(params) => {
    return HttpApi.put('UPDATE_SYSTEM_PAYMENT', params)
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
};

//////////////////
///// DELETE ///////
//////////////////
export const deleteServiceDocs = (params) => {
  return HttpApi.del('DELETE_SERVICE_DOCS', params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}