import * as ActionTypes from '../actionTypes';

export const initialState = {
    store: {},
    store_list: [],
    recentCallInfo: null,
    openModal: null,
    timeForSearch: {
        startTime: null,
        endTime: null,
    },
}
/*
*/
export const store_reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.STORE:
            return Object.assign({}, state, {
                store: action.store
            });
        case ActionTypes.STORE_LIST:
            return Object.assign({}, state, {
                store_list: action.store_LIST
            });
        case ActionTypes.RECENT_CALL_INFO:
            return Object.assign({}, state, {
                recentCallInfo: action.recentCallInfo
            });
        case ActionTypes.OPEN_MODAL:
            return Object.assign({}, state, {
                openModal: action.openModal
            });
        case ActionTypes.TIME_FOR_SEARCH:
            return Object.assign({}, state, {
                timeForSearch: action.timeForSearch
            });
        default:
            return state;
        }
}
