import * as ActionTypes from '../actionTypes';

export const initialState = {
    store_memo: {},
    store_memo_list: []
}
/*
*/
export const store_memo_reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.STORE_MEMO:
            return Object.assign({}, state, {
                store_memo: action.store_memo
            });
        case ActionTypes.STORE_MEMO_LIST:
            return Object.assign({}, state, {
                store_memo_list: action.store_memo_LIST
            });
        default:
            return state;
        }
}
