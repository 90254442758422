import React, {Component} from 'react';
import onClickOutside from "react-onclickoutside";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import moment from 'moment';

// Components
import TimeRangePicker from '../../../Common/TimeRangePicker';
import DatePicker from "react-datepicker";

// Styles
import styleBoard from '../../../Styles/Components/Board.css'
import "react-datepicker/dist/react-datepicker.css";

// Utils
import {dateUtil} from "../../../../Utils";
import { action } from '../../../../Data'

class PeriodSearchBar extends Component  {
  constructor(props) {
    super(props);
    this.today = new Date();
    this.state = {
      store: {},
      searchQuery: '',

      startDate: new Date(moment().subtract(7, 'days')),
      endDate: new Date(),

      selectDays: [
        {
          name: '어제',
          period: 1,
          isClicked: false,
        },
        {
          name: '1주',
          period: 7,
          isClicked: true,
        },
        {
          name: '1달',
          period: 30,
          isClicked: false,
        }
      ]
    }

    this.handleSelectDay = this.handleSelectDay.bind(this)
    this.handleSearch = this.handleSearch.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.handleSelectPeriod = this.handleSelectPeriod.bind(this)
  }

  handleDateChange(start, end) {
    this.setState({
      startDate: start,
      endDate: end,
    })
    let selectDays = this.state.selectDays
    selectDays.forEach((day) => {
      day.isClicked = false
    })
    this.setState({
      selectDays: selectDays
    })
  }

  handleSelectPeriod(period, index) {
    if (index !== 0) {
      this.setState({
        startDate: new Date(moment(this.state.endDate).subtract(period,'days'))
      })
    } else {
      this.setState({
        startDate: new Date(moment(this.state.endDate).subtract(period,'days')),
      })
    }

    let selectDays = this.state.selectDays
    selectDays.forEach((day) => {
      day.isClicked = false
    })
    selectDays[index].isClicked = true

    this.setState({
      selectDays: selectDays
    })
  }

  handleSelectDay(index) {
    let selected = this.state.everyday
    selected[index].isClicked = !selected[index].isClicked

    this.setState({
      everyday: selected,
    })
  }

  handleSearch(){
    let params = {
      startDate: dateUtil.format('YYYY-MM-DD', this.state.startDate),
      endDate: dateUtil.format('YYYY-MM-DD',this.state.endDate),
    }

    this.props.handleSearch(params);
  }


  render() {
    let activeBtn2 = {
      backgroundColor: '#5b9fdc',
      color: '#fff'
    }

    let renderSelectDays = this.state.selectDays ? this.state.selectDays.map((day, index) => {
      return (
        <div key={index} className={styleBoard.periodBtn} onClick={() => this.handleSelectPeriod(day.period, index)} style={day.isClicked  ? activeBtn2 : null}>{day.name}</div>
      )
    }) : null

    return (
      <div className={styleBoard.searchPeriodBox} >
        <div className={styleBoard.searchPeriodTitle}>조회 기간</div>
        <div className={styleBoard.searchPeriodDateBox}>
          <TimeRangePicker
            onRef={(ref) => {this.timeRangePicker = ref}}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            onUpdate={(start, end) => this.handleDateChange(start, end)}
            dateFormat={'yyyy MM/dd'}
            orientation={'left'}
            handleChangingStatus={this.props.handleChangingStatus}
          />
        </div>
        <div className={styleBoard.periodContent}>
          {renderSelectDays}
        </div>
        <div className={styleBoard.searchBtn}>
          <img src="/Assets/img/ic-search.png" onClick={this.handleSearch}/>
        </div>
      </div>
    )
  }
}

let EnhancedComponent = onClickOutside(PeriodSearchBar);

export default connect((state) => {
  return {
    author: state.data.account.author,
  }
})(withRouter(EnhancedComponent))