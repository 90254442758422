import React, {Component} from 'react';
import {withRouter, Route, Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {Helmet} from "react-helmet";

// styles
import styleMobile from '../../Styles/Components/Mobile.css'
import styleBook from '../../Styles/Components/Book.css'
import { action } from '../../../Data'

// Components
import HeaderComponent from './Header'
import WaitingStatusComponent from './WaitingStatus'

// Utils
import * as parseUrlParameter from '../../../Utils/parseUrlParameter'
import { withTranslation } from 'react-i18next'
import styleReservationModal from '@/Service/Styles/Components/ReservationModal.css'

class ConfirmationView extends Component {
  constructor(props){
    super(props);

    this.state = {
      store: {},
      brand: {},
      lunchStartTime: '',
      dinnerEndTime: '',
      storePlace: [],
      storeReservation: {},
      selectedPlaces: [],
      selectedMenus: [],
    }

    this.getStoreValues = this.getStoreValues.bind(this);
    this.setStorePlaces = this.setStorePlaces.bind(this);
    this.setReservedMenus = this.setReservedMenus.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleChangingStatus = this.handleChangingStatus.bind(this);
  }

  componentDidMount() {
    this.setState({
      store: this.props.store.store,
      brand: this.props.store.brand,
    });
    this.getStoreValues();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.author !== prevProps.author) {
      this.setState({
        store: this.props.author.store,
        brand: this.props.author.brand,
      }, () => {
        this.setState({
          lunchStartTime: this.state.store.lunchStartTime ? this.state.store.lunchStartTime : '',
          dinnerEndTime: this.state.store.dinnerEndTime ? this.state.store.dinnerEndTime : '',
        })
      })
    }
  }

  getStoreValues() {
      let params = parseUrlParameter.parse(this.props.location.search);

      if (!params.storeReservationId) return;

      this.props.dispatch(action.store_reservation.getById({
          storeReservationId: params.storeReservationId
      })).then((res) => {
        console.log(res);
          this.setState({
              storeReservation: res.store_reservation,
              selectedMenus: res.store_reservation_menu,
              selectedPlaces: res.store_reservation_place,
          });
      });

    this.props.dispatch(action.service_system.getServiceSystem()).then((res) => {
      this.setState({
        serviceSystem: res[0]
      })
    })
  }

  setStorePlaces() {
      this.props.dispatch(action.store_place.getList({
          fk_storeId: this.state.store.storeId,
          order: 'createdAt',
          orderMethod: 'asc'
      })).then((res) => {
          this.setState({
              storePlace: res
          })
      })
  }

  setReservedMenus() {
      let menus = [];
      if (this.state.selectedMenus.length > 0) {
          menus = this.state.selectedMenus.map((item) => {
              return item.menu;
          })
      }
      return menus.join(' ');
  }

  handleChangingStatus(text, bool) {
    this.setState({
      isStatusChanging: true,
      alertText: text,
      isPositive: bool
    }, () => {
      setTimeout(() => {
        this.setState({ isStatusChanging: false });
      }, bool ? 1000 : 3000);
    })
  }

  handleCancel() {
      if (confirm('예약을 취소하시겠습니까?')) {
          const cancelReservation = this.state.selectedPlaces.map((item) => {
              return new Promise((resolve, reject) => {
                  this.props.dispatch(action.store_reservation_place.update({
                      storeReservationPlaceId: item.storeReservationPlaceId,
                      fk_storePlaceId: item.fk_storePlaceId,
                      waitingStatus: 20
                  })).then((res) => {
                      resolve(res);
                  }).catch((err) => {
                      reject(err);
                  })
              })
          });

          Promise.all(cancelReservation)
              .then((res) => {
                  this.handleChangingStatus('예약을 취소했습니다.', true);
                  setTimeout(() => {
                    this.props.history.push('/mobile');
                  }, 1000);
              })
              .catch((err) => {
                this.handleChangingStatus('예약 취소에 실패했습니다.', false);
              })
      }
  }

  render(){
    const { t, i18n } = this.props;

    let customWaitingStatus = {
      display: 'none'
    }

    let renderReservedPlaces = this.state.selectedPlaces.length > 0 ? this.state.selectedPlaces.map((item) => {
        return <div className={styleMobile.building} style={{width: (100/this.state.selectedPlaces.length) + '%'}}>
          {i18n.language === 'ko' ? (item.name || item.nameEn) : (item.nameEn || item.name)} <span>{item.waitingNumber}</span>
        </div>
    }) : null;

    return (
      <div>
        <Helmet
            title={'모바일 대기예약'}
        />
        <HeaderComponent />
        <WaitingStatusComponent customWaitingStatus={customWaitingStatus}/>
        <div className={styleMobile.mobileContent} style={{borderTop: 'none', paddingBottom:'24px'}}>
          {this.state.isStatusChanging && !this.state.isPositive ? <div className={styleReservationModal.completedAlert} style={{top: '40%', backgroundColor: '#F08080'}}>{this.state.alertText}</div> : null}
          {this.state.isStatusChanging && this.state.isPositive ? <div className={styleReservationModal.completedAlert} style={{top: '40%'}}>{this.state.alertText}</div> : null}
          <div className={styleMobile.reservationConfirm}>
            <div className={styleMobile.reservationResult}>
              <div className={styleMobile.myNumber}>
                <div className={styleMobile.title}>{t('my_waiting_number')}</div>
                  {renderReservedPlaces}
              </div>
              <div className={styleMobile.alignCenter}>
                <div className={styleMobile.myNumOfPpl}>
                  <div className={styleMobile.title}>{t('number_of_people')}</div>
                  <div className={styleMobile.gray}><span>{this.state.storeReservation.numberOfPeople}</span> {t('number_of_people_unit')}</div>
                </div>
                <div className={styleMobile.myBabyChair}>
                  <div className={styleMobile.title}>{t('baby_seat_amount')}</div>
                  <div className={styleMobile.gray}><span>{this.state.storeReservation.babySeatAmount}</span> {t('baby_seat_amount_unit')}</div>
                </div>
              </div>
            </div>
            {!this.props.store.store_system.isMenu ? null :
            <div className={styleMobile.myMenu}>
              <div className={styleMobile.title}>{t('menu')}</div>
              <div className={styleMobile.menuName}>{this.setReservedMenus()}</div>
            </div>}
            <p className={styleBook.extraInfo}>
              {i18n.language === 'ko' ? (this.state.store.guideText || this.state.store.guideTextEn) : (this.state.store.guideText || this.state.store.guideTextEn)}
            </p>
            <div className={styleMobile.cancelBtn} onClick={this.handleCancel}>
              {t('cancel_reservation')}
            </div>
          </div>
          <div className={styleMobile.kmLogo}>
            <img src={this.state.serviceSystem ? this.state.serviceSystem.service_system.whiteBgLogoUrl : "/Assets/img/km-logo-2-16-px.png"}/>
          </div>
        </div>
      </div>
    )
  }
}

export default connect((state) => {
  return {
      store: state.data.store.store
  };
})(withRouter(withTranslation()(ConfirmationView)));