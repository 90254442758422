import * as ActionTypes from '../actionTypes';

// API
import * as HttpApi from '../Api/index';

export const actionTypes = ActionTypes;

//////////////////
///// GET ////////
//////////////////
export const getList = (params) => {
	return (dispatch) => {
		return HttpApi.get('GET_SERVICE_DOCS_LIST', params)
			.then((response) => {
				return Promise.resolve(response.data);
			})
			.catch((err) => {
				return Promise.reject(err);
			});
	};
};

export const getCount = (params) => {
	return (dispatch) => {
		return HttpApi.get('GET_SERVICE_DOCS_COUNT', params)
			.then((response) => {
				return Promise.resolve(response.data);
			})
			.catch((err) => {
				return Promise.reject(err);
			});
	};
};

export const getById = (params) => {
	return (dispatch) => {
		return HttpApi.get('GET_SERVICE_DOCS_DETAIL', params)
			.then((response) => {
				return Promise.resolve(response.data);
			})
			.catch((err) => {
				return Promise.reject(err);
			});
	};
};

//////////////////
///// POST ///////
//////////////////
export const create = (params) => {
	return (dispatch) => {
		return HttpApi.post('POST_SERVICE_DOCS_CREATE', params)
			.then((response) => {
				return Promise.resolve(response.data);
			})
			.catch((err) => {
				return Promise.reject(err);
			});
	};
};

//////////////////
///// PUT  ///////
//////////////////
export const update = (params) => {
	return (dispatch) => {
		return HttpApi.put('PUT_SERVICE_DOCS_UPDATE', params)
			.then((response) => {
				return Promise.resolve(response.data);
			})
			.catch((err) => {
				return Promise.reject(err);
			});
	};
};

//////////////////
///// DELETE /////
//////////////////
export const remove = (params) => {
    return (dispatch) => {
        return HttpApi.del('DELETE_SERVICE_DOCS_DELETE', params)
            .then((response) => {
                return Promise.resolve(response.data);
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };
};

//////////////////
/// FUNCTIONS ////
//////////////////