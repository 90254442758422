import React, {Component} from 'react';
import {withRouter, Route} from 'react-router-dom';
import {connect} from 'react-redux';

// Components
import ReservationComponent from '../../Components/Store/Reservation'
import BoardComponent from '../../Components/Store/Board'
import StatisticsComponent from '../../Components/Store/Statistics'
import AdministratorComponent from '../../Components/Store/Administrator'
import CalendarComponent from '../../Components/Store/Calendar'

// Actions
import { action } from '../../../Data'

// styles
import styleHome from '../../Styles/Containers/home'

class StoreContainer extends Component{
  constructor(){
    super();
    this.state = {
        isLoading: false,
    }
  }
  componentWillMount() {

  }
  componentDidMount() {

  }
  render(){
    return(
      <div className={styleHome.homeContainer}>
        { this.state.isLoading ? null : <Route path={'/store/reservation'} exact={false} strict={false} component={ReservationComponent}/>}
            {/*<Route path={'/store/seatList'} exact={false} strict={false} component={SeatListComponent}/>*/}
          { this.state.isLoading ? null : <Route path={'/store/board'} exact={false} strict={false} component={BoardComponent}/>}
          { this.state.isLoading ? null : <Route path={'/store/statistics'} exact={false} strict={false} component={StatisticsComponent}/>}
          { this.state.isLoading ? null : <Route path={'/store/administrator'} exact={false} strict={false} component={AdministratorComponent}/>}
          { this.state.isLoading ? null : <Route path={'/store/calendar'} exact={false} strict={false} component={CalendarComponent}/>}
      </div>
    )
  }
}

export default connect((state) => {
    return {
        recentCallInfo: state.data.store.recentCallInfo,
        store_place: state.data.store_place.store_place,
        store: state.data.store.store,
    }
})(withRouter(StoreContainer))