import * as ActionTypes from '../actionTypes';

export const initialState = {
    notification: {},
    notification_list: []
}
/*
*/
export const notification_reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.NOTIFICATION:
            return Object.assign({}, state, {
                notification: action.notification
            });
        case ActionTypes.NOTIFICATION_LIST:
            return Object.assign({}, state, {
                notification_list: action.notification_LIST
            });
        default:
            return state;
        }
}
