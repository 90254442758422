import React, {Component} from 'react';
import {withRouter, Route, Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {Helmet} from "react-helmet";
import Modal from '../../../../Common/Modal';

// Components
import InfiniteList from '../../../../Common/InfiniteList';
import ReceiverList from '../ReceiverList';
import MessageBox from '../MessageBox';

// Actions
import { action } from '../../../../../Data';


// styles
import styleStore from '../../../../Styles/Components/Store.css'
import styleBoard from '../../../../Styles/Components/Board.css'
import Swiper from 'react-id-swiper';

// Utils
import { parseUrlParameter, converter, dateUtil } from '../../../../../Utils';
import styleReservationModal from '@/Service/Styles/Components/ReservationModal.css'

class BoardEtc extends Component{
    constructor(){
        super();

        this.state = {
            type: null,
            couponList: [],

            currentCoupon: {}, // 현재 쿠폰
            selectedList: [],
            selectedCoupon: {}, // 팝업에서 선택된 쿠폰
        }

        this.setEventType = this.setEventType.bind(this);

        this.handleSelectCoupon = this.handleSelectCoupon.bind(this);
        this.handleModal = this.handleModal.bind(this);

        this.handleAddToSelectedList = this.handleAddToSelectedList.bind(this);
        this.handleChangingStatus = this.handleChangingStatus.bind(this);
    }

    componentWillMount() {
        this.setState({
            customerParams: {
                fk_storeId: this.props.author && this.props.author.store ? this.props.author.store.storeId : null,
            },
        })
    }

    componentDidMount() {
        this.setEventType();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.search !== this.props.location.search) {
            this.setEventType();
        }
    }

    setEventType() {
        const query = parseUrlParameter.parse(this.props.location.search);
        this.setState({
            type: Number(query.type),
        }, () => {
            if (this.state.type === 1 || this.state.type === 2) {
/*
                this.getCouponList();
*/
                this.handleModal();
            }
        })
    }

    handleSelectCoupon() {
        if (!this.state.selectedCoupon) {
            return this.handleChangingStatus('쿠폰을 선택해주세요.', false);
        }
        this.setState({
            currentCoupon: this.state.selectedCoupon,
        }, () => {
            this.messageBox.setState({
                content: this.state.currentCoupon.content,
            })
            this.handleModal();
        });
    }

    handleModal() {
        this.couponModal.setState({
            isOpened: !this.couponModal.state.isOpened
        })
    }

    handleAddToSelectedList() {
        this.setState({
            selectedList: this.receiverList.state.selectedList
        })
    }

    handleChangingStatus(text, bool) {
        this.setState({
            isStatusChanging: true,
            alertText: text,
            isPositive: bool
        }, () => {
            setTimeout(() => {
                this.setState({ isStatusChanging: false });
            }, bool ? 1000 : 3000);
        })
    }

    render(){
        // 쿠폰 선택 창 팝업
        let renderPopUpBody = () => {
            let storeList = () => {
                return this.state.couponList.length > 0 ? this.state.couponList.map((coupon) => {
                    return <div key={coupon.storeCouponId} style={{backgroundColor: coupon.storeCouponId === this.state.selectedCoupon.storeCouponId ? 'orange' : 'transparent'}} onClick={() => {this.setState({ selectedCoupon: coupon })}}>{coupon.title}</div>
                }) : null;
            }

            return (
                <div style={{position: 'relative'}}>
                    <p>명함이벤트 쿠폰</p>
                    <Swiper slideClass={styleBoard.slide}>
                        {storeList()}
                    </Swiper>
                    <div onClick={this.handleSelectCoupon} style={{position: 'absolute', width: '100px', height: '20px', bottom: '-100px', right: '0px'}}>쿠폰선택</div>
                </div>
            )
        }

        return(
            <div className={styleStore.homeContainer}>
                <div className={styleStore.homeContent}>
                    {this.state.isStatusChanging && !this.state.isPositive ? <div className={styleReservationModal.completedAlert} style={{top: '40%', backgroundColor: '#F08080'}}>{this.state.alertText}</div> : null}
                    {this.state.isStatusChanging && this.state.isPositive ? <div className={styleReservationModal.completedAlert} style={{top: '40%'}}>{this.state.alertText}</div> : null}
                    <ReceiverList
                        pageTitle={'기타 문자 전송'}
                        onRef={(ref) => {this.receiverList = ref;}}
                        handleAddToSelectedList={this.handleAddToSelectedList}
                        handleChangingStatus={this.handleChangingStatus}
                    />
                    <MessageBox
                        onRef={(ref) => {this.messageBox = ref;}}
                        handleModal={this.handleModal}
                        disableModal={true}
                        selectedList={this.state.selectedList}
                        handleChangingStatus={this.handleChangingStatus}
                    />
                    <Modal onRef={(ref) => {this.couponModal = ref;}} modalBody={renderPopUpBody()}/>
                </div>
            </div>
        )
    }
}

export default connect((state) => {
    return {
        author: state.data.account.author,
    }
})(withRouter(BoardEtc))