import React, {Component} from 'react';
import {withRouter, Route} from 'react-router-dom';
import {connect} from 'react-redux';

// styles
import styleReservationModal from '../../../../Styles/Components/ReservationModal.css';

// action
import { action } from '../../../../../Data/index';

// Utils
import { dateUtil } from '../../../../../Utils/index';

class CustomerDetailModal extends Component{
  constructor(props){
    super(props);
    this.state = {
      reservedAt: new Date(),
      originalPhoneNumber: '',
      name: '',

      store: {},
      brand: {},
      storeCustomer: {},

      selectedColor: {
        0: { color: '#ffffff', active: '/Assets/img/label-inactive-blacklist.png', inactive: '/Assets/img/label-inactive-blacklist.png', isChecked: false },
        1: { color: '#22d256', active: '/Assets/img/label-guest-state-01.png', inactive: '/Assets/img/label-guest-state-01-selected.png', isChecked: false },
        2: { color: '#f8cf26', active: '/Assets/img/label-guest-state-02.png', inactive: '/Assets/img/label-guest-state-02-selected.png', isChecked: false },
        3: { color: '#ec5126', active: '/Assets/img/label-guest-state-03.png', inactive: '/Assets/img/label-guest-state-03-selected.png', isChecked: false }
      },

    };

    this.handleReservationValues = this.handleReservationValues.bind(this)
  }

  componentWillMount() {
    this.setState({
      store: this.props.store.store || {},
      brand: this.props.store.brand || {},
    });
  }

  componentDidMount() {
    this.props.onRef(this);
    console.log(this.props)
    console.log(this.props)
    console.log(this.props)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.author !== prevProps.author && this.props.customerDetail !== prevProps.customerDetail) {
      this.setState({
        store: this.props.store.store,
        brand: this.props.store.brand
      })
    }
  }

  handleReservationValues(value, field, state) {
    let newState = {};
    newState[state] = this.state[state];

    newState[state][field] = value;

    this.setState(newState);
  }

  render(){
    let vipStyle = {
      color: '#1e2028',
      border: '1px solid #7434cc'
    }

    let renderVisitRecords = this.props.visitRecords ? this.props.visitRecords.map((list, index) => {
      return (
        <ul key={index} className={styleReservationModal.listHeader}>
          <li>{list.store_reservation.visitStatus}</li>
          <li>{dateUtil.format('slash', list.store_reservation.reservedAt)}</li>
          <li>{list.store_reservation.reservedAt.substr(11, 5)}</li>
          <li>{list.store_reservation.numberOfPeople}명</li>
          <li>0원</li>
        </ul>
      )
    }) : null;

    let renderTextRecords = this.props.textRecords ? this.props.textRecords.map((list, index) => {
      return (
        <ul key={index} className={styleReservationModal.listHeader}>
          <li>
            {list.notification.type === 20 || list.notification.type === 21 ? '쿠폰' : null}
            {list.notification.type === 30 || list.notification.type === 31 ? '공지' : null}
            {list.notification.type >= 1 && list.notification.type <= 13 ? '예약' : null}
            {list.notification.type === 99 ? '기타' : null}
          </li>
          <li>{dateUtil.format('slash', list.notification.sendAt)}</li>
          <li>{list.notification.sendAt.substr(11, 5)}</li>
          <li>{list.notification.isUsed === 1 ? '완료' : ''}</li>
          <li>{list.notification.message}</li>
        </ul>
      )
    }) : null;

    let renderStoreCustomerInfos = this.props.storeCustomerInfos ? this.props.storeCustomerInfos.map((info, index) => {
      return (
        <li key={index}>
          {info.store_customer_info.content}
        </li>
      )
    }) : null

    return(
      <div>
        <div className={styleReservationModal.modalLeft}>
          <div className={styleReservationModal.visitNum}>
            방문횟수 <span>{this.props.customerDetail ? this.props.customerDetail.visitAmount : 0}</span>
          </div>
          <div className={styleReservationModal.noShow}>
            No Show <span>{this.props.customerDetail ? this.props.customerDetail.noShowAmount : '-'}</span>
          </div>
          <div className={styleReservationModal.recentCall}>
            최근통화 { dateUtil.getFromNow(this.state.storeCustomer.calledAt)}
          </div>
          <div className={styleReservationModal.contactInfo + ' ' + styleReservationModal.contactInfo2}>
            <div className={styleReservationModal.phone}>
              <input type="text" placeholder="010-1234-1235" value={this.props.customerDetail ? this.props.customerDetail.phoneNumber : ''} />
            </div>
            <div className={styleReservationModal.name}>
              <input type="text" placeholder="미입력" value={this.props.customerDetail ? this.props.customerDetail.name : ''}
                     style={this.props.customerDetail && this.props.customerDetail.isVip ? {color: '#7434cc'} : null}/>
              <div className={styleReservationModal.blackList} style={{backgroundColor: this.state.selectedColor.color}}/>
            </div>
          </div>
          <div>
            <ul className={styleReservationModal.stateBtn} style={{float: 'none'}}>
              <li onClick={() => this.handleReservationValues(!this.props.customerDetail.isVip, 'isVip', 'storeCustomer')} style={this.props.customerDetail && this.props.customerDetail.isVip ? vipStyle : null}>VIP</li>
              <li onClick={() => {this.setState({showColors: !this.state.showColors, type: 0})}}>
                <img src={this.state.selectedColor[this.props.customerDetail ? this.props.customerDetail.type : 0].inactive} />
              </li>
            </ul>
            {/*{this.state.showColors ?*/}
              {/*<div className={styleReservationModal.colorList}>*/}
                {/*{*/}
                  {/*Object.keys(this.state.selectedColor).map((key, index) => {*/}
                    {/*return this.props.customerDetail.type != key ?*/}
                      {/*<li key={index} onClick={() => this.setState({ showColors: false }, () => {*/}
                        {/*this.handleReservationValues(key, 'type', 'storeCustomer')*/}
                      {/*})}>*/}
                        {/*<img src={this.state.selectedColor[key].active} />*/}
                      {/*</li> : null*/}
                  {/*})*/}
                {/*}*/}
              {/*</div>*/}
              {/*: null}*/}
          </div>
          <div className={styleReservationModal.borderBottom}></div>
          <div className={styleReservationModal.storeCustomerInfo}>
            <div className={styleReservationModal.infoTitle}>고객 정보</div>
            <ul className={styleReservationModal.listBox2}>
              {renderStoreCustomerInfos}
            </ul>
          </div>
        </div>
        <div className={styleReservationModal.modalRight}>
          <div className={styleReservationModal.rightDiv}>
            <div className={styleReservationModal.listTitle}>방문 기록</div>
            <ul className={styleReservationModal.listHeader}>
              <li>상태</li><li>날짜</li><li>시간</li><li>인원</li><li>매출액</li>
            </ul>
            {renderVisitRecords}
          </div>
          <div className={styleReservationModal.rightDiv}>
            <div className={styleReservationModal.listTitle}>문자 내역</div>
            <ul className={styleReservationModal.listHeader}>
              <li>상태</li><li>날짜</li><li>시간</li><li>사용</li><li>내용</li>
            </ul>
            {renderTextRecords}
          </div>
        </div>
      </div>
    );
  }
}

export default connect((state) => {
  return {
    author: state.data.account.author,
    store: state.data.store.store,
    store_place: state.data.store_place.store_place,
    store_place_floor: state.data.store_place_floor.store_place_floor,
    reservationUpdated: state.data.store_reservation.reservationUpdated
  }
})(withRouter(CustomerDetailModal))
