// Pages
import RootPage from './Containers/Root';

const routes = [
    {
        path: '/',
        exact: true,
        strict: false,
        component: RootPage,
    },
    {
        path: '/auth',
        exact: false,
        strict: false,
        component: RootPage,
    },
    {
        path: '/store',
        exact: false,
        strict: false,
        component: RootPage,
    },
    {
        path: '/waiting',
        exact: false,
        strict: false,
        component: RootPage,
    },
    {
        path: '/mobile',
        exact: false,
        strict: false,
        component: RootPage,
    },
    { // 전화예약 테스트
        path: '/test',
        exact: false,
        strict: false,
        component: RootPage,
    },
];
export default routes;
