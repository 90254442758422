/*
* @params  {string} search
*/
export const parse = ( search ) => {
    let vars = search.substr(1).split('&');
    let queryString = {};
    for (let i = 0; i < vars.length; i++) {
        let pair = vars[i].split('=');
        // If first entry with this name
        if (typeof queryString[pair[0]] === 'undefined') {
            queryString[pair[0]] = decodeURIComponent(pair[1]);
            // If second entry with this name
        } else if (typeof queryString[pair[0]] === 'string') {
            let arr = [queryString[pair[0]], decodeURIComponent(pair[1])];
            queryString[pair[0]] = arr;
            // If third or later entry with this name
        } else {
            queryString[pair[0]].push(decodeURIComponent(pair[1]));
        }
    }
    return queryString;
}

/*
* params => key, value 형태의 json
* queryString => 현재 url의 쿼리 정보
 */
export const setQueryString = ( queries, queryString, removeQuery, isOnlyNewQuery ) => {
    try {
        let result = null;
        let queryKeyAndValue = queryString ? parse(queryString) : {};

        if (isOnlyNewQuery) queryKeyAndValue = {};

        queryKeyAndValue = Object.assign(queryKeyAndValue, queries)

        result = Object.keys(queryKeyAndValue).filter((key) => {
            if (removeQuery && removeQuery.length > 0) {
                return removeQuery.indexOf(key) === -1;
            }
            return key;
        }).map((key) => {
            return key + '=' + queryKeyAndValue[key]
        })
        return '?' + result.join('&');
    } catch (e) {
        console.log(e);
        return;
    }
}