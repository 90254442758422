import React, {Component} from 'react';
import {Route, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import { MoonLoader } from 'react-spinners';
import * as Const from '../../../../Data/const';

// Actions
import {action} from "../../../../Data/index";
// styles

// component
import Tables from '@/Service/Common/TableManager/Tables';

// Utils
import { converter, parseUrlParameter, string } from '../../../../Utils'
import _ from 'underscore'
import empty from 'just-is-empty'
import {dateUtil} from "@/Utils";
import styleReservation from '@/Service/Styles/Components/Reservation.css'
import ReservationList from '@/Service/Components/Store/Reservation/ReservationList'

class TableView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            store: {},
            brand: {},

            selectedPlace: {},
            selectedFloor: {},
            selectedDate: new Date(),
            selectedTime: 0,

            tableDataValue: {
                tableDataList: [],
                floorTableData: []
            },
        }

      this.updateStoreTableValues = this.updateStoreTableValues.bind(this);
	    this.getStoreValues = this.getStoreValues.bind(this);
	    this.getStoreTableValues = this.getStoreTableValues.bind(this);
	    this.getStorePlaceFloor = this.getStorePlaceFloor.bind(this);
    }

    componentWillMount() {
        console.log("Reservation >> index :: componentWillMount");
        const params = parseUrlParameter.parse(this.props.location.search);
        /*if (params.date) {
            this.props.dispatch(action.store_reservation.setSelectedDate(new Date(params.date)));
        }*/
        this.getStoreValues();
        setInterval(this.getStoreTableValues, Const.intervalTime);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.author && this.props.author.accountId !== prevProps.author.accountId) {
            this.getStoreValues();
        }

        if (this.props.reservationUpdated !== prevProps.reservationUpdated
        || this.props.store_place !== prevProps.store_place
        || this.props.store_place_floor !== prevProps.store_place_floor
        || this.props.location.search !== prevProps.location.search) {
            this.getStoreValues();
        }
        if (prevState.tableDataValue
            && prevState.tableDataValue.floorTableData
            && prevState.tableDataValue.floorTableData.length > 0
            && JSON.stringify(this.state.tableDataValue.floorTableData) !== JSON.stringify(prevState.tableDataValue.floorTableData)) {
            this.getStoreValues();
        }
        // 전화예약 체크
        if (this.props.recentCallInfo && (prevProps.recentCallInfo !== this.props.recentCallInfo)) {
            this.openCustomerModal();
        }
    }

    // 테이블 정보 리셋
    getStoreTableValues() {
        const params = parseUrlParameter.parse(this.props.location.search);
        console.log(params);
        if (!params.date || !params.storeId || !params.storePlaceId || !params.storePlaceFloorId ||!params.reservationStartTime || !params.reservationEndTime) {
            alert('필수 정보가 부족합니다.');
            return;
        }
        let apiParams = {
            count: 100,
            fk_storeId: params.storeId,
            fk_storePlaceId: params.storePlaceId,
            fk_storePlaceFloorId: params.storePlaceFloorId,
            reservationStartTime: dateUtil.toISOString(params.date + ' ' + params.reservationStartTime),
            reservationEndTime: dateUtil.toISOString(params.date + ' ' + dateUtil.isDayBreak(params.reservationEndTime))
        }

        this.props.dispatch(action.store_table.getList(apiParams)).then((result) => {
            const storeTableList = result.map((item) => {
                let storeTable = item.store_table;
	            storeTable.storeId = storeTable.fk_storeId;
	            storeTable.storePlaceFloorId = storeTable.fk_storePlaceFloorId;
	            storeTable.storePlaceId = storeTable.fk_storePlaceId;
	            storeTable.tableInfo = JSON.parse(storeTable.tableInfo);
	            storeTable.store_reservation = item.store_reservation;
	            ////// 새로 추가 (김진혁) - 이름별, 메뉴별 토글할 때 필요한 값 ////////
                /////////////// mode 값 정의 //////////////////////////////////////////
                //// 없거나 customer => 이름 띄우기 ///////////////////////////////////
                //// menu => 메뉴 띄우기 //////////////////////////////////////////////
                if (params.mode === 'menu') {
                    storeTable.mode = 'menu';
                } else if (params.mode === 'customer') {
                    storeTable.mode = 'customer';
                } else {
                    storeTable.mode = 'customer';
                }

                return storeTable;
            });
            let updateSelectFloorData = [];
            let findFloorData = _.filter(storeTableList, (item) => {
                return item.storePlaceId == params.storePlaceId && item.storePlaceFloorId == params.storePlaceFloorId;
            });
            if(!empty(findFloorData)){
                updateSelectFloorData = [].concat(findFloorData);
            }
            this.setState({
                tableDataValue: {
                    tableDataList:storeTableList,
                    floorTableData: updateSelectFloorData
                }
            }, () => {
            });
        });
    }


    getStoreValues() {
        // GET 파라미터 파싱 (?xxx=xxx)

        this.setState({
            account: this.props.author.account,
            store: this.props.store.store || {},
            brand: this.props.store.brand || {},
            selectedPlace: this.props.store_place || {},
            selectedFloor: this.props.store_place_floor || {},
            selectedDate: this.props.selectedDate || new Date(),
            selectedTime: this.props.selectedTime || 0,
            tableDataValue: {
                tableDataList:[],
                floorTableData: []
            }
        }, () => {
            this.getStorePlaceFloor();
            this.getStoreTableValues();
        });
    }

    getStorePlaceFloor() {
        const params = parseUrlParameter.parse(this.props.location.search);

        this.props.dispatch(action.store_place_floor.getById({
            storePlaceFloorId: params.storePlaceFloorId
        })).then((res) => {
            if (res) {
                this.setState({
                    selectedPlace: res.store_place,
                    selectedFloor: res.store_place_floor,
                })
            }
        })
    }

    updateStoreTableValues(storeReservation) {
        let floorTableData = this.state.tableDataValue.floorTableData;
        for (let i = 0; i < storeReservation.length; i++) {
            if (storeReservation[i].store_reservation_table) {
                for (let a = 0; a < floorTableData.length; a++) {
                    if (storeReservation[i].store_reservation_table[0].fk_storeTableId == floorTableData[a].storeTableId) {
                        floorTableData[a].store_customer = storeReservation[i].store_customer;
                        floorTableData[a].store_reservation = storeReservation[i].store_reservation;
                    }
                }
            }
        }
        this.setState({
            tableDataValue: {
                tableDataList: this.state.tableDataValue.tableDataList,
                floorTableData: floorTableData
            }
        })
    }


    render() {
        if (this.state.tableDataValue && this.state.tableDataValue.floorTableData) {
          return (
                <div style={{'backgroundColor': '#F9F9F9', 'width': '100%', 'height': '100%'}}>
                    <div className={styleReservation.tableBgText1}>{this.state.selectedPlace.name}</div>
                    <div className={styleReservation.tableBgText2}>{this.state.selectedFloor.name}</div>
                    <Tables
                      editValue={{index : -1}}
                      floorTableData={this.state.tableDataValue.floorTableData}
                      selectTableShape={() => {}}
                      setTablePosition={() => {}}
                      ref={this.tableRef}
                    />
                </div>
          )
        } else {
          return (
            <div style={{marginLeft: 'auto', marginRight: 'auto', marginTop: '100px', width: '100px'}}>
              <MoonLoader
                size={80}
                color={'#dae8f2'}
              />
            </div>
          )
        }
    }
}

export default connect((state) => {
    return {
        author: state.data.account.author,
        store: state.data.store.store,
        recentCallInfo: state.data.store.recentCallInfo,
        store_place: state.data.store_place.store_place,
        store_place_floor: state.data.store_place_floor.store_place_floor,
        selectedReservationId: state.data.store_reservation.selectedReservationId,
        selectedDate: state.data.store_reservation.selectedDate,
        selectedTime: state.data.store_reservation.selectedTime,
        reservationUpdated: state.data.store_reservation.reservationUpdated,
        timeForSearch: state.data.store.timeForSearch
    }
})(withRouter(TableView))
