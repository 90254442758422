import * as ActionTypes from '../actionTypes';

export const initialState = {
    store_customer: {},
    store_customer_list: [],
    selectedCustomerId: null

}
/*
*/
export const store_customer_reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.STORE_CUSTOMER:
            return Object.assign({}, state, {
                store_customer: action.store_customer
            });
        case ActionTypes.STORE_CUSTOMER_LIST:
            return Object.assign({}, state, {
                store_customer_list: action.store_customer_LIST
            });
        case ActionTypes.SELECTED_CUSTOMER_ID:
            return Object.assign({}, state, {
                selectedCustomerId: action.selectedCustomerId
            });
        default:
            return state;
        }
}
