/**
 * @providesModule Converter
 */

/*
* @params  {number} amount
*/
export const compareJSONList = (a1, a2) => {
    // 둘 중 하나라도 0개인 경우

    if (a1.length === 0 || a2.length === 0) {
        return a1.length === 0 && a2.length === 0;
    }

    const orderedArray1 = a1.map((item) => {
        const ordered = {};
        Object.keys(item).sort().forEach((key) => {
            ordered[key] = item[key];
        });
        return ordered;
    });

    const orderedArray2 = a2.map((item) => {
        const ordered = {};
        Object.keys(item).sort().forEach((key) => {
            ordered[key] = item[key];
        });
        return ordered;
    });

    return JSON.stringify(orderedArray1) === JSON.stringify(orderedArray2);
}

// array1 => 원래 배열
// array2 => 바뀐 배열
// keyField => 아이디 키값 이름
export const getJSONArrayChangedValues = (array1, array2, keyField) => {
    if (compareJSONList(array1, array2)) return null; // 배열 같으면 null 반환
    return {
        created: differenceInFirstArray(array2, array1, keyField),
        updated: filterDifferentValueInSameKey(array1, array2, keyField),
        deleted: differenceInFirstArray(array1, array2, keyField),
    }
}

function filterDifferentValueInSameKey(array1, array2, keyField) {
    let sameInFirstArray1 = sameInFirstArray(array1, array2, keyField);
    let sameInFirstArray2 = sameInFirstArray(array2, array1, keyField);

    let updated = [];

    var sorting = (a,b) => {
        if (a[keyField] < b[keyField]) {
            return -1;
        }
        if (a[keyField] > b[keyField]) {
            return 1;
        }
    }

    sameInFirstArray1.sort(sorting);
    sameInFirstArray2.sort(sorting);

    var keyObj1 = Object.keys(sameInFirstArray1);
    var keyObj2 = Object.keys(sameInFirstArray2);

    // find values
    var valueObj1 = Object.values(sameInFirstArray1);
    var valueObj2 = Object.values(sameInFirstArray2);

    if(keyObj1.length > keyObj2.length) {
        var biggestKey = keyObj1.length;
    } else {
        var biggestKey = keyObj2.length;
    }

    for(var i=0; i<biggestKey; i++) {
        if(!(keyObj1[i] == keyObj2[i] && JSON.stringify(valueObj1[i]) == JSON.stringify(valueObj2[i]))) {
            updated.push(valueObj1[i]);
        }
    }

    return updated;
}

function differenceInFirstArray(array1, array2, compareField) {
    return array1.filter(function (current) {
        return array2.filter(function (current_b) {
            return current_b[compareField] === current[compareField];
        }).length === 0;
    });
}
export function sameInFirstArray(array1, array2, compareField) {
    return array1.filter(function (current) {
        return array2.filter(function (current_b) {
            return current_b[compareField] === current[compareField];
        }).length !== 0;
    });
}
