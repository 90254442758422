import React, {Component} from 'react';
import {Router, browserHistory} from 'react-router';
import {withRouter, Route, Link} from 'react-router-dom';
import {connect} from 'react-redux';

// Components
import Demo from './Demo'
import GeneralInfoComponent from './GeneralInfo'
import TableManagementComponent from './TableManagement'
import ReservationManagementComponent from './ReservationManagement'
import ReservationFeeInquiryComponent from './ReservationFeeInquiry'

// styles
import styleStore from '../../../Styles/Components/Store.css'
class AdministratorComponent extends Component{
  constructor(){
    super();

    this.checkAdmin = this.checkAdmin.bind(this);
  }

  componentDidMount() {
      if (this.props.location.pathname === '/store/administrator/tableManagement') {
          this.checkAdmin();
      }
  }

  componentDidUpdate(prevProps) {
      if (this.props.location.pathname !== prevProps.location.pathname
        && this.props.location.pathname === '/store/administrator/tableManagement'
      ) {
          this.checkAdmin();
      }
  }

  checkAdmin() {
      const account = this.props.author.account;

      if (!(account && account.type === 10)) {
          alert('접근 권한이 없습니다.');
          this.props.history.push('/store/administrator/generalInfo');
      }
  }

  render(){
    return(
      <div style={{backgroundColor: '#eceff2'}}>
        <Route path={'/store/administrator/demo'} exact={false} strict={false} component={Demo}/>
        <Route path={'/store/administrator/generalInfo'} exact={false} strict={false} component={GeneralInfoComponent}/>
        <Route path={'/store/administrator/tableManagement'} exact={false} strict={false} component={TableManagementComponent}/>
        <Route path={'/store/administrator/reservationManagement'} exact={false} strict={false} component={ReservationManagementComponent}/>
        <Route path={'/store/administrator/reservationFeeInquiry'} exact={false} strict={false} component={ReservationFeeInquiryComponent}/>
      </div>
    )
  }
}

export default connect((state) => {
    return {
        author: state.data.account.author,
    };
})(withRouter(AdministratorComponent))
