import React, {Component} from 'react';
import { withRouter, Route, Link } from 'react-router-dom'
import {connect} from 'react-redux';
import moment from 'moment';
import _ from 'underscore';
import * as Const from '../../../../Data/const';

// styles
import styleSeatList from '../../../Styles/Components/SeatList.css'
import { action } from '@/Data'
import { dateUtil, string, parseUrlParameter } from '@/Utils'
import DatePicker from "react-datepicker";

class SeatList extends Component{
  constructor(props){
    super(props);
    this.state = {
      storeTables: [],
      row: 9,
      timeSection: [],
      selectedPlace: {},
      selectedFloor: {},
      //selectedTime: 1,
      selectedDate: new Date(),

      isNameSensorOn: true,

      allCount: 0,
      allPeople: 0,
      tableConfig: [
          { title: '좌석No.', isChecked: true },
          { title: '시간', isChecked: true },
          { title: '이름', btn: '이름 별표', isChecked: true },
          { title: '인원', btn: '인원 표기', isChecked: true },
          { title: '메뉴', btn: '메뉴 표기', isChecked: true },
      ],

      isLunch: false,
      isDinner: false,

      isFullScreen: false,
    }

    this.getStoreValues = this.getStoreValues.bind(this);
    this.getStoreReservationTotal = this.getStoreReservationTotal.bind(this);
    this.handleRowSetting = this.handleRowSetting.bind(this);
    this.setSelectedTime = this.setSelectedTime.bind(this);
    this.handleNameSensor = this.handleNameSensor.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);

    this.handlePrint = this.handlePrint.bind(this);
    this.handleExcel = this.handleExcel.bind(this);
    this.setExcelQuery = this.setExcelQuery.bind(this);
  }

  componentDidMount() {
    this.setState({
        store: this.props.store.store || {},
        brand: this.props.store.brand || {},
    }, () => {
      let newState = {};

      newState['startTime'] = dateUtil.getTimeStringToDate(this.state.store['lunchStartTime']);
      newState['endTime'] = dateUtil.getTimeStringToDate(this.state.store['dinnerEndTime']);

      this.setState(newState);

      this.getStoreValues();
      setInterval(this.getStoreValues, Const.intervalTime);
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.author !== prevProps.author
      //|| this.props.selectedTime !== prevProps.selectedTime
    || this.props.reservationUpdated !== prevProps.reservationUpdated
    || this.props.store_place !== prevProps.store_place
      || prevState.startTime !== this.state.startTime
      || prevState.endTime !== this.state.endTime
) {
      this.setState({
        store: this.props.store.store,
        brand: this.props.store.brand
      }, () => {
          this.getStoreValues();
      })
    }
  }

  getStoreValues() {
    if (!this.state.store.storeId || !Object.keys(this.props.store_place).length) {
      return;
    }
    const params = parseUrlParameter.parse(this.props.location.search);

    let selectedDate = this.props.selectedDate || new Date(params.date) || new Date();

    this.setState({
        selectedPlace: this.props.store_place || {},
        selectedFloor: this.props.store_place_floor || {},
      selectedDate: selectedDate,
     // selectedTime: this.props.selectedTime || 1,
      timeSection: [
        {
            index: 0,
          name: '전체',
          startTime: this.state.store.lunchStartTime ? this.state.store.lunchStartTime.substr(0,5) : '-',
          endTime: this.state.store.dinnerEndTime ? this.state.store.dinnerEndTime.substr(0,5) : '-',
          src: '/Assets/img/ic-all.png',
          isChecked: true
        }, {
              index: 1,
          name: '점심',
          startTime: this.state.store.lunchStartTime ? this.state.store.lunchStartTime.substr(0,5) : '-',
          endTime: this.state.store.lunchEndTime ? this.state.store.lunchEndTime.substr(0,5) : '-',
          src: '/Assets/img/sun.png',
          isChecked: false
        }, {
              index: 2,
          name: '저녁',
          startTime: this.state.store.dinnerStartTime ? this.state.store.dinnerStartTime.substr(0,5) : '-',
          endTime: this.state.store.dinnerEndTime ? this.state.store.dinnerEndTime.substr(0,5) : '-',
          src: '/Assets/img/sun.png',
          isChecked: false
        }
      ],
    }, () => {
//        console.log(this.state.startTime);
      let params = {
        count: 100,
        fk_storeId: this.state.store.storeId,
        fk_storePlaceId: this.state.selectedPlace.storePlaceId,
        fk_storePlaceFloorId: this.state.selectedFloor.storePlaceFloorId,
        reservationStartTime: dateUtil.toISOString(dateUtil.format('dash', this.state.selectedDate)
          + ' ' + (dateUtil.format('HH:mm:ss', this.state.startTime))) ,
        reservationEndTime: dateUtil.toISOString(dateUtil.format('dash', this.state.selectedDate)
        + ' ' + dateUtil.isDayBreak(dateUtil.format('HH:mm:ss', this.state.endTime))),
        order: 'tableNo',
        orderMethod: 'asc',
      }

        this.props.dispatch(action.store.setTimeForSearch({
            startTime: dateUtil.format('HH:mm:ss', this.state.startTime),
            endTime: dateUtil.format('HH:mm:ss', this.state.endTime)
        }));

        this.props.dispatch(action.store_table.getList(params)).then((res) => {
          const storeReservations = [];
          // 여기서 테이블 값 바꿔주고 순서도 tableNo 값으로 정렬

          const filteredStoreTables = (res || []).filter((item) => {
              if (item.store_reservation && item.store_reservation.length > 0) {
                  item.store_reservation.forEach((reservation) => {
                      const convertedStoreTable = JSON.parse(JSON.stringify(item.store_table));
                      convertedStoreTable.store_reservation = reservation;
                      convertedStoreTable.reservedAt = reservation.reservedAt;
                      storeReservations.push(convertedStoreTable);
                  });
              } else {
                  return true;
              }
          }).map((item) => {
              return JSON.parse(JSON.stringify(item.store_table));
          });

          const result = filteredStoreTables.concat(storeReservations).sort((a, b) => {
              const aJSON = JSON.parse(a.tableInfo);
              const bJSON = JSON.parse(b.tableInfo);
              if (aJSON.tableInfo > bJSON.tableInfo) {
                  return 1;
              }
              if (aJSON.tableInfo < bJSON.tableInfo) {
                  return -1;
              }
              // a must be equal to b
              return 0;
          });


        this.setState({
            storeTables: result,
            row: result.length >= 9 ? 9 : result.length
        });
      });
      this.getStoreReservationTotal();
    });
  }

  getStoreReservationTotal() {
    let params = {
        storeId: this.state.store.storeId,
        storePlaceId: this.state.selectedPlace.storePlaceId,
        /*createdFrom: new Date(dateUtil.format('dash', this.state.selectedDate)
            + ' ' + dateUtil.format('HH:mm:ss', this.state.startTime)).toISOString(),
        createdTo: new Date(dateUtil.format('dash', this.state.selectedDate)
            + ' ' + dateUtil.isDayBreak(dateUtil.format('HH:mm:ss', this.state.endTime))).toISOString(),*/
        createdFrom: dateUtil.toISOString(dateUtil.format('dash', this.state.selectedDate)
            + ' ' + dateUtil.format('HH:mm:ss', this.state.startTime)),
        createdTo: dateUtil.toISOString(dateUtil.format('dash', this.state.selectedDate)
            + ' ' + dateUtil.isDayBreak(dateUtil.format('HH:mm:ss', this.state.endTime))),
        reservationStatus: 0
    }
      this.props.dispatch(action.store_reservation.getTotalByDate(params)).then((res) => {
          this.setState({
              allCount: res && res[0] ? res[0].allCount : 0,
              allPeople: res && res[0] ? res[0].allPeople : 0
          });
      })
  }

  handleRowSetting(index) {
      let newState = this.state.tableConfig;
      newState[index].isChecked = !newState[index].isChecked;
      this.setState({
          tableConfig: newState
      });
  }

  setSelectedTime(index) {
    let newState = {};
    if (index === 1) {
      newState['startTime'] = dateUtil.getTimeStringToDate(this.state.store['lunchStartTime']);
      newState['endTime'] = dateUtil.getTimeStringToDate(this.state.store['lunchEndTime']);
      this.setState(newState);

      this.setState({
        isLunch: true,
        isDinner: false,
      })
    } else if (index === 2) {
      newState['startTime'] = dateUtil.getTimeStringToDate(this.state.store['dinnerStartTime']);
      newState['endTime'] = dateUtil.getTimeStringToDate(this.state.store['dinnerEndTime']);
      this.setState(newState);

      this.setState({
        isLunch: false,
        isDinner: true,
      })
    }
  }

  handleNameSensor() {
    this.setState({
      isNameSensorOn: !this.state.isNameSensorOn
    })
  }

  handleTimeChange(time, field) {
    let newState = {};
    newState[field] = time;
    this.setState(newState);
    this.setState({
      isLunch: false,
      isDinner: false,
    }, () => {
      this.getStoreValues();
    })
  }

  handlePrint() {
    if ((screen.availHeight || screen.height-30) <= window.innerHeight) {
      this.setState({ isFullScreen: true }, () => {
        window.print();
        if (this.state.isFullScreen) {
          this.handleFullScreen(document.body);
      }
      })
    } else {
      this.setState({ isFullScreen: false }, () => {
        window.print();
      })
    }
  }

  handleExcel() {
    // if ((screen.availHeight || screen.height-30) <= window.innerHeight) {
    //   this.setState({ isFullScreen: true }, () => {
    //     this.setExcelQuery();
    //
    //     if (this.state.isFullScreen) {
    //       this.handleFullScreen(document.body);
    //     }
    //   })
    // } else {
    //   this.setState({ isFullScreen: false }, () => {
    //     this.setExcelQuery();
    //   })
    // }
  }

  handleFullScreen(element) {
    let requestMethod = element.requestFullScreen || element.webkitRequestFullScreen || element.mozRequestFullScreen || element.msRequestFullScreen;
    if (requestMethod) {
      requestMethod.call(element);
    } else if (typeof window.ActiveXObject !== "undefined") { // Older IE.
      let wscript = new ActiveXObject("WScript.Shell");

      if (wscript !== null) {
        wscript.SendKeys("{F11}");
      }
    }
  }


  setExcelQuery() {
    if (this.state.timeSection.length > 0) {
      const query = {
        fk_storeId: this.state.store.storeId,
        fk_storePlaceId: this.state.selectedPlace.storePlaceId,
        fk_storePlaceFloorId: this.state.selectedFloor.storePlaceFloorId,
        reservationStartTime: dateUtil.toISOString(dateUtil.format('dash', this.state.selectedDate)
          + ' ' + dateUtil.format('HH:mm:ss', this.state.startTime)),
        reservationEndTime: dateUtil.toISOString(dateUtil.format('dash', this.state.selectedDate)
          + ' ' + dateUtil.isDayBreak(dateUtil.format('HH:mm:ss', this.state.endTime))),
        order: 'tableNo',
        orderMethod: 'asc',
      }

      return window.open('/store-table/excel?' + Object.keys(query).map((key) => {
        return key + '=' + query[key];
      }).join('&'))
    }
    return window.open('/store/reservation');
  }

  render(){
    let renderTableHeader = this.state.tableConfig.map((row, index) => {
        let colCount = this.state.tableConfig.filter((item) => {
          return item.isChecked;
        }).length;
        return row.isChecked  ? <li key={index} style={{ width: (100 / colCount) + '%' }}>{row.title}</li> : null;
    });

    let renderTableRows = (type, bool) => {
        return this.state.storeTables.map((table, index) => {
            const tableConfig = this.state.tableConfig;
            const colCount = this.state.tableConfig.filter((item) => {
                return item.isChecked ;
            }).length;
            let previousTableId = index === 0 ? null : this.state.storeTables[index - 1].storeTableId;
            const renderList = (
                <ul key={index} className={styleSeatList.row}>
                    {
                        tableConfig.map((row, index) => {
                            let rowItem = null;
                            if (index === 0) rowItem = <li key={index} style={{ width: (100 / colCount) + '%' }}>{previousTableId !== table.storeTableId ? table.tableNo : null}</li>
                            if (index === 1) rowItem = <li key={index} style={{ width: (100 / colCount) + '%' }}>{table.store_reservation ? dateUtil.format('HH:mm', table.store_reservation.reservedAt) : ''}</li>
                            if (index === 2) rowItem = <li key={index} style={{ width: (100 / colCount) + '%' }}>
                              {table.store_reservation && bool ? string.sensorName(table.store_reservation.store_customer.name) : ''}
                              {table.store_reservation && !bool ? table.store_reservation.store_customer.name : ''}
                            </li>
                            if (index === 3) rowItem = <li key={index} style={{ width: (100 / colCount) + '%' }}>{table.store_reservation ? (table.store_reservation.numberOfPeople || 0) + '명' : ''}</li>
                            if (index === 4) rowItem = <li key={index} style={{ width: (100 / colCount) + '%' }}>{table.store_reservation ? (table.store_reservation.store_menu || []).map((menu) => {
                                return menu.menu
                            }).join(',') : ''}</li>

                            return row.isChecked  ? rowItem: null
                        })
                    }
                </ul>
            )

            if (type === 'left') {
                return index + 1 <= this.state.row ? renderList : null;
            } else if (type === 'right') {
                return index + 1 > this.state.row ? renderList : null;
            }
        })
    }

    let renderTableBtnSetting = this.state.tableConfig.map((row, index) => {
        return row.btn ? <div key={index} className={styleSeatList.btn + ((this.state.tableConfig[index].isChecked && index !== 2)|| this.state.isNameSensorOn? ' ' + styleSeatList.active : '')}
                              onClick={index !== 2 ? () => this.handleRowSetting(index) : this.handleNameSensor}>{row.btn}</div> : null
      })

      let renderTableListLeft = this.state.isNameSensorOn ? renderTableRows('left', true) : renderTableRows('left', false);

      let renderTableListRight = this.state.isNameSensorOn ? renderTableRows('right', true) : renderTableRows('right', false);

      // let setExcelQuery = () => {
      //     if (this.state.timeSection.length > 0) {
      //         const query = {
      //             fk_storeId: this.state.store.storeId,
      //             fk_storePlaceId: this.state.selectedPlace.storePlaceId,
      //             fk_storePlaceFloorId: this.state.selectedFloor.storePlaceFloorId,
      //             reservationStartTime: dateUtil.toISOString(dateUtil.format('dash', this.state.selectedDate)
      //                 + ' ' + dateUtil.format('HH:mm:ss', this.state.startTime)),
      //             reservationEndTime: dateUtil.toISOString(dateUtil.format('dash', this.state.selectedDate)
      //                 + ' ' + dateUtil.isDayBreak(dateUtil.format('HH:mm:ss', this.state.endTime))),
      //             order: 'tableNo',
      //             orderMethod: 'asc',
      //         }
      //         return '/store-table/excel?' + Object.keys(query).map((key) => {
      //             return key + '=' + query[key];
      //         }).join('&');
      //     }
      //     return '/store/reservation';
      // }

    return(
        <div>
          <div className={styleSeatList.leftContent}>
            <p className={styleSeatList.topBox}><strong>좌석별</strong> 리스트</p>
            <div className={styleSeatList.middleBox}>
              <div className={styleSeatList.timeSelect}>
                <p className={styleSeatList.selectTitle}>시간</p>
                <div>
                  <div className={styleSeatList.timeBox}>
                    <DatePicker
                      selected={this.state.startTime}
                      onChange={(time) => this.handleTimeChange(time, 'startTime')}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      dateFormat="HH:mm"
                    />
                  </div>~
                  <div className={styleSeatList.timeBox}>
                    <DatePicker
                      selected={this.state.endTime}
                      onChange={(time) => this.handleTimeChange(time, 'endTime')}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      dateFormat="HH:mm"
                    />
                  </div>
                </div>
                <div>
                  <p className={styleSeatList.timeBtn} onClick={() => this.setSelectedTime(1)} style={{color: this.state.isLunch ? '#5b9fdc' : null}}>
                    {this.state.isLunch ? <img className={styleSeatList.timeIcon} src="/Assets/img/sun.png"/> : <img className={styleSeatList.timeIcon} src="/Assets/img/ic-sun-inactive.png"/>}
                    점심
                  </p>
                  <p className={styleSeatList.timeBtn} onClick={() => this.setSelectedTime(2)} style={{color: this.state.isDinner ? '#5b9fdc' : null}}>
                    {this.state.isDinner ? <img className={styleSeatList.timeIcon} src="/Assets/img/ic-moon.png"/> : <img className={styleSeatList.timeIcon} src="/Assets/img/ic-moon-inactive.png"/>}
                    저녁
                  </p>
                </div>
              </div>
              <div className={styleSeatList.rowSelect}>
                <p className={styleSeatList.selectTitle}>행개수</p>
                <div>
                  <p className={styleSeatList.arrowIcon} onClick={() => this.state.row > 1 ? this.setState({row: this.state.row-1}) : null}><img src="/Assets/img/arr-before-s.png"/></p>
                  <p className={styleSeatList.rowBox}>
                    {this.state.row} 행
                  </p>
                  <p className={styleSeatList.arrowIcon} onClick={() => this.setState({row: this.state.row >= this.state.storeTables.length ? this.state.row : this.state.row + 1})}><img src="/Assets/img/arr-next-s.png"/></p>
                </div>
              </div>
              <div className={styleSeatList.infoSelect}>
                  {renderTableBtnSetting}
              </div>
            </div>
            <div className={styleSeatList.bottomBox}>
              <div onClick={this.handlePrint}><img className={styleSeatList.funcIcon} src="/Assets/img/ic-print.png"/>인쇄</div>
              <div onClick={this.setExcelQuery}><img className={styleSeatList.funcIcon} src="/Assets/img/ic-excel.png"/>엑셀 저장</div>
            </div>
          </div>
          <div className={styleSeatList.rightContent} id="newWindow">
            <div className={styleSeatList.rightTitle}>
              <p className={styleSeatList.dateTime}>
                {moment(this.state.selectedDate).format('YYYY년 MM월 DD일 (dd)')} {dateUtil.format('HH:mm', this.state.startTime)} ~ {dateUtil.format('HH:mm', this.state.endTime)}
              </p>
              <p className={styleSeatList.listNum}>총 예약 {this.state.allCount}건/{this.state.allPeople}명</p>
            </div>
            <div className={styleSeatList.seatList} id={styleSeatList.printArea}>
              <div className={styleSeatList.leftList + ' ' + styleSeatList.tableList}>
                <ul>
                    {renderTableHeader}
                </ul>
                {renderTableListLeft}
              </div>
              <div className={styleSeatList.rightList + ' ' + styleSeatList.tableList}>
                <ul>
                    {renderTableHeader}
                </ul>
                {renderTableListRight}
              </div>
            </div>
          </div>
        </div>
    )
  }
}

export default connect((state) => {
  return {
    author: state.data.account.author,
    store: state.data.store.store,
    store_place: state.data.store_place.store_place,
    store_place_floor: state.data.store_place_floor.store_place_floor,
    selectedDate: state.data.store_reservation.selectedDate,
    selectedTime: state.data.store_reservation.selectedTime,
    reservationUpdated: state.data.store_reservation.reservationUpdated,
  }
})(withRouter(SeatList))
